import React, { useEffect, useState } from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { Dialog } from "@material-ui/core";
import { clone } from "shared/utility";
import CustomInput from "components/CustomInput/CustomInputValidate";
import { required } from "shared/validation";
import Button from "../../../components/CustomButtons/Button";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Datetime from "react-datetime"
import Table from "components/Table/Table.jsx";
import { Typography } from "@material-ui/core";
import Notification from "components/Notification/Notification";

const CourtTime = (props) => {

    const initialData = {
        date: "",
        time: "12:00 AM",
    };
    const [openForm, setOpenForm] = useState(false);
    const [action, setAction] = useState(false);
    const [index, setIndex] = useState(-1);
    const [openAskBeforeDelete, setOpenAskBeforeDelete] = useState(false);
    const { updateDataObj } = props;
    const [formData, setFormData] = useState(initialData);
    const [hour, setHour] = useState('12');
    const [minutes, setMinutes] = useState('00');
    const [ampm, setAMPM] = useState('AM');
    const refData = props.formData.court_times ? props.formData.court_times : [];
    const [notification, setNotification] = useState("");

    const checkValidity = () => {
        const time = formData.time ? formData.time.trim() : "";
        const date = formData.date ? formData.date.trim() : "";
        if (time && date) {
            return true;
        }
        return false;
    };

    console.log('refdata', refData);
    return (
        <div style={{ width: "100%" }}>
            {props.action !== "History View" && (
                <GridItem xs={12} md={12} sm={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            {props.canEdit() &&
                            <Button
                                color="primary"
                                onClick={() => {
                                    setAction("Add");
                                    setFormData(initialData);
                                    setIndex(-1);
                                    setOpenForm(true);
                                }}
                            >
                                <i className="fas fa-plus"></i> ADD COURT DATE/TIME
                            </Button>}
                        </GridItem>
                    </GridContainer>
                </GridItem>
            )}
            {Array.isArray(refData) && (
                <GridItem xs={12} md={12}>
                    <h3>Court Date/Time</h3>
                        <Table 
                            tableHeaderColor="primary"
                            tableHead={[`#`, "Date", "Time","Actions"]}
                            tableData={refData.map((r,i) => {
                                return [
                                    `${i + 1}`, r.date, r.time, <div>
                                        <>
                                                {props.action !== "History View" && props.canEdit() && (
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            setFormData(clone(r));
                                                            setIndex(i);
                                                            setAction("Edit");
                                                            setOpenForm(true);
                                                            if (r.time) {
                                                                const arr = r.time.split(':');
                                                                const new_hour = arr[0];
                                                                const new_minute = arr[1].split(' ')[0];
                                                                const new_ampm = arr[1].split(' ')[1];
                                                                setHour(new_hour);
                                                                setMinutes(new_minute);
                                                                setAMPM(new_ampm);
                                                            }
                                                            
                                                        }}
                                                    >
                                                        <i className="fas fa-edit"></i> EDIT
                                                    </Button>
                                                )}
                                            </>,
                                            <>
                                                {props.action !== "History View" && props.canDelete() && (
                                                    <Button
                                                        color="danger"
                                                        onClick={() => {
                                                            setIndex(i);
                                                            setOpenAskBeforeDelete(true);
                                                        }}
                                                    >
                                                        <i className="fas fa-trash"></i> DELETE
                                                    </Button>
                                                )}
                                            </>
                                    </div>
                                ]
                            })}
                        />
                </GridItem>
            )}
            {openForm && (
                <Dialog
                    open={openForm}
                    onClose={() => {
                        setOpenForm(false);
                    }}
                    fullWidth={true}
                    maxWidth="md"
                >
                    <div className="dialog-wrapper" style={{minHeight:500}}>
                        <h3>Court Date/Time</h3>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4}>
                                <Datetime
                                    inputProps={{
                                        placeholder: "Date"
                                    }}
                                    closeOnSelect={true}
                                    defaultValue={formData.date}
                                    onChange={(e) => {
                                        const newData = clone(formData);
                                        if (typeof e === 'object') {
                                            const stringDate = e.format('MM/DD/YYYY');
                                            // use stringDate to set value
                                            newData.date = stringDate;
                                            setFormData(newData);
                                            props.setChangesMade(true);
                                            return;

                                        }
                                        // use e to set value if it is a string
                                        newData.date = e;
                                        props.setChangesMade(true);
                                        setFormData(newData);
                                    }}
                                    timeFormat={false}
                                // value={value}
                                />
                            </GridItem>
                            <GridItem xs={12} />
                            <GridItem xs={4} sm={4} md={4}>
                                <CustomSelect
                                    label="Hour"
                                    options={['01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
                                        '11', '12']}
                                    choose={(e, n) => {
                                        setHour(e);
                                        props.setChangesMade(true);
                                        setFormData({ date: formData.date, time: `${e}:${minutes} ${ampm}` })
                                    }}
                                    default={hour}
                                />
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4}>
                                <CustomSelect
                                    label="Minutes"
                                    options={["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"]}
                                    choose={(e, n) => {
                                        setMinutes(e);
                                        props.setChangesMade(true);
                                        setFormData({ date: formData.date, time: `${hour}:${e} ${ampm}` })
                                    }}
                                    default={minutes}
                                />
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4}>
                                <CustomSelect
                                    label="AM/PM"
                                    options={['AM', 'PM']}
                                    choose={(e, n) => {
                                        setAMPM(e);
                                        props.setChangesMade(true);
                                        setFormData({ date: formData.date, time: `${hour}:${minutes} ${e}` })
                                    }}
                                    default={ampm}
                                />
                            </GridItem>
                        </GridContainer>
                        <hr />
                        {action === "Add" && (
                            <Button
                                color="success"
                                className="mr-20"
                                onClick={() => {
                                    if (!checkValidity()) {
                                        return setNotification('Date and time are both required');
                                    } else {
                                        setNotification('')
                                        updateDataObj('court_times', refData.concat([formData]));
                                        setOpenForm(false);
                                        console.log(formData)
                                    }
                                }}
                            >
                                ADD
                            </Button>
                        )}
                        {action === "Edit" && (
                            <Button
                                color="primary"
                                className="mr-20"
                                onClick={() => {
                                    if (checkValidity()) {
                                        console.log(formData)
                                        updateDataObj(
                                            'court_times',
                                            refData.map((dr, j) => {
                                                if (j === index) {
                                                    return clone(formData);
                                                }
                                                return dr;
                                            })
                                        );

                                        console.log(formData)
                                        setOpenForm(false);
                                        console.log(formData)
                                    }
                                }}
                            >
                                UPDATE
                            </Button>
                        )}
                        <Button
                            color="white"
                            onClick={() => {
                                setOpenForm(false);
                            }}
                        >
                            CANCEL
                        </Button>
                        {notification && (
                            <h4 style={{ color: 'red' }}>Date and Time are both required.</h4>
                        )}
                    </div>
                </Dialog>
            )}
            {openAskBeforeDelete && (
                <Dialog
                    maxWidth="sm"
                    fullWidth={true}
                    open={openAskBeforeDelete}
                    onClose={() => {
                        setOpenAskBeforeDelete(false);
                    }}
                >
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <div className="dialog-wrapper text-center">
                                <h4>Are you sure you want to delete this Court Date/Time?</h4>
                                <Button
                                    color="danger"
                                    className="mr-20"
                                    onClick={() => {
                                        updateDataObj(
                                            "court_times",
                                            refData.filter((dr, j) => {
                                                if (j === index) return false;
                                                return true;
                                            })
                                        );
                                        props.setChangesMade(true);
                                        setOpenAskBeforeDelete(false);
                                    }}
                                >
                                    OK DELETE
                                </Button>
                                <Button
                                    color="white"
                                    onClick={() => {
                                        setOpenAskBeforeDelete(false);
                                    }}
                                >
                                    CANCEL
                                </Button>
                            </div>
                        </GridItem>
                    </GridContainer>
                </Dialog>
            )}
        </div>
    );
};

export default CourtTime;