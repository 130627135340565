import { put, select } from "redux-saga/effects";
import axios from "../axios/axios-crm";
import * as actions from "../actions";
import { clone } from "shared/utility";
import client_axios from "../axios/axios-client";
import moment from "moment";

export function* getDefendantsSaga(action) {
	yield put(actions.getDefendantsStart());
	try {
		let url = "/defendants?q=get";
		if (action.data) {
			const fields = Object.keys(action.data);
			for (let i = 0; i < fields.length; i++) {
				const f = fields[i];
				if (action.data[f]) url += `&${f}=${action.data[f]}`;
			}
		}
		const response = yield axios.get(url);
		yield put(actions.getDefendantsSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getDefendantsFail(e.response.data));
			}
		}
		yield put(actions.getDefendantsFail(e));
	}
}

export function* getLeadsSaga(action) {
	yield put(actions.getLeadsStart());
	try {
		let url = "/leads?q=get";
		if (action.data) {
			const fields = Object.keys(action.data);
			for (let i = 0; i < fields.length; i++) {
				const f = fields[i];
				if (action.data[f]) url += `&${f}=${action.data[f]}`;
			}
		}
		const response = yield axios.get(url);
		yield put(actions.getLeadsSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getLeadsFail(e.response.data));
			}
		}
		yield put(actions.getLeadsFail(e));
	}
}

export function* createDefendantSaga(action) {
	yield put(actions.createDefendantStart());
	try {
		const newData = clone(action.data);
		['front_id_photo','back_id_photo','front_credit_card_photo',
		'back_credit_card_photo','defendant_photos','defendant_holding_id_photo',
		'ex_parent_1_children','ex_parent_2_children','ex_parent_3_children',
		'proof_of_income','proof_of_address','selfie_photo','defendant_holding_power_photo',
		'rreferences','lead_data'
		].forEach(f => {
			if (newData[f]) {
				newData[f] = JSON.stringify(newData[f]);
			}
		})

		newData.rreferences = JSON.stringify({});

		const response = yield axios.post("/defendant", newData);
		const defendant_id = response.data.insertId;

		if (action.bookingData) {
			if (action.bookingData.length > 0) {
				for (let i = 0; i < action.bookingData.length; i++) {
					const booking = clone(action.bookingData[i]);
					booking.defendant_id = defendant_id;
					Object.keys(booking).forEach(field => {
						if (typeof booking[field] === 'object') {
							booking[field] = JSON.stringify(booking[field]);
						}
					})
					console.log('booking call', booking);
					const bookingCall = yield axios.post("/booking", booking);
				}
			}
		}


		if (action.indemnitorData) {
			if (action.indemnitorData.length > 0) {
				for (let i = 0; i < action.indemnitorData.length; i++) {
					const indemnitor = clone(action.indemnitorData[i]);
					indemnitor.defendant_id = defendant_id;
					Object.keys(indemnitor).forEach(field => {
						if (typeof indemnitor[field] === 'object') {
							indemnitor[field] = JSON.stringify(indemnitor[field]);
						}
					})
					const indemnitorCall = yield axios.post("/indemnitor", indemnitor);
				}
			}
		}

		if (action.arrestData) {
			if (action.arrestData.length > 0) {
				for (let i = 0; i < action.arrestData.length; i++) {
					const arrest = clone(action.arrestData[i]);
					Object.keys(arrest).forEach(field => {
						if (typeof arrest[field] === 'object') {
							arrest[field] = JSON.stringify(arrest[field]);
						}
					})
					arrest.defendant_id = defendant_id;
					const arrestCall = yield axios.post("/arrest", arrest);
				}
			}
		}
		
		if (action.shouldSendSMS === "Yes") {
			yield put(
				actions.sendSmsToDefendant({
					phone: action.data.phone,
					id: response.data.insertId,
					ind: false,
					defendant_id: response.data.insertId
				})
			);
		} 

		console.log('phot ?', action.photosData, newData.lead_data);
		let photosCount = 0;
		if (action.photosData) {
			for (let [key, value] of action.photosData.entries()) {
				console.log('phot ? key', key, value);
				if (key && value) photosCount++;
			}
		} 
		if ((newData.lead_data === 0 || !newData.lead_data) && photosCount) {
			console.log('phot ? here');
			yield put(
				actions.uploadDefendantPhotos({
					id: response.data.insertId,
					photos: action.photosData,
				})
			);
		}


		yield put(actions.createDefendantSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.createDefendantFail(e.response.data));
			}
		}
		yield put(actions.createDefendantFail(e));
	}
}

export function* getDefendantSaga(action) {
	yield put(actions.getDefendantStart());
	try {
		const response = yield axios.get(`/defendant?id=${action.data}`);
		yield put(actions.getDefendantSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getDefendantFail(e.response.data));
			}
		}
		yield put(actions.getDefendantFail(e));
	}
}

export function* updateDefendantSaga(action) {
	yield put(actions.updateDefendantStart());
	try {
		const newData = clone(action.data);
		['front_id_photo','back_id_photo','front_credit_card_photo',
		'back_credit_card_photo','defendant_photos','defendant_holding_id_photo',
		'ex_parent_1_children','ex_parent_2_children','ex_parent_3_children',
		'proof_of_income','proof_of_address','selfie_photo','defendant_holding_power_photo',
		'rreferences','lead_data'
		].forEach(f => {
			if (newData[f]) {
				newData[f] = JSON.stringify(newData[f]);
			}
		})
		newData.rreferences = JSON.stringify({});

		const response = yield axios.put("/defendant", newData);
		if (action.photoUpdate && action.photoUpdateElement) {
			yield put(actions.uploadDefendantPhotos(action.photoUpdate, action.photoUpdateElement));
		}
		// ** Remove autosend document for signing **
		// const pdfData = action.pdfData;
		// if (pdfData) {
		// 	if (pdfData.sendPdf === true && pdfData.booking_id !== false) {
		// 		yield put(
		// 			actions.sendSinglePdf({
		// 				type: pdfData.type,
		// 				defendant_id: pdfData.defendant_id,
		// 				booking_id: pdfData.booking_id,
		// 				indemnitor_id: pdfData.indemnitor_id
		// 			})
		// 		);
		// 	}
		// }
		
		yield put(actions.updateDefendantSuccess(response.data));

	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.updateDefendantFail(e.response.data));
			}
		}
		yield put(actions.updateDefendantFail(e));
	}
}

export function* deleteDefendantSaga(action) {
	yield put(actions.deleteDefendantStart());
	try {
		const response = yield axios.delete(`/defendant?id=${action.data}`);
		yield put(actions.deleteDefendantSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.deleteDefendantFail(e.response.data));
			}
		}
		yield put(actions.deleteDefendantFail(e));
	}
}

export function* sendSmsToDefendantSaga(action) {
	yield put(actions.sendSmsToDefendantStart());
	try {
		const response = yield client_axios.post("/sendOTP", action.data);
		yield put(actions.sendSmsToDefendantSuccess(action.data.phone));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.sendSmsToDefendantFail(e.response.data));
			}
		}
		yield put(actions.sendSmsToDefendantFail(e));
	}
}

export function* resendSmsToDefendantSaga(action) {
	yield put(actions.sendSmsToDefendantStart());
	try {
		const response = yield client_axios.post("/resendOTP", action.data);
		yield put(actions.resendSmsToDefendantSuccess(action.data.phone));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.resendSmsToDefendantFail(e.response.data));
			}
		}
		yield put(actions.resendSmsToDefendantFail(e));
	}
}

export function* uploadDefendantPhotosSaga(action) {
	yield put(actions.uploadDefendantPhotosStart(action.fieldName ? action.fieldName : ''));
	try {
		const response = yield axios.post(
			`/defendant/upload?id=${action.data.id}`,
			action.data.photos
		);
		yield put(actions.uploadDefendantPhotosSuccess());
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.uploadDefendantPhotosFail(e.response.data));
			}
		}
		yield put(actions.uploadDefendantPhotosFail(e));
	}
}

export function * downloadDefendantPhotoSaga(action) {
	yield put(actions.downloadDefendantPhotoStart())
	try {
		// const response = yield axios.get(`/defendants/download`, action.data);
		const response = yield axios({
			url: `/defendant/download`,
			method: "POST",
			data: action.data,
			responseType: "blob",
		})
		console.log(response)
		const url = URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', action.data.name); //or any other extension
		document.body.appendChild(link);
		link.click();
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				// return yield put(actions.uploadDefendantPhotosFail(e.response.data));
			}
		}
		// yield put(actions.uploadDefendantPhotosFail(e));
	}
}


export function* getDefendantUsersSaga(action) {
	yield put(actions.getDefendantUsersStart());
	try {
		const response = yield axios.get('/defendant_users?id='+action.data);
		console.log(response)
		yield put(actions.getDefendantUsersSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
			  return yield put(actions.getDefendantUsersFail(e.response.data));
			}
		  }
		yield put(actions.getDefendantUsersFail(e));
	}
  }
  
  export function* createDefendantUsersSaga(action) {
	yield put(actions.createDefendantUsersStart());
	try {
		console.log('got here')
		const response = yield axios.post('/defendant_users', action.data);
		yield put(actions.createDefendantUsersSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
			  return yield put(actions.createDefendantUsersFail(e.response.data));
			}
		  }
		yield put(actions.createDefendantUsersFail(e));
	}
  }
  
  export function* deleteDefendantUsersSaga(action) {
	yield put(actions.deleteDefendantUsersStart());
	try {
		const response = yield axios.delete('/defendant_users?id='+action.data);
		yield put(actions.deleteDefendantUsersSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
			  return yield put(actions.deleteDefendantUsersFail(e.response.data));
			}
		  }
		yield put(actions.deleteDefendantUsersFail(e));
	}
  }

	
