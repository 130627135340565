import { put } from 'redux-saga/effects';
import axios from '../axios/axios-settings';
import * as actions from '../actions/settings';

export function* getSmsConfigSaga(action) {
  yield put(actions.getSmsConfigStart());
  try {
      const response = yield axios.get('/sms_config');
      yield put(actions.getSmsConfigSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getSmsConfigFail(e.response.data));
          }
        }
      yield put(actions.getSmsConfigFail(e));
  }
}

export function* updateSmsConfigSaga(action) {
  yield put(actions.updateSmsConfigStart());
  try {
      const response = yield axios.put('/sms_config', action.data);
      yield put(actions.updateSmsConfigSuccess(response.data));
      yield put(actions.getSmsConfig());
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.updateSmsConfigFail(e.response.data));
          }
        }
      yield put(actions.updateSmsConfigFail(e));
  }
}

export function* deleteSmsConfigSaga(action) {
  yield put(actions.deleteSmsConfigStart());
  try {
      const response = yield axios.delete('/sms_config?id='+action.data);
      yield put(actions.deleteSmsConfigSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.deleteSmsConfigFail(e.response.data));
          }
        }
      yield put(actions.deleteSmsConfigFail(e));
  }
}

export function* createSmsConfigSaga(action) {
  yield put(actions.createSmsConfigStart());
  try {
      const response = yield axios.post('/sms_config', action.data);
      yield put(actions.createSmsConfigSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.createSmsConfigFail(e.response.data));
          }
        }
      yield put(actions.createSmsConfigFail(e));
  }
}
