const devStatus = 'live'; // local_live, local_stage, live, stage (NOTE: local means turning on Chrome Redux Tools)
const is_local_server = false; // set to true for localhost development

let domain_url;

if (is_local_server) {
    domain_url = "http://localhost:3000";
} else {
    if (devStatus.indexOf('stage') !== -1) {
        domain_url = 'https://staging.aabail.crs-consulting.com';
    } else if (devStatus.indexOf('live') !== -1) {
        domain_url = 'https://portal.greatestbailbonds.com';
    }
}

export const domain = domain_url;
export default devStatus;
