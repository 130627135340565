import { sortedIndex, sortedLastIndex } from "lodash";
import React from "react";
import ReactTable from "react-table";
import { formatDate2, formatDate3 } from "shared/utility";

const ArrestsList = (props) => {
	const { arrestData, setFormData, setAction, setOpenForm } = props;

	const dataList = arrestData
		? arrestData.map((l, key) => {
				// map arrests to table rows
				return {
					key: key + 1,
					id: l.id,
					court: l.court,
					county: l.county,
					arrest_when: formatDate2(l.arrest_when),
					bail_post_date: formatDate2(l.bail_post_date),
					disposition: l.disposition,
					previous_bail: l.previous_bail,
					with_whom: l.with_whom,
					surety: l.surety,
					charges: l.charges,
				};
		  })
		: [];

	const columns =
		props.action === "Client Edit"
			? [
					{
						Header: "#",
						accessor: "key",
					},
					{
						Header: "Arrest Date",
						accessor: "arrest_when",
					},
			  ]
			: [
					{
						Header: "Court",
						accessor: "court",
					},
					{
						Header: "Arrest Date",
						accessor: "arrest_when",
					},
					{
						Header: "Bail Post Date",
						accessor: "bail_post_date",
					},
					{
						Header: "Disposition",
						accessor: "disposition",
					},
					{
						Header: "Charges",
						accessor: "charges",
					},
			  ];

	return (
		<div className="table-adjust2 table-scroll">
			<ReactTable
				filterable
				className="-striped -highlight"
				showPaginationTop
				showPaginationBottom
				resizable={false}
				defaultPageSize={10}
				pageSizeOptions={[10, 20, 30]}
				data={dataList}
				columns={columns}
				getTrProps={(state, rowInfo) => {
					if (rowInfo && rowInfo.row) {
						return {
							onClick: (e) => {
								if (rowInfo) {
									if (rowInfo.original) {
										props.setIndex(rowInfo.index);
										setAction("Edit");

										setFormData(rowInfo.original);
										setOpenForm(true);
									}
								}
							},
						};
					} else {
						return {};
					}
				}}
			/>
		</div>
	);
};

export default ArrestsList;
