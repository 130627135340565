import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    error: null,
    success: null,
    data: [],
    indemnitor: null,
    indemnitorData: null,
    loading_upload_indemnitor_photos: null,
    error_upload_indemnitor_photos: null,
    success_upload_indemnitor_photos: null
}

// MESSAGES 
const clearIndemnitorsMessages = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

// SET INDEMNITOR DATA 
const setIndemnitorData = (state, action) => {
    return updateObject(state, {
        indemnitorData: action.data
    })
}

// GET_INDEMNITORS

const getIndemnitorsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getIndemnitorsSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully gotten indemnitors',
        data: action.response.data
    })
}

const getIndemnitorsFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_INDEMNITOR
const getIndemnitorStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loading: true
    })
}
const getIndemnitorSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully gotten Indemnitor',
        indemnitorData: action.response,
        loading: false
    })
}
const getIndemnitorFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading: false
    })
}

// CREATE_INDEMNITOR

const createIndemnitorStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const createIndemnitorSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully created indemnitor',
    })
}

const createIndemnitorFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// UPDATE_INDEMNITOR

const updateIndemnitorStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const updateIndemnitorSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully updated indemnitor'
    })
}

const updateIndemnitorFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// DELETE_INDEMNITOR

const deleteIndemnitorStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const deleteIndemnitorSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully deleted indemnitor'
    })
}

const deleteIndemnitorFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// UPLOAD_INDEMNITOR_PHOTOS
const uploadIndemnitorPhotosStart = (state, action) => {
    return updateObject(state, {
        loading_upload_indemnitor_photos: action.fieldName,
        error_upload_indemnitor_photos: null,
        success_upload_indemnitor_photos: null
    })
}
const uploadIndemnitorPhotosSuccess = (state, action) => {
    return updateObject(state, {
        loading_upload_indemnitor_photos: false,
        success_upload_indemnitor_photos: 'Successfully uploaded photos',
        loading_upload_indemnitor_photos: ''
    })
}
const uploadIndemnitorPhotosFail = (state, action) => {
    return updateObject(state, {
        loading_upload_indemnitor_photos: false,
        error_upload_indemnitor_photos: action.err,
        loading_upload_indemnitor_photos: ''
    })
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_INDEMNITORS_MESSAGES:
            return clearIndemnitorsMessages(state, action);

        case actionTypes.GET_INDEMNITORS_START:
            return getIndemnitorsStart(state, action);
        case actionTypes.GET_INDEMNITORS_SUCCESS:
            return getIndemnitorsSuccess(state, action);
        case actionTypes.GET_INDEMNITORS_FAIL:
            return getIndemnitorsFail(state, action);

        case actionTypes.GET_INDEMNITOR_START:
            return getIndemnitorStart(state, action);
        case actionTypes.GET_INDEMNITOR_SUCCESS:
            return getIndemnitorSuccess(state, action);
        case actionTypes.GET_INDEMNITOR_FAIL:
            return getIndemnitorFail(state, action);

        case actionTypes.CREATE_INDEMNITOR_START:
            return createIndemnitorStart(state, action);
        case actionTypes.CREATE_INDEMNITOR_SUCCESS:
            return createIndemnitorSuccess(state, action);
        case actionTypes.CREATE_INDEMNITOR_FAIL:
            return createIndemnitorFail(state, action);

        case actionTypes.UPDATE_INDEMNITOR_START:
            return updateIndemnitorStart(state, action);
        case actionTypes.UPDATE_INDEMNITOR_SUCCESS:
            return updateIndemnitorSuccess(state, action);
        case actionTypes.UPDATE_INDEMNITOR_FAIL:
            return updateIndemnitorFail(state, action);

        case actionTypes.DELETE_INDEMNITOR_START:
            return deleteIndemnitorStart(state, action);
        case actionTypes.DELETE_INDEMNITOR_SUCCESS:
            return deleteIndemnitorSuccess(state, action);
        case actionTypes.DELETE_INDEMNITOR_FAIL:
            return deleteIndemnitorFail(state, action);

        case actionTypes.UPLOAD_INDEMNITOR_PHOTOS_START:
            return uploadIndemnitorPhotosStart(state, action);
        case actionTypes.UPLOAD_INDEMNITOR_PHOTOS_SUCCESS:
            return uploadIndemnitorPhotosSuccess(state, action);
        case actionTypes.UPLOAD_INDEMNITOR_PHOTOS_FAIL:
            return uploadIndemnitorPhotosFail(state, action);

        case actionTypes.SET_INDEMNITOR_DATA:
            return setIndemnitorData(state, action);

        default: return state;
    }
};

export default reducer;