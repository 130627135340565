import React from "react";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Heading from "components/Heading/Heading.jsx";
import Timeline from "components/Timeline/Timeline.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import sapimage from "assets/img/sapimage.PNG";

import { stories } from "variables/general.jsx";

class SAPPage extends React.Component {
  render() {
    return (
      <div>
        <img src={sapimage}  alt="..." />
      </div>
    );
  }
}

export default SAPPage;
