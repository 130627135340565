import * as actionTypes from "./actionTypes";

// SEND_FCS 
export const sendFcs = (data, data_type) => {
    console.log(data, data_type)
    return {
        type: actionTypes.SEND_FCS,
        data,
        data_type
    }
}
export const sendFcsStart = () => {
    return {
        type: actionTypes.SEND_FCS_START
    }
}
export const sendFcsSuccess = (response) => {
    return {
        type: actionTypes.SEND_FCS_SUCCESS,
        response
    }
}
export const sendFcsFail = (err) => {
    return {
        type: actionTypes.SEND_FCS_FAIL,
        err
    }
}
export const getDocumentPdf = (data) => {
    return {
        responseType: 'blob',
        type: actionTypes.GET_DOCUMENT_PDF,
        data
    }
}
export const getDocumentPdfStart = () => {
    return {
        type: actionTypes.GET_DOCUMENT_PDF_START
    }
}
export const getDocumentPdfSuccess = (response) => {
    return {
        type: actionTypes.GET_DOCUMENT_PDF_SUCCESS,
        response
    }
}
export const getDocumentPdfFail = (err) => {
    return {
        type: actionTypes.GET_DOCUMENT_PDF_FAIL,
        err
    }
}
export const setDocumentPdf = (data) => {
    return {
        type: actionTypes.SET_DOCUMENT_PDF,
        data
    }
}

// SEND SINGLE PDF
export const sendSinglePdf = (data) => {
    return {
        type: actionTypes.SEND_SINGLE_PDF,
        data
    }
}
export const sendSinglePdfStart = () => {
    return {
        type: actionTypes.SEND_SINGLE_PDF_START
    }
}
export const sendSinglePdfSuccess = (response) => {
    return {
        type: actionTypes.SEND_SINGLE_PDF_SUCCESS,
        response
    }
}
export const sendSinglePdfFail = (err) => {
    return {
        type: actionTypes.SEND_SINGLE_PDF_FAIL,
        err
    }
}

// SEND_CC 
export const sendCc = (data) => {
    return {
        type: actionTypes.SEND_CC,
        data
    }
}
export const sendCcStart = () => {
    return {
        type: actionTypes.SEND_CC_START
    }
}
export const sendCcSuccess = (response) => {
    return {
        type: actionTypes.SEND_CC_SUCCESS,
        response
    }
}
export const sendCcFail = (err) => {
    return {
        type: actionTypes.SEND_CC_FAIL,
        err
    }
}

// SEND_REMINDER 
export const sendReminder = (data) => {
    return {
        type: actionTypes.SEND_REMINDER,
        data
    }
}
export const sendReminderStart = () => {
    return {
        type: actionTypes.SEND_REMINDER_START
    }
}
export const sendReminderSuccess = (response) => {
    return {
        type: actionTypes.SEND_REMINDER_SUCCESS,
        response
    }
}
export const sendReminderFail = (err) => {
    return {
        type: actionTypes.SEND_REMINDER_FAIL,
        err
    }
}

// DOWNLOAD_DOCUMENT 
export const downloadDocument = (data) => {
    return {
        type: actionTypes.DOWNLOAD_DOCUMENT,
        data
    }
}
export const downloadDocumentStart = () => {
    return {
        type: actionTypes.DOWNLOAD_DOCUMENT_START
    }
}
export const downloadDocumentSuccess = (response) => {
    return {
        type: actionTypes.DOWNLOAD_DOCUMENT_SUCCESS,
        response
    }
}
export const downloadDocumentFail = (err) => {
    return {
        type: actionTypes.DOWNLOAD_DOCUMENT_FAIL,
        err
    }
}