import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DefendantsList from "./DefendantsList";
import DefendantsForm from "./DefendantsForm";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { clone } from "shared/utility";
import Button from "../../../components/CustomButtons/Button";
import { Dialog } from "@material-ui/core";
import DefendantModal from "./DefendantsModal";
import ReminderModal from "./ReminderModal";
import DialogTitle from "@material-ui/core/DialogTitle";
import Notification from "../../../components/Notification/Notification";
import { getUserPerms } from 'shared/authValidation';

const Defendants = (props) => {
	const [openedModal, setOpenedModal] = useState(false);
	const [defendantData, setDefendantData] = useState(null);
	const [bookingData, setBookingData] = useState([]);
	const [allBookings, setAllBookings] = useState([]);
	const [bookingOptions, setBookingOptions] = useState([]);
	const [sendInfo, setSendInfo] = useState('');
	const [sendType, setSendType] = useState('');
	const [sendName, setSendName] = useState('');
	const [indemnitorData, setIndemnitorData] = useState([]);
	const [arrestData, setArrestData] = useState([]);
	const [sendOptions, setSendOptions] = useState([]);
	const [sendOptionsObj, setSendOptionsObj] = useState([]);
	const [openedReminderModal, setOpenedReminderModal] = useState(false);
	const [reminderData, setReminderData] = useState(null);
	const [sendNotification, setSendNotification] = useState({
		messageType: "",
		additional_info: "",
		send_to: "",
	})

	const [action, setAction] = useState("Add");
	const [filters, setFilters] = useState({
		first_name: "",
		last_name: "",
		phone: "",
		power_of_attorney_number: "",
		booking: "",
		case_number: "",
		arrest_when: "",
		bail_post_date: "",
		name: "",
		surety: "",
		docs_sent: "",
		pending_signature: ""
	});
	const [offset, setOffset] = useState(0);
	const [rows, setRows] = useState(10);
	const [sortData, setSortData] = useState({
		sortBy: "date_created",
		sortDirection: "DESC",
	});

	const [id, setId] = useState(null);

	// Tracking updateDataObj
	const [unsavedWarning, setUnsavedWarning] = useState(false);
	const [openedWaning, setOpenedWarning] = useState(false);
	const [smsNotification, setSmsNotification] = useState(false);
	const [listIndemnitors, setListIndemnitors] = useState(null);

	// Error/Success Modal
	const [openedMessageModal, setOpenedMessageModal] = useState(false);

	const { getDefendant, setIndemnitorData2 } = props;

	useEffect(() => {
		console.log('error', props.error)
		if (props.error) {
			if (props.error === 'Not authorized to access') {
				window.location.href = '/';
			}
		}
		if (props.success || props.error) {
			if (
				props.success !== "Successfully got defendant" &&
				props.success !== "Successfully got defendants"
			) {
				setOpenedMessageModal(true);
			}
		}
	}, [props.success, props.error]);

	// Delete defendant modal
	const [openedDefendantModal, setOpenedDefendantModal] = useState(false);
	const userPerms = getUserPerms();

	const getData = () => {
		const params = {
			offset,
			rows,
			sort_by: sortData.sortBy,
			sort_direction: sortData.sortDirection,
		};
		Object.keys(filters).forEach((field) => {
			if (filters[field]) {
				params[field] = filters[field];
			}
		});
		props.getDefendants(params);
		props.getIndemnitors(null);
	};

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		let reset = true;
		for (let key in filters) {
			if (filters[key] !== "") {
				reset = false;
			}
		}
		reset && getData();
	}, [filters]);

	useEffect(() => {
		getData();
	}, [sortData, rows, offset]);

	useEffect(() => {
		if (props.defendant) {
			setDefendantData(props.defendant);

			if (props.defendant.length > 0) {
				setId(props.defendant[0].id);
			}
		}
	}, [props.defendant]);

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={12}>
				<h3>
					<strong>CRM</strong>
				</h3>
				<Button
					color="primary"
					onClick={() => {
						setAction("Add");
						setBookingData([]);
						setIndemnitorData([]); // reset local state indemnitorData
						setIndemnitorData2([]); // reset redux indemnitorData
						setArrestData([]);
						setDefendantData(null);
						setOpenedModal(true);
					}}
				>
					<i className="fas fa-plus"></i> ADD
				</Button>
				<DefendantsList
					data={
						props.defendantsData
							? props.defendantsData.data
								? props.defendantsData.data
								: []
							: []
					}
					count={
						props.defendantsData
							? props.defendantsData.count
								? props.defendantsData.count
								: 0
							: 0
					}
					filters={filters}
					setFilters={(f, val) => {
						const newFilters = clone(filters);
						newFilters[f] = val;
						setFilters(newFilters);
					}}
					resetFilters={() => {
						setFilters({
							first_name: "",
							last_name: "",
							phone: "",
							power_of_attorney_number: "",
							booking: "",
							case_number: "",
							arrest_when: "",
						});
					}}
					getDefendants={getData}
					offset={offset}
					setOffset={setOffset}
					rows={rows}
					setRows={setRows}
					sortBy={sortData.sortBy}
					sortDirection={sortData.sortDirection}
					setSortData={setSortData}
					setAction={setAction}
					setDefendantData={setDefendantData}
					setOpenedModal={setOpenedModal}
					getDefendant={props.getDefendant}
					allIndemnitors={props.allIndemnitors}
					openedReminderModal={setOpenedReminderModal}
					setOpenedReminderModal={setOpenedReminderModal}
					setSendOptions={setSendOptions}
					setSendOptionsObj={setSendOptionsObj}
					allBookings={allBookings}
					setAllBookings={setAllBookings}
					setBookingOptions={setBookingOptions}
					getBookings={props.getBookings}
					getDefendantUsers={props.getDefendantUsers}
					reminderData={reminderData}
					setReminderData={setReminderData}
					listIndemnitors={listIndemnitors}
					setListIndemnitors={setListIndemnitors}
				/>
			</GridItem>

			<Dialog
				open={openedModal}
				onClose={() => {
					if (unsavedWarning) {
						setOpenedWarning(true);
					} else {
						setOpenedModal(false);
					}
				}}
				fullScreen
			>
				<div className="dialog-wrapper" style={{ marginBottom: '90px' }}>
					<DefendantsForm
						action={action}
						defendantData={defendantData}
						bookingData={bookingData}
						setBookingData={setBookingData}
						createDefendant={props.createDefendant}
						getDefendant={props.getDefendant}
						setOpenedDefendantModal={setOpenedDefendantModal}
						updateDefendant={props.updateDefendant}
						deleteDefendant={props.deleteDefendant}
						smsNotification={smsNotification}
						setSmsNotification={setSmsNotification}
						close={() => {
							if (unsavedWarning) {
								setOpenedWarning(true);
							} else {
								setOpenedModal(false);
							}
						}}
						submit={() => {
							setOpenedModal(false);
							setOpenedWarning(false);
						}}
						setUnsavedWarning={setUnsavedWarning}
						showCloseBtn
						defendantID={id}
						indemnitorData={indemnitorData}
						setIndemnitorData={setIndemnitorData}
						arrestData={arrestData}
						setArrestData={setArrestData}
						sendSmsToDefendant={props.sendSmsToDefendant}
						error={props.error}
						success={props.success}
						closeFinal={() => {
							setOpenedModal(false);
						}}
						downloadDefendantPhoto={props.downloadDefendantPhoto}
						uploadDefendantPhotos={props.uploadDefendantPhotos}
						getAdminUsers={props.getAdminUsers}
						userData={props.userData}
						getDefendantUsers={props.getDefendantUsers}
						createDefendantUsers={props.createDefendantUsers}
						deleteDefendantUsers={props.deleteDefendantUsers}
						defendantUsers={props.defendantUsers}
						userPerms={userPerms}

						sendNotification={sendNotification}
						setSendNotification={setSendNotification}

						sendOptions={sendOptions}
						sendOptionsObj={sendOptionsObj}
						setSendOptions={setSendOptions}
						setSendOptionsObj={setSendOptionsObj}

						allBookings={allBookings}
						setAllBookings={setAllBookings}
						bookingOptions={bookingOptions}

						sendInfo={sendInfo}
						setSendInfo={setSendInfo}
						sendType={sendType}
						setSendType={setSendType}
						sendName={sendName}
						setSendName={setSendName}

						getBookings={props.getBookings}
						theBookingData={props.theBookingData}
						sendSmsOnly={props.sendSmsOnly}
						listIndemnitors={listIndemnitors}
						setListIndemnitors={setListIndemnitors}
					/>
				</div>
			</Dialog>

			<DefendantModal
				deleteDefendant={props.deleteDefendant}
				id={id}
				open={openedDefendantModal}
				close={() => setOpenedDefendantModal(false)}
			/>
			<ReminderModal
				reminderData={reminderData}
				open={openedReminderModal}
				close={() => setOpenedReminderModal(false)}
				sendReminder={props.sendReminder}
				downloadDocument={props.downloadDocument}
			/>

			{/* Unsaved data warning modal */}
			<Dialog
				open={openedWaning}
				onClose={() => {
					setOpenedWarning(false);
					setUnsavedWarning(false);
				}}
				maxWidth="md"
				fullWidth={true}
			>
				<DialogTitle>
					<div style={{ textAlign: "center" }}>
						Are you sure? Any unsaved data will be lost.
					</div>
				</DialogTitle>

				<div style={{ textAlign: "center", padding: 20 }}>
					<Button
						color="danger"
						style={{ marginRight: 10 }}
						onClick={() => {
							setOpenedWarning(false);
							setUnsavedWarning(false);
							setOpenedModal(false);
						}}
					>
						OK, CLOSE
					</Button>
					<Button
						color="white"
						style={{ marginRight: 10 }}
						onClick={() => {
							setOpenedWarning(false);
						}}
					>
						CANCEL
					</Button>
				</div>
			</Dialog>

			{/* Error, success modal */}
			{props.success !== "Successfully got indemnitors" &&
				(props.success || props.error) && (
					<Notification
						open={openedMessageModal}
						message={<h4>{`${props.success || props.error}`}</h4>}
						close={() => {
							if (props.error) {
								if (props.error === 'Not authorized to access') {
									window.location.href = '/';
								}
							}
							console.log('closing', props.success, defendantData);
							setOpenedMessageModal(false);
							getData();
							if (defendantData && (props.success === 'Successfully updated defendant' && !props.error)) {
								if (defendantData.length > 0) {
									if (defendantData[0].id) {
										getDefendant(defendantData[0].id);
									}
								}
							}
							props.clearCRMMessages();
						}}
					/>
				)}
		</GridContainer>
	);
};

const mapStateToProps = (state) => {
	return {
		error: state.crm.error,
		success: state.crm.success,
		defendantsData: state.crm.defendantsData,
		defendant: state.crm.defendant,
		allIndemnitors: state.crm.indemnitorsData,
		userData: state.user.userList,
		defendantUsers: state.crm.defendantUsers,
		theBookingData: state.crm_booking.data
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		clearCRMMessages: () => {
			dispatch(actions.clearCRMMessages());
		},
		getDefendants: (params) => {
			dispatch(actions.getDefendants(params));
		},
		createDefendant: (data, bookingData, defendantData, arrestData, shouldSendSMS, photoData) => {
			dispatch(
				actions.createDefendant(data, bookingData, defendantData, arrestData, shouldSendSMS, photoData)
			);
		},
		deleteDefendant: (id) => {
			dispatch(actions.deleteDefendant(id));
		},
		getDefendant: (id) => {
			dispatch(actions.getDefendant(id));
		},
		updateDefendant: (data, photoUpdate, photoUpdateElement) => {
			dispatch(actions.updateDefendant(data, photoUpdate, photoUpdateElement));
		},
		setIndemnitorData2: (data) => {
			dispatch(actions.setIndemnitorData(data));
		},
		sendSmsToDefendant: (data) => {
			dispatch(actions.sendSmsToDefendant(data));
		},
		sendSmsOnly: (data) => {
			dispatch(actions.sendSmsOnly(data));
		},
		downloadDefendantPhoto: (data) => {
			dispatch(actions.downloadDefendantPhoto(data));
		},
		uploadDefendantPhotos: (data, fieldName) => {
			dispatch(actions.uploadDefendantPhotos(data, fieldName));
		},
		getIndemnitors: (defendant_id) => {
			dispatch(actions.getIndemnitors(defendant_id));
		},
		getAdminUsers: () => {
			dispatch(actions.getAdminUsers());
		},
		getDefendantUsers: (defendant_id) => {
			dispatch(actions.getDefendantUsers(defendant_id));
		},
		createDefendantUsers: (data) => {
			dispatch(actions.createDefendantUsers(data));
		},
		deleteDefendantUsers: (id) => {
			dispatch(actions.deleteDefendantUsers(id));
		},
		sendReminder: (data) => {
			dispatch(actions.sendReminder(data));
		},
		downloadDocument: (data) => {
			dispatch(actions.downloadDocument(data));
		},
		getBookings: (id) => {
			dispatch(actions.getBookings(id));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Defendants);
