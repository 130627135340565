import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import IndemnitorsList from "./IndemnitorsList";
import IndemnitorsForm from "./IndemnitorsForm";
import { initialData } from './IndemnitorsModel';
import { Dialog } from '@material-ui/core';
import { clone } from "shared/utility";
import { Close, Cached } from '@material-ui/icons';
import Notification from '../../../components/Notification/Notification';

const Indemnitors = (props) => {

    const [openForm, setOpenForm] = useState(false);
    const [action, setAction] = useState('Add');
    const [formData, setFormData] = useState({});
    const [index, setIndex] = useState(-1);
    const [notification, setNotification] = useState('');
    const { defendant_id, indemnitorData, setIndemnitorData } = props;
    const [sendSMS, setSendSMS] = useState("Yes");
    const [photos, setPhotos] = useState(new FormData());
    const [requiredFieldsComplete, setRequiredFieldsComplete] = useState(false)

	const appendFile= (input, blob_field) => {
		if (blob_field) {
			const newFormData = new FormData();
			const length = 1;
			const name = blob_field;
			for (let i = 0; i < length; ++i) {
				newFormData.append(blob_field, input, blob_field+'.png');
			}
			for (let [key, value] of photos.entries()) {
				if (key !== name) {
					newFormData.append(key, value);
				}
			}
			setPhotos(newFormData);
		} else {
			const newFormData = new FormData();
			const { length } = input.target.files;
			for (let i = 0; i < length; ++i) {
				newFormData.append(input.target.name, input.target.files[i]);
			}
			for (let [key, value] of photos.entries()) {
				if (key !== input.target.name) {
					newFormData.append(key, value);
				}
			}
			setPhotos(newFormData);
		}
		
	};

    useEffect(() => {
        console.log(requiredFieldsComplete)
        if (props.success === 'Successfully gotten indemnitors') {
            setIndemnitorData(props.data);
            props.clearIndemnitorMessages();
            
        } else if (requiredFieldsComplete === true && props.success) {
            if (props.is_client) {
                setNotification("Information Saved, Check your email to Digitally Sign.");
            } else {
                if (props.success !== 'Successfully gotten Indemnitor') setNotification(props.success);
            }
            props.clearIndemnitorMessages();
        } else if (requiredFieldsComplete !== true && props.success === 'Successfully created indemnitor' ||
            props.success === 'Successfully updated indemnitor' ||
            props.success === 'Successfully deleted indemnitor') {
            setNotification(props.success);
            props.clearIndemnitorMessages();
        }
    }, [props.success, props.error]);

    useEffect(() => {
        if (defendant_id && props.action === "Client Edit") {
            props.getIndemnitors(defendant_id);
        }
    }, [defendant_id])

    useEffect(() => {
        if (defendant_id) props.getIndemnitors(defendant_id);
    }, [])

    useEffect(() => {
        if (props.is_client) {
            setOpenForm(true);
            setFormData(props.indemnitorData);
            setAction('Edit');
        }
    }, [])

    const updateDataObj = (field, value) => {
        const newObj = clone(formData);
        newObj[field] = value;
        setFormData(newObj)
    }

    return <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
            <h3 style={{ display: 'inline-block' }} className="mr-20">Indemnitors</h3>
            {props.action === 'Edit' && <Button
                justIcon
                round
                simple
                onClick={() => {
                    props.getIndemnitors(defendant_id);
                }}
                color="info"
            >
                <Cached />
            </Button>}<br />
            {props.canEdit() &&
                <Button color="primary"
                onClick={() => {
                    setAction('Add');
                    console.log('initialData', initialData);
                    setPhotos(new FormData());
                    props.setIndemnitorData2(null);
                    setFormData(clone(initialData));
                    setOpenForm(true);
                }}
            ><i className="fas fa-plus"></i> ADD NEW</Button>}

            {!props.is_client &&
                <IndemnitorsList
                    indemnitorData={indemnitorData}
                    setIndemnitorData={setIndemnitorData}
                    setAction={setAction}
                    setFormData={setFormData}
                    setOpenForm={setOpenForm}
                    setIndex={setIndex}
                    action={props.action}
                    getIndemnitor={props.getIndemnitor}
                    setPhotos={setPhotos}
                />
            }
            {openForm && <Dialog 
                            open={openForm}
                            onClose={() => {
                                setOpenForm(false);
                            }}
                            fullScreen
                        >
                            <div>
                                <span
                                    className="close-btn hoverable float-right"
                                    onClick={() => {
                                        setOpenForm(false);
                                    }}
                                >
                                    <Close />
                                </span>
                            </div>
                            <div className="dialog-wrapper2" style={{maxWidth:1200}}>
                                <IndemnitorsForm 
                                    is_client={props.is_client}
                                    bookingData={props.bookingData}
                                    formData={formData}
                                    setFormData={setFormData}
                                    updateDataObj={updateDataObj}
                                    action={action}
                                    close={() => {
                                        setOpenForm(false);
                                    }}
                                    defendant_id={defendant_id}
                                    index={index}
                                    add={(formDat) => {
                                        if (props.action === 'Add') {
                                            setIndemnitorData(indemnitorData.concat([formDat]));
                                            setOpenForm(false)
                                        } else {
                                            console.log('upload photos -->', formDat, photos);
                                            props.createIndemnitor(formDat, 
                                                sendSMS && formDat.phone ? sendSMS : "No",
                                                photos
                                            )
                                        }
                                    }}
                                    update={(formDat, index, pdfData) => {
                                        if (props.action === 'Add') {
                                            setIndemnitorData(indemnitorData.map((bd,i) => {
                                                if (i === index) {
                                                    return formDat;
                                                } else {
                                                    return bd;
                                                }
                                            }));
                                            setOpenForm(false)
                                        } else {
                                            props.updateIndemnitor(formDat, photos, pdfData);
                                        }
                                    }}
                                    delete={(id, index) => {
                                        if (props.action === 'Add') {
                                            setIndemnitorData(indemnitorData.filter((bd,i) => {
                                                if (i === index) return false;
                                                return true;
                                            }))
                                            setOpenForm(false)
                                        } else {
                                            props.deleteIndemnitor(id);
                                        }
                                    }}
                                    sendSMS={sendSMS}
                                    setSendSMS={setSendSMS}
                                    sendSmsToDefendant={props.sendSmsToDefendant}
                                    appendFileNew={appendFile}
                                    photos={photos}
                                    setPhotos={setPhotos}
                                    downloadIndemnitorPhoto={props.downloadIndemnitorPhoto}
                                    updateIndemnitor={props.updateIndemnitor}
                                    requiredFieldsComplete={requiredFieldsComplete}
							        setRequiredFieldsComplete={setRequiredFieldsComplete}
                                    canEdit={props.canEdit}
                                    canDelete={props.canDelete}
                                />
                            </div>
                        </Dialog>}
        </GridItem>
        {notification && <Notification
            open={notification ? true : false}
            close={() => {
                props.getIndemnitors(defendant_id);
                setNotification('');
                if (!props.is_client) {
                    if (formData.id && notification === 'Successfully updated indemnitor') {
                        props.getIndemnitor(formData.id);
                    } else {
                        setOpenForm(false);
                    }
                } else {
                    if (formData.id && notification === 'Successfully updated indemnitor') {
                        props.getIndemnitor(formData.id);
                    }
                }
            }}
            message={<h4>{`${notification}`}</h4>}
        />}
    </GridContainer>
}

const mapStateToProps = state => {
    const b = state.crm_indemnitor;
    return {
        error: b.error,
        success: b.success,
        data: b.data,
        indemnitor: b.indemnitor,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearIndemnitorMessages: () => {
            dispatch(actions.clearIndemnitorMessages());
        },
        getIndemnitors: (defendant_id) => {
            dispatch(actions.getIndemnitors(defendant_id));
        },
        getIndemnitor: (indemnitor_id) => {
            dispatch(actions.getIndemnitor(indemnitor_id));
        },
        setIndemnitorData2: (data) => {
            dispatch(actions.setIndemnitorData(data));
        },
        createIndemnitor: (data, shouldSendSMS, photosData) => {
            console.log('creating', data, photosData);
            dispatch(actions.createIndemnitor(data, shouldSendSMS, photosData));
        },
        updateIndemnitor: (data, photosData, pdfData, element) => {
            dispatch(actions.updateIndemnitor(data, photosData, pdfData, element));
        },
        deleteIndemnitor: (id) => {
            dispatch(actions.deleteIndemnitor(id));
        },
        sendSmsToDefendant: (data) => {
            dispatch(actions.sendSmsToDefendant(data));
        },
        downloadIndemnitorPhoto: data => {
            dispatch(actions.downloadIndemnitorPhoto(data));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Indemnitors);