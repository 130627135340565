import { clone } from "../../../shared/utility";

const save = {};

export const updateCleanup = (cd) => {
    const cdata = clone(cd);
    // quick fixes
    if (cdata.rreferences) {
        delete cdata.rreferences;
    }
    ["ex_parent_1", "ex_parent_2", "ex_parent_3"].forEach((pa) => {
        if (cdata[`${pa}_children`]) {
            cdata[`${pa}_children`] = JSON.stringify(cdata[`${pa}_children`]);
        }
    });
    return cdata;
}

export const extractDefendantSends = (cd) => {
    console.log(cd)
    if (!cd) return {
        sendToOptions: [],
        optionsObj: []
    }
    let sendToOptions = [];
    let optionsObj = [];
    const d = cd;
    if (d.phone) {
        sendToOptions.push(d.first_name + " " + d.last_name);
        optionsObj.push({ id: d.id, name: d.first_name + " " + d.last_name, phone: d.phone, type: 'defendant' })
    };
    if (d.indemnitors) {
        d.indemnitors.map((ind, i) => {
            if (ind.phone) {
                sendToOptions.push(ind.name);
                optionsObj.push({ id: ind.id, name: ind.name, phone: ind.phone, type: 'indemnitor' });
            }
        })
    }
    if (d.landlord_phone) {
        sendToOptions.push(d.landlord_name);
        optionsObj.push({ id: d.id, name: d.landlord_name, phone: d.landlord_phone, type: 'landlord' })
    };
    if (d.employers_phone) {
        sendToOptions.push(d.employed_by);
        optionsObj.push({ id: d.id, name: d.employed_by, phone: d.employers_phone, type: 'employer' })
    };
    if (d.superior_phone) {
        sendToOptions.push(d.superior);
        optionsObj.push({ id: d.id, name: d.superior, phone: d.superior_phone, type: 'superior' })
    };
    if (d.defendants_attorney_phone) {
        sendToOptions.push(d.defendants_attorney_name);
        optionsObj.push({ id: d.id, name: d.defendants_attorney_name, phone: d.defendants_attorney_phone, type: 'attorney' });
    };
    if (d.spouse_phone) {
        sendToOptions.push(d.spouse_name);
        optionsObj.push({ id: d.id, name: d.spouse_name, phone: d.spouse_phone, type: 'spouse' });
    };
    if (d.ex_parent_1_phone) {
        sendToOptions.push(d.ex_parent_1_name);
        optionsObj.push({ id: d.id, name: d.ex_parent_1_name, phone: d.ex_parent_1_phone, type: 'ex-parent' })
    };
    if (d.ex_parent_2_phone) {
        sendToOptions.push(d.ex_parent_2_name);
        optionsObj.push({ id: d.id, name: d.ex_parent_2_name, phone: d.ex_parent_2_phone, type: 'ex-parent' })
    };
    if (d.ex_parent_3_phone) {
        sendToOptions.push(d.ex_parent_3_name);
        optionsObj.push({ id: d.id, name: d.ex_parent_3_name, phone: d.ex_parent_3_phone, type: 'ex-parent' })
    };
    if (d.mother_phone) {
        sendToOptions.push(d.mother_name);
        optionsObj.push({ id: d.id, name: d.mother_name, phone: d.mother_phone, type: 'mother' });
    };
    if (d.father_phone) {
        sendToOptions.push(d.father_name);
        optionsObj.push({ id: d.id, name: d.father_name, phone: d.father_phone, type: 'father' });
    };
    if (d.defendant_brother_phone) {
        sendToOptions.push(d.defendant_brother_name);
        optionsObj.push({ id: d.id, name: d.defendant_brother_name, phone: d.defendant_brother_phone, type: 'brother' });
    };
    if (d.defendant_sister_phone) {
        sendToOptions.push(d.defendant_sister_name);
        optionsObj.push({ id: d.id, name: d.defendant_sister_name, phone: d.defendant_sister_phone, type: 'sister' })
    };
    if (d.bestfriend_phone) {
        sendToOptions.push(d.bestfriend_name);
        optionsObj.push({ id: d.id, name: d.bestfriend_name, phone: d.bestfriend_phone, type: 'friend' })
    };
    if (d.reference_1_phone) {
        sendToOptions.push(d.reference_1_name);
        optionsObj.push({ id: d.id, name: d.reference_1_name, phone: d.reference_1_phone, type: 'reference' })
    };
    if (d.reference_2_phone) {
        sendToOptions.push(d.reference_2_name);
        optionsObj.push({ id: d.id, name: d.reference_2_name, phone: d.reference_2_phone, type: 'reference' })
    };
    if (d.reference_3_phone) {
        sendToOptions.push(d.reference_3_name);
        optionsObj.push({ id: d.id, name: d.reference_3_name, phone: d.reference_3_phone, type: 'reference' })
    };
    if (d.reference_4_phone) {
        sendToOptions.push(d.reference_4_name);
        optionsObj.push({ id: d.id, name: d.reference_4_name, phone: d.reference_4_phone, type: 'reference' })
    };
    console.log(sendToOptions)
    return {
        sendToOptions,
        optionsObj
    }
}

export default save;