import { stateList } from "../../../shared/utility";

const fields = {
	name: {
		type: "input",
		label: "Full Name",
		validations: ["min1"],
		uppercase: true,
		required: true,
		client_required: true,
	},
	date_of_birth: {
		type: "date",
		label: "Date of Birth",
		client_required: true,
	},
	place_of_birth: {
		type: "input",
		label: "Place of Birth",
		validations: ["min1"],
	},
	us_citizen: {
		type: "select",
		label: "US Citizen",
		options: ["Yes", "No"],
	},
	how_long_in_us: {
		type: "input",
		label: "If No (how long in US)",
		validations: ["min1"],
	},
	ss: {
		type: "input",
		label: "SS#",
		validations: ["min1"],
		client_required: true,
	},
	dl: {
		type: "input",
		label: "DL#",
		validations: ["min1"],
		client_required: true,
	},
	dl_state: {
		type: "select",
		label: "Driver's License State",
		options: stateList,
		client_required: true,
	},
	address: {
		type: "input",
		label: "Address",
		validations: ["min1"],
		uppercase: true,
		client_required: true,
	},
	city: {
		type: "input",
		label: "City",
		validations: ["min1"],
		uppercase: true,
		client_required: true,
	},
	state: {
		type: "select",
		label: "Current State",
		options: stateList,
		client_required: true,
	},
	zip: {
		type: "input",
		label: "Zipcode",
		validations: ["min1"]
	},
	country: {
		type: "input",
		label: "Country",
		validations: ["min1"],
	},
	phone: {
		type: "input",
		label: "Mobile Phone",
		validations: [
			"min10",
			"no_letters",
			"max10",
			"no_special_chars",
		],
		required: true,
		client_required: true,
		number_only: true,
		max_length: 10
	},
	home_phone: {
		type: "input",
		label: "Home Phone",
		validations: [
			"min10",
			"no_letters",
			"max10",
			"no_special_chars",
		],
		required: true,
		client_required: true,
	},
	email: {
		type: "input",
		label: "Email",
		validations: ["min1", "email"],
		client_required: true,
	},
	relation_to_defendant: {
		type: "input",
		label: "Relation to Defendant",
		validations: ["min1"],
		client_required: true,
	},
	employed_by: {
		type: "input",
		label: "Employed By",
		validations: ["min1"],
		client_required: true,
	},
	employers_address: {
		type: "input",
		label: "Employer Address",
		validations: ["min1"],
		uppercase: true,
		client_required: true,
	},
	employers_phone: {
		type: "input",
		label: "Employer Phone",
		validations: ["min1"],
		client_required: true,
	},
	occupation: {
		type: "input",
		label: "Occupation",
		validations: ["min1"],
		client_required: true,
	},
	how_long: {
		type: "input",
		label: "How Long",
		validations: ["min1"],
		client_required: true,
	},
	superior: {
		type: "input",
		label: "Superior",
		validations: ["min1"],
		uppercase: true,
		client_required: true,
	},
	monthly_income: {
		type: "input",
		label: "Monthly Income",
		validations: ["min1"],
		client_required: true,
	},
	spouse: {
		type: "input",
		label: "Spouse",
		validations: ["min1"],
		uppercase: true,
		client_required: true,
	},
	spouse_address: {
		type: "input",
		label: "Address",
		validations: ["min1"],
		uppercase: true,
	},
	spouse_phone: {
		type: "input",
		label: "Mobile Phone",
		validations: ["min1"],
		client_required: true,
	},
	spouse_home_phone: {
		type: "input",
		label: "Home Phone",
		validations: ["min1"],
		client_required: true,
	},
	spouse_employed_by: {
		type: "input",
		label: "Employed By",
		validations: ["min1"],
		uppercase: true,
	},
	spouse_employers_address: {
		type: "input",
		label: "Address",
		validations: ["min1"],
		uppercase: true,
	},
	spouse_employers_phone: {
		type: "input",
		label: "Phone",
		validations: ["min1"],
	},
	has_vehicle: {
		type: "radio",
		label: "Do you have a vehicle?",
		options: ["Yes", "No"],
		client_required: true,
	},
	vehicle_make: {
		type: "input",
		label: "Vehicle Make By",
		validations: ["min1"],
		client_required: true,
	},
	model: {
		type: "input",
		label: "Model",
		validations: ["min1"],
		client_required: true,
	},
	year: {
		type: "input",
		label: "Year",
		validations: ["min1"],
		client_required: true,
	},
	color: {
		type: "input",
		label: "Color",
		validations: ["min1"],
		client_required: true,
	},
	lic: {
		type: "input",
		label: "LIC#",
		validations: ["min1"],
		client_required: true,
	},
	registered_owner: {
		type: "input",
		label: "Registered Owner",
		validations: ["min1"],
		uppercase: true,
	},
	vehicle_financed: {
		type: "select",
		label: "Vehicle Financed",
		options: ["Yes", "No"],
	},
	where_financed: {
		type: "input",
		label: "(If yes) Where Financed",
		validations: ["min1"],
	},
	how_much_owned: {
		type: "input",
		label: "(If yes) How much Owed",
		validations: ["min1"],
	},
	real_property: {
		type: "input",
		label: "Real Property",
		validations: ["min1"],
	},
	in_who_name: {
		type: "input",
		label: "In Who's name",
		validations: ["min1"],
		uppercase: true,
	},
	indemnitor_instagram: {
		type: "input",
		label: "Indemnitor Instagram",
		validations: ["min1"],
	},
	rent_own: {
		type: "select",
		label: "Rent/Own",
		options: ["Rent", "Own"],
	},
	lived_at_this_address_for: {
		type: "input",
		label: "Lived at this address for?",
		validations: ["min1"],
		uppercase: true,
	},
	landlord_name: {
		type: "input",
		label: "(If Rent) Landlord Name",
		validations: ["min1"],
		uppercase: true,
	},
	landlord_phone: {
		type: "input",
		label: "(If Rent) Landlord Phone",
		validations: ["min1"],
		uppercase: true,
	},
	front_id_photo: {
		type: "photo",
		label: "Front ID Photo",
		maxCount: 1,
	},
	back_id_photo: {
		type: "photo",
		label: "Back ID Photo",
		maxCount: 1,
	},
	indemnitor_holding_id_photo: {
		type: "photo",
		label: "Close Up Picture Of Indemnitor Holding ID",
		maxCount: 1,
	},
	notes: {
		type: "text",
		label: "Notes",
		validations: ["min1"]
	},
	rreferences: {
		type: "object",
	},
};

["reference_1", "reference_2", "reference_3", "reference_4"].forEach((z) => {
	fields[`${z}_name`] = {
		type: "input",
		label: "Name",
		validations: ["min1"],
		client_required: true,
	};
	fields[`${z}_relationship`] = {
		type: "input",
		label: "Relationship",
		validations: ["min1"],
		client_required: true,
	};
	fields[`${z}_dob_or_age`] = {
		type: "input",
		label: "DOB or Approximate Age",
		validations: ["min1"],
	};
	fields[`${z}_phone`] = {
		type: "input",
		label: "Phone",
		validations: ["min1", "max10", "no_letters"],
		client_required: true,
	};
	fields[`${z}_address`] = {
		type: "input",
		label: "Address",
		validations: ["min1"],
	};
	fields[`${z}_city`] = {
		type: "input",
		label: "City",
		validations: ["min1"],
	};
	fields[`${z}_state`] = {
		type: "select",
		label: "State",
		options: stateList,
	};
	fields[`${z}_country`] = {
		type: "input",
		label: "Country",
		validations: ["min1"],
	};
	fields[`${z}_social_media`] = {
		type: "input",
		label: "Social Media",
		validations: ["min1"],
	};
});

export default fields;

const initialDat = {};
Object.keys(fields).forEach((field) => {
	if (field === "rreferences") {
		initialDat[field] = [];
	} else if (fields[field].type === "photo" || fields[field].type === "file") {
		initialDat[field] = "[]";
	} else {
		initialDat[field] = "";
	}
});

export const initialData = initialDat;
