export const required = value => value ? undefined : 'Required'

export const no_numbers = value => {
  var no_numbers = /[^0-9]/g;
  if (value) {
    if (!value.match(no_numbers)) {
      return 'No numbers';
    }
  }
  return undefined;
}

export const no_special_chars = value => {
  var myRegex = /^[a-z 0-9]+$/i;
  var whitelisted = myRegex.test(value);
  return value && !whitelisted ? "Alphanumeric characters only" : undefined
}

export const no_letters = value => {
  var numbers = /^[-+ 0-9]+$/g;
  if (value) {
    if (!value.match(numbers)) {
      return 'Only Numbers, - and + allowed';
    }
  }
  return undefined;
}

export const min1 = value => {
  if (value) {
    if (value.length < 1) {
      return 'Minimum 1 characters'
    }
  }
  return undefined;
}

export const min2 = value => {
  if (value) {
    if (value.length < 2) {
      return 'Minimum 2 characters'
    }
  }
  return undefined;
}

export const min4 = value => {
  if (value) {
    if (value.length < 4) {
      return 'Minimum 4 characters'
    }
  }
  return undefined;
}

export const min5 = value => {
  if (value) {
    if (value.length < 5) {
      return 'Minimum 5 characters'
    }
  }
  return undefined;
}

export const min6 = value => {
  if (value) {
    if (value.length < 6) {
      return 'Minimum 6 characters'
    }
  }
  return undefined;
}

export const min10 = value => {
  if (value) {
    if (value.length < 10) {
      return 'Minimum 10 characters'
    }
  }
  return undefined;
}

export const max4 = value => {
  if (value) {
    if (value.length > 4) {
      return 'Maximum 4 characters'
    }
  }
  return undefined;
}

export const max10 = value => {
  if (value) {
    if (value.length > 10) {
      return 'Maximum 10 characters'
    }
  }
  return undefined;
}

export const max15 = value => {
  if (value) {
    if (value.length > 15) {
      return 'Maximum 15 characters'
    }
  }
  return undefined;
}

export const max20 = value => {
  if (value) {
    if (value.length > 20) {
      return 'Maximum 20 characters'
    }
  }
  return undefined;
}

export const max50 = value => {
  if (value) {
    if (value.length > 50) {
      return 'Maximum 50 characters'
    }
  }
  return undefined;
}

export const max100 = value => {
  if (value) {
    if (value.length > 100) {
      return 'Maximum 100 characters'
    }
  }
  return undefined;
}

export const max125 = value => {
  if (value) {
    if (value.length > 125) {
      return 'Maximum 125 characters'
    }
  }
  return undefined;
}

export const max250 = value => {
  if (value) {
    if (value.length > 250) {
      return 'Maximum 250 characters'
    }
  }
  return undefined;
}

export const max500 = value => {
  if (value) {
    if (value.length > 500) {
      return 'Maximum 500 characters'
    }
  }
  return undefined;
}

export const email = value => {
    if (value) {
      // const itMatches = value.match(/^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i);

      const itMatches = value.match(/\S+@\S+\.\S+/);

      return value && !itMatches ? 'Invalid email address' : undefined;
    }
    return value;
}
    // value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    //     'Invalid email address' : undefined

export const blacklist = value => {
          var myRegex = /[!#%^*+=[\]'`~;{}|_:<>?]/g;
          var blacklisted = myRegex.test(value);
          return value && blacklisted ? "!#%^*+=[]'`~;{}|_:<>? characters are not allowed" : undefined
  }

export const tel_blacklist = value => {
          var myRegex = /[!@#$%^&*=[\]'`~;,{}|_:<>?]/g;
          var blacklisted = myRegex.test(value);
          return value && blacklisted ? "!#%^*+=[]'`~;{}|_:<>? characters are not allowed" : undefined
  }

export const zipcode_blacklist = value => {
          var myRegex = /[!@#$%^&*=[\]'`~;,{}|_:<>?]/g;
          var blacklisted = myRegex.test(value);
          return value && blacklisted ? "!@#$%^&*=[]'`~;,{}|_:<>? characters are not allowed" : undefined
  }

export const alphanum_whitelist = value => {
          var myRegex = /^[a-z0-9]+$/i;
          var whitelisted = myRegex.test(value);
          return value && !whitelisted ? "Alphanumeric characters only" : undefined
  }

  export const alpha_whitelist = value => {
            var myRegex = /^[a-z \-A-Z]+$/i;
            var whitelisted = myRegex.test(value);
            return value && !whitelisted ? "Alphabet characters only, no characters accents" : undefined
    }

export const num_whitelist = value => {
  if (value || value === 0) {
    var myRegex = /^[0-9+ \-()]+$/i;
    var whitelisted = myRegex.test(value);
    return value && !whitelisted ? "No letters allowed" : undefined
  }
}

export const email_blacklist = value => {
          var myRegex = /[!#$%^&*+=[\]\\';,()/{}|:<>?]/i;
          var blacklisted = myRegex.test(value);
          return value && blacklisted ? "!#$%^&*+=[]\\';,()/{}|:<>? characters only" : undefined
  }

export const not_just_num = value => {
  return value && !isNaN(value) ? "Cannot be made up of only numbers" : undefined
}

export const no_num_start = value => {
  return value && !isNaN(value[0]) ? "Value cannot start with a number" : undefined
}
