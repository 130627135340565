import * as actionTypes from '../actions/actionTypes';
import { updateObject, getParameterByName } from '../../shared/utility';
import setAuthToken from 'shared/setAuthToken';
import jwt_decode from 'jwt-decode';

const initialState = {
  token: localStorage.getItem('token'),
  error: null,
  origin: null,
  loading: false,
  message: '',
  permObj: null,
  loggedInStatus: false
}

const setLoading = (state, action) => {
  return updateObject(state, {
    loading: true
  });
}

const setRedirect = (state, action) => {
  const origin = getParameterByName('o') ? getParameterByName('o') : 'none';
  
  const { token } = action.user;
  localStorage.setItem('token', token);
  setAuthToken(token);
  const decoded = jwt_decode(token);
  return updateObject(state, {
    token: action.payload,
    message: '',
    loading: false,
    origin,
    permObj: decoded.permObj,
    loggedInStatus: true
  });
}

const setLogout = (state, action) => {
  localStorage.removeItem('token');
  //window.location.href = '/pages/login-page';
  return updateObject(state, {
    origin: null});
}

const setMessageError = (state, action) => {
  return updateObject(state, {
    message: 'Username / Password Invalid',
    loading: false
  });
}

// const authReducer = (state = initialState, { type, payload }) => {
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_REQUEST: {
      return setLoading(state, action);
    }
    case actionTypes.AUTH_SUCCESS: {
      return setRedirect(state, action);
      // return { ...state, token: payload };
    }
    case actionTypes.AUTH_FAILURE: {
      return setMessageError(state, action);
      // return { ...state, error: action.payload }
    }
    case actionTypes.AUTH_LOGOUT: {
      return setLogout(state, action);
    }
    default:
      return state;
  }
};

export default authReducer;
