import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  userList: null,
  error: null,
  loading: false,
  syncSuccess: null
}

// GET_ADMIN_USERS
const getAdminUsersStart = (state, action) => {
  return updateObject(state, {
    error: null,
    success: null
  })
}
const getAdminUsersSuccess = (state, action) => {
  return updateObject(state, {
    success: 'Success',
    userList: action.response
  })
}
const getAdminUsersFail = (state, action) => {
  return updateObject(state, {
    error: action.err
  })
}

// CREATE_USER
const createUserStart = (state, action) => {
  return updateObject(state, {
    error: null,
    success: null
  })
}
const createUserSuccess = (state, action) => {
  return updateObject(state, {
    success: 'Successfully created user'
  })
}
const createUserFail = (state, action) => {
  return updateObject(state, {
    error: action.err
  })
}

// DELETE_USER
const deleteUserStart = (state, action) => {
  return updateObject(state, {
    error: null,
    success: null
  })
}
const deleteUserSuccess = (state, action) => {
  return updateObject(state, {
    success: 'Successfully deleted user'
  })
}
const deleteUserFail = (state, action) => {
  return updateObject(state, {
    error: action.err
  })
}

// UPDATE_USER
const updateUserStart = (state, action) => {
  return updateObject(state, {
    error: null,
    success: null
  })
}
const updateUserSuccess = (state, action) => {
  return updateObject(state, {
    success: 'Successfully updated user'
  })
}
const updateUserFail = (state, action) => {
  return updateObject(state, {
    error: action.err
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_USER_START:
      return createUserStart(state, action);
    case actionTypes.CREATE_USER_SUCCESS:
      return createUserSuccess(state, action);
    case actionTypes.CREATE_USER_FAIL:
      return createUserFail(state, action);

    case actionTypes.GET_ADMIN_USERS_START:
      return getAdminUsersStart(state, action);
    case actionTypes.GET_ADMIN_USERS_SUCCESS:
      return getAdminUsersSuccess(state, action);
    case actionTypes.GET_ADMIN_USERS_FAIL:
      return getAdminUsersFail(state, action);

    case actionTypes.DELETE_USER_START:
      return deleteUserStart(state, action);
    case actionTypes.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state, action);
    case actionTypes.DELETE_USER_FAIL:
      return deleteUserFail(state, action);

    case actionTypes.UPDATE_USER_START:
      return updateUserStart(state, action);
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state, action);
    case actionTypes.UPDATE_USER_FAIL:
      return updateUserFail(state, action);

    default: return state;
  }
};

export default reducer;
