import React, { useState, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { clone } from "shared/utility";
import Button from "components/CustomButtons/Button";
import Element from "../Element";
// import { initialData } from "./DefendantsModel";
import defendantModel from "../Defendants/DefendantsModel";
import bookingModel from "../Bookings/BookingsModel";
import indemnitorModel from "../Indemnitors/IndemnitorsModel";
import arrestModel from "../Arrests/ArrestsModel";
import Close from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrestsForm from "../Arrests/ArrestsForm";
// import { Tabs, Tab } from "@material-ui/core";
// import Person from "@material-ui/icons/Person";
// import DateRange from "@material-ui/icons/DateRange";
// import Business from "@material-ui/icons/Business";
// import Delete from "@material-ui/icons/Delete";
// import Edit from "@material-ui/icons/Edit";
import DefendantsForm from "../Defendants/DefendantsForm";
import BookingsForm from "../Bookings/BookingsForm";
import IndemnitorsForm from "../Indemnitors/IndemnitorsForm";

const readonlyModel = (fields) => {
	const newFields = clone(fields);
	for (let key in newFields) {
		newFields[key].readonly = true;
	}
	return newFields;
};

const model = {
	defendant: readonlyModel(defendantModel),
	booking: readonlyModel(bookingModel),
	indemnitor: readonlyModel(indemnitorModel),
	arrest: readonlyModel(arrestModel),
};

export const CRMHistoryForm = (props) => {
	const type = props.history
		? props.history.action.split(" ")[1].toLowerCase()
		: "";

	const [data, setData] = useState(null);

	useEffect(() => {
		if (props.history) {
			setData(props.history.data);
		}
	}, [props.history]);

	const renderElement = (field, model, data) => {
		return <Element field={field} data={data} model={model} />;
	};

	const renderFields = (m) => {
		// return renderElement('first_name')
		return Object.keys(m).map((element, i) => {
			return (
				<GridItem key={i} xs={3} sm={3} md={3}>
					<Element field={element} data={data} model={m} />
				</GridItem>
			);
		});
	};

	return (
		<div>
			{props.showCloseBtn && (
				<div
					className="close-btn hoverable"
					onClick={() => {
						props.close();
					}}
				>
					<Close />
				</div>
			)}
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<h3>
						{
							<div
								style={{
									marginRight: 20,
									marginBottom: 10,
									display: "inline-block",
								}}
								onClick={() => {
									props.close();
								}}
								className="hoverable"
							>
								<Tooltip
									id="tooltip-top-start1"
									title="Back to List"
									placement="top"
								>
									<ArrowBack />
								</Tooltip>
							</div>
						}
						{props.history && `${props.history.action} HISTORY`}
					</h3>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					{type === "defendant" && props.history && data ? (
						<DefendantsForm action="History View" defendantData={[data]} />
					) : null}
					{type === "booking" && props.history && data ? (
						<BookingsForm action="History View" formData={data} />
					) : null}
					{type === "indemnitor" && props.history && data ? (
						<IndemnitorsForm action="History View" formData={data} />
					) : null}

					{type === "arrest" && props.history && data ? (
						<ArrestsForm action="History View" formData={data} />
					) : null}
				</GridItem>
			</GridContainer>
		</div>
	);
};
