import React, {useState} from "react";
import CustomRadio from "components/CustomRadio/CustomRadio";
import Button from "../../../components/CustomButtons/Button";
import GridItem from "components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import TextField from '@material-ui/core/TextField';
import { clone } from "shared/utility";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import moment from "moment";

export const DefendantsFormGeneral = (props) => {
	const { renderElement, updateDataObj } = props;
	return (
		<GridContainer
			className={`${props.action === "History View" && "no-click"}`}
		>
			{props.action !== "Client Edit" && <GridItem xs={12} sm={3} md={3}>
				<CustomSelect
					label="Status"
					options={["New Lead", "Assigned to Agent"]}
					choose={(e, n) => {
						const newData = clone(props.data);
						newData.status = e;
						updateDataObj('status', newData.status);
					}}
					default={props.data.status ? props.data.status: 'New Lead'}
				/>
			</GridItem>}
			{props.action !== "Client Edit" && <GridItem xs={12} sm={9} md={9}></GridItem>}
			<GridItem xs={12} sm={3} md={3}>
				{renderElement("first_name", props.model)}
			</GridItem>
			<GridItem xs={12} sm={3} md={3}>
				{renderElement("middle_name", props.model)}
			</GridItem>
			<GridItem xs={12} sm={3} md={3}>
				{renderElement("last_name", props.model)}
			</GridItem>
			<GridItem xs={12} sm={3} md={3}></GridItem>
			<GridItem xs={12} sm={3} md={3}>
				{renderElement("phone", props.model)}
				{props.action === "Add" && props.data.phone && (
					<CustomRadio
						label="Send form link SMS message to defendant on add, Yes, No"
						options={["Yes", "No"]}
						onChange={(e) => {
							props.setSendSMS(e.target.value);
							const newData = clone(props.data);
							const currentDate = moment().format("MM/DD/YYYY h:mm:ss a");
							newData.link_last_sent = currentDate;
							updateDataObj('link_last_sent', newData.link_last_sent);
						}}
						value={props.data.phone ? props.sendSMS : "No"}
						size="sm"
					/>
				)}
				{props.action === "Edit" && props.data.phone && (
					<Button
						onClick={() => {
							// const newData = clone(props.data);
							// // const currentDate = moment().format("MM/DD/YYYY h:mm:ss a");
							// // newData.link_last_sent = currentDate;
							// // updateDataObj(newData);
							props.sendSmsToDefendant({
								id: props.data.id,
								phone: props.data["phone"],
								ind: false,
								defendant_id: props.data.id
							});
						}}
						color="primary"
					>
						Send SMS
					</Button>
				)}
			</GridItem>
			<GridItem xs={12} sm={3} md={3}>
				{renderElement("home_phone", props.model)}
			</GridItem>
			<GridItem xs={12} sm={3} md={3}>
				{renderElement("date_of_birth", props.model)}
			</GridItem>
			<GridItem xs={12} sm={12} md={12}>
				<br />
			</GridItem>
			<GridItem xs={12} sm={12} md={12}>
				<br />
			</GridItem>
			<GridItem xs={12} sm={3} md={3}>
				{renderElement("power_of_attorney_number", props.model)}
			</GridItem>
			<GridItem xs={12} sm={3} md={3}>
				{renderElement("defendants_attorney_name", props.model)}
			</GridItem>
			<GridItem xs={12} sm={6} md={6}>
				{renderElement("defendants_attorney_address", props.model)}
			</GridItem>
			<GridItem xs={12} sm={3} md={3}>
				{renderElement("defendants_attorney_phone", props.model)}
			</GridItem>
			<GridItem xs={12} sm={3} md={3}></GridItem>
			{props.action !== "Client Edit" &&
			<GridItem xs={12}>
				<div className="boxed mt-20">
					<GridItem xs={12}>
						<TextField
							id="standard-multiline-flexible"
							label="General Notes"
							multiline
							fullWidth={true}
							variant="standard"
							value={props.data.general_notes}
							onChange={(e) => {
								const newData = clone(props.data);
								newData.general_notes = e.target.value;
								updateDataObj('general_notes', newData.general_notes);
							}}
						/>
					</GridItem>
				</div>
			</GridItem>
}

		</GridContainer>
	);
};
