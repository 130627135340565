import React from "react";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "../../../components/CustomButtons/Button";
import { Dialog } from "@material-ui/core";
import { clone } from "shared/utility";
import CustomInput from "components/CustomInput/CustomInputValidate";
import { required } from "shared/validation";
import Table from "components/Table/Table";
import DefendantsFormChildren from "./DefendantsFormChildren";

export const DefendantsFormFamily = (props) => {
	const { renderElement } = props;
	console.log('DefendantsFormFamily', props);

	const field_parents = [
		"spouse",
		"ex_parent_1",
		"ex_parent_2",
		"ex_parent_3",
		"mother",
		"father",
		"spouse_mother",
		"spouse_father",
		"defendant_brother",
		"defendant_sister",
		"bestfriend",
	];
	const field_parents_heading = [
		"Spouse",
		"Ex/Parent 1",
		"Ex/Parent 2",
		"Ex/Parent 3",
		"Mother",
		"Father",
		`Spouse's Mother`,
		`Spouse's Father`,
		`Defendant's Brother`,
		`Defendant's Sister`,
		"Bestfriend",
	];

	const renderFields = () => {
		return field_parents.map((fp, i) => {
			return (
				<GridItem xs={12} sm={12} md={12} key={`${i}-fp`} className="">
					<div className="mb-20">
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<h4 className="bold">
									{field_parents_heading[i].toUpperCase()}
								</h4>
							</GridItem>
							{["name", "phone" , "address", "dob"].map((fpp, j) => {
								if (fp !== 'spouse' && fpp === 'dob') {
									return null;
								}
								const field = `${fp}_${fpp}`;
								if (fpp === 'dob') {
									return (
										<GridItem key={`${j}-fa`} xs={12} sm={3} md={3}>
											{renderElement(field, props.model)}
										</GridItem>
									);
								}
								if (fpp === "address") {
									return (
										<GridItem key={`${j}-fa`} xs={12} sm={9} md={9}>
											{renderElement(field, props.model)}
										</GridItem>
									);
								}
								return (
									<GridItem key={`${j}-ff`} xs={12} sm={6} md={6}>
										{renderElement(field, props.model)}
									</GridItem>
								);
							})}
						</GridContainer>
						{fp === "spouse" && (
							<GridContainer>
								<GridItem xs={12} sm={6} md={6}>
									{renderElement("spouse_how_long", props.model)}
								</GridItem>
								<GridItem xs={12} sm={6} md={6}>
									{renderElement("spouse_employer", props.model)}
								</GridItem>
							</GridContainer>
						)}
						{fp.includes("ex_parent") && (
							<GridContainer>
								<GridItem xs={12} sm={6} md={6}>
									{props.data[`${fp}_has_children`] ? renderElement(`${fp}_has_children`, props.model) : null}
								</GridItem>
								{props.data[`${fp}_has_children`] === "Yes" && (
									<GridItem xs={12} sm={12} md={12}>
										<DefendantsFormChildren
											data={props.data}
											childField={`${fp}_children`}
											updateDataObj={props.updateDataObj}
											action={props.action}
											renderElement={renderElement}
											model={props.model}
											has_child={props.data[`${fp}_has_children`]}
										/>
									</GridItem>
								)}
							</GridContainer>
						)}
					</div>
				</GridItem>
			);
		});
	};

	return (
		<GridContainer
			className={`${props.action === "History View" && "no-click"}`}
		>
			{renderFields()}
		</GridContainer>
	);
};
