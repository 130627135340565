import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Element from "containers/CRM/Element";
import fields from "./ArrestsModel";
import Notification from "components/Notification/Notification";
import { clone } from "shared/utility";
import { Dialog } from "@material-ui/core";

const ArrestsForm = (props) => {
	const { updateDataObj, formData, setFormData, canEdit, canDelete } = props;
	const [notification, setNotification] = useState("");
	const [askBeforeDelete, setAskBeforeDelete] = useState(false);
	const renderElement = (field, model) => {
		return (
			<Element
				field={field}
				data={formData}
				updateDataObj={updateDataObj}
				model={model}
			/>
		);
	};

	const fieldsObj = {
		arrest_info: [
			"court",
			"county",
			"arrest_when",
			"bail_post_date",
			"disposition",
			"previous_bail",
			"with_whom",
			"surety",
			"charges",
		],
	};

	const checkValidity = () => {
		let pass = true;
		if (!formData.court) return false;
		if (!formData.arrest_when) return false;
		if (!formData.bail_post_date) return false;

		return pass;
	};

	const renderFields = () => {
		return Object.keys(fieldsObj).map((key, index) => {
			return (
				<GridContainer
					className={`${props.action === "History View" && "no-click"}`}
					key={`${index}-a`}
				>
					{props.action !== "History View" && (
						<GridItem xs={12} sm={12} md={12}>
							<div
								style={{
									marginRight: 20,
									marginBottom: 10,
									display: "inline-block",
								}}
								onClick={() => {
									props.close();
								}}
								className="hoverable"
							>
								<Tooltip
									id="tooltip-top-start1"
									title="Back to List"
									placement="top"
								>
									<i className="fas fa-arrow-left font-22"></i>
								</Tooltip>
							</div>
							{props.action === "Add" && canEdit() && (
								<div className="bottom-dweller">
									<Button
										// className={`darkblue-text inline-block mr-20 hoverable`}
										simple
										color="primary"
										onClick={() => {
											if (!checkValidity())
												return setNotification(
													"Some fields are required. Put N/A to leave blank."
												);
											const newFormData = clone(formData);
											newFormData.defendant_id = props.defendant_id;
											props.add(newFormData);
										}}
										style={{ display: "inline-block" }}
									>
										<Tooltip
											title="Add"
											placement="top"
											style={{ display: "inline-block" }}
										>
											<i className="fas fa-save font-22"></i>
										</Tooltip>
										SAVE ARREST
									</Button>
								</div>
							)}

							{props.action === "Edit" && canEdit() && (
								<div className="bottom-dweller">
									<Button
										// className={`darkblue-text inline-block mr-20 hoverable`}
										simple
										color="primary"
										onClick={() => {
											if (!checkValidity())
												return setNotification(
													"Some fields are required. Put N/A to leave blank."
												);
											const newFormData = clone(formData);
											props.update(newFormData, props.index);
										}}
										style={{ display: "inline-block" }}
									>
										<Tooltip
											title="Update"
											placement="top"
											style={{ display: "inline-block" }}
										>
											<i className="fas fa-save font-22"></i>
										</Tooltip>
										SAVE ARREST
									</Button>
								</div>
							)}

							{props.action === "Edit" && canDelete() && (
								<div
									className={`${
										props.action === "Edit" ? "red" : "lightgray"
									}-text inline-block mr-20 hoverable`}
									onClick={() => {
										setAskBeforeDelete(true);
									}}
									style={{ display: "inline-block", float: "right" }}
								>
									<Tooltip
										title="Delete"
										placement="top"
										style={{ display: "inline-block" }}
									>
										<i className="fas fa-trash font-22"></i>
									</Tooltip>
									<span className="icon-text">DELETE</span>
								</div>
							)}
							<h4 className="bold">Arrest Info</h4>
						</GridItem>
					)}
					{fieldsObj[key].map((element, i) => {
						return (
							<GridItem key={`${i}-arrest`} xs={12} sm={3} md={3}>
								{renderElement(element, fields)}
							</GridItem>
						);
					})}
				</GridContainer>
			);
		});
	};
	return (
		<div>
			{renderFields()}
			{notification && (
				<Notification
					open={notification ? true : false}
					close={() => {
						setNotification("");
					}}
					message={notification}
				/>
			)}
			{askBeforeDelete && (
				<Dialog
					open={askBeforeDelete}
					onClose={() => {
						setAskBeforeDelete(false);
					}}
				>
					<div className="dialog-wrapper">
						<h3>Are you sure you want to delete this Arrest?</h3>
						<Button
							color="danger"
							onClick={() => {
								props.delete(formData.id, props.index);
								setAskBeforeDelete(false);
							}}
							className="mr-20"
						>
							YES, DELETE
						</Button>
						<Button
							color="white"
							onClick={() => {
								setAskBeforeDelete(false);
							}}
						>
							CANCEL
						</Button>
					</div>
				</Dialog>
			)}
		</div>
	);
};

export default ArrestsForm;
