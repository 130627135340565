import { put } from 'redux-saga/effects';
import axios from '../axios/axios-crm';

import * as actions from '../actions/crm_history';

export function* getHistoriesSaga(action) {
  yield put(actions.getHistoriesStart());
  try {
      let url = '/crm_histories?q=get';
      if (action.data) {
          const fields = Object.keys(action.data);
          for (let i = 0; i < fields.length; i++) {
              const f = fields[i];
              if (action.data[f]) url += `&${f}=${action.data[f]}`;
          }
      }
      const response = yield axios.get(url);
      
      yield put(actions.getHistoriesSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getHistoriesFail(e.response.data));
          }
        }
      yield put(actions.getHistoriesFail(e));
  }
}

export function* getHistorySaga(action) {
  yield put(actions.getHistoryStart());
  try {
      const response = yield axios.get(`/crm_history?id=${action.data}`);
      yield put(actions.getHistorySuccess(response.data));
  }catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getHistoryFail(e.response.data));
          }
        }
      yield put(actions.getHistoryFail(e));
  }
}