import * as actionTypes from './actionTypes';

// LOGIN USER
export const loginUser = (user) => {
    return {
        type: actionTypes.AUTH_REQUEST,
        user
    }
}

export const loginUserSuccess = (user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        user
    }
}

export const loginUserFail = (err) => {
    return {
        type: actionTypes.AUTH_FAILURE,
        err
    }
}

export const logoutUser = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  }
}

// CREATE_USER 
export const createUser = (data) => {
    return {
        type: actionTypes.CREATE_USER,
        data
    }
}
export const createUserStart = () => {
    return {
        type: actionTypes.CREATE_USER_START
    }
}
export const createUserSuccess = (response) => {
    return {
        type: actionTypes.CREATE_USER_SUCCESS,
        response
    }
}
export const createUserFail = (err) => {
    return {
        type: actionTypes.CREATE_USER_FAIL,
        err
    }
}

// GET_ADMIN_USERS 
export const getAdminUsers = (data) => {
    return {
        type: actionTypes.GET_ADMIN_USERS,
        data
    }
}
export const getAdminUsersStart = () => {
    return {
        type: actionTypes.GET_ADMIN_USERS_START
    }
}
export const getAdminUsersSuccess = (response) => {
    return {
        type: actionTypes.GET_ADMIN_USERS_SUCCESS,
        response
    }
}
export const getAdminUsersFail = (err) => {
    return {
        type: actionTypes.GET_ADMIN_USERS_FAIL,
        err
    }
}

// DELETE_USER 
export const deleteUser = (data) => {
    return {
        type: actionTypes.DELETE_USER,
        data
    }
}
export const deleteUserStart = () => {
    return {
        type: actionTypes.DELETE_USER_START
    }
}
export const deleteUserSuccess = (response) => {
    return {
        type: actionTypes.DELETE_USER_SUCCESS,
        response
    }
}
export const deleteUserFail = (err) => {
    return {
        type: actionTypes.DELETE_USER_FAIL,
        err
    }
}

// UPDATE_USER 
export const updateUser = (data) => {
    return {
        type: actionTypes.UPDATE_USER,
        data
    }
}
export const updateUserStart = () => {
    return {
        type: actionTypes.UPDATE_USER_START
    }
}
export const updateUserSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS,
        response
    }
}
export const updateUserFail = (err) => {
    return {
        type: actionTypes.UPDATE_USER_FAIL,
        err
    }
}

