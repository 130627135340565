import * as actionTypes from './actionTypes';

// GET_SMS_CONFIG 
export const getSmsConfig = (data) => {
  return {
      type: actionTypes.GET_SMS_CONFIG,
      data
  }
}
export const getSmsConfigStart = () => {
  return {
      type: actionTypes.GET_SMS_CONFIG_START
  }
}
export const getSmsConfigSuccess = (response) => {
  return {
      type: actionTypes.GET_SMS_CONFIG_SUCCESS,
      response
  }
}
export const getSmsConfigFail = (err) => {
  return {
      type: actionTypes.GET_SMS_CONFIG_FAIL,
      err
  }
}

// UPDATE_SMS_CONFIG 
export const updateSmsConfig = (data) => {
  return {
      type: actionTypes.UPDATE_SMS_CONFIG,
      data
  }
}
export const updateSmsConfigStart = () => {
  return {
      type: actionTypes.UPDATE_SMS_CONFIG_START
  }
}
export const updateSmsConfigSuccess = (response) => {
  return {
      type: actionTypes.UPDATE_SMS_CONFIG_SUCCESS,
      response
  }
}
export const updateSmsConfigFail = (err) => {
  return {
      type: actionTypes.UPDATE_SMS_CONFIG_FAIL,
      err
  }
}

// DELETE_SMS_CONFIG 
export const deleteSmsConfig = (data) => {
  return {
      type: actionTypes.DELETE_SMS_CONFIG,
      data
  }
}
export const deleteSmsConfigStart = () => {
  return {
      type: actionTypes.DELETE_SMS_CONFIG_START
  }
}
export const deleteSmsConfigSuccess = (response) => {
  return {
      type: actionTypes.DELETE_SMS_CONFIG_SUCCESS,
      response
  }
}
export const deleteSmsConfigFail = (err) => {
  return {
      type: actionTypes.DELETE_SMS_CONFIG_FAIL,
      err
  }
}

// CREATE_SMS_CONFIG 
export const createSmsConfig = (data) => {
  return {
      type: actionTypes.CREATE_SMS_CONFIG,
      data
  }
}
export const createSmsConfigStart = () => {
  return {
      type: actionTypes.CREATE_SMS_CONFIG_START
  }
}
export const createSmsConfigSuccess = (response) => {
  return {
      type: actionTypes.CREATE_SMS_CONFIG_SUCCESS,
      response
  }
}
export const createSmsConfigFail = (err) => {
  return {
      type: actionTypes.CREATE_SMS_CONFIG_FAIL,
      err
  }
}
