import React, { useState } from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Button from '../CustomButtons/Button';
import { dataURItoBlob } from '../../shared/utility';
import ImagePreview from './ImagePreview'; // source code : ./src/demo/AppWithImagePreview/ImagePreview

function App (props) {
  const [dataUri, setDataUri] = useState('');

  function handleTakePhotoAnimationDone (dataUri) {
    console.log('takePhoto');
    setDataUri(dataUri);
  }

  const isFullscreen = false;
  return (
    <div>
        <h2 className="text-center">Take Photo</h2>
      {
        (dataUri)
          ? <ImagePreview dataUri={dataUri}
            isFullscreen={isFullscreen}
            isImageMirror={false}
          />
          : <Camera 
            onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
            isFullscreen={isFullscreen}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            isImageMirror={false}
          />
      }
        <div className="text-center m-20 mt-20">
            <small>* press button to take picture</small><br />
            {dataUri && <Button color="primary" className="mr-20" onClick={() => {
                const blob = dataURItoBlob(dataUri, 'image/png');
                props.usePhoto(blob);
                props.close();
            }}>USE PHOTO</Button>}
            <Button color="white" onClick={() => {
                props.close();
            }}>CLOSE</Button>
        </div>
    </div>
  );
}

export default App;