import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import Button from '../CustomButtons/Button';
import Photo from './Photo';

const Upload = props => {
    const [uploadMode, setUploadMode] = useState('');
    const element = props.uploadData ? props.uploadData.element : "";
    const multiple = props.uploadData ? props.uploadData.multiple : "";
    const title = props.uploadData ? props.uploadData.label : "Upload";
    return (
    <div>
        <Dialog
            open={props.open}
            onClose={() => {
                props.close();
            }}
            fullWidth={true}
            maxWidth="md"
        >
            <div className="dialog-wrapper3">
                  {uploadMode === '' && <div className="text-center">
                    <h3 className="text-center">
                        {title}
                    </h3>
                    <label className="photo-upload-btn mr-20">
                        Upload File
                        <input
                            onChange={(e) => {
                                props.appendFile(e);
                                setUploadMode('');
                                props.close();
                            }}
                            accept=".gif,.jpg,.png,.jpeg,.pdf"
                            type="file"
                            name={element}
                            multiple={multiple}
                            style={{ display: "none", visibility: "hidden" }}
                        />
                    </label>
                    {/* <Button
                        className="mr-20"
                        color="primary"
                        onClick={() => {
                            setUploadMode('file');
                        }}
                    >Upload File</Button> */}
                    <Button
                        className="mr-20"
                        color="primary"
                        onClick={() => {
                            setUploadMode('camera');
                        }}
                    >Take Camera Photo</Button>
                    <Button
                        className="mr-20"
                        color="white"
                        onClick={() => {
                            props.close();
                        }}
                    >
                        CANCEL
                    </Button>
                </div>}

                {/* {uploadMode === 'file' && <div className="text-center">
                    <h3 className="text-center">
                        {title}
                    </h3>
                    <label className="photo-upload-btn mr-20">
                        Select File
                        <input
                            onChange={(e) => {
                                props.appendFile(e);
                                setUploadMode('');
                                props.close();
                            }}
                            accept=".gif,.jpg,.png,.jpeg,.pdf"
                            type="file"
                            name={element}
                            multiple={multiple}
                            style={{ display: "none", visibility: "hidden" }}
                        />
                    </label> 
                    <Button color="white" onClick={() => {
                        setUploadMode('');
                    }}>
                        CANCEL
                    </Button>
                </div>} */}
                {uploadMode === 'camera' && <div>
                    <Photo 
                        usePhoto={(e) => {
                            props.appendFile(e, element);
                            props.close();
                        }}
                        close={() => {
                            setUploadMode('');
                        }}
                    />
                </div>}
            </div>
        </Dialog>
        
      </div>
    )
}

export default Upload;