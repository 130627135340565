import { put } from "redux-saga/effects";
import * as actions from "../actions";
import axios from "../axios/axios-documents";

export function* sendFcsSaga(action) {
    yield put(actions.sendFcsStart());
    try {
        const { type, defendant_id, booking_id } = action.data;
        const response = yield axios.post('/send', {
            type,
            defendant_id,
            booking_id,
        });
        yield put(actions.sendFcsSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.sendFcsFail(e.response.data));
            }
          }
        yield put(actions.sendFcsFail(e));
    }
}
export function* getDocumentPdfSaga(action) {
    yield put(actions.getDocumentPdfStart());
    try {
      let queries = [];
          const { type, defendant_id, indemnitor_id, booking_id, includeCC } = action.data;
          if (type) queries.push(`type=${type}`)
          if (defendant_id) queries.push(`defendant_id=${defendant_id}`);
          if (booking_id) queries.push(`booking_id=${booking_id}`);
          if (indemnitor_id) queries.push(`indemnitor_id=${indemnitor_id}`);
          if (includeCC) queries.push(`includeCC=${includeCC}`);
          const query_string = queries.length > 0 ? `?${queries.join('&')}` : '';
      const response = yield axios.get('/documents_pdf'+query_string);
      console.log(response.data)
        yield put(actions.getDocumentPdfSuccess(response.data));
        console.log(response.data)
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getDocumentPdfFail(e.response.data));
            }
          }
        yield put(actions.getDocumentPdfFail(e));
    }
}

export function* sendSinglePdfSaga(action) {
  yield put(actions.sendSinglePdfStart());
  try {
    let queries = [];
    const { type, defendant_id, indemnitor_id, booking_id, includeCC } = action.data;
    if (type) queries.push(`type=${type}`)
    if (defendant_id) queries.push(`defendant_id=${defendant_id}`);
    if (booking_id) queries.push(`booking_id=${booking_id}`);
    if (indemnitor_id) queries.push(`indemnitor_id=${indemnitor_id}`);
    if (includeCC) queries.push(`includeCC=${includeCC}`);
    const query_string = queries.length > 0 ? `?${queries.join('&')}` : '';
    const response = yield axios.post('/send_single' + query_string);
    console.log(response.data)
    yield put(actions.sendSinglePdfSuccess(response.data));
    console.log(response.data)
  } catch (e) {
    if (e.response) {
      if (e.response.data) {
        return yield put(actions.sendSinglePdfFail(e.response.data));
      }
    }
    yield put(actions.sendSinglePdfFail(e));
  }
}

export function* sendCcSaga(action) {
  yield put(actions.sendCcStart());
  try {
      const response = yield axios.post('/cc', action.data);
      yield put(actions.sendCcSuccess(response.data));
  } catch (e) {
    console.log('e', e.response, e.message)
      if (e.response) {
        if (e.response.data) {
          return yield put(actions.sendCcFail(e.response.data));
        }
      }
      yield put(actions.sendCcFail(e));
  }
}

export function* sendReminderSaga(action) {
  yield put(actions.sendReminderStart());
  try {
      const response = yield axios.post('/reminder', action.data);
      yield put(actions.sendReminderSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.sendReminderFail(e.response.data));
          }
        }
      yield put(actions.sendReminderFail(e));
  }
}

export function* downloadDocumentSaga(action) {
  yield put(actions.downloadDocumentStart());
  try {
      const response = yield axios.get('/document_download?envelope_id='+action.data,
      { 
        responseType: 'blob',
        responseType: 'arraybuffer',
        responseEncoding: 'binary',
        headers: { Accept: 'application/pdf'} 
      });
      const blob = new Blob([new Uint8Array(response.data).buffer], {type: "application/pdf"});
      var link = window.URL.createObjectURL(blob);
      window.open(link,'', 'height=650,width=840');
      // const url = window.URL.createObjectURL(blob);
      // window.open(url,'', 'height=650,width=840');
      // const link = document.createElement('a');
      // link.href = url;
      // const d = new Date();
      // const now = d.getTime();
      // //link.setAttribute('download', `docusign_document_${now}.pdf`);
      // document.body.appendChild(link);
      // link.click();
      yield put(actions.downloadDocumentSuccess('OK'));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.downloadDocumentFail(e.response.data));
          }
        }
      yield put(actions.downloadDocumentFail(e));
  }
}