import React from "react";
import { useState, useEffect } from "react";
import Table from "components/Table/Table.jsx";
import SortButton from "components/SortButton/SortButton";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Cached from "@material-ui/icons/Cached";
import moment from "moment";
import Datetime from "react-datetime";
import Tooltip from "@material-ui/core/Tooltip";
import { getUserPerms } from "../../../shared/authValidation";
import { extractDefendantSends } from './DefendantsLogic';

const List = (props) => {
	const userPerms = getUserPerms();
	const isAdmin = userPerms ?
		userPerms.hasOwnProperty('CRM admin') ?
			true
			:
			false
		:
		false;
	console.log('userPerms', userPerms);
	const { rows, offset, setOpenedReminderModal, setAllBookings, setBookingOptions, setReminderData } = props;
	const page_num = Math.ceil((offset + 1) / rows);
	const page_count = Math.ceil(props.count / rows);
	const pages_links = isNaN(page_count)
		? null
		: Array(page_count)
			.fill()
			.map((element, index) => index + 1)
			.map((num) => {
				if (page_count > 10) {
					const four = page_num + 4;
					const four2 = page_num - 4;
					if (num > four || num < four2) {
						return null;
					}
				}
				return (
					<span
						onClick={() => {
							props.setOffset((num - 1) * rows);
						}}
						className={
							num === page_num ? "tiny-box current" : "hoverable tiny-box"
						}
						key={num}
						style={{
							marginRight: -1,
						}}
					>
						{num}
					</span>
				);
			});
	const [dataArr, setDataArr] = useState([]);
	const showBack = page_num === 1 ? false : true;
	const showForward = page_num === page_count ? false : true;

	const renderNav = () => {
		return (
			<div className="text-center">
				{props.data.length > 0 && (
					<p style={{ paddingTop: "10px" }}>
						Showing {offset + 1} to {offset + props.data.length} of{" "}
						{props.count} defendant(s)
					</p>
				)}
				{props.data.length > 0 && (
					<div style={{ marginTop: 20 }}>
						<span
							onClick={() => {
								props.setOffset(0);
							}}
							style={{ borderRight: !showBack ? '1px solid #ccc' : '0' }}
							className="hoverable tiny-box">
							First
						</span>
						<span
							onClick={() => {
								if (showBack) {
									props.setOffset((page_num - 1 - 1) * rows);
								}
							}}
							style={{ opacity: showBack ? 1 : 0.2, marginRight: -1 }}
							className={showBack ? "hoverable tiny-box" : "tiny-box"}
						>
							Previous
						</span>
						{pages_links}
						{page_count > 10 ? ' . . . ' : ''}
						<span
							onClick={() => {
								if (showForward) {
									props.setOffset((page_num + 1 - 1) * rows);
								}
							}}
							style={{ opacity: showForward ? 1 : 0.2, marginRight: -1 }}
							className={showForward ? "hoverable tiny-box" : "tiny-box"}
						>
							Next
						</span>
						<span
							onClick={() => {
								props.setOffset((page_count - 1) * rows);
							}}
							style={{ borderLeft: '1px solid #ccc' }}
							className="hoverable tiny-box">
							Last
						</span>
					</div>
				)}
			</div>
		);
	};

	const getNumbers = (cd) => {
		const extracted_data = extractDefendantSends(cd);
		const sendToOptions = extracted_data.sendToOptions;
		const optionsObj = extracted_data.optionsObj;
		props.setSendOptions(sendToOptions);
		props.setSendOptionsObj(optionsObj);
	}

	const getDefendantBookings = (cd) => {
		console.log('getting defendant bookings', cd.allBookings);
		const bookingOptions = cd.allBookings ? cd.allBookings.map(ab => ab.booking) : [];
		setBookingOptions(bookingOptions);
		setAllBookings(cd.allBookings ? cd.allBookings : []);
	}

	useEffect(() => {
		console.log(props.data)
		if (props.data) {
			setDataArr(
				props.data.map((cd, i) => {
					const indemnitors = cd.indemnitors ? cd.indemnitors : [];
					const created = moment(cd.date_created).format("MM/DD/YYYY h:mm:ss a").split(" ");
					const open = () => {
						props.getDefendant(cd.id);
					};
					const documentsCell = {
						pending_signature: 0,
						docs_sent: 0,
						link_last_sent: cd.link_last_sent
					};
					const defendantReminderData = {
						data: cd, 
						type: 'defendant', 
						defendant_name: cd.first_name + " " + cd.last_name,
						defendant_id: cd.id,
						phone: cd.phone,
						the_data: cd
					};
					let defendant_documents = [];
					let indemnitor_documents = [];
					if (cd.documents) {
						if (cd.documents.length > 0) {
							console.log('cddocuments', cd.documents);
							cd.documents.forEach(ccd => {
								if (!ccd.indemnitor_id) {
									defendant_documents.push(ccd);
								} else {
									indemnitor_documents.push(ccd);
								}
							})
						}
					}
					defendant_documents.forEach(dd => {
						if (dd.status == 2) documentsCell.pending_signature++;
					})

					let rows = [
						<>
							<div
								className="hoverable"
								onClick={() => {
									getNumbers(cd)
									//getDefendantBookings(cd);
									open();
									props.setAction("Edit");
									props.setDefendantData(cd);
									props.setOpenedModal(true);
									props.getBookings(cd.id);
									props.getDefendantUsers(cd.id);
									props.setListIndemnitors(indemnitors);
								}}
							>
								{cd.first_name}
							</div>
							<br></br>
							<div>
								{(indemnitors) && <div> {indemnitors.map((ind, i) => {
									const fullName = ind.name.split(" ");
									const firstName = fullName[0];
									return <div style={{ textIndent: '10px', color: '#6B6B6B' }} key={`inddw-${i}`}><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span> {firstName}<br></br></div>;

								})}
								</div>}
							</div>
						</>
						,
						<>
							<div
								className="hoverable"
								onClick={() => {
									getNumbers(cd);
									getDefendantBookings(cd);
									open();
									props.setAction("Edit");
									props.setDefendantData(cd);
									props.setOpenedModal(true);
									props.setListIndemnitors(indemnitors);
								}}
							>
								{cd.last_name}
							</div>
							<br></br>
							<div>
								{(indemnitors) && <div> {indemnitors.map((ind, i) => {
									const fullName = ind.name.split(" ");
									const lastName = fullName[1];
									return <div style={{ textIndent: '10px', color: '#6B6B6B' }} key={`indq-${i}`}>{lastName}<br></br></div>;
								})}
								</div>}
							</div>
						</>
						,
						<>
							<div>
								{cd.home_phone}
							</div>
							<br></br>
							<div>
								{(indemnitors) && <div> {indemnitors.map((ind, i) => {
									return <div style={{ color: '#6B6B6B' }} key={`indqa-${i}`}>{ind.home_phone}<br></br></div>;
								})}
								</div>}
							</div>
						</>
						,
						<>
							<div>
								{cd.date_of_birth}
							</div>
							<br></br>
							<div>
								{(indemnitors) && <div> {indemnitors.map((ind, i) => {
									return <div style={{ color: '#6B6B6B' }} key={`oain-${i}`}>{ind.date_of_birth}<br></br></div>;
								})}
								</div>}
							</div>
						</>
						,
						<div style={{ paddingBottom: '45px' }}>{cd.bail_post_date}</div>,
						<div style={{ paddingBottom: '45px' }}>{cd.arrest_when}</div>,
						<div style={{ paddingBottom: '45px' }}>{cd.arrest_county}</div>,
						<div style={{ paddingBottom: '20px' }}>Arrest Case:{cd.arrest_case}<br></br>Court Case:{cd.court_case}<br></br>Other Case:{cd.case_number}</div>,
						<div style={{ paddingBottom: '45px' }}>{cd.surety}</div>,
						<div style={{ paddingBottom: '45px' }}>
							<div>{documentsCell.link_last_sent}</div>
							<br />
							<div>
								{indemnitors && <div>
									{indemnitors.map((ind,i) => {
										return <div key={`${i}-indsa`}>
											{ind.link_last_sent}
										</div>
									})}	
								</div>}
							</div>
						</div>,
						<>
							<div style={{ paddingBottom: '45px' }}
								className="hoverable"
								onClick={() => {
									setReminderData(defendantReminderData)
									open();
									props.setOpenedReminderModal(true);
								}}
							>
								{defendant_documents.length}
							</div>
							<div>
								{(indemnitors) && <div>
									{indemnitors.map((ind, i) => {
										let inddocs = [];
										indemnitor_documents.forEach(indy => {
											if (indy.indemnitor_id === ind.id) {
												inddocs.push(indy);
											}
										})
										return (
											<div
												key={`aainda-${i}`}
												className="hoverable"
												onClick={() => {
													setReminderData({
														data: ind, 
														type: 'indemnitor', 
														defendant_name: cd.first_name + " " + cd.last_name,
														defendant_id: cd.id,
														indemnitor_id: ind.id,
														indemnitor_name: ind.name,
														phone: ind.phone
													})
													open();
													props.setOpenedReminderModal(true);
												}}
											>
												{inddocs.length}
											</div>)
									})}</div>}
							</div>
						</>
						,
						<>
							<div style={{ paddingBottom: '45px' }}
								className="hoverable"
								onClick={() => {
									setReminderData(defendantReminderData)
									open();
									props.setOpenedReminderModal(true);
								}}
							>
								{documentsCell.pending_signature} 
								{/* Pending Signature */}
							</div>
							<div>
								{(indemnitors) && <div>
									{indemnitors.map((ind, i) => {
										let ind_document_pending = 0;
										indemnitor_documents.forEach(indd => {
											if (ind.id === indd.indemnitor_id) {
												if (indd.status == 2) {
													ind_document_pending++;
												}
											}
										})
										return (
											<div
												key={`doci-${i}`}
												className="hoverable"
												onClick={() => {
													setReminderData({
														data: ind, 
														type: 'indemnitor', 
														defendant_name: cd.first_name + " " + cd.last_name,
														defendant_id: cd.id,
														indemnitor_id: ind.id,
														indemnitor_name: ind.name,
														phone: ind.phone
													})
													open();
													props.setOpenedReminderModal(true);
												}}
											>
												{ind_document_pending}
											</div>)
									})}</div>}
							</div>
						</>,
						<div style={{ paddingBottom: '45px' }}>{created[0]}<br></br>{created[1] + " " + created[2]}</div>,
						<div style={{ paddingBottom: '45px' }}>{cd.created_by_name}</div>,
					];
					return rows;
				})
			);
		}
	}, [props.data]);

	const setSort = (sortBy, sortDirection) => {
		props.setSortData({
			sortBy,
			sortDirection,
		});
	};

	return (
		<div>
			<form
				onSubmit={(e) => {
					console.log('got here defendant search')
					e.preventDefault();
					props.getDefendants();
				}}
			>
				<GridContainer>
					<GridItem xs={8}>
						<h3 style={{ display: "inline-block" }}>Defendants</h3>
						<Button
							justIcon
							round
							simple
							onClick={() => {
								// props.getDefendants();
								window.location.reload();
							}}
							color="info"
						>
							<Tooltip
								title="Refresh"
								placement="right"
								style={{ display: "inline-block" }}
							>
								<Cached />
							</Tooltip>
						</Button>
					</GridItem>
					<GridItem xs={12} sm={2} md={2}>
						<Button color="warning" type="submit" className="outlined-btn mr-20">
							SEARCH
						</Button>
					</GridItem>
					<GridItem xs={12} sm={2} md={2}>
						<Button
							color="primary"
							onClick={() => {
								props.resetFilters();
							}}
							className="outlined-btn"
						>
							CLEAR
						</Button>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<CustomInput
							label="Search by First Name"
							value={props.filters.first_name}
							onChange={(e) => {
								props.setFilters("first_name", e.target.value);
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<CustomInput
							label="Search by Phone"
							value={props.filters.phone}
							onChange={(e) => {
								props.setFilters("phone", e.target.value);
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<CustomInput
							label="Search by Case #"
							value={props.filters.case_number}
							onChange={(e) => {
								props.setFilters("case_number", e.target.value);
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<CustomSelect
							label="Filter by Surety"
							options={['FCS', 'Bankers']}
							choose={(e, n) => {
								props.setFilters('surety', e);
							}}
							default={props.filters ? props.filters.surety ? props.filters.surety : '' : ''}
						/>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<CustomInput
							label="Search by Last Name"
							value={props.filters.last_name}
							onChange={(e) => {
								props.setFilters("last_name", e.target.value);
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<div style={{ paddingTop: 25 }}>
							<Datetime
								inputProps={{
									placeholder: "Search by Bail Post Date",
									readOnly: true,
								}}
								closeOnSelect={true}
								onChange={(e) => {
									if (typeof e === "object") {
										const stringDate = e.format("MM/DD/YYYY");
										props.setFilters("bail_post_date", stringDate);
									} else {
										props.setFilters("bail_post_date", e);
									}
								}}
								timeFormat={false}
								value={
									props.filters.bail_post_date
										? props.filters.bail_post_date
										: ""
								}
							/>
						</div>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<div style={{ paddingTop: 25 }}>
							<Datetime
								inputProps={{
									placeholder: "Search by Arrest Date",
									readOnly: true,
								}}
								closeOnSelect={true}
								onChange={(e) => {
									if (typeof e === "object") {
										const stringDate = e.format("MM/DD/YYYY");
										props.setFilters("arrest_when", stringDate);
									} else {
										props.setFilters("arrest_when", e);
									}
								}}
								timeFormat={false}
								value={
									props.filters.arrest_when
										? props.filters.arrest_when
										: ""
								}
							/>
						</div>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<CustomSelect
							label="Filter by Docs Sent"
							options={['Yes', 'No']}
							choose={(e, n) => {
								props.setFilters('docs_sent', e);
							}}
							default={props.filters.docs_sent}
						/>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<CustomInput
							label="Search by Indemnitor Name"
							value={props.filters.name}
							onChange={(e) => {
								props.setFilters("name", e.target.value);
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<CustomInput
							label="Search by Booking #"
							value={props.filters.booking}
							onChange={(e) => {
								props.setFilters("booking", e.target.value);
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<CustomInput
							label="Search by Power #"
							value={props.filters.power_of_attorney_number}
							onChange={(e) => {
								props.setFilters("power_of_attorney_number", e.target.value);
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<CustomSelect
							label="Filter by Pending Signature"
							options={['Yes', 'No']}
							choose={(e, n) => {
								props.setFilters('pending_signature', e);
							}}
							default={props.filters.pending_signature}
						/>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						{renderNav()}
					</GridItem>
				</GridContainer>
			</form>
			{props.data.length !== 0 && (
				<div className="illi-table">
					<Table
						tableHead={[
							<div className="th-first">
								<strong>
									First Name
								</strong>
								<SortButton
									setSort={setSort}
									sortBy="first_name"
									sortDirection={props.sortDirection}
									currentSortBy={props.sortBy}
								/>
							</div>,
							<div className="th-last">
								<strong>
									Last Name
								</strong>
								<SortButton
									setSort={setSort}
									sortBy="last_name"
									sortDirection={props.sortDirection}
									currentSortBy={props.sortBy}
								/>
							</div>,
							<div className="th-phone">
								<strong>
									Phone
								</strong>
								<SortButton
									setSort={setSort}
									sortBy="home_phone"
									sortDirection={props.sortDirection}
									currentSortBy={props.sortBy}
								/>
							</div>,
							<div className="th-dob">
								<strong>
									DOB
								</strong>
								<SortButton
									setSort={setSort}
									sortBy="date_of_birth"
									sortDirection={props.sortDirection}
									currentSortBy={props.sortBy}
								/>
							</div>,
							<div className="th-bpd">
								<strong>
									Bail Post Date
								</strong>
								<SortButton
									setSort={setSort}
									sortBy="bail_post_date"
									sortDirection={props.sortDirection}
									currentSortBy={props.sortBy}
								/>
							</div>,
							<div className="th-arrestDate">
								<strong>
									Arrest Date
								</strong>
								<SortButton
									setSort={setSort}
									sortBy="arrest_when"
									sortDirection={props.sortDirection}
									currentSortBy={props.sortBy}
								/>
							</div>,
							<div className="th-arrestCounty">
								<strong>
									Arrest County
								</strong>
								<SortButton
									setSort={setSort}
									sortBy="county"
									sortDirection={props.sortDirection}
									currentSortBy={props.sortBy}
								/>
							</div>,
							<div className="th-case">
								<strong>
									Case #'s
								</strong>
							</div>,
							<div className="th-surety">
								<strong>
									Surety
								</strong>
							</div>,
							<div className="th-linkSent">
								<strong>
									Link Last Sent
								</strong>
								<SortButton
									setSort={setSort}
									sortBy=""
									sortDirection={props.sortDirection}
								// currentSortBy={props.sortBy}
								/>
							</div>,
							<div className="th-docSent">
								<strong>
									Docs Sent

								</strong>
								<SortButton
									setSort={setSort}
									sortBy=""
									sortDirection={props.sortDirection}
								// currentSortBy={props.sortBy}
								/>
							</div>,
							<div className="th-signature">
								<strong>
									Pending Signature
								</strong>
								<SortButton
									setSort={setSort}
									sortBy=""
									sortDirection={props.sortDirection}
								// currentSortBy={props.sortBy}
								/>
							</div>,
							<div className="th-created">
								<strong>
									Created
								</strong>
								<SortButton
									setSort={setSort}
									sortBy="date_created"
									sortDirection={props.sortDirection}
									currentSortBy={props.sortBy}
								/>
							</div>,
							<div className="th-createdBy">
								<strong>
									Created By
								</strong>
							</div>
						]}
						tableData={dataArr}
					/>
				</div>
			)}
			<div className="text-center">
				<em>{props.data.length === 0 ? "No defendants found" : ""}</em>
			</div>
			<GridContainer>
				<GridItem xs={12} sm={12} md={3}>
					<CustomSelect
						label="Rows per page"
						options={[10, 15, 20, 25, 30]}
						choose={(e, n) => {
							props.setOffset(0);
							props.setRows(e);
						}}
						default={props.rows}
					/>
				</GridItem>
			</GridContainer>
			<div>{renderNav()}</div>
		</div>
	);
};

export default List;
