import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    error: null,
    success: null,
    pdf_data: null,
    cc_error: null,
    cc_success: null,
    cc_loading: false,
    downloaded_document: null
};

// SEND_FCS
const sendFcsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
    })
}
const sendFcsSuccess = (state, action) => {
    if (action.response.msg === 'Fields Required') {
        return updateObject(state, {
            success: action.response.error,
        })
    } else {
        return updateObject(state, {
            success: 'Successfully sent Documents for Docusign.',
        })
    }
}
const sendFcsFail = (state, action) => {
    return updateObject(state, {
        error: action.err ? action.err : 'Something went wrong. Please contact tech support if the error persists.'
    })
}

const getDocumentPdfStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        pdf_data: null,
    })
}
const getDocumentPdfSuccess = (state, action) => {
    if (action.response.msg === 'Fields Required') {
        return updateObject(state, {
            success: action.response,
        })
    } else {
        return updateObject(state, {
            success: 'Successfully prepared PDF data',
            pdf_data: action.response
        })
    }
}
const getDocumentPdfFail = (state, action) => {
    return updateObject(state, {
        error: action.err ? action.err : 'Something went wrong. Please contact tech support if the error persists.'
    })
}

const setDocumentPdf = (state, action) => {
    return updateObject(state, {
        pdf_data: action.data ? action.data : null
    })
}

// SEND SINGLE PDF
const sendSinglePdfStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
    })
}
const sendSinglePdfSuccess = (state, action) => {
    if (action.response.msg === 'Fields Required') {
        return updateObject(state, {
            success: action.response.error,
        })
    } else {
        return updateObject(state, {
            success: 'Successfully sent Documents for Docusign.',
        })
    }
}
const sendSinglePdfFail = (state, action) => {
    return updateObject(state, {
        error: action.err ? action.err : 'Something went wrong. Please contact tech support if the error persists.'
    })
}

// SEND_CC
const sendCcStart = (state, action) => {
    return updateObject(state, {
        cc_error: null,
        cc_success: null,
        cc_loading: true,
    })
}
const sendCcSuccess = (state, action) => {
    return updateObject(state, {
        cc_success: 'Successfully sent Credit Card Authorization',
        cc_loading: false,
    })
}
const sendCcFail = (state, action) => {
    return updateObject(state, {
        cc_error: action.err ? 
            typeof action.err === 'string' ?
                action.err
            :
                'Something went wrong. If the issue persists, contact support.'
        : 
            'Something went wrong. If the issue persists, contact support.',
        cc_loading: false,
    })
}

// SEND_REMINDER
const sendReminderStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}
const sendReminderSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success'
    })
}
const sendReminderFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// DOWNLOAD_DOCUMENT
const downloadDocumentStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        downloaded_document: null
    })
}
const downloadDocumentSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully downloaded',
        downloaded_document: action.response
    })
}
const downloadDocumentFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// ======================
// Reducer actionTypes section
// ======================
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SEND_FCS_START:
            return sendFcsStart(state, action);
        case actionTypes.SEND_FCS_SUCCESS:
            return sendFcsSuccess(state, action);
        case actionTypes.SEND_FCS_FAIL:
            return sendFcsFail(state, action);

        case actionTypes.GET_DOCUMENT_PDF_START:
            return getDocumentPdfStart(state, action);
        case actionTypes.GET_DOCUMENT_PDF_SUCCESS:
            return getDocumentPdfSuccess(state, action);
        case actionTypes.GET_DOCUMENT_PDF_FAIL:
            return getDocumentPdfFail(state, action);
        case actionTypes.SET_DOCUMENT_PDF:
            return setDocumentPdf(state, action);

        case actionTypes.SEND_SINGLE_PDF_START:
            return sendSinglePdfStart(state, action);
        case actionTypes.SEND_SINGLE_PDF_SUCCESS:
            return sendSinglePdfSuccess(state, action);
        case actionTypes.SEND_SINGLE_PDF_FAIL:
            return sendSinglePdfFail(state, action);

        case actionTypes.SEND_CC_START:
            return sendCcStart(state, action);
        case actionTypes.SEND_CC_SUCCESS:
            return sendCcSuccess(state, action);
        case actionTypes.SEND_CC_FAIL:
            return sendCcFail(state, action);

        case actionTypes.SEND_REMINDER_START:
            return sendReminderStart(state, action);
        case actionTypes.SEND_REMINDER_SUCCESS:
            return sendReminderSuccess(state, action);
        case actionTypes.SEND_REMINDER_FAIL:
            return sendReminderFail(state, action);

        case actionTypes.DOWNLOAD_DOCUMENT_START:
            return downloadDocumentStart(state, action);
        case actionTypes.DOWNLOAD_DOCUMENT_SUCCESS:
            return downloadDocumentSuccess(state, action);
        case actionTypes.DOWNLOAD_DOCUMENT_FAIL:
            return downloadDocumentFail(state, action);

        default:
            return state;
    }
};

export default reducer;