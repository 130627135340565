import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    error: null,
    success: null,
    data: [],
    arrest: null
}

// MESSAGES 
const clearArrestMessages = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

// GET_ARRESTS

const getArrestsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getArrestsSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully gotten arrests',
        data: action.response.data
    })
}

const getArrestsFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// CREATE_ARREST

const createArrestStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const createArrestSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully created arrest',
    })
}

const createArrestFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// UPDATE_ARREST

const updateArrestStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const updateArrestSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully updated arrest'
    })
}

const updateArrestFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// DELETE_ARREST

const deleteArrestStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const deleteArrestSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully deleted arrest'
    })
}

const deleteArrestFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_ARREST_MESSAGES:
            return clearArrestMessages(state, action);

        case actionTypes.GET_ARRESTS_START:
            return getArrestsStart(state, action);
        case actionTypes.GET_ARRESTS_SUCCESS:
            return getArrestsSuccess(state, action);
        case actionTypes.GET_ARRESTS_FAIL:
            return getArrestsFail(state, action);

        case actionTypes.CREATE_ARREST_START:
            return createArrestStart(state, action);
        case actionTypes.CREATE_ARREST_SUCCESS:
            return createArrestSuccess(state, action);
        case actionTypes.CREATE_ARREST_FAIL:
            return createArrestFail(state, action);

        case actionTypes.UPDATE_ARREST_START:
            return updateArrestStart(state, action);
        case actionTypes.UPDATE_ARREST_SUCCESS:
            return updateArrestSuccess(state, action);
        case actionTypes.UPDATE_ARREST_FAIL:
            return updateArrestFail(state, action);

        case actionTypes.DELETE_ARREST_START:
            return deleteArrestStart(state, action);
        case actionTypes.DELETE_ARREST_SUCCESS:
            return deleteArrestSuccess(state, action);
        case actionTypes.DELETE_ARREST_FAIL:
            return deleteArrestFail(state, action);

        default: return state;
    }
};

export default reducer;