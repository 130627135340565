import * as actionTypes from "./actionTypes";

// CLEAR INDEMNITOR MESSAGES
export const clearIndemnitorMessages = () => {
	return {
		type: actionTypes.CLEAR_INDEMNITORS_MESSAGES,
	};
};

// SET INDEMNITOR DATA 
export const setIndemnitorData = (data) => {
	return {
		type: actionTypes.SET_INDEMNITOR_DATA,
		data
	}
}

// GET INDEMNITORS
export const getIndemnitors = (data) => {
	return {
		type: actionTypes.GET_INDEMNITORS,
		data,
	};
};
export const getIndemnitorsStart = () => {
	return {
		type: actionTypes.GET_INDEMNITORS_START,
	};
};
export const getIndemnitorsSuccess = (response) => {
	return {
		type: actionTypes.GET_INDEMNITORS_SUCCESS,
		response,
	};
};
export const getIndemnitorsFail = (err) => {
	return {
		type: actionTypes.GET_INDEMNITORS_FAIL,
		err,
	};
};

// CREATE_INDEMNITOR
export const createIndemnitor = (data, shouldSendSMS, photosData, pdfData) => {
	return {
		type: actionTypes.CREATE_INDEMNITOR,
		data,
		shouldSendSMS,
		photosData,
		pdfData
	};
};
export const createIndemnitorStart = () => {
	return {
		type: actionTypes.CREATE_INDEMNITOR_START,
	};
};
export const createIndemnitorSuccess = (response) => {
	return {
		type: actionTypes.CREATE_INDEMNITOR_SUCCESS,
		response,
	};
};
export const createIndemnitorFail = (err) => {
	return {
		type: actionTypes.CREATE_INDEMNITOR_FAIL,
		err,
	};
};

// UPDATE INDEMNITOR
export const updateIndemnitor = (data, photosData, pdfData, element) => ({
	type: actionTypes.UPDATE_INDEMNITOR,
	data,
	photosData,
	pdfData,
	element
});

export const updateIndemnitorStart = () => ({
	type: actionTypes.UPDATE_INDEMNITOR_START,
});

export const updateIndemnitorSuccess = (response) => ({
	type: actionTypes.UPDATE_INDEMNITOR_SUCCESS,
	response,
});

export const updateIndemnitorFail = (err) => ({
	type: actionTypes.UPDATE_INDEMNITOR_FAIL,
	err,
});

// GET INDEMNITOR BY ID
export const getIndemnitor = (data) => ({
	type: actionTypes.GET_INDEMNITOR,
	data,
});

export const getIndemnitorStart = () => ({
	type: actionTypes.GET_INDEMNITOR_START,
});

export const getIndemnitorSuccess = (response) => ({
	type: actionTypes.GET_INDEMNITOR_SUCCESS,
	response,
});

export const getIndemnitorFail = (err) => ({
	type: actionTypes.GET_INDEMNITOR_FAIL,
	err,
});

// DELETE INDEMNITOR
export const deleteIndemnitor = (data) => ({
	type: actionTypes.DELETE_INDEMNITOR,
	data,
});

export const deleteIndemnitorStart = () => ({
	type: actionTypes.DELETE_INDEMNITOR_START,
});

export const deleteIndemnitorSuccess = (response) => ({
	type: actionTypes.DELETE_INDEMNITOR_SUCCESS,
	response,
});

export const deleteIndemnitorFail = (err) => ({
	type: actionTypes.DELETE_INDEMNITOR_FAIL,
	err,
});

// CHECK EXISTING INDEMNITOR
export const checkExistingIndemnitor = (data) => ({
	type: actionTypes.CHECK_EXISTING_INDEMNITOR,
	data,
});

export const checkExistingIndemnitorStart = () => ({
	type: actionTypes.CHECK_EXISTING_INDEMNITOR_START,
});

export const checkExistingIndemnitorSuccess = (response) => ({
	type: actionTypes.CHECK_EXISTING_INDEMNITOR_SUCCESS,
	response,
});

export const checkExistingIndemnitorFail = (err) => ({
	type: actionTypes.CHECK_EXISTING_INDEMNITOR_FAIL,
	err,
});

// UPLOAD INDEMNITOR PHOTOS
export const uploadIndemnitorPhotos = (data, fieldName) => ({
	type: actionTypes.UPLOAD_INDEMNITOR_PHOTOS,
	data,
	fieldName
});

export const uploadIndemnitorPhotosStart = (fieldName) => ({
	type: actionTypes.UPLOAD_INDEMNITOR_PHOTOS_START,
	fieldName
});

export const uploadIndemnitorPhotosSuccess = (response) => ({
	type: actionTypes.UPLOAD_INDEMNITOR_PHOTOS_SUCCESS,
	response,
});

export const uploadIndemnitorPhotosFail = (err) => ({
	type: actionTypes.UPLOAD_INDEMNITOR_PHOTOS_FAIL,
	err,
});

// DOWNLOAD INDEMNITOR PHOTO
export const downloadIndemnitorPhoto = (data) => ({
	type: actionTypes.DOWNLOAD_INDEMNITOR_PHOTO,
	data,
});

export const downloadIndemnitorPhotoStart = () => ({
	type: actionTypes.DOWNLOAD_INDEMNITOR_PHOTO_START,
});

export const downloadIndemnitorPhotoSuccess = (response) => ({
	type: actionTypes.DOWNLOAD_INDEMNITOR_PHOTO_SUCCESS,
	response,
});

export const downloadIndemnitorPhotoFail = (err) => ({
	type: actionTypes.DOWNLOAD_INDEMNITOR_PHOTO_FAIL,
	err,
});

