import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import clientDashboardRoutes from "routes/clientDashboard.jsx";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/white-illi-logo.png";
import devStatus from "shared/devStatus";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import ClientDefendants from "../containers/CRM/ClientDefentdants/ClientDefendants";

var ps;

class ClientDashboard extends React.Component {
	state = {
		mobileOpen: false,
		miniActive: false,
		dashRoutes: clientDashboardRoutes(),
	};
	// handleDrawerToggle = () => {
	//   this.setState({ mobileOpen: !this.state.mobileOpen });
	// };
	// getRoute() {
	//   return this.props.location.pathname !== "/maps/full-screen-maps";
	// }
	// componentDidMount() {
	//   if (navigator.platform.indexOf("Win") > -1) {
	//     ps = new PerfectScrollbar(this.refs.mainPanel, {
	//       suppressScrollX: true,
	//       suppressScrollY: false
	//     });
	//     document.body.style.overflow = "hidden";
	//   }
	// }
	// componentWillUnmount() {
	//   if (navigator.platform.indexOf("Win") > -1) {
	//     ps.destroy();
	//   }
	// }
	// componentDidUpdate(e) {
	//   if (e.history.location.pathname !== e.location.pathname) {
	//     this.refs.mainPanel.scrollTop = 0;
	//     if(this.state.mobileOpen){
	//       this.setState({mobileOpen: false})
	//     }
	//   }
	// }
	// sidebarMinimize() {
	//   this.setState({ miniActive: !this.state.miniActive });
	// }
	render() {
		const { classes, ...rest } = this.props;

		const switchRoutes = (
			<Switch>
				{this.state.dashRoutes.map((prop, key) => {
					// if (prop.redirect)
					// 	return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
					// if (prop.collapse)
					// 	return prop.views.map((prop, key) => {
					// 		return (
					// 			<Route path={prop.path} component={prop.component} key={key} />
					// 		);
					// 	});
					return (
						<Route path={prop.path} component={prop.component} key={key} />
					);
				})}
			</Switch>
		);

		return (
			<GridContainer>
				<GridItem xs={12} md={12} sm={12}>
					{switchRoutes}
					{/* <ClientDefendants /> */}
				</GridItem>
			</GridContainer>

			// <div>
			//   <div className={classes.wrapper}>
			//     <Sidebar
			//       loading={this.props.loading}
			//       routes={this.state.dashRoutes}
			//       logoText={(devStatus === 'live' || devStatus === 'local_live') ? 'Greatest Bail Bonds Portal' : 'Stage Portal'}
			//       logo={logo}
			//       image={image}
			//       handleDrawerToggle={this.handleDrawerToggle}
			//       open={this.state.mobileOpen}
			//       color="blue"
			//       bgColor="black"
			//       miniActive={this.state.miniActive}
			//       bp_data={this.props.bp_data}
			//       gin_data={this.props.gin_data}
			//       {...rest}
			//     />
			//     <div className={mainPanel} ref="mainPanel">
			//       {/* <Header
			//         sidebarMinimize={this.sidebarMinimize.bind(this)}
			//         miniActive={this.state.miniActive}
			//         routes={this.state.dashRoutes}
			//         handleDrawerToggle={this.handleDrawerToggle}
			//         {...rest}
			//       /> */}
			//       {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
			//       {this.getRoute() ? (
			//         <div className={classes.content}>
			//           <div className={classes.container}>{switchRoutes}</div>
			//         </div>
			//       ) : (
			//         <div className={classes.map}>{switchRoutes}</div>
			//       )}
			//       {this.getRoute() ? <Footer fluid /> : null}
			//     </div>
			//   </div>
			// </div>
		);
	}
}

ClientDashboard.propTypes = {
	// classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
	return {
		loading: state.auth.loading,
		bp_data: state.common,
		gin_data: state.gin,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientDashboard);
