/*
    Handling Users
*/
export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const GET_WORKSPACE_USERS = "GET_WORKSPACE_USERS";
export const GET_WORKSPACE_USERS_START = "GET_WORKSPACE_USERS_START";
export const GET_WORKSPACE_USERS_SUCCESS = "GET_WORKSPACE_USERS_SUCCESS";
export const GET_WORKSPACE_USERS_FAIL = "GET_WORKSPACE_USERS_FAIL";

export const SYNC_USERS = "SYNC_USERS";
export const SYNC_USERS_START = "SYNC_USERS_START";
export const SYNC_USERS_SUCCESS = "SYNC_USERS_SUCCESS";
export const SYNC_USERS_FAIL = "SYNC_USERS_FAIL";

export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_START = "CREATE_USER_START";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";

export const GET_ADMIN_USERS = "GET_ADMIN_USERS";
export const GET_ADMIN_USERS_START = "GET_ADMIN_USERS_START";
export const GET_ADMIN_USERS_SUCCESS = "GET_ADMIN_USERS_SUCCESS";
export const GET_ADMIN_USERS_FAIL = "GET_ADMIN_USERS_FAIL";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
/*
    Handling UserPerms
*/
export const GET_USERS = "GET_USERS";
export const GET_USERS_START = "GET_USERS_START";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

export const GET_USERS_FILTERED = "GET_USERS_FILTERED";
export const GET_USERS_FILTERED_START = "GET_USERS_FILTERED_START";
export const GET_USERS_FILTERED_SUCCESS = "GET_USERS_FILTERED_SUCCESS";
export const GET_USERS_FILTERED_FAIL = "GET_USERS_FILTERED_FAIL";

export const GET_USER = "GET_USER";
export const GET_USER_START = "GET_USER_START";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const GET_USER_BY_NAME = "GET_USER_BY_NAME";
export const GET_USER_BY_NAME_START = "GET_USER_BY_NAME_START";
export const GET_USER_BY_NAME_SUCCESS = "GET_USER_BY_NAME_SUCCESS";
export const GET_USER_BY_NAME_FAIL = "GET_USER_BY_NAME_FAIL";

export const GET_USER_BULK = "GET_USER_BULK";
export const GET_USER_BULK_START = "GET_USER_BULK_START";
export const GET_USER_BULK_SUCCESS = "GET_USER_BULK_SUCCESS";
export const GET_USER_BULK_FAIL = "GET_USER_BULK_FAIL";

export const SAVE_USER_PERMS = "SAVE_USER_PERMS";
export const SAVE_USER_PERMS_START = "SAVE_USER_PERMS_START";
export const SAVE_USER_PERMS_SUCCESS = "SAVE_USER_PERMS_SUCCESS";
export const SAVE_USER_PERMS_FAIL = "SAVE_USER_PERMS_FAIL";

export const CHOOSE_NEW_PERMS = "CHOOSE_NEW_PERMS";

export const USERS_SYNC = "USERS_SYNC";
export const SYNC_USER_START = "SYNC_USER_START";
export const SYNC_USER_SUCCESS = "SYNC_USER_SUCCESS";
export const SYNC_USER_FAIL = "SYNC_USER_FAIL";

export const BULK_SYNC = "BULK_SYNC";
export const BULK_SYNC_START = "BULK_SYNC_START";
export const BULK_SYNC_SUCCESS = "BULK_SYNC_SUCCESS";
export const BULK_SYNC_FAIL = "BULK_SYNC_FAIL";

export const BULK_ASSIGN = "BULK_ASSIGN";
export const BULK_ASSIGN_START = "BULK_ASSIGN_START";
export const BULK_ASSIGN_SUCCESS = "BULK_ASSIGN_SUCCESS";
export const BULK_ASSIGN_FAIL = "BULK_ASSIGN_FAIL";
export const BULK_ASSIGN_END = "BULK_ASSIGN_END";

export const NOTIFY_MAIL = "NOTIFY_MAIL";

/*Defendant user access*/
export const GET_DEFENDANT_USERS = 'GET_DEFENDANT_USERS';
export const GET_DEFENDANT_USERS_START = 'GET_DEFENDANT_USERS_START';
export const GET_DEFENDANT_USERS_SUCCESS = 'GET_DEFENDANT_USERS_SUCCESS';
export const GET_DEFENDANT_USERS_FAIL = 'GET_DEFENDANT_USERS_FAIL';

export const CREATE_DEFENDANT_USERS = 'CREATE_DEFENDANT_USERS';
export const CREATE_DEFENDANT_USERS_START = 'CREATE_DEFENDANT_USERS_START';
export const CREATE_DEFENDANT_USERS_SUCCESS = 'CREATE_DEFENDANT_USERS_SUCCESS';
export const CREATE_DEFENDANT_USERS_FAIL = 'CREATE_DEFENDANT_USERS_FAIL';

export const DELETE_DEFENDANT_USERS = 'DELETE_DEFENDANT_USERS';
export const DELETE_DEFENDANT_USERS_START = 'DELETE_DEFENDANT_USERS_START';
export const DELETE_DEFENDANT_USERS_SUCCESS = 'DELETE_DEFENDANT_USERS_SUCCESS';
export const DELETE_DEFENDANT_USERS_FAIL = 'DELETE_DEFENDANT_USERS_FAIL';

/*
  Settings
*/

export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const UPDATE_SETTINGS_START = "UPDATE_SETTINGS_START";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_FAIL = "UPDATE_SETTINGS_FAIL";

export const READ_SETTINGS = "READ_SETTINGS";
export const READ_SETTINGS_START = "READ_SETTINGS_START";
export const READ_SETTINGS_SUCCESS = "READ_SETTINGS_SUCCESS";
export const READ_SETTINGS_FAIL = "READ_SETTINGS_FAIL";

export const MAINTENANCE_MODE = "MAINTENANCE_MODE";
export const MAINTENANCE_MODE_SUCCESS = "MAINTENANCE_MODE_SUCCESS";
export const CHECK_MAINTENANCE_MODE = "CHECK_MAINTENANCE_MODE";
export const CHECK_MAINTENANCE_MODE_SUCCESS = "CHECK_MAINTENANCE_MODE_SUCCESS";

export const TPP_MAINTENANCE = "TPP_MAINTENANCE";
export const TPP_MAINTENANCE_START = "TPP_MAINTENANCE_START";
export const TPP_MAINTENANCE_SUCCESS = "TPP_MAINTENANCE_SUCCESS";
export const TPP_MAINTENANCE_FAIL = "TPP_MAINTENANCE_FAIL";

export const TPP_MAINTENANCE_CHECK = "TPP_MAINTENANCE_CHECK";
export const TPP_MAINTENANCE_CHECK_START = "TPP_MAINTENANCE_CHECK_START";
export const TPP_MAINTENANCE_CHECK_SUCCESS = "TPP_MAINTENANCE_CHECK_SUCCESS";
export const TPP_MAINTENANCE_CHECK_FAIL = "TPP_MAINTENANCE_CHECK_FAIL";

/* 
    CRM
*/

export const CLEAR_CRM_MESSAGES = "CLEAR_CRM_MESSAGES";

export const GET_DEFENDANTS = "GET_DEFENDANTS";
export const GET_DEFENDANTS_START = "GET_DEFENDANTS_START";
export const GET_DEFENDANTS_SUCCESS = "GET_DEFENDANTS_SUCCESS";
export const GET_DEFENDANTS_FAIL = "GET_DEFENDANTS_FAIL";

export const GET_LEADS = 'GET_LEADS';
export const GET_LEADS_START = 'GET_LEADS_START';
export const GET_LEADS_SUCCESS = 'GET_LEADS_SUCCESS';
export const GET_LEADS_FAIL = 'GET_LEADS_FAIL';

export const CREATE_DEFENDANT = "CREATE_DEFENDANT";
export const CREATE_DEFENDANT_START = "CREATE_DEFENDANT_START";
export const CREATE_DEFENDANT_SUCCESS = "CREATE_DEFENDANT_SUCCESS";
export const CREATE_DEFENDANT_FAIL = "CREATE_DEFENDANT_FAIL";

export const UPDATE_DEFENDANT = "UPDATE_DEFENDANT";
export const UPDATE_DEFENDANT_START = "UPDATE_DEFENDANT_START";
export const UPDATE_DEFENDANT_SUCCESS = "UPDATE_DEFENDANT_SUCCESS";
export const UPDATE_DEFENDANT_FAIL = "UPDATE_DEFENDANT_FAIL";

export const GET_DEFENDANT = 'GET_DEFENDANT';
export const GET_DEFENDANT_START = 'GET_DEFENDANT_START';
export const GET_DEFENDANT_SUCCESS = 'GET_DEFENDANT_SUCCESS';
export const GET_DEFENDANT_FAIL = 'GET_DEFENDANT_FAIL';

export const DELETE_DEFENDANT = 'DELETE_DEFENDANT';
export const DELETE_DEFENDANT_START = 'DELETE_DEFENDANT_START';
export const DELETE_DEFENDANT_SUCCESS = 'DELETE_DEFENDANT_SUCCESS';
export const DELETE_DEFENDANT_FAIL = 'DELETE_DEFENDANT_FAIL';

export const CHECK_EXISTING_DEFENDANT = 'CHECK_EXISTING_DEFENDANT';
export const CHECK_EXISTING_DEFENDANT_START = 'CHECK_EXISTING_DEFENDANT_START';
export const CHECK_EXISTING_DEFENDANT_SUCCESS = 'CHECK_EXISTING_DEFENDANT_SUCCESS';
export const CHECK_EXISTING_DEFENDANT_FAIL = 'CHECK_EXISTING_DEFENDANT_FAIL';

export const RESEND_SMS_TO_DEFENDANT = 'RESEND_SMS_TO_DEFENDANT';
export const RESEND_SMS_TO_DEFENDANT_START = 'RESEND_SMS_TO_DEFENDANT_START';
export const RESEND_SMS_TO_DEFENDANT_SUCCESS = 'RESEND_SMS_TO_DEFENDANT_SUCCESS';
export const RESEND_SMS_TO_DEFENDANT_FAIL = 'RESEND_SMS_TO_DEFENDANT_FAIL';

export const SEND_SMS_TO_DEFENDANT = 'SEND_SMS_TO_DEFENDANT';
export const SEND_SMS_TO_DEFENDANT_START = 'SEND_SMS_TO_DEFENDANT_START';
export const SEND_SMS_TO_DEFENDANT_SUCCESS = 'SEND_SMS_TO_DEFENDANT_SUCCESS';
export const SEND_SMS_TO_DEFENDANT_FAIL = 'SEND_SMS_TO_DEFENDANT_FAIL';

// BOOKING
export const GET_BOOKINGS = "GET_BOOKINGS";
export const GET_BOOKINGS_START = "GET_BOOKINGS_START";
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS";
export const GET_BOOKINGS_FAIL = "GET_BOOKINGS_FAIL";

export const CREATE_BOOKING = "CREATE_BOOKING";
export const CREATE_BOOKING_START = "CREATE_BOOKING_START";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";
export const CREATE_BOOKING_FAIL = "CREATE_BOOKING_FAIL";

export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const UPDATE_BOOKING_START = "UPDATE_BOOKING_START";
export const UPDATE_BOOKING_SUCCESS = "UPDATE_BOOKING_SUCCESS";
export const UPDATE_BOOKING_FAIL = "UPDATE_BOOKING_FAIL";

export const GET_BOOKING = 'GET_BOOKING';
export const GET_BOOKING_START = 'GET_BOOKING_START';
export const GET_BOOKING_SUCCESS = 'GET_BOOKING_SUCCESS';
export const GET_BOOKING_FAIL = 'GET_BOOKING_FAIL';

export const DELETE_BOOKING = 'DELETE_BOOKING';
export const DELETE_BOOKING_START = 'DELETE_BOOKING_START';
export const DELETE_BOOKING_SUCCESS = 'DELETE_BOOKING_SUCCESS';
export const DELETE_BOOKING_FAIL = 'DELETE_BOOKING_FAIL';

export const CLEAR_BOOKING_MESSAGES = 'CLEAR_BOOKING_MESSAGES';


// INDEMNITORS
export const CLEAR_INDEMNITORS_MESSAGES = 'CLEAR_INDEMNITORS_MESSAGES';

export const GET_INDEMNITORS = "GET_INDEMNITORS";
export const GET_INDEMNITORS_START = "GET_INDEMNITORS_START";
export const GET_INDEMNITORS_SUCCESS = "GET_INDEMNITORS_SUCCESS";
export const GET_INDEMNITORS_FAIL = "GET_INDEMNITORS_FAIL";

export const GET_INDEMNITOR = 'GET_INDEMNITOR';
export const GET_INDEMNITOR_START = 'GET_INDEMNITOR_START';
export const GET_INDEMNITOR_SUCCESS = 'GET_INDEMNITOR_SUCCESS';
export const GET_INDEMNITOR_FAIL = 'GET_INDEMNITOR_FAIL';

export const CREATE_INDEMNITOR = "CREATE_INDEMNITOR";
export const CREATE_INDEMNITOR_START = "CREATE_INDEMNITOR_START";
export const CREATE_INDEMNITOR_SUCCESS = "CREATE_INDEMNITOR_SUCCESS";
export const CREATE_INDEMNITOR_FAIL = "CREATE_INDEMNITOR_FAIL";

export const UPDATE_INDEMNITOR = "UPDATE_INDEMNITOR";
export const UPDATE_INDEMNITOR_START = "UPDATE_INDEMNITOR_START";
export const UPDATE_INDEMNITOR_SUCCESS = "UPDATE_INDEMNITOR_SUCCESS";
export const UPDATE_INDEMNITOR_FAIL = "UPDATE_INDEMNITOR_FAIL";

export const DELETE_INDEMNITOR = 'DELETE_INDEMNITOR';
export const DELETE_INDEMNITOR_START = 'DELETE_INDEMNITOR_START';
export const DELETE_INDEMNITOR_SUCCESS = 'DELETE_INDEMNITOR_SUCCESS';
export const DELETE_INDEMNITOR_FAIL = 'DELETE_INDEMNITOR_FAIL';

export const CHECK_EXISTING_INDEMNITOR = 'CHECK_EXISTING_INDEMNITOR';
export const CHECK_EXISTING_INDEMNITOR_START = 'CHECK_EXISTING_INDEMNITOR_START';
export const CHECK_EXISTING_INDEMNITOR_SUCCESS = 'CHECK_EXISTING_INDEMNITOR_SUCCESS';
export const CHECK_EXISTING_INDEMNITOR_FAIL = 'CHECK_EXISTING_INDEMNITOR_FAIL';

export const UPLOAD_INDEMNITOR_PHOTOS = "UPLOAD_INDEMNITOR_PHOTOS";
export const UPLOAD_INDEMNITOR_PHOTOS_START = "UPLOAD_INDEMNITOR_PHOTOS_START";
export const UPLOAD_INDEMNITOR_PHOTOS_SUCCESS = "UPLOAD_INDEMNITOR_PHOTOS_SUCCESS";
export const UPLOAD_INDEMNITOR_PHOTOS_FAIL = "UPLOAD_INDEMNITOR_PHOTOS_FAIL";

export const DOWNLOAD_INDEMNITOR_PHOTO = "DOWNLOAD_INDEMNITOR_PHOTO";
export const DOWNLOAD_INDEMNITOR_PHOTO_START = "DOWNLOAD_INDEMNITOR_PHOTO";
export const DOWNLOAD_INDEMNITOR_PHOTO_SUCCESS = "DOWNLOAD_INDEMNITOR_PHOTO_SUCCESS";
export const DOWNLOAD_INDEMNITOR_PHOTO_FAIL = "DOWNLOAD_INDEMNITOR_PHOTO_FAIL";

export const SET_INDEMNITOR_DATA = "SET_INDEMNITOR_DATA";

// HISTORIES
export const CLEAR_HISTORY_MESSAGES = 'CLEAR_INDEMNITORS_MESSAGES';

export const GET_HISTORIES = 'GET_HISTORIES';
export const GET_HISTORIES_START = 'GET_HISTORIES_START';
export const GET_HISTORIES_SUCCESS = 'GET_HISTORIES_SUCCESS';
export const GET_HISTORIES_FAIL = 'GET_HISTORIES_FAIL';

export const GET_HISTORY = 'GET_HISTORY';
export const GET_HISTORY_START = 'GET_HISTORY_START';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const GET_HISTORY_FAIL = 'GET_HISTORY_FAIL';


// ARREST
export const GET_ARRESTS = "GET_ARRESTS";
export const GET_ARRESTS_START = "GET_ARRESTS_START";
export const GET_ARRESTS_SUCCESS = "GET_ARRESTS_SUCCESS";
export const GET_ARRESTS_FAIL = "GET_ARRESTS_FAIL";

export const CREATE_ARREST = "CREATE_ARREST";
export const CREATE_ARREST_START = "CREATE_ARREST_START";
export const CREATE_ARREST_SUCCESS = "CREATE_ARREST_SUCCESS";
export const CREATE_ARREST_FAIL = "CREATE_ARREST_FAIL";

export const UPDATE_ARREST = "UPDATE_ARREST";
export const UPDATE_ARREST_START = "UPDATE_ARREST_START";
export const UPDATE_ARREST_SUCCESS = "UPDATE_ARREST_SUCCESS";
export const UPDATE_ARREST_FAIL = "UPDATE_ARREST_FAIL";

export const GET_ARREST = 'GET_ARREST';
export const GET_ARREST_START = 'GET_ARREST_START';
export const GET_ARREST_SUCCESS = 'GET_ARREST_SUCCESS';
export const GET_ARREST_FAIL = 'GET_ARREST_FAIL';

export const DELETE_ARREST = 'DELETE_ARREST';
export const DELETE_ARREST_START = 'DELETE_ARREST_START';
export const DELETE_ARREST_SUCCESS = 'DELETE_ARREST_SUCCESS';
export const DELETE_ARREST_FAIL = 'DELETE_ARREST_FAIL';

export const CLEAR_ARREST_MESSAGES = 'CLEAR_ARREST_MESSAGES';


// CLIENTS
export const CLIENT_AUTH_REQUEST = "CLIENT_AUTH_REQUEST";
export const CLIENT_AUTH_SUCCESS = "CLIENT_AUTH_SUCCESS";
export const CLIENT_AUTH_FAILURE = "CLIENT_AUTH_FAILURE";
export const CLIENT_AUTH_LOGOUT = "CLIENT_AUTH_LOGOUT";

export const UPLOAD_DEFENDANT_PHOTOS = 'UPLOAD_DEFENDANT_PHOTOS';
export const UPLOAD_DEFENDANT_PHOTOS_START = 'UPLOAD_DEFENDANT_PHOTOS_START';
export const UPLOAD_DEFENDANT_PHOTOS_SUCCESS = 'UPLOAD_DEFENDANT_PHOTOS_SUCCESS';
export const UPLOAD_DEFENDANT_PHOTOS_FAIL = 'UPLOAD_DEFENDANT_PHOTOS_FAIL';

export const DOWNLOAD_DEFENDANT_PHOTO = "DOWNLOAD_DEFENDANT_PHOTO";
export const DOWNLOAD_DEFENDANT_PHOTO_START = "DOWNLOAD_DEFENDANT_PHOTO_START";
export const DOWNLOAD_DEFENDANT_PHOTO_SUCCESS = "DOWNLOAD_DEFENDANT_PHOTO_SUCCESS";
export const DOWNLOAD_DEFENDANT_PHOTO_FAIL = "DOWNLOAD_DEFENDANT_PHOTO_FAIL";

export const SEND_SMS_ONLY = 'SEND_SMS_ONLY';
export const SEND_SMS_ONLY_START = 'SEND_SMS_ONLY_START';
export const SEND_SMS_ONLY_SUCCESS = 'SEND_SMS_ONLY_SUCCESS';
export const SEND_SMS_ONLY_FAIL = 'SEND_SMS_ONLY_FAIL';

// DOCUMENTS
export const SEND_FCS = 'SEND_FCS';
export const SEND_FCS_START = 'SEND_FCS_START';
export const SEND_FCS_SUCCESS = 'SEND_FCS_SUCCESS';
export const SEND_FCS_FAIL = 'SEND_FCS_FAIL';

// GET DOCUMENT PDF
export const GET_DOCUMENT_PDF = 'GET_DOCUMENT_PDF';
export const GET_DOCUMENT_PDF_START = 'GET_DOCUMENT_PDF_START';
export const GET_DOCUMENT_PDF_SUCCESS = 'GET_DOCUMENT_PDF_SUCCESS';
export const GET_DOCUMENT_PDF_FAIL = 'GET_DOCUMENT_PDF_FAIL';
export const SET_DOCUMENT_PDF = 'SET_DOCUMENT_PDF';

// SEND SINGLE PDF
export const SEND_SINGLE_PDF = 'SEND_SINGLE_PDF'
export const SEND_SINGLE_PDF_START = 'SEND_SINGLE_PDF_START';
export const SEND_SINGLE_PDF_SUCCESS = 'SEND_SINGLE_PDF_SUCCESS';
export const SEND_SINGLE_PDF_FAIL = 'SEND_SINGLE_PDF_FAIL';

// SEND CC
export const SEND_CC = 'SEND_CC';
export const SEND_CC_START = 'SEND_CC_START';
export const SEND_CC_SUCCESS = 'SEND_CC_SUCCESS';
export const SEND_CC_FAIL = 'SEND_CC_FAIL';

// SETTINGS 
export const GET_SMS_CONFIG = 'GET_SMS_CONFIG';
export const GET_SMS_CONFIG_START = 'GET_SMS_CONFIG_START';
export const GET_SMS_CONFIG_SUCCESS = 'GET_SMS_CONFIG_SUCCESS';
export const GET_SMS_CONFIG_FAIL = 'GET_SMS_CONFIG_FAIL';

export const UPDATE_SMS_CONFIG = 'UPDATE_SMS_CONFIG';
export const UPDATE_SMS_CONFIG_START = 'UPDATE_SMS_CONFIG_START';
export const UPDATE_SMS_CONFIG_SUCCESS = 'UPDATE_SMS_CONFIG_SUCCESS';
export const UPDATE_SMS_CONFIG_FAIL = 'UPDATE_SMS_CONFIG_FAIL';

export const DELETE_SMS_CONFIG = 'DELETE_SMS_CONFIG';
export const DELETE_SMS_CONFIG_START = 'DELETE_SMS_CONFIG_START';
export const DELETE_SMS_CONFIG_SUCCESS = 'DELETE_SMS_CONFIG_SUCCESS';
export const DELETE_SMS_CONFIG_FAIL = 'DELETE_SMS_CONFIG_FAIL';

export const CREATE_SMS_CONFIG = 'CREATE_SMS_CONFIG';
export const CREATE_SMS_CONFIG_START = 'CREATE_SMS_CONFIG_START';
export const CREATE_SMS_CONFIG_SUCCESS = 'CREATE_SMS_CONFIG_SUCCESS';
export const CREATE_SMS_CONFIG_FAIL = 'CREATE_SMS_CONFIG_FAIL';

// SEND REMINDER SMS
export const SEND_REMINDER = 'SEND_REMINDER';
export const SEND_REMINDER_START = 'SEND_REMINDER_START';
export const SEND_REMINDER_SUCCESS = 'SEND_REMINDER_SUCCESS';
export const SEND_REMINDER_FAIL = 'SEND_REMINDER_FAIL';

// DOWNLOAD DOCUSIGN PDF
export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT';
export const DOWNLOAD_DOCUMENT_START = 'DOWNLOAD_DOCUMENT_START';
export const DOWNLOAD_DOCUMENT_SUCCESS = 'DOWNLOAD_DOCUMENT_SUCCESS';
export const DOWNLOAD_DOCUMENT_FAIL = 'DOWNLOAD_DOCUMENT_FAIL';

