import * as actionTypes from "../actions/actionTypes";
import { updateObject, clone } from "../../shared/utility";

const initialState = {
	error: null,
	success: null,
	defendantsData: [],
	defendant: null,
	indemnitorsData: [],
	indemnitor: null,
	booking: null,
	error_upload_defendant_photos: null,
	success_upload_defendant_photos: null,
	loading_upload_defendant_photos: '',
	defendantUsers: null,

};

// MESSAGES
const clearCRMMessages = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

// GET_DEFENDANTS
const getDefendantsStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};
const getDefendantsSuccess = (state, action) => {
	return updateObject(state, {
		// success: "Successfully got defendants",
		defendantsData: action.response,
	});
};
const getDefendantsFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// GET_LEADS
const getLeadsStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};
const getLeadsSuccess = (state, action) => {
	return updateObject(state, {
		// success: "Successfully got leads",
		defendantsData: action.response,
	});
};
const getLeadsFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// CREATE_DEFENDANT
const createDefendantStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};
const createDefendantSuccess = (state, action) => {
	let message = "Successfully created defendant";
	if (
		action.response &&
		action.response.msg === "The phone number is used by another defendant"
	) {
		message = action.response.msg;
	}
	return updateObject(state, {
		success: message,
	});
};
const createDefendantFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// UPDATE DEFENDANT
const updateDefendantStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const updateDefendantSuccess = (state, action) => {
	let message = "Successfully updated defendant";
	if (
		action.response &&
		action.response.msg === "The phone number is used by another defendant"
	) {
		message = action.response.msg;
	}
	return updateObject(state, {
		success: message,
	});
};

const updateDefendantFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// GET DEFENDANT
const getDefendantStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getDefendantSuccess = (state, action) => {
	console.log('gotten defendant', action.response)
	const defendants = clone(action.response);
	const defendant = defendants[0];
	if (defendant.ex_parent_1_children) {
		if (typeof defendant.ex_parent_1_children === 'string') {
			defendant.ex_parent_1_children = [];
		}
	}
	if (defendant.ex_parent_2_children) {
		if (typeof defendant.ex_parent_2_children === 'string') {
			defendant.ex_parent_2_children = [];
		}
	}
	if (defendant.ex_parent_3_children) {
		if (typeof defendant.ex_parent_3_children === 'string') {
			defendant.ex_parent_3_children = [];
		}
	}
	return updateObject(state, {
		defendant: [defendant],
	});
};

const getDefendantFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// DELETE DEFENDANT
const deleteDefendantStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const deleteDefendantSuccess = (state, action) => {
	return updateObject(state, {
		success: "Successfully deleted defendant",
	});
};
const deleteDefendantFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// INDEMNITORS
const getIndemnitorsStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};
const getIndemnitorsSuccess = (state, action) => {
	return updateObject(state, {
		success: "Successfully got indemnitors",
		indemnitorsData: action.response,
	});
};
const getIndemnitorsFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// CREATE_INDEMNITOR
const createIndemnitorStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};
const createIndemnitorSuccess = (state, action) => {
	return updateObject(state, {
		success: "Successfully created indemnitor",
	});
};
const createIndemnitorFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// UPDATE INDEMNITOR
const updateIndemnitorStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const updateIndemnitorSuccess = (state, action) => {
	return updateObject(state, {
		success: "Successfully updated indemnitor",
	});
};

const updateIndemnitorFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// GET INDEMNITOR
const getIndemnitorStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getIndemnitorSuccess = (state, action) => {
	return updateObject(state, {
		indemnitor: action.response,
	});
};

const getIndemnitorFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// DELETE INDEMNITOR
const deleteIndemnitorStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const deleteIndemnitorSuccess = (state, action) => {
	return updateObject(state, {
		success: "Successfully deleted indemnitor",
	});
};
const deleteIndemnitorFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// SEND SMS
const sendSmsToDefendantStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const sendSmsToDefendantSuccess = (state, action) => {
	return updateObject(state, {
		success: `Link sent to ${action.response}`,
	});
};

const sendSmsToDefendantFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// RESEND SMS
const resendSmsToDefendantStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const resendSmsToDefendantSuccess = (state, action) => {
	return updateObject(state, {
		success: `Link sent to ${action.response}`,
	});
};

const resendSmsToDefendantFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// UPLOAD_DEFENDANT_PHOTOS
const uploadDefendantPhotosStart = (state, action) => {
	return updateObject(state, {
		error_upload_defendant_photos: null,
		success_upload_defendant_photos: null,
		loading_upload_defendant_photos: action.fieldName,
	})
}
const uploadDefendantPhotosSuccess = (state, action) => {
	return updateObject(state, {
		success_upload_defendant_photos: 'Successfully uploaded defendant photo(s)',
		loading_upload_defendant_photos: '',
	})
}
const uploadDefendantPhotosFail = (state, action) => {
	return updateObject(state, {
		error_upload_defendant_photos: action.err,
		loading_upload_defendant_photos: '',
	})
}

const getDefendantUsersStart = (state, action) => {
  return updateObject(state, {
    error: null,
    success: null
  })
}
const getDefendantUsersSuccess = (state, action) => {
	console.log(action)
  return updateObject(state, {
    defendantUsers: action.response
  })
}
const getDefendantUsersFail = (state, action) => {
  return updateObject(state, {
    error: action.err
  })
}

const createDefendantUsersStart = (state, action) => {
  return updateObject(state, {
    error: null,
    success: null
  })
}
const createDefendantUsersSuccess = (state, action) => {
  return updateObject(state, {
  })
}
const createDefendantUsersFail = (state, action) => {
  return updateObject(state, {
    error: action.err
  })
}

const deleteDefendantUsersStart = (state, action) => {
  return updateObject(state, {
    error: null,
    success: null
  })
}
const deleteDefendantUsersSuccess = (state, action) => {
  return updateObject(state, {
  })
}
const deleteDefendantUsersFail = (state, action) => {
  return updateObject(state, {
    error: action.err
  })
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CRM_MESSAGES:
			return clearCRMMessages(state, action);

		case actionTypes.GET_DEFENDANTS_START:
			return getDefendantsStart(state, action);
		case actionTypes.GET_DEFENDANTS_SUCCESS:
			return getDefendantsSuccess(state, action);
		case actionTypes.GET_DEFENDANTS_FAIL:
			return getDefendantsFail(state, action);

		case actionTypes.GET_LEADS_START:
			return getLeadsStart(state, action);
		case actionTypes.GET_LEADS_SUCCESS:
			return getLeadsSuccess(state, action);
		case actionTypes.GET_LEADS_FAIL:
			return getLeadsFail(state, action);

		case actionTypes.CREATE_DEFENDANT_START:
			return createDefendantStart(state, action);
		case actionTypes.CREATE_DEFENDANT_SUCCESS:
			return createDefendantSuccess(state, action);
		case actionTypes.CREATE_DEFENDANT_FAIL:
			return createDefendantFail(state, action);

		case actionTypes.UPDATE_DEFENDANT_START:
			return updateDefendantStart(state, action);
		case actionTypes.UPDATE_DEFENDANT_SUCCESS:
			return updateDefendantSuccess(state, action);
		case actionTypes.UPDATE_DEFENDANT_FAIL:
			return updateDefendantFail(state, action);

		case actionTypes.GET_DEFENDANT_START:
			return getDefendantStart(state, action);
		case actionTypes.GET_DEFENDANT_SUCCESS:
			return getDefendantSuccess(state, action);
		case actionTypes.GET_DEFENDANT_FAIL:
			return getDefendantFail(state, action);

		case actionTypes.DELETE_DEFENDANT_START:
			return deleteDefendantStart(state, action);
		case actionTypes.DELETE_DEFENDANT_SUCCESS:
			return deleteDefendantSuccess(state, action);
		case actionTypes.DELETE_DEFENDANT_FAIL:
			return deleteDefendantFail(state, action);

		// INDEMNITORS
		case actionTypes.GET_INDEMNITORS_START:
			return getIndemnitorsStart(state, action);
		case actionTypes.GET_INDEMNITORS_SUCCESS:
			return getIndemnitorsSuccess(state, action);
		case actionTypes.GET_INDEMNITORS_FAIL:
			return getIndemnitorsFail(state, action);

		case actionTypes.CREATE_INDEMNITOR_START:
			return createIndemnitorStart(state, action);
		case actionTypes.CREATE_INDEMNITOR_SUCCESS:
			return createIndemnitorSuccess(state, action);
		case actionTypes.CREATE_INDEMNITOR_FAIL:
			return createIndemnitorFail(state, action);

		case actionTypes.UPDATE_INDEMNITOR_START:
			return updateIndemnitorStart(state, action);
		case actionTypes.UPDATE_INDEMNITOR_SUCCESS:
			return updateIndemnitorSuccess(state, action);
		case actionTypes.UPDATE_SETTINGS_FAIL:
			return updateIndemnitorFail(state, action);

		case actionTypes.GET_INDEMNITOR_START:
			return getIndemnitorStart(state, action);
		case actionTypes.GET_INDEMNITOR_SUCCESS:
			return getIndemnitorSuccess(state, action);
		case actionTypes.GET_INDEMNITOR_FAIL:
			return getIndemnitorFail(state, action);

		case actionTypes.DELETE_INDEMNITOR_START:
			return deleteIndemnitorStart(state, action);
		case actionTypes.DELETE_INDEMNITOR_SUCCESS:
			return deleteIndemnitorSuccess(state, action);
		case actionTypes.DELETE_INDEMNITOR_FAIL:
			return deleteIndemnitorFail(state, action);

		case actionTypes.SEND_SMS_TO_DEFENDANT_START:
			return sendSmsToDefendantStart(state, action);
		case actionTypes.SEND_SMS_TO_DEFENDANT_SUCCESS:
			return sendSmsToDefendantSuccess(state, action);
		case actionTypes.SEND_SMS_TO_DEFENDANT_FAIL:
			return sendSmsToDefendantFail(state, action);

		case actionTypes.RESEND_SMS_TO_DEFENDANT_START:
			return resendSmsToDefendantStart(state, action);
		case actionTypes.RESEND_SMS_TO_DEFENDANT_SUCCESS:
			return resendSmsToDefendantSuccess(state, action);
		case actionTypes.RESEND_SMS_TO_DEFENDANT_FAIL:
			return resendSmsToDefendantFail(state, action);

		case actionTypes.UPLOAD_DEFENDANT_PHOTOS_START:
			return uploadDefendantPhotosStart(state, action);
		case actionTypes.UPLOAD_DEFENDANT_PHOTOS_SUCCESS:
			return uploadDefendantPhotosSuccess(state, action);
		case actionTypes.UPLOAD_DEFENDANT_PHOTOS_FAIL:
			return uploadDefendantPhotosFail(state, action);

		case actionTypes.GET_DEFENDANT_USERS_START:
			return getDefendantUsersStart(state, action);
		case actionTypes.GET_DEFENDANT_USERS_SUCCESS:
			return getDefendantUsersSuccess(state, action);
		case actionTypes.GET_DEFENDANT_USERS_FAIL:
			return getDefendantUsersFail(state, action);

		case actionTypes.CREATE_DEFENDANT_USERS_START:
			return createDefendantUsersStart(state, action);
		case actionTypes.CREATE_DEFENDANT_USERS_SUCCESS:
			return createDefendantUsersSuccess(state, action);
		case actionTypes.CREATE_DEFENDANT_USERS_FAIL:
			return createDefendantUsersFail(state, action);

		case actionTypes.DELETE_DEFENDANT_USERS_START:
			return deleteDefendantUsersStart(state, action);
		case actionTypes.DELETE_DEFENDANT_USERS_SUCCESS:
			return deleteDefendantUsersSuccess(state, action);
		case actionTypes.DELETE_DEFENDANT_USERS_FAIL:
			return deleteDefendantUsersFail(state, action);

		default:
			return state;
	}
};

export default reducer;
