import * as actionTypes from "./actionTypes";

// MESSAGES

export const clearCRMMessages = () => {
	return {
		type: actionTypes.CLEAR_CRM_MESSAGES,
	};
};

// GET_DEFENDANTS
export const getDefendants = (data) => {
	return {
		type: actionTypes.GET_DEFENDANTS,
		data,
	};
};
export const getDefendantsStart = () => {
	return {
		type: actionTypes.GET_DEFENDANTS_START,
	};
};
export const getDefendantsSuccess = (response) => {
	return {
		type: actionTypes.GET_DEFENDANTS_SUCCESS,
		response,
	};
};
export const getDefendantsFail = (err) => {
	return {
		type: actionTypes.GET_DEFENDANTS_FAIL,
		err,
	};
};

// GET_LEADS
export const getLeads = (data) => {
	return {
		type: actionTypes.GET_LEADS,
		data,
	};
};
export const getLeadsStart = () => {
	return {
		type: actionTypes.GET_LEADS_START,
	};
};
export const getLeadsSuccess = (response) => {
	return {
		type: actionTypes.GET_LEADS_SUCCESS,
		response,
	};
};
export const getLeadsFail = (err) => {
	return {
		type: actionTypes.GET_LEADS_FAIL,
		err,
	};
};

// CREATE_DEFENDANT
export const createDefendant = (data, bookingData, indemnitorData, arrestData, shouldSendSMS, photosData) => {
	return {
		type: actionTypes.CREATE_DEFENDANT,
		data,
        bookingData,
		indemnitorData,
		arrestData,
		shouldSendSMS,
		photosData
	};
};
export const createDefendantStart = () => {
	return {
		type: actionTypes.CREATE_DEFENDANT_START,
	};
};
export const createDefendantSuccess = (response) => {
	return {
		type: actionTypes.CREATE_DEFENDANT_SUCCESS,
		response,
	};
};
export const createDefendantFail = (err) => {
	return {
		type: actionTypes.CREATE_DEFENDANT_FAIL,
		err,
	};
};

// UPDATE DEFENDANT
export const updateDefendant = (data, photoUpdate, photoUpdateElement, pdfData) => ({
	type: actionTypes.UPDATE_DEFENDANT,
	data,
	photoUpdate, 
	photoUpdateElement,
	pdfData
});

export const updateDefendantStart = () => ({
	type: actionTypes.UPDATE_DEFENDANT_START,
});

export const updateDefendantSuccess = (response) => ({
	type: actionTypes.UPDATE_DEFENDANT_SUCCESS,
	response,
});

export const updateDefendantFail = (err) => ({
	type: actionTypes.UPDATE_DEFENDANT_FAIL,
	err,
});

// GET DEFENDANT BY ID
export const getDefendant = (data) => ({
	type: actionTypes.GET_DEFENDANT,
	data,
});

export const getDefendantStart = () => ({
	type: actionTypes.GET_DEFENDANT_START,
});

export const getDefendantSuccess = (response) => ({
	type: actionTypes.GET_DEFENDANT_SUCCESS,
	response,
});

export const getDefendantFail = (err) => ({
	type: actionTypes.GET_DEFENDANT_FAIL,
	err,
});

export const deleteDefendant = (data) => ({
	type: actionTypes.DELETE_DEFENDANT,
	data,
});

export const deleteDefendantStart = () => ({
	type: actionTypes.DELETE_DEFENDANT_START,
});

export const deleteDefendantSuccess = (response) => ({
	type: actionTypes.DELETE_DEFENDANT_SUCCESS,
	response,
});

export const deleteDefendantFail = (err) => ({
	type: actionTypes.DELETE_DEFENDANT_FAIL,
	err,
});

export const checkExistingDefendant = (data) => ({
	type: actionTypes.CHECK_EXISTING_DEFENDANT,
	data,
});

export const checkExistingDefendantStart = () => ({
	type: actionTypes.CHECK_EXISTING_DEFENDANT_START,
});

export const checkExistingDefendantSuccess = (response) => ({
	type: actionTypes.CHECK_EXISTING_DEFENDANT_SUCCESS,
	response,
});

export const checkExistingDefendantFail = (err) => ({
	type: actionTypes.CHECK_EXISTING_DEFENDANT_FAIL,
	err,
});

// // SEND SMS
// export const sendSmsToDefendant = (data) => ({
// 	type: actionTypes.SEND_SMS_TO_DEFENDANT,
// 	data,
// })

// export const sendSmsToDefendantStart = () => ({
// 	type: actionTypes.SEND_SMS_TO_DEFENDANT_START,
// })

// export const sendSmsToDefendantSuccess = (response) => ({
// 	type: actionTypes.SEND_SMS_TO_DEFENDANT_SUCCESS,
// 	response,
// })

// export const sendSmsToDefendantFail = (err) => ({
// 	type: actionTypes.SEND_SMS_TO_DEFENDANT_FAIL,
// 	err,
// })

// // RESEND SMS
// export const resendSmsToDefendant = (data) => ({
// 	type: actionTypes.RESEND_SMS_TO_DEFENDANT,
// 	data,
// })

// export const resendSmsToDefendantStart = () => ({
// 	type: actionTypes.RESEND_SMS_TO_DEFENDANT_START,
// })

// export const resendSmsToDefendantSuccess = (response) => ({
// 	type: actionTypes.RESEND_SMS_TO_DEFENDANT_SUCCESS,
// 	response,
// })

// export const resendSmsToDefendantFail = (err) => ({
// 	type: actionTypes.RESEND_SMS_TO_DEFENDANT_FAIL,
// 	err,
// })

// Upload defendant photos
export const uploadDefendantPhotos = (data, fieldName) => ({
	type: actionTypes.UPLOAD_DEFENDANT_PHOTOS,
	data,
	fieldName
})

export const uploadDefendantPhotosStart = (fieldName) => ({
	type: actionTypes.UPLOAD_DEFENDANT_PHOTOS_START,
	fieldName
})

export const uploadDefendantPhotosSuccess = (response) => ({
	type: actionTypes.UPLOAD_DEFENDANT_PHOTOS_SUCCESS,
	response,
})


export const uploadDefendantPhotosFail = (err) => ({
	type: actionTypes.UPLOAD_DEFENDANT_PHOTOS_FAIL,
	err,
})

// DOWNLOAD PHOTOS
export const downloadDefendantPhoto = (data) => ({
  type: actionTypes.DOWNLOAD_DEFENDANT_PHOTO,
  data
})

export const downloadDefendantPhotoStart = () => ({
	type: actionTypes.DOWNLOAD_DEFENDANT_PHOTO_START,
})

export const downloadDefendantPhotoSuccess = (response) => ({
	type: actionTypes.DOWNLOAD_DEFENDANT_PHOTO_SUCCESS,
	response
})

export const downloadDefendantPhotoFail = (err) => ({
	type: actionTypes.DOWNLOAD_DEFENDANT_PHOTO_FAIL,
	err,
})

export const getDefendantUsers = (data) => {
    return {
        type: actionTypes.GET_DEFENDANT_USERS,
        data
    }
  }
  export const getDefendantUsersStart = () => {
    return {
        type: actionTypes.GET_DEFENDANT_USERS_START
    }
  }
  export const getDefendantUsersSuccess = (response) => {
    return {
        type: actionTypes.GET_DEFENDANT_USERS_SUCCESS,
        response
    }
  }
  export const getDefendantUsersFail = (err) => {
    return {
        type: actionTypes.GET_DEFENDANT_USERS_FAIL,
        err
    }
  }
  
  export const createDefendantUsers = (data) => {
    return {
        type: actionTypes.CREATE_DEFENDANT_USERS,
        data
    }
  }
  export const createDefendantUsersStart = () => {
    return {
        type: actionTypes.CREATE_DEFENDANT_USERS_START
    }
  }
  export const createDefendantUsersSuccess = (response) => {
    return {
        type: actionTypes.CREATE_DEFENDANT_USERS_SUCCESS,
        response
    }
  }
  export const createDefendantUsersFail = (err) => {
    return {
        type: actionTypes.CREATE_DEFENDANT_USERS_FAIL,
        err
    }
  }
  
  export const deleteDefendantUsers = (data) => {
    return {
        type: actionTypes.DELETE_DEFENDANT_USERS,
        data
    }
  }
  export const deleteDefendantUsersStart = () => {
    return {
        type: actionTypes.DELETE_DEFENDANT_USERS_START
    }
  }
  export const deleteDefendantUsersSuccess = (response) => {
    return {
        type: actionTypes.DELETE_DEFENDANT_USERS_SUCCESS,
        response
    }
  }
  export const deleteDefendantUsersFail = (err) => {
    return {
        type: actionTypes.DELETE_DEFENDANT_USERS_FAIL,
        err
    }
  }


