import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import BookingsList from "./BookingsList";
import BookingsForm from "./BookingsForm";
import { initialData } from './BookingsModel';
import { Dialog } from '@material-ui/core';
import { clone } from "shared/utility";
import { Close, Cached } from '@material-ui/icons';
import Notification from '../../../components/Notification/Notification';

const Bookings = (props) => {

    const [openForm, setOpenForm] = useState(false);
    const [action, setAction] = useState('Add');
    const [formData, setFormData] = useState(null);
    const [index, setIndex] = useState(-1);
    const [notification, setNotification] = useState('');
    const [doing, setDoing] = useState('');
    const [leadData, setLeadData] = useState(initialData);
    const { defendant_id, bookingData, setBookingData, defendantData, canEdit, canDelete } = props;

    useEffect(() => {
        if (props.success === 'Successfully gotten bookings') {
            setBookingData(props.data);
            props.clearBookingMessages();
        } else if (props.success === 'Successfully created booking' ||
            props.success === 'Successfully updated booking' ||
            props.success === 'Successfully deleted booking') {
            setNotification(props.success);
            props.clearBookingMessages();
        }
    }, [props.success, props.error]);

    useEffect(() => {
        if (defendant_id && props.action === "Client Edit") {
            props.getBookings(defendant_id);
        }
    }, [defendant_id])

    useEffect(() => {
        if (defendant_id) {
            props.getIndemnitors(defendant_id);
            props.getBookings(defendant_id);
        }
    }, [])

    const updateDataObj = (field, value) => {
        const newObj = clone(formData);
        newObj[field] = value;
        setFormData(newObj)
    }



    return <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
            <h3 style={{ display: 'inline-block' }} className="mr-20">Bookings</h3>
            {props.action === 'Edit' && <Button
                justIcon
                round
                simple
                onClick={() => {
                    props.getBookings(defendant_id);
                }}
                color="info"
            >
                <Cached />
            </Button>}<br />
            {canEdit() &&
                <Button color="primary"
                onClick={() => {
                    setAction('Add');
                    setFormData(clone(initialData));
                    setOpenForm(true);
                }}
            ><i className="fas fa-plus"></i> ADD NEW</Button>}
            <BookingsList
                bookingData={bookingData}
                setBookingData={setBookingData}
                setAction={setAction}
                setFormData={setFormData}
                setOpenForm={setOpenForm}
                setIndex={setIndex}
                action={props.action}
            />
            {openForm && <Dialog
                open={openForm}
                onClose={() => {
                    setOpenForm(false);
                }}
                fullScreen
            >
                <div>
                    <span
                        className="close-btn hoverable float-right"
                        onClick={() => {
                            setOpenForm(false);
                        }}
                    >
                        <Close />
                    </span>
                </div>
                <div className="dialog-wrapper2" style={{ maxWidth: 1200 }}>
                    <BookingsForm
                        doc_error={props.doc_error}
                        doc_success={props.doc_success}
                        pdf_data={props.pdf_data}
                        sendFcs={props.sendFcs}
                        getDocumentPdf={props.getDocumentPdf}
                        setDocumentPdf={props.setDocumentPdf}
                        formData={formData}
                        setFormData={setFormData}
                        updateDataObj={updateDataObj}
                        parentAction={props.action}
                        action={action}
                        close={() => {
                            setOpenForm(false);
                        }}
                        initialData={initialData}
                        sendSinglePdf={props.sendSinglePdf}
                        defendant_id={defendant_id}
                        defendantData={props.defendantData}
                        indemnitorData={props.action === 'Add' ? props.createDefendantIndemnitors : props.indemnitorData}
                        index={index}
                        add={(formDat) => {

                            if (props.action === 'Add') {
                                setBookingData(bookingData.concat([formDat]));
                                setOpenForm(false)
                            } else {
                                setDoing('create');
                                props.createBooking(formDat)
                            }
                        }}
                        update={(formDat, index) => {
                            if (props.action === 'Add') {
                                setBookingData(bookingData.map((bd, i) => {
                                    if (i === index) {
                                        return formDat;
                                    } else {
                                        return bd;
                                    }
                                }));
                                setOpenForm(false)
                            } else {
                                props.updateBooking(formDat);
                            }
                        }}
                        delete={(id, index) => {
                            if (props.action === 'Add') {
                                setBookingData(bookingData.filter((bd, i) => {
                                    if (i === index) return false;
                                    return true;
                                }))
                                setOpenForm(false)
                            } else {
                                props.deleteBooking(id);
                            }
                        }}
                        sendCc={props.sendCc}
                        cc_error={props.cc_error}
                        cc_success={props.cc_success}
                        cc_loading={props.cc_loading}
                        canEdit={canEdit}
                        canDelete={canDelete}
                    />
                </div>
            </Dialog>}
        </GridItem>
        {notification && <Notification
            open={notification ? true : false}
            close={() => {
                props.getBookings(defendant_id);
                setNotification('');
                if (doing === 'create') {
                    setOpenForm(false);
                    setDoing('');
                }
            }}
            message={<h4>{`${notification}`}</h4>}
        />}
    </GridContainer>
}

const mapStateToProps = state => {
    const b = state.crm_booking;
    const i = state.crm_indemnitor;
    const d = state.documents;
    return {
        error: b.error,
        success: b.success,
        data: b.data,
        booking: b.booking,
        indemnitorData: i.data,
        doc_error: d.error,
        doc_success: d.success,
        pdf_data: d.pdf_data,
        cc_error: d.cc_error,
        cc_success: d.cc_success,
        cc_loading: d.cc_loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearBookingMessages: () => {
            dispatch(actions.clearBookingMessages());
        },
        getBookings: (defendant_id) => {
            dispatch(actions.getBookings(defendant_id));
        },
        createBooking: (data) => {
            dispatch(actions.createBooking(data));
        },
        updateBooking: (data) => {
            console.log('updating', data);
            dispatch(actions.updateBooking(data));
        },
        deleteBooking: (id) => {
            dispatch(actions.deleteBooking(id));
        },
        getIndemnitors: (defendant_id) => {
            dispatch(actions.getIndemnitors(defendant_id));
        },
        sendFcs: (data, data_type) => {
            dispatch(actions.sendFcs(data, data_type));
        },
        getDocumentPdf: (data, data_type) => {
            dispatch(actions.getDocumentPdf(data));
        },
        setDocumentPdf: (data) => {
            dispatch(actions.setDocumentPdf(data));
        },
        sendCc: (data) => {
            dispatch(actions.sendCc(data));
        }, 
        sendSinglePdf: (data) => {
            dispatch(actions.sendSinglePdf(data));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);