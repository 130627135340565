import React from 'react';
import CustomInput from 'components/CustomInput/CustomInputValidate';
import Datetime from "react-datetime";
import CustomRadio from "components/CustomRadio/CustomRadio";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import { FormGroup, FormControlLabel } from '@material-ui/core';
import {
    min1, min6, min4, min10, email, required,
    no_letters, no_special_chars, no_numbers, max4,
    max10, max15, max20, max50, max100, max250, max500
} from 'shared/validation';
import moment from 'moment';
const validate = {
    min1,
    min6,
    min4,
    min10,
    email,
    required,
    no_letters,
    no_special_chars,
    no_numbers,
    max4,
    max10,
    max15,
    max20,
    max50,
    max100,
    max250,
    max500
};

const Element = (props) => {
    const { field, data, updateDataObj, model, options_override, action, is_client, inBooking, setChangesMade } = props;

    const getValidations = (f) => {

        return model[f].validations.map((v) => validate[v]);
    };
    // console.log('model_field', model[field], model, field);

    let isRequired;
    
    if (model[field] && model[field].client_required || model[field] && model[field].required) {
        isRequired = true;
    } else {
        isRequired = false;
    }


    // const isRequired = action !== 'Client Edit' ?
    //     model[field] ? model[field].required ? true : false : false
    //     :
    //     model[field] ? model[field].client_required ? true : false : false;

    if (model[field].type === 'input') {
        return <div id={field}>
            <CustomInput
                // value={props.value}
                label={model[field].label}
                value={data[field] ? data[field] : ''}
                onChange={(e) => {
                    if (model[field].max_length) {
                        const len = model[field].max_length;
                        if (e.target.value.length > len) return;
                    }
                    updateDataObj(field, e.target.value);
                    if (inBooking) {
                        setChangesMade(true);
                    }
                }}
                validate={model[field].validations ? getValidations(field) : []}
                readonly={model[field].readonly ? true : false}
                uppercase={model[field].uppercase ? true : false}
                number_only={model[field].number_only ? true : false}
            />
            {(isRequired && !data[field]) && <div className="red-text"><small>Required</small></div>}
        </div>
    } else if (model[field].type === 'date') {
        const date_is_valid = moment(data[field], 'MM/DD/YYYY', true).isValid();
        return <div id={field} className={`mt-25 custom relative ${(isRequired && !data[field]) ? 'required-date' : ''}`}>
            {data[field] && <div className="tiny-label">{model[field].label}</div>}
            <div className="hoverable gray-text date-clear">
                {data[field] && <small onClick={() => {
                    updateDataObj(field, '');
                }}>clear</small>}
            </div>
            <Datetime
                inputProps={{
                    placeholder: model[field].label
                }}
                closeOnSelect={true}
                onChange={(e) => {
                    if (typeof e === 'object') {
                        const stringDate = e.format('MM/DD/YYYY');
                        if (inBooking) {
                            setChangesMade(true);
                        }
                        return updateDataObj(field, stringDate);;
                    }
                    if (inBooking) {
                        setChangesMade(true);
                    }
                    updateDataObj(field, e)
                }}
                timeFormat={false}
                value={data[field] ? data[field] : ''}
            />
            {(isRequired && !data[field]) && <div className="red-text"><small>Required</small></div>}
            {(!date_is_valid && data[field]) && <div className="red-text mt-10">Invalid Date, please format to MM/DD/YYYY</div>}
        </div>
    } else if (model[field].type === 'radio') {
        return <div id={field} className={`${model[field].readonly ? 'gray-bg-input' : ''}`}>
            <div className={`${(isRequired && !data[field]) ? 'required-radio' : ''}`}>
                <CustomRadio
                    label={[model[field].label].concat(model[field].options).join(',')}
                    options={model[field].options}
                    onChange={(e) => {
                        updateDataObj(field, e.target.value);
                        if (inBooking) {
                            setChangesMade(true);
                        }
                    }}
                    value={data[field]}
                    readonly={model[field].readonly ? true : false}
                />
                {(isRequired && !data[field]) && <div className="red-text mb-20 small-text">Required</div>}
            </div>
        </div>
    } else if (model[field].type === 'select') {
        return <div id={field} className={`${(isRequired && !data[field]) ? 'required-date' : ''}`}>
            <CustomSelect
                label={model[field].label}
                options={options_override ? options_override : model[field].options}
                choose={(e, n) => {
                    updateDataObj(field, e);
                    if (inBooking) {
                        setChangesMade(true);
                    }
                }}
                default={data[field]}
            />
            {(isRequired && !data[field]) && <div className="red-text small-text">Required</div>}
        </div>
    } else if (model[field].type === 'checkbox') {
        const dataArr = data[field] ? data[field].split(',') : [];
        return <div className={`group-wrap`}>
            {model[field].label}
            <FormGroup row className="mt-15">
                {model[field].options.map((f, i) => {
                    return <FormControlLabel
                        key={`fc-${i}`}
                        control={
                            <Checkbox
                                id={field}
                                checked={dataArr.indexOf(f) !== -1}
                                onChange={() => {
                                    const v = dataArr.indexOf(f) !== -1 ?
                                        dataArr.filter(c => {
                                            if (c === f) return false;
                                            return true;
                                        })
                                        :
                                        dataArr.concat([f]);
                                    updateDataObj(field, v.join(','));
                                    if (inBooking) {
                                        setChangesMade(true);
                                    }
                                }}
                                color="default"
                            />
                        }
                        label={f}
                    />
                })}
            </FormGroup>
        </div>
    }
    return null;
}

export default Element;