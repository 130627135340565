import React, { useState, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import indemnitorFields from "../Indemnitors/IndemnitorsModel";
import { updateCleanup } from "./DefendantsLogic";
import { clone, removeWhitespace, checkEmpty } from "shared/utility";
import { connect } from "react-redux";
import { getIndemnitorId } from "../../../shared/clientAuthValidation";

const DefendantsFormReview = (props) => {
	console.log('DefendantsFormReview', props);
	const [err, setErr] = useState("");
	const [saveDefendant, setSaveDefendant] = useState(false)
	const [notification, setNotification] = useState(null);
	const [reviewObj, setReviewObj] = useState({
		errors: {},
		addressErrors: {},
		otherAddressErrors: {},
		partnersErrors: {},
	});
	const [finalErrorCount, setFinalErrorCount] = useState(0);
	const {
		dataObj,
		addressData,
		partnersData,
		bookingData,
		indemnitorData,
		arrestData,
		action,
		successMessage,
		closeFinal,
		bookings, // client use
	} = props;

	useEffect(() => {
		if (props.error) {
			props.setCurrentTab("General");
		}
	}, [props.error]);

	useEffect(() => {
		if (successMessage) {
			if (action === 'Add' && closeFinal) closeFinal();
		}
	}, [successMessage, action]);

	useEffect(() => {
		if (props.action === "Client Edit") {
			const newReviewObj = {};

			let errorMessage = "";
			let errorCount = 0;

			if (!props.data) errorCount++;
			if (props.data) {
				if (!props.data.first_name) {
					errorMessage += "First Name is required. ";
					errorCount++;
				}
				if (!props.data.last_name) {
					errorMessage += "Last Name is required. ";
					errorCount++;
				}
			}
			setErr(errorMessage);
			if (errorMessage) return;

			setReviewObj(newReviewObj);
			setFinalErrorCount(errorCount);

		}
	}, [props.data]);

	useEffect(() => {
		const newReviewObj = {};

		let errorMessage = "";
		let errorCount = 0;

		if (!props.data) errorCount++;
		if (props.data) {
			if (!props.data.first_name) {
				errorMessage += "First Name is required. ";
				errorCount++;
			}
			if (!props.data.last_name) {
				errorMessage += "Last Name is required. ";
				errorCount++;
			}
		}
		setErr(errorMessage);
		if (errorMessage) return;

		const cleanedData = removeWhitespace(props.data);
		if (cleanedData.date_of_birth === "") {
			cleanedData.date_of_birth = null;
		}

		setReviewObj(newReviewObj);
		setFinalErrorCount(errorCount);

		if (errorCount === 0 && props.action === "Edit") {
			if (props.setUnsavedWarning) props.setUnsavedWarning(false);
			doUpdate(cleanedData);
			return;
		}
		if (errorCount === 0 && props.action === "Add") {
			if (props.setUnsavedWarning) props.setUnsavedWarning(false);
			doCreate(
				cleanedData,
				bookingData,
				indemnitorData,
				arrestData,
				props.photos
			);
			return;
		}

		if (errorCount !== 0) {
			setErr(errorMessage);
			setFinalErrorCount(errorCount);
		}
	}, []);

	const { errors, addressErrors, otherAddressErrors, partnersErrors } =
		reviewObj;
	const errorCount = finalErrorCount;

	const success = props.success
		? props.success.indexOf("Success") !== -1
			? true
			: false
		: false;

	const doCreate = (
		cd,
		bookingData,
		indemnitorData,
		arrestData,
		photosData
	) => {
		const cdata = clone(cd);
		if (cd.rreferences) {
			cdata.rreferences = JSON.stringify(cd.rreferences);
		}
		["ex_parent_1", "ex_parent_2", "ex_parent_3"].forEach((pa) => {
			if (cdata[`${pa}_children`]) {
				cdata[`${pa}_children`] = JSON.stringify(cdata[`${pa}_children`]);
			}
		});
		props.createDefendant(
			cdata,
			bookingData,
			indemnitorData,
			arrestData,
			props.sendSMS,
			photosData
		);
	};

	const doUpdate = (cd, pdfData) => {
		console.log('pdfData', pdfData);
		const cdata = updateCleanup(cd);
		props.updateDefendant(cdata, null, null, pdfData ? pdfData : false);
	};

	let emptyValues = [];
	const checkValidity = () => {
		let pass = false;
		
		Object.keys(props.model).forEach(field => {
			if (props.model[field].client_required === true) {
				if (!props.data[field]) {
					if (!props.data.has_vehicle || props.data.has_vehicle === "No") {
						if (field === "vehicle_make" || field === "model" || field === "color" || field === "vehicle_year" || field === "lic" || field === "license_plate_state" || field === "vehicle_financed" || field === "where_financed" || field === "how_much_owned") {
							console.log(field);
						} else {
							emptyValues.push(field);
						}
					}
					else if (props.data.has_vehicle || props.data.has_vehicle === "Yes") {
						if (field === "vehicle_make" || field === "model" || field === "color" || field === "vehicle_year" || field === "lic" || field === "license_plate_state") {
							if (!props.data[field]) {
								emptyValues.push(field)
							}
						}
						else if (!props.data.vehicle_financed || props.data.vehicle_financed === "No") {
							if (field === "where_financed" || field === "how_much_owned") {
								console.log(field)
							} else {
								emptyValues.push(field);
							}
						}
						else if (props.data.vehicle_financed && props.data.vehicle_financed === "Yes") {
							if (field === "where_financed" || field === "how_much_owned") {
								if (!props.data[field]) {
									emptyValues.push(field);
								}
							} else {
								emptyValues.push(field);
							}
						} else {
							emptyValues.push(field);
						}
					} else {
						emptyValues.push(field);
					}
				}
				if (props.model[field].type === "photo") {
					if (!props.photos.has(field) && props.data[field].length === 0) {
						emptyValues.push(field);
					} else if (checkEmpty(props.data[field]) && !props.photos.has(field)) {
						emptyValues.push(field);
					}
				}
			}
		})
		console.log('emptyValues', emptyValues)

		if (emptyValues.length === 0) {
			setNotification(false);
			pass = true;
		} else {
			pass = false;
		}

		return pass;
	};

	let emptyIndemnitorValues = [];
	const checkIndemnitor = () => {
		let pass = false;
		for (let i = 0; i < props.allIndemnitors.length; i++) {
			Object.keys(indemnitorFields).forEach(field => {
				if (indemnitorFields[field].client_required === true) {
					console.log(field)
					if (!props.allIndemnitors[i][field]) {
						if (!props.allIndemnitors[i].has_vehicle || props.allIndemnitors[i].has_vehicle === "No") {
							if (field === "vehicle_make" || field === "model" || field === "color" || field === "year" || field === "lic") {
								console.log('has vehicle', field);
							} else {
								emptyIndemnitorValues.push(field);
							}
						}
						else if (props.allIndemnitors[i].has_vehicle || props.allIndemnitors[i].has_vehicle === "Yes") {
							if (field === "vehicle_make" || field === "model" || field === "color" || field === "year" || field === "lic") {
								if (!props.allIndemnitors[i][field]) {
									emptyIndemnitorValues.push(field);
								}
							} else {
								emptyIndemnitorValues.push(field);
							}
						} else {
							emptyIndemnitorValues.push(field);
						}
					}
				}
			});
		}
		if (emptyIndemnitorValues.length === 0) {
			setNotification(false);
			pass = true;
		} else {
			pass = false;
		}

		return pass;
	};

	const handleScroll = () => {
		const scrollField = document.getElementById(emptyValues[0]);
		console.log('scrollField', scrollField, emptyValues[0]);
		if (scrollField) scrollField.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
			inline: 'start',
		});
	}


	return (
		<div>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					{err ? (
						<div>
							<h4 style={{ color: "red" }}>
								Please correct these errors before submitting.
							</h4>
							<hr />
							<div className="red-text">{err}</div>
						</div>
					) : null}

					{notification && (
							<div style={{ color: "red" }}>
								<h4>{notification}</h4>
							</div>
						)}

					{errorCount === 0 &&
						!err &&
						!success &&
						props.action !== "Client Edit" ? (
						<div>

							{props.action === "Add" && (
								<div>
									{!props.error && <h4 className="success-text">Adding...</h4>}
								</div>
							)}

							<hr />
						</div>
					) : (
						<div></div>
					)}

					<div style={{ marginTop: 20 }}>
						<div className="success-text">
							<h4>{props.success}</h4>
						</div>
						{!props.success && (
							<div style={{ color: "red" }}>
								<h4>{props.error}</h4>
							</div>
						)}
					</div>
				</GridItem>

				{props.action === "Client Edit" && (
					<GridItem xs={12} sm={12} md={12}>
						<div className="text-center">
							<Button
								simple
								onClick={() => {
									if (!checkValidity()) {
										props.setRequiredFieldsComplete(false);
										handleScroll();
										setNotification('Please fill required fields to create docs for digital signature. Put N/A if not applicable.')
									} else {
										props.setRequiredFieldsComplete(true);
									}
									const indemnitorExists = getIndemnitorId();
									if (indemnitorExists && checkValidity()) {
										if(!checkIndemnitor()) {
											setNotification('Some Indemnitor fields are required.');
										}
									}

									const cleanedData = removeWhitespace(props.data);
									if (cleanedData.date_of_birth === "") {
										cleanedData.date_of_birth = null;
									}
									
									let pdfData = null;

									if (errorCount === 0 && props.action === "Client Edit" && checkValidity()) {
										if (bookings) {
											if (bookings.length > 0) {
												// get latest booking (by ID number)
												const sorted_bookings = bookings.concat([]).sort((a, b) => (a.id < b.id) ? 1 : -1);
												console.log('sorted_bookings', sorted_bookings);
												const latest_booking = sorted_bookings[0];
												if (latest_booking.surety) {
													let type = 'FCS';
													if (latest_booking.surety === 'Bankers') {
														type = 'AABB';
													}
													pdfData = {
														sendPdf: true,
														type,
														indemnitor_id: 'defendant',
														defendant_id: props.data.id,
														booking_id: latest_booking.id,
													}
												} 
											}
										}
									}
									doUpdate(cleanedData, pdfData);
								}}
								color="white"
								className="font-18"
							>
								<span className="darkblue-text">
									<i className="fas fa-save mr-10"></i> Save Info
								</span>
							</Button>
						</div>
					</GridItem>
				)}
			</GridContainer>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		bookings: state.crm_booking.data
	};
};

export default connect(mapStateToProps, null)(DefendantsFormReview);
