import React, { useState, Fragment, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Element from "containers/CRM/Element";
import fields from "./IndemnitorsModel";
import Notification from "components/Notification/Notification";
import NotificationAsk from "../../../components/Notification/NotificationAsk";
import { clone } from "shared/utility";
import { Dialog } from "@material-ui/core";
import CustomRadio from "../../../components/CustomRadio/CustomRadio";
import IndemnitorsFormReferences from "../Defendants/DefendantsFormReferences";
import ClipLoader from "react-spinners/ClipLoader";
import indemnitorFields from "../Indemnitors/IndemnitorsModel";
import Upload from "../../../components/Uploads/Upload";
import TextField from '@material-ui/core/TextField';
import { connect } from "react-redux";
import * as actions from "store/actions";

const checkEmpty2 = (elem) => {
	let isEmpty = true;
	if (elem) {
		if (typeof elem === 'object') {
			if (Array.isArray(elem)) {
				if (elem.length > 0) {
					isEmpty = false;
				}
			} else {
				isEmpty = false;
			}
		}
	}
	return isEmpty;
}

const IndemnitorsForm = (props) => {
	console.log('IndemnitorsForm', props);
	const { updateDataObj, formData, setFormData, action, photo_success, photo_loading, 
		is_client, updateIndemnitor, getIndemnitor, indemnitorData, appendFileNew,
		doc_success, doc_error, defendant_id, bookings, clearIndemnitorMessages, canEdit, canDelete
	} = props;
	const [notification, setNotification] = useState("");
	const [askBeforeDelete, setAskBeforeDelete] = useState(false);
	const [missingFields, setMissingFields] = useState(false);
	const [openUpload, setOpenUpload] = useState(false);
	const [startUpload, setStartUpload] = useState(false);
	const [uploadData, setUploadData] = useState(null);
	const [openAskReplace, setOpenAskReplace] = useState(null);

	useEffect(() => {
		if (startUpload) {
			if (photo_success) {
				getIndemnitor(formData.id);
				setStartUpload(false);
			}
		}
	}, [photo_success]);

	useEffect(() => {
		if (indemnitorData) {
			if (indemnitorData[0]) {
				setFormData(indemnitorData[0]);
				console.log('update indemnitor? setting', indemnitorData[0].name);
			}
		}
	}, [indemnitorData]);

	const renderElement = (field, model) => {
		return (
			<Element
				field={field}
				data={formData}
				updateDataObj={updateDataObj}
				model={model}
				action={action}
				is_client={is_client}
			/>
		);
	};
	const fieldsObj = {
		indemnitors_info: [
			"name",
			"date_of_birth",
			"place_of_birth",
			"us_citizen",
			// "how_long_in_us",
			"ss",
			"dl",
			"dl_state",
			"front_id_photo",
			"back_id_photo",
			null,
			null,
			null,
			"address",
			"city",
			"state",
			"zip",
			"country",
			"phone",
			"home_phone",
			"email",
			"relation_to_defendant",
			"real_property",
			"in_who_name",
			"indemnitor_instagram",
			"rent_own",
			"lived_at_this_address_for",
			// "landlord_name",
			// "landlord_phone",
		],

		employment: [
			"employed_by",
			"employers_address",
			"employers_phone",
			"occupation",
			"how_long",
			"superior",
			"monthly_income",
		],

		spouse: [
			"spouse",
			"spouse_address",
			"spouse_phone",
			"spouse_home_phone",
			"spouse_employed_by",
			"spouse_employers_address",
			"spouse_employers_phone",
		],

		vehicle_info: [
			"has_vehicle",
			// "where_financed",
			// "how_much_owned",
		],
	};

	const checkValidity = () => {
		let pass = true;

		fieldsObj.indemnitors_info.forEach((field) => {
			if (field === "name" && formData[field].trim() === "") pass = false;
		});

		if (!formData.name) return false;
		return pass;
	};

	let emptyValues = [];
	const checkEmptyFields = () => {
		let pass = false;

		if (is_client === true) {
			Object.keys(indemnitorFields).forEach(field => {
				if (indemnitorFields[field].client_required === true) {
					if (!formData[field]) {
						if (!formData.has_vehicle || formData.has_vehicle === "No") {
							if (field === "vehicle_make" || field === "model" || field === "color" || field === "year" || field === "lic") {
								console.log('has vehicle', field);
							} else {
								emptyValues.push(field);
							}
						}
						else if (formData.has_vehicle || formData.has_vehicle === "Yes") {
							if (field === "vehicle_make" || field === "model" || field === "color" || field === "year" || field === "lic") {
								if (!formData[field]) {
									emptyValues.push(field)
								}
							} else {
								emptyValues.push(field);
							}
						} else {
							emptyValues.push(field);
						}
					}
				}
			})
			console.log(emptyValues)

			if (emptyValues.length === 0) {
				setMissingFields(false);
				pass = true;
			} else {
				pass = false;
			}
		} else {
			pass = true;
		}

		return pass;
	};

	const handleScroll = () => {
		const scrollField = document.getElementById(emptyValues[0]);
		console.log('scrollField', scrollField, emptyValues[0]);
		if (scrollField) scrollField.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
			inline: 'start',
		});
	}

	const renderFields = () => {
		return Object.keys(fieldsObj).map((key, index) => {
			return (
				<GridContainer
					className={`${props.action === "History View" && "no-click"}`}
					key={`${index}-i`}
				>
					<GridItem xs={12} sm={12} md={12}>
						<h4 className="bold">{key.split("_").join(" ").toUpperCase()}</h4>
					</GridItem>
					{fieldsObj[key].map((element, i) => {
						if (!element) return <GridItem xs={12} sm={3} md={3} key={`${i}-indemnitor`}></GridItem>
						if (element === "us_citizen" && formData.us_citizen === "No") {
							return (
								<Fragment key={`${i}-indemnitor`}>
									<GridItem xs={12} sm={3} md={3}>
										{renderElement(element, fields)}
									</GridItem>
									<GridItem xs={12} sm={3} md={3}>
										{renderElement("how_long_in_us", fields)}
									</GridItem>
								</Fragment>
							);
						}

						if (fields[element].type === "photo" || fields[element].type === "file") {
							
							const hasUploaded = props.photos.has(element) 
								|| 
							(
								formData[element] && !checkEmpty2(formData[element])
							);
							const photoData = {
								element,
								multiple: false,
								label: fields[element].label
							}
							return (
								<GridItem key={i} xs={12} sm={3} md={3}>
								  <div className="mt-10 mb-10">
									<label style={{ display: "block" }}>
										{fields[element].label}
									</label>
									{photo_loading === element && <div>
										<ClipLoader />
									</div>}
									{photo_loading !== element && <div>
										{props.action !== "History View" && (
											<div>
												{hasUploaded && <Button color="danger" onClick={() => {
													setOpenAskReplace(photoData);
												}}>REPLACE</Button>}
												{!hasUploaded && <Button color="primary" onClick={() => {
													setOpenUpload(true);
													setUploadData(photoData);
												}}>UPLOAD</Button>}
											</div>
										)}
										{(props.action !== "History View" && hasUploaded) && (
												<i className="fa fa-check green-text ml-20"></i>
											)}
										{formData[element] &&
											!checkEmpty2(formData[element]) &&
											(props.action === "Edit" && !is_client) && (
												<div>
													<a
														onClick={() => {
															props.downloadIndemnitorPhoto(formData[element]);
														}}
														className="text-ellipsis"
														style={{ cursor: "pointer" }}
													>
														Download {formData[element].name}
													</a>
												</div>
											)}

										{((formData[element] && is_client) && hasUploaded) &&
												<div className="mb-10">1 file uploaded</div>}
									</div>}
								  </div>
								</GridItem>
							);
						}

						if (element === "phone") {
							if (props.action === "Add") {
								return (
									<GridItem key={i} xs={12} sm={3} md={3}>
										{renderElement(element, fields)}
										{formData.phone && <CustomRadio
											label="Send form link SMS message to indemnitor on add, Yes, No"
											options={["Yes", "No"]}
											onChange={(e) => {
												props.setSendSMS(e.target.value);
											}}
											value={props.sendSMS}
											size="sm"
										/>}
									</GridItem>
								);
							} else if (props.action === "Edit") {
								return (
									<GridItem key={i} xs={12} sm={3} md={3}>
										{renderElement(element, fields)}
										{!is_client && <Button
											onClick={() => {
												props.sendSmsToDefendant({
													id: formData.id,
													phone: formData["phone"],
													ind: true,
													defendant_id: props.defendant_id
												});
											}}
											color="primary"
										>
											Send SMS
										</Button>}
									</GridItem>
								);
							}
						}

						if (element === "rent_own" && formData.rent_own === "Rent") {
							return (
								<Fragment key={`${i}-indemnitor`}>
									<GridItem xs={12} sm={3} md={3}>
										{renderElement(element, fields)}
									</GridItem>
									<GridItem xs={12} sm={3} md={3}>
										{renderElement("landlord_name", fields)}
									</GridItem>
									<GridItem xs={12} sm={3} md={3}>
										{renderElement("landlord_phone", fields)}
									</GridItem>
								</Fragment>
							);
						}

						if (element === "has_vehicle" && formData.has_vehicle === "Yes") {
							return (
								<Fragment key={i}>
									<GridItem xs={12} sm={12} md={12}>
										{renderElement(element, fields)}
									</GridItem>
									{[
										"vehicle_make",
										"model",
										"year",
										"color",
										"lic",
										"registered_owner",
										"vehicle_financed",
									].map((el, j) => {
										if (
											el === "vehicle_financed" &&
											formData.vehicle_financed === "Yes"
										) {
											return (
												<Fragment key={`${j}-indemnitor`}>
													<GridItem xs={12} sm={3} md={3}>
														{renderElement(el, fields)}
													</GridItem>
													<GridItem xs={12} sm={3} md={3}>
														{renderElement("where_financed", fields)}
													</GridItem>
													<GridItem xs={12} sm={3} md={3}>
														{renderElement("how_much_owned", fields)}
													</GridItem>
												</Fragment>
											);
										}
										return (
											<GridItem xs={12} sm={3} md={3} key={`${j}-indemnitor`}>
												{renderElement(el, fields)}
											</GridItem>
										);
									})}
								</Fragment>
							);
						}

						return (
							<GridItem key={`${i}-indemnitor`} xs={12} sm={3} md={3}>
								{renderElement(element, fields)}
							</GridItem>
						);
					})}
				</GridContainer>
			);
		});
	};
	return (
		<div>
			{props.action !== "History View" && (
				<div>
					{!is_client && <div
						style={{
							marginRight: 20,
							marginBottom: 10,
							display: "inline-block",
						}}
						onClick={() => {
							props.close();
						}}
						className="hoverable"
					>
						<Tooltip
							id="tooltip-top-start1"
							title="Back to List"
							placement="top"
						>
							<ArrowBack />
						</Tooltip>
					</div>}

					{(props.action === "Add" && !is_client) && (
						<div className="bottom-dweller">
							<Button
								// className={`darkblue-text inline-block mr-20 hoverable`}
								simple
								color="primary"
								onClick={() => {
									if (!checkValidity())
										return setNotification("Name fields are required.");
									if (!checkEmptyFields()) {
										handleScroll()
										setMissingFields(true);
									}
									const newFormData = clone(formData);
									newFormData.defendant_id = props.defendant_id;
									newFormData.rreferences = JSON.stringify(
										newFormData.rreferences
									);
									props.add(newFormData);
								}}
								style={{ display: "inline-block" }}
								className="font-18"
							>
								<Tooltip
									title="Add"
									placement="top"
									style={{ display: "inline-block" }}
								>
									<i className="fas fa-save font-22"></i>
								</Tooltip>
								SAVE INDEMNITOR
							</Button>
						</div>
					)}

					{props.action === "Edit" && (
						<div className="bottom-dweller">

							{missingFields && (
								<div style={{ color: "red" }}>
									<h4>Please fill required fields to create docs for digital signature. Put N/A if not applicable.</h4>
								</div>
							)}
					{canEdit() &&
							<Button
								simple
								color="primary"
								// className={`darkblue-text inline-block mr-20 hoverable`}
								onClick={() => {
									if (!checkValidity()) {
										 setNotification("Name fields are required.");
									}
									if (!checkEmptyFields() && checkValidity()) {
										 props.setRequiredFieldsComplete(false);
										 handleScroll()
										 setMissingFields(true);
									} else {
										props.setRequiredFieldsComplete(true);
									}

									const newFormData = clone(formData);
									newFormData.rreferences = JSON.stringify(
										newFormData.rreferences
									);
									let pdfData = null;
									if (bookings && is_client && checkEmptyFields()) {
										if (bookings.length > 0) {
											// get latest booking (by ID number)
											const sorted_bookings = bookings.concat([]).sort((a, b) => (a.id < b.id) ? 1 : -1);
											console.log('sorted_bookings', sorted_bookings);
											const latest_booking = sorted_bookings[0];
											if (latest_booking.surety) {
												let type = 'FCS';
												if (latest_booking.surety === 'Bankers') {
													type = 'AABB';
												}
												pdfData = {
													sendPdf: true,
													type,
													indemnitor_id: newFormData.id,
													defendant_id,
													booking_id: latest_booking.id,
												}
											}
										}
									}
									props.update(newFormData, props.index, pdfData);
								}}
								style={{ display: "inline-block" }}
								className="font-18"
							>
								<Tooltip
									title="Update"
									placement="top"
									style={{ display: "inline-block" }}
								>
									<i className="fas fa-save font-22"></i>
								</Tooltip>
								SAVE INDEMNITOR
							</Button>}
						</div>
					)}

					{props.action === "Edit" && !is_client && canDelete() &&(
						<div
							className={`${props.action === "Edit" ? "red" : "lightgray"
								}-text inline-block mr-20 hoverable`}
							onClick={() => {
								setAskBeforeDelete(true);
							}}
							style={{ display: "inline-block", float: "right" }}
						>
							<Tooltip
								title="Delete"
								placement="top"
								style={{ display: "inline-block" }}
							>
								<i className="fas fa-trash font-22"></i>
							</Tooltip>
							<span className="icon-text">DELETE</span>
						</div>
					)}
				</div>
			)}
			{renderFields()}

			<IndemnitorsFormReferences
				action={props.action}
				model={fields}
				data={formData}
				updateDataObj={updateDataObj}
				renderElement={renderElement}
			/>
			<div className="boxed">
				<GridContainer>
					<GridItem xs={12}>
						<TextField
							id="standard-multiline-flexible"
							label="Notes"
							multiline
							fullWidth={true}
							variant="standard"
							value={formData.notes}
							onChange={(e) => {
								console.log(formData)
								const newData = clone(formData);
								newData.notes = e.target.value;
								setFormData(newData);
							}}
						/>
					</GridItem>
				</GridContainer>
			</div>

			<GridItem xs={12} sm={12} md={12}></GridItem>
			{notification && (
				<Notification
					open={notification ? true : false}
					close={() => {
						clearIndemnitorMessages();
						setNotification("");
					}}
					message={notification}
				/>
			)}
			{askBeforeDelete && (
				<Dialog
					open={askBeforeDelete}
					onClose={() => {
						setAskBeforeDelete(false);
					}}
				>
					<div className="dialog-wrapper">
						<h3>Are you sure you want to delete this Indemnitor?</h3>
						<Button
							color="danger"
							onClick={() => {
								props.delete(formData.id, props.index);
								setAskBeforeDelete(false);
								props.close();
							}}
							className="mr-20"
						>
							YES, DELETE
						</Button>
						<Button
							color="white"
							onClick={() => {
								setAskBeforeDelete(false);
							}}
						>
							CANCEL
						</Button>
					</div>
				</Dialog>
			)}

					{openUpload && <Upload 
						open={openUpload}
						close={() => {
							setOpenUpload(false);
						}}
						uploadData={uploadData}
						appendFile={(input, blob_field) => {
							if (formData.id) {
								if (blob_field) {
									console.log('adding');
									const newFormData = new FormData();
									const element = blob_field;
									newFormData.append(element, input, element+'.png');
									setStartUpload(true);
									updateIndemnitor(formData, {
										id: formData.id,
										photos: newFormData
									}, null, element);
								} else {
									const newFormData = new FormData();
									const { length } = input.target.files;
									for (let i = 0; i < length; ++i) {
										newFormData.append(input.target.name, input.target.files[i]);
									}
									setStartUpload(true);
									updateIndemnitor(formData, {
										id: formData.id,
										photos: newFormData
									}, null, uploadData.element);
								}
							} else {
								appendFileNew(input, blob_field);
							}
						}}
					/>}

					{openAskReplace && <NotificationAsk 
						open={openAskReplace ? true : false}
						close={() => {
							setOpenAskReplace(null);
						}}
						success={() => {
							if (formData.id) {
								// EDIT
								setOpenUpload(true);
								setUploadData(openAskReplace);
								setOpenAskReplace(null);
							} else {
								// NEW
								let shouldReplace = false;
								const photoField = openAskReplace.element;
								if (photos) {
									if (photos.hasOwnProperty(photoField)) {
										const newPhotos = new FormData();
										for (let [key, value] of photos.entries()) {
											if (key !== photoField) {
												newPhotos.append(key, value);
											}
										}
										setPhotos(newPhotos);
										shouldReplace = true;
									}
								}
								if (formData) {
									if (formData[photoField] && typeof formData[photoField] === 'object') {
										if (formData[photoField].length > 0) {
											updateDataObj(photoField, []);
											shouldReplace = true;
										}
									}
								}
								if (shouldReplace) {
									setOpenUpload(true);
									setUploadData(openAskReplace);
								}
								setOpenAskReplace(null);
							}
						}}
						message={`Replace this file/photo ?`}
						success_button_text="YES"
						fail_button_text="NO"
					/>}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		photo_loading: state.crm_indemnitor.loading_upload_indemnitor_photos,
		photo_error: state.crm_indemnitor.error_upload_indemnitor_photos,
		photo_success: state.crm_indemnitor.success_upload_indemnitor_photos,
		indemnitorData: state.crm_indemnitor.indemnitorData,
		bookings: state.crm_booking.data
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getIndemnitor: (data) => {
			dispatch(actions.getIndemnitor(data));
		},
		downloadIndemnitorPhoto: (data) => {
			dispatch(actions.downloadIndemnitorPhoto(data));
		},
		uploadIndemnitorPhotos: (data, fieldName) => {
			dispatch(actions.uploadIndemnitorPhotos(data, fieldName));
		},
		clearIndemnitorMessages: () => {
			dispatch(actions.clearIndemnitorMessages());
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(IndemnitorsForm);
