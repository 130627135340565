import jwt_decode from 'jwt-decode';

const isClientLoggedIn = () => {
  const token = localStorage.getItem('client-token');
  if (!token) {
    
    return false;
  } else if (token) {
    const decoded = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    let totalSeconds = Math.round(((currentTime - decoded.exp)));
    totalSeconds = -totalSeconds > 0 ? -totalSeconds : totalSeconds;
    

    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;

    // If you want strings with leading zeroes:
    minutes = String(minutes).padStart(2, "0");
    hours = String(hours).padStart(2, "0");
    seconds = String(seconds).padStart(2, "0");

    
    if (decoded.exp < currentTime) {
      localStorage.removeItem('client-token');
      window.location.href = '/client';
      return false;
    }

    return true;
  }
}

export const clientLogout = () => {
  localStorage.removeItem('client-token');
  window.location.href = '/client';
}

export const getClientID = () => {
  const token = localStorage.getItem('client-token');
  if (!token) {
    
    return false;
  } else if (token) {
    const decoded = jwt_decode(token);
    console.log('getting client id', decoded);
    return decoded.id;
  }
}

export const getIndemnitorId = () => {
  const token = localStorage.getItem('client-token');
  if (!token) {
    
    return false;
  } else if (token) {
    const decoded = jwt_decode(token);
    console.log('getting client id', decoded);
    return decoded.indemnitor_id;
  }
}

export default isClientLoggedIn;
