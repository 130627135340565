import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from 'moment';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import IconButton from '@material-ui/core/IconButton';
import Table from "components/Table/Table.jsx";
import Notification from '../../../components/Notification/Notification';
import { Close } from '@material-ui/icons';

const documentTypes = {
    1: 'FCS cc',
    2: 'AABB cc',
    3: 'n/a',
    4: 'FCS',
    5: 'AABB'
}

const Modal = (props) => {
    const [message, setMessage] = useState('');
    const { reminderData } = props;
    const documents = reminderData ? reminderData.data.documents : null;
    const the_data = reminderData ? reminderData.data : null;
    const clientType = reminderData ? reminderData.type : null;
    const defendant_id = reminderData ? 
        reminderData.defendant_id ? 
            reminderData.defendant_id 
        : 
            null 
    : null;
    const indemnitor_id = reminderData ? 
        reminderData.indemnitor_id ? 
            reminderData.indemnitor_id 
        : 
            null 
    : null;
    console.log(reminderData)

    let has_phone = false;
    if (reminderData) {
        if (reminderData.phone) {
            if (reminderData.phone.length === 10) {
                has_phone = true;
            }
        }
    }

    return (
        <Dialog
            open={props.open}
            keepMounted
            onClose={props.close}
            maxWidth="md"
            fullWidth={true}
            style={{ padding: "25px" }}
        >
            <GridContainer style={{ padding: '20px' }}>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{float:'right',padding:10,color:'black'}} className="hoverable" onClick={() => { props.close() }}>
                        <Close 
                        />
                    </div>
                </GridItem>
                <GridItem
                    xs={12} sm={4} md={4}
                >
                   {clientType && clientType === 'defendant' ? 
                        <p>Defendant: {reminderData.defendant_name}</p> 
                    : 
                        clientType && clientType === 'indemnitor' ? 
                            <p>Indemnitor: {reminderData.indemnitor_name} <br></br> for {reminderData.defendant_name}</p> 
                        : 
                            ""}
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                    <Button
                        color="primary"
                        onClick={() => {
                            const obj = {
                                defendant_id,
                                phone: reminderData.phone
                            };
                            if (indemnitor_id) obj.indemnitor_id = indemnitor_id;
                            props.sendReminder(obj);
                            setMessage('Sent Reminder.');
                            props.close();
                        }}
                        disabled={has_phone ? false : true}
                    >
                        Send Reminder
                    </Button>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12}>
                    <div className="dialog-wrapper">
                        {(reminderData && documents) && <Table 
                            tableHead={[
                                'Surety',
                                'Doc Type',
                                'Date Sent',
                                'Last Reminded',
                                'Signed On',
                                'Preview'
                            ]}
                            tableData={documents.filter(dc => {
                                if (indemnitor_id) {
                                    if (dc.indemnitor_id === indemnitor_id) {
                                        return true;
                                    }
                                } else {
                                    if (!dc.indemnitor_id) return true;
                                }
                            }).map((doc, i) => {
                                const d = doc.data;
                                const h = doc.history ? doc.history : [];
                                const surety = documentTypes[doc.type];
                                const getDocType = () => {
                                    if (doc.type !== 3) {
                                        if (surety.indexOf('cc') !== -1) {
                                            return 'Docs With CC';
                                        } else {
                                            return 'Docs Without CC';
                                        }
                                    } else {
                                        return 'CC only';
                                    }
                                }
                                // let last_reminded = moment(doc.date_created).format('MM/DD/YY h:mma');
                                let last_reminded = the_data ? the_data.link_last_sent ? the_data.link_last_sent : '' : '';
                                let signed_on = <span style={{ color: 'red' }}>PENDING</span>;
                                if (doc.history) {
                                    if (doc.history.length > 0) {
                                        doc.history.forEach(dh => {
                                            if (dh.description === 'notified') {
                                                last_reminded = dh.time;
                                            }
                                            if (dh.description === 'declined') {
                                                signed_on = <span style={{ color: 'red' }}>DECLINED</span>;
                                            }
                                            if (dh.description === 'signed') {
                                                signed_on = dh.time;
                                            }
                                        })
                                    }
                                }
                                return [
                                    surety.split(' ')[0],
                                    getDocType(),
                                    moment(d.date_created).format('MM/DD/YY h:mma'),
                                    last_reminded,
                                    signed_on,
                                    <IconButton 
                                        color="primary" 
                                        component="span"
                                        disabled={doc.status != 2 ? false : true}
                                        onClick={() => {
                                            props.downloadDocument(doc.envelope_id);
                                        }}
                                    >
                                        <ArrowDownwardIcon />
                                    </IconButton>
                                ];
                            })}
                        />}
                    </div>
                </GridItem>
            </GridContainer>

            {message && <Notification 
                open={message ? true : false}
                close={() => { setMessage('') }}
                message={message}
            />}

        </Dialog>
    )
}

export default Modal;