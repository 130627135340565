import React, { useEffect, useState } from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { Dialog } from "@material-ui/core";
import { clone } from "shared/utility";
import CustomInput from "components/CustomInput/CustomInputValidate";
import { required } from "shared/validation";
import Button from "../../../components/CustomButtons/Button";
import Table from "components/Table/Table";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";

const infractionList = ['Infraction', 'Misdemeanor', 'Felony'];

const BookingsFormCharges = (props) => {
    console.log(props)
    const initialData = {
        code: "",
        type: "",
    };
    const [openForm, setOpenForm] = useState(false);
    const [action, setAction] = useState(false);
    const [formData, setFormData] = useState(initialData);
    const [index, setIndex] = useState(-1);
    const [openAskBeforeDelete, setOpenAskBeforeDelete] = useState(false);
    const { updateDataObj, data, model } = props;
    const refData = props.formData.charges ? props.formData.charges : [];

    useEffect(() => {
        if (!props.formData.charges) {
            updateDataObj('charges', []);
        }
    }, []);
 
    const renderElement = (field, label, isRequired, uppercase) => {
        return (
            <CustomInput
                value={formData[field]}
                label={label}
                onChange={(e) => {
                    const newData = clone(formData);
                    newData[field] = e.target.value;
                    props.setChangesMade(true);
                    setFormData(newData);
                }}
                validate={isRequired ? [required] : []}
            />
        );
    };
    const checkValidity = () => {
        const code = formData.code ? formData.code.trim() : "";
        const type = formData.type ? formData.type.trim() : "";
        if (code && type) {
            return true;
        }
        return false;
    };
    
    return (
        <div style={{ width: "100%" }}>
            {props.action !== "History View" && (
                <GridItem xs={12} md={12} sm={12}>
                    <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                {props.canEdit() && <Button
                                    color="primary"
                                    onClick={() => {
                                        setAction("Add");
                                        console.log('initialData', initialData);
                                        setFormData(clone(initialData));
                                        setIndex(-1);
                                        setOpenForm(true);
                                    }}
                                >
                                    <i className="fas fa-plus"></i> ADD CHARGE
                                </Button>}
                            </GridItem>
                    </GridContainer>
                </GridItem>
            )}
            {Array.isArray(refData) && (
                <GridContainer>
                    {refData.map((r, i) => {
                        return (
                            <GridItem xs={12} sm={4} md={4} key={`${i}-reff`}>
                                <div className="boxed">
                                    {props.action !== "History View" && props.canEdit() && (
                                        <Button
                                            className="mr-20"
                                            color="primary"
                                            onClick={() => {
                                                setFormData(clone(r));
                                                setIndex(i);
                                                setAction("Edit");
                                                setOpenForm(true);
                                            }}
                                        >
                                            <i className="fas fa-edit"></i> EDIT
                                        </Button>
                                    )}
                                    {props.action !== "History View" && props.canDelete() && (
                                        <Button
                                            color="danger"
                                            onClick={() => {
                                                setIndex(i);
                                                setOpenAskBeforeDelete(true);
                                            }}
                                        >
                                            <i className="fas fa-trash"></i> DELETE
                                        </Button>
                                    )}
                                    <div style={{ lineHeight: 1.5 }} className="mt-20">
                                        <h4>Charge #{i+1}</h4>
                                        <Table
                                            tableData={[
                                                ["Code", "code"],
                                                ["Type", "type"]
                                            ].map((m) => {
                                                return [
                                                    <div style={{ textAlign: "right" }}>
                                                        <strong>{m[0]}</strong>
                                                    </div>,
                                                    <div style={{ textAlign: "right" }}>
                                                        {r[m[1]]}
                                                    </div>
                                                ];
                                            })}
                                        />
                                    </div>
                                </div>
                            </GridItem>
                        );
                    })}
                </GridContainer>
            )}
            {openForm && (
                <Dialog
                    open={openForm}
                    onClose={() => {
                        setOpenForm(false);
                    }}
                    fullWidth={true}
                    maxWidth="md"
                >
                    <div className="dialog-wrapper">
                        <h3>{action} Charge</h3>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4}>
                                <CustomSelect
                                    label="Type"
                                    choose={(e, n) => {
                                        const newData = clone(formData);
                                        newData["type"] = e;
                                        props.setChangesMade(true);
                                        setFormData(newData);
                                    }}
                                    options={infractionList}
                                    default={refData[index] ? refData[index].type : ''}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                {renderElement("code", "Charge code", true, true)}
                            </GridItem>
                        </GridContainer>
                        <hr />
                        {action === "Add" && (
                            <Button
                                color="success"
                                className="mr-20"
                                onClick={() => {
                                    if (checkValidity()) {
                                        updateDataObj('charges', refData.concat([formData]));
                                        setOpenForm(false);
                                    }
                                }}
                            >
                                ADD
                            </Button>
                        )}
                        {action === "Edit" && (
                            <Button
                                color="primary"
                                className="mr-20"
                                onClick={() => {
                                    if (checkValidity()) {
                                        updateDataObj(
                                            "charges",
                                            refData.map((dr, j) => {
                                                if (j === index) {
                                                    return clone(formData);
                                                }
                                                return dr;
                                            })
                                        );
                                        setOpenForm(false);
                                    }
                                }}
                            >
                                UPDATE
                            </Button>
                        )}
                        <Button
                            color="white"
                            onClick={() => {
                                setOpenForm(false);
                            }}
                        >
                            CANCEL
                        </Button>
                    </div>
                </Dialog>
            )}
            {openAskBeforeDelete && (
                <Dialog
                    maxWidth="sm"
                    fullWidth={true}
                    open={openAskBeforeDelete}
                    onClose={() => {
                        setOpenAskBeforeDelete(false);
                    }}
                >
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <div className="dialog-wrapper text-center">
                                <h4>Are you sure you want to delete this Charge?</h4>
                                <Button
                                    color="danger"
                                    className="mr-20"
                                    onClick={() => {
                                        updateDataObj(
                                            "charges",
                                            refData.filter((dr, j) => {
                                                if (j === index) return false;
                                                return true;
                                            })
                                        );
                                        props.setChangesMade(true);
                                        setOpenAskBeforeDelete(false);
                                    }}
                                >
                                    OK DELETE
                                </Button>
                                <Button
                                    color="white"
                                    onClick={() => {
                                        setOpenAskBeforeDelete(false);
                                    }}
                                >
                                    CANCEL
                                </Button>
                            </div>
                        </GridItem>
                    </GridContainer>
                </Dialog>
            )}
        </div>
    );
};
export default BookingsFormCharges;