import * as actionTypes from "./actionTypes";

// BOOKINGS

// CLEAR BOOKING MESSAGES 
export const clearBookingMessages = () => {
    return {
        type: actionTypes.CLEAR_BOOKING_MESSAGES
    }
}

// GET_BOOKINGS
export const getBookings = (data) => {
	return {
		type: actionTypes.GET_BOOKINGS,
		data,
	};
};
export const getBookingsStart = () => {
	return {
		type: actionTypes.GET_BOOKINGS_START,
	};
};
export const getBookingsSuccess = (response) => {
	return {
		type: actionTypes.GET_BOOKINGS_SUCCESS,
		response,
	};
};
export const getBookingsFail = (err) => {
	return {
		type: actionTypes.GET_BOOKINGS_FAIL,
		err,
	};
};

// CREATE_BOOKING
export const createBooking = (data) => {
	return {
		type: actionTypes.CREATE_BOOKING,
		data,
	};
};
export const createBookingStart = () => {
	return {
		type: actionTypes.CREATE_BOOKING_START,
	};
};
export const createBookingSuccess = (response) => {
	return {
		type: actionTypes.CREATE_BOOKING_SUCCESS,
		response,
	};
};
export const createBookingFail = (err) => {
	return {
		type: actionTypes.CREATE_BOOKING_FAIL,
		err,
	};
};

// UPDATE BOOKING
export const updateBooking = (data) => ({
	type: actionTypes.UPDATE_BOOKING,
	data,
});

export const updateBookingStart = () => ({
	type: actionTypes.UPDATE_BOOKING_START,
});

export const updateBookingSuccess = (response) => ({
	type: actionTypes.UPDATE_BOOKING_SUCCESS,
	response,
});

export const updateBookingFail = (err) => ({
	type: actionTypes.UPDATE_BOOKING_FAIL,
	err,
});

// GET BOOKING BY ID
export const getBooking = (data) => ({
	type: actionTypes.GET_BOOKING,
	data,
});

export const getBookingStart = () => ({
	type: actionTypes.GET_BOOKING_START,
});

export const getBookingSuccess = (response) => ({
	type: actionTypes.GET_BOOKING_SUCCESS,
	response,
});

export const getBookingFail = (err) => ({
	type: actionTypes.GET_BOOKING_FAIL,
	err,
});

// DELETE BOOKING
export const deleteBooking = (data) => ({
	type: actionTypes.DELETE_BOOKING,
	data,
});

export const deleteBookingStart = () => ({
	type: actionTypes.DELETE_BOOKING_START,
});

export const deleteBookingSuccess = (response) => ({
	type: actionTypes.DELETE_BOOKING_SUCCESS,
	response,
});

export const deleteBookingFail = (err) => ({
	type: actionTypes.DELETE_BOOKING_FAIL,
	err,
});
