import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

class Dashboard extends React.Component {
  state = {
    value: 0,
    isLoggedIn: true
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  componentDidMount() {
    const token = localStorage.getItem('token');
    
    if (token) {
      this.setState({isLoggedIn:true});
    } else {
      this.setState({isLoggedIn:false});
    }
  }

  render() {
    const { classes } = this.props;
    
    if (!this.state.isLoggedIn) {
      return <Redirect to="/pages/login-page" />;
    }
    const titleCSS = {
      textAlign: 'center',
      color: 'white',
      background: 'linear-gradient(60deg, #2463aa, #2463aa)',
      boxShadow: '0 12px 20px -10px rgba(71, 161, 218, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(71, 161, 218, 0.2)',
    }
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={12}>
            <div style={titleCSS}><strong><h2 style={{paddingTop:'30px',paddingBottom:'30px'}}>Welcome to the Greatest Bail Bonds Portal</h2></strong></div>
          </GridItem>
        </GridContainer>
        <br />

      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
