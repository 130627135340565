import React, { useState, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import Element from "containers/CRM/Element";
import fields from "./BookingsModel";
import Notification from "components/Notification/Notification";
import { clone } from "shared/utility";
import { Dialog } from "@material-ui/core";
import BookingsFormCharges from "..//Bookings/ChargesForm";
import TextField from '@material-ui/core/TextField';
import CourtTime from "./CourtTime";
import CustomInput from "components/CustomInput/CustomInputValidate";
import Table from "components/Table/Table.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import PdfModal from "./pdfPreviewModal";
import { ClipLoader } from "react-spinners";
import Datetime from "react-datetime"
import moment from 'moment';

const checkValidity = (formData) => {
	let pass = false;
	Object.keys(formData).forEach(field => {
		if (typeof formData[field] === 'object') {
			if (formData[field]) {
				if (formData[field].length > 0) pass = true;
			}
		} else {
			if (formData[field]) pass = true;
		}
	})
	return pass;
};

const BookingsForm = (props) => {
	const { updateDataObj, formData, setFormData, defendant_id, doc_error, doc_success, cc_success, cc_error, cc_loading, setDocumentPdf, canEdit, canDelete } = props;
	const [openedPdfModal, setOpenedPdfModal] = useState(false);
	const [notification, setNotification] = useState("");
	const [askBeforeDelete, setAskBeforeDelete] = useState(false);
	const [openAsk, setOpenAsk] = useState(false);
	const [target, setTarget] = useState(false);
	const [previewTarget, setPreviewTarget] = useState(false);
	const [previewPerson, setPreviewPerson] = useState(null);
	const [ccTarget, setCCTarget] = useState(false);
	const [documentType, setDocumentType] = useState('Send Document With CC Authorization');
	const [changesMade, setChangesMade] = useState(false);
	console.log('target', target, previewTarget);

	const convert = (msg) => {
		const divs = msg.split(',').map(m => {
			return <div key={`${m}-i`}>{m}</div>;
		});
		return [<h3>Required</h3>].concat(divs);
	}

	useEffect(() => {
		console.log('success or error changed', target, doc_success, doc_error);
		if (target) {
			if (doc_success) {
				setNotification(doc_success);
				setTarget(false);
			} else if (doc_error) {
				setNotification(doc_error);
				setTarget(false);
			}
		}
		if (previewTarget) {
			if (doc_success && !doc_success.msg) {
				setNotification('');
				setOpenedPdfModal(true);
				setPreviewTarget(false);
			} else if (doc_success) {
				setNotification(doc_success.error ? convert(doc_success.error) : '');
				setPreviewTarget(false);
			} else if (doc_error) {
				setNotification(doc_error);
				setPreviewTarget(false);
			}
		}
	}, [doc_success, doc_error])

	useEffect(() => {
		if (ccTarget) {
			if (cc_success) {
				setNotification(cc_success);
				setCCTarget(false);
			} else if (cc_error) {
				setNotification(cc_error);
				setCCTarget(false);
			}
		}
	}, [cc_success, cc_error]);

	console.log('BookingsForm', props);

	const renderElement = (field, model, options_override) => {
		return (
			<Element
				field={field}
				data={formData}
				updateDataObj={updateDataObj}
				model={model}
				options_override={options_override}
				inBooking={true}
				setChangesMade={setChangesMade}
			/>
		);
	};

	const renderInput = (field, label, max, number, shouldShowMax) => {
		console.log('fl', field, label, max, number,);

		return (
			<div>
				<TextField
					value={formData[field] ? formData[field] : ''}
					label={label}
					fullWidth={true}
					onChange={(e) => {
						if (e.target.value.length > max) return;
						const newData = clone(formData);
						newData[field] = e.target.value;
						setChangesMade(true);
						setFormData(newData);
					}}
					inputProps={{
						maxLength: max,
						type: number ? 'number' : 'text'
					}}
					maxLength={max}
					type={number ? 'number' : 'text'}
				/>
				{(max && shouldShowMax) ? <small><em>Maximum characters = {max}</em></small> : ''}
			</div>
		);
	};

	const previewText = () => {
		if (formData.surety === 'FCS' && documentType !== 'Send CC Authorization ONLY') {
			return 'FCS';
		} 
		else if (formData.surety === 'Bankers' && documentType !== 'Send CC Authorization ONLY') {
			return 'AABB';
		} else {
			return 'CC AUTH ONLY'
		}
	}

	const date_is_valid = moment(formData.credit_card_charge_date, 'MM/DD/YYYY', true).isValid();

	const fieldsObj = {
		booking_info: [
			"booking",
			'booking_name',
			"bail_amount",
			"bail_post_date",
			"jail_court",
			"court_name",
			"case_number",
			"title_10",
			"arrest_county",
			"arrest_state",
			"arrest_case",
			"arrest_date",
			"where_held",
			"case_county",
			"case_state",
			"court_case",
			"payment_schedule",
			"notes",
		]
	};

	let previewOptions = [
		{
			id: props.defendantData.id + '_D',
			name: props.defendantData.first_name + ' ' + props.defendantData.last_name
		},
	];

	if (props.indemnitorData) {
		props.indemnitorData.map((person, i) => {
			const singleIndemnitor = {
				id: person.id + '_I',
				name: person.name,
			}
			previewOptions.push(singleIndemnitor)
		})
	}

	console.log('previewOptions', previewOptions);

	const options_override = props.indemnitorData ? props.indemnitorData.map(i => {
		return i.name;
	}) : [];

	return (
		<div>
			<GridContainer>
				{props.action !== "History View" && (
					<GridItem xs={12} sm={12} md={12}>
						<div
							style={{
								marginRight: 20,
								marginBottom: 10,
								display: "inline-block",
							}}
							onClick={() => {
								if (changesMade) {
									setOpenAsk(true);
								} else { props.close(); }
							}}
							className="hoverable"
						>
							<Tooltip
								id="tooltip-top-start1"
								title="Back to List"
								placement="top"
							>
								<i className="fas fa-arrow-left font-22"></i>
							</Tooltip>
						</div>

						{props.action === "Add" && (
							<div className="bottom-dweller">
								{changesMade && props.action === "Add" && <div className="text-center">
									<div>Change(s) Detected, please make sure to save your booking.</div>
								</div>}
								<Button
									// className={`darkblue-text inline-block mr-20 hoverable`}
									simple
									color="primary"
									onClick={() => {
										if (!checkValidity(formData))
											return setNotification(
												"Some fields are required. Put N/A to leave blank."
											);
										const newFormData = clone(formData);
										newFormData.defendant_id = props.defendant_id;
										setChangesMade(false);
										props.add(newFormData);
									}}
									style={{ display: "inline-block" }}
									className="font-18"
								>
									<Tooltip
										title="Add"
										placement="top"
										style={{ display: "inline-block" }}
									>
										<i className="fas fa-save font-22"></i>
									</Tooltip>
									SAVE BOOKING
								</Button>
							</div>
						)}

						{props.action === "Edit" && canEdit() && (
							<div className="bottom-dweller">
								{changesMade && props.action === "Edit" && canEdit() && <div className="text-center">
									<div>Change(s) Detected, please make sure to save your booking.</div>
								</div>}
								<Button
									// className={`darkblue-text inline-block mr-20 hoverable`}
									simple
									color="primary"
									onClick={() => {
										if (!checkValidity(formData))
											return setNotification(
												"Some fields are required. Put N/A to leave blank."
											);
										const newFormData = clone(formData);
										props.update(newFormData, props.index);
										setChangesMade(false);
										console.log(newFormData)
									}}
									style={{ display: "inline-block" }}
									className="font-18"
								>
									<Tooltip
										title="Update"
										placement="top"
										style={{ display: "inline-block" }}
									>
										<i className="fas fa-save font-22"></i>
									</Tooltip>
									SAVE BOOKING
								</Button>
							</div>
						)}

						{props.action === "Edit" && canDelete() && (
							<div
								className={`${props.action === "Edit" ? "red" : "lightgray"
									}-text inline-block mr-20 hoverable`}
								onClick={() => {
									setChangesMade(false);
									setAskBeforeDelete(true);
								}}
								style={{ display: "inline-block", float: "right" }}
							>
								<Tooltip
									title="Delete"
									placement="top"
									style={{ display: "inline-block" }}
								>
									<i className="fas fa-trash font-22"></i>
								</Tooltip>
								<span className="icon-text">DELETE</span>
							</div>
						)}

						<h4 className="bold">Booking Info</h4>
					</GridItem>
				)}


				{props.action !== "Client Edit" && <GridItem xs={12} sm={6} md={12}>
					<div className="boxed">
						<GridContainer>
							<GridItem xs={12} sm={9} md={4}>
								<div>
									<CustomSelect
										label="Surety"
										options={["Bankers", "FCS"]}
										choose={(e, n) => {
											const newData = clone(formData);
											newData.surety = e;
											setChangesMade(true);
											updateDataObj('surety', newData.surety);
										}}
										inputProps={{
											required: true
										}}
										default={formData.surety}
									/>
									{openedPdfModal && (
										<div>
											<PdfModal
												pdfData={props.pdf_data}
												open={openedPdfModal}
												close={() => {
													setDocumentPdf(null);
													setOpenedPdfModal(false);
												}}
											/>
										</div>
									)
									}
								</div>
							</GridItem>
							{defendant_id && <GridItem xs={12} sm={6} md={4}>
								<div>
									{formData.surety && <CustomSelect
										indx="id"
										desc='name'
										label='Select Person to Send Document'
										options={previewOptions}
										default={previewPerson}
										choose={(e) => {
											console.log('choosing', e)
											setPreviewPerson(e);
										}}
									>
									</CustomSelect>}
									{!formData.surety && <div style={{color:'red'}}>Please select Surety</div>}
									{<Button
										color="primary"
										disabled={previewPerson ? false : true}
										onClick={() => {
											if (changesMade) {
												setNotification('Please save your booking first');
												return;
											}
											const personType = previewPerson.split('_');
											let indId = '';
											if (personType[1] === 'I') {
												indId = personType[0];
											} else {
												indId = 'defendant';
											}

											if (documentType !== "Send CC Authorization ONLY") {
												let includeCC = "Yes";
												if (documentType === 'Send Document Without CC Authorization') {
													includeCC = "No";
												}
												if (formData.surety === 'FCS') {
													console.log('formData', formData, defendant_id);
													setPreviewTarget(true);
													props.getDocumentPdf({
														type: 'FCS',
														defendant_id,
														booking_id: formData.id,
														includeCC,
														indemnitor_id: indId
													})
												} else {
													setPreviewTarget(true);
													props.getDocumentPdf({
														type: 'AABB',
														defendant_id,
														booking_id: formData.id,
														includeCC,
														indemnitor_id: indId
													})
												}
											} else {
												setPreviewTarget(true);
												props.getDocumentPdf({
													type: 'CC',
													booking_id: formData.id,
													indemnitor_id: indId,
													defendant_id
												})
											}
										}}
									>PREVIEW {previewText()} PDF</Button>}
								</div>
							</GridItem>}
							{<GridItem xs={12} sm={6} md={4}>
								<div>
									<CustomSelect
										label="Document Type"
										options={["Send Document With CC Authorization", "Send Document Without CC Authorization", "Send CC Authorization ONLY"]}
										choose={(e, n) => {
											setDocumentType(e);
										}}
										default={documentType}
									/>
									{props.action !== 'Add' && <div>
										{formData.surety && <Button
											color="primary"
											disabled={previewPerson ? false : true}
											onClick={() => {
												if (changesMade) {
													setNotification('Please save your booking first');
													return;
												}

												const personType = previewPerson.split('_');
												let indId = '';

												if (documentType !== "Send CC Authorization ONLY") {
													let includeCC = "Yes";
													if (documentType === 'Send Document Without CC Authorization') {
														includeCC = "No";
													}
													if (personType[1] === 'I') {
														indId = personType[0];
													} else {
														indId = 'defendant';
													}
													if (formData.surety === 'FCS') {
														console.log('formData', formData, defendant_id);
														setTarget(true);
														props.sendSinglePdf({
															type: 'FCS',
															defendant_id,
															booking_id: formData.id,
															includeCC,
															indemnitor_id: indId
														})
													} else {
														setTarget(true);
														props.sendSinglePdf({
															type: 'AABB',
															defendant_id,
															booking_id: formData.id,
															includeCC,
															indemnitor_id: indId
														})
													}
												} else {
													const personType = previewPerson.split('_');
													let indId = '';

													if (personType[1] === 'I') {
														indId = personType[0];
													}
													setCCTarget(true);
													props.sendCc({
														booking_id: formData.id,
														type: personType[1] === 'I' ? 'indemnitor' : 'defendant',
														indemnitor_id: parseInt(indId),
														defendant_id
													})
												}
											}}
										>
											SEND DOCS	{/* {formData.surety === 'FCS' ? 'FCS' : 'AABB'} */}
										</Button>}
									</div>}
								</div>
							</GridItem>}
						</GridContainer>
					</div>
				</GridItem>}
			</GridContainer>
			{Object.keys(fieldsObj).map((key, index) => {
				return (
					<div key={`hvv-${index}`}>
						<GridContainer
							className={`${props.action === "History View" && "no-click"}`}
							key={`${index}-b`}
						>
							{fieldsObj[key].map((element, i) => {
								// console.log('element', element)
								if (element === null) return <GridItem key={`${i}-booking`} xs={12} sm={3} md={3}></GridItem>;
								if (element === 'title_10') {
									return (
										<GridItem key={`${i}-booking`} xs={12} sm={3} md={3}>
											{renderElement(element, fields, ['Self'].concat(options_override))}
										</GridItem>
									);
								}
								return (
									<GridItem key={`${i}-booking`} xs={12} sm={3} md={3}>
										{renderElement(element, fields)}
									</GridItem>
								);
							})}

						</GridContainer>
					</div>
				);
			})}

			<div className="boxed">
				<GridContainer>
					<BookingsFormCharges
						{...props}
						setChangesMade={setChangesMade}
						canDelete={canDelete}
						canEdit={canEdit}
						 />
				</GridContainer>
			</div>

			<div className="boxed">
				<GridContainer>
					<CourtTime
						{...props}
						canDelete={canDelete}
						canEdit={canEdit}
						setChangesMade={setChangesMade} />
				</GridContainer>
			</div>

			<div className="boxed">
				<GridContainer>
					<GridItem xs={12} sm={7}>
						<TextField
							id="standard-multiline-flexible"
							label="Notes"
							multiline
							fullWidth={true}
							variant="standard"
							value={formData.notes}
							onChange={(e) => {
								const newData = clone(formData);
								newData.notes = e.target.value;
								setChangesMade(true);
								setFormData(newData);
							}}
						/>
					</GridItem>
				</GridContainer>
			</div>

			<div className="boxed">
				<GridContainer>

					<GridItem xs={12}>
						<hr />
						<h3>Promissory Section</h3>
					</GridItem>
					{/* <GridItem xs={3}>
						{renderInput('bail_amount', 'Bail Amount', 45, true)}
						<div>
							{(!formData.bail_amount) && <div className="red-text"><small>Required</small></div>}
						</div>
					</GridItem> */}
					<GridItem xs={3}>
						{renderInput('bond_no', 'Power No/Bond No')}
					</GridItem>
					<GridItem xs={3}>
						{renderInput('premium_amount', 'Premium Amount', 45, true)}
					</GridItem>
					<GridItem xs={3}>
						{renderInput('amount_paid_down', 'Amount Paid Down', 45, true)}
					</GridItem>
					<GridItem xs={3}>
						{renderInput('unpaid_balance', 'Unpaid Balance', 45, true)}
					</GridItem>
					<GridItem xs={3}>
						{renderInput('cash_collateral', 'Cash Collateral', 45, true)}
					</GridItem>
					<GridItem xs={12}>
						<GridItem xs={12}>
							<Table
								tableHeaderColor="primary"
								tableHead={[<strong>PAYMENTS</strong>]}
								tableData={[
									[
										'Payment #1',
										<GridItem xs={12}>
											{renderInput('payment_amount_1', 'Payment Amount', 10, true)}
										</GridItem>,

										<GridItem xs={12}>
											{renderInput('payment_date_1', 'Date Payments Due', 10)}
										</GridItem>
									],
									[
										'Payment #2',
										<GridItem xs={12}>
											{renderInput('payment_amount_2', 'Payment Amount', 10, true)}
										</GridItem>,

										<GridItem xs={12}>
											{renderInput('payment_date_2', 'Date Payments Due', 10)}
										</GridItem>
									],
									[
										'Payment #3',
										<GridItem xs={12}>
											{renderInput('payment_amount_3', 'Payment Amount', 10, true)}
										</GridItem>,

										<GridItem xs={12}>
											{renderInput('payment_date_3', 'Date Payments Due', 10)}
										</GridItem>
									],
									[
										'Payment #4',
										<GridItem xs={12}>
											{renderInput('payment_amount_4', 'Payment Amount', 10, true)}
										</GridItem>,

										<GridItem xs={12}>
											{renderInput('payment_date_4', 'Date Payments Due', 10)}
										</GridItem>
									],
								]

								}
							/>
						</GridItem>
					</GridItem>
					<div className="boxed mt-20">
						<h3>Other Payment Schedule</h3>
						<GridItem xs={12}>
							<div style={{ marginBottom: 5 }}>
								<TextField
									id="standard-multiline-flexible"
									label="Line 1"
									fullWidth={true}
									variant="standard"
									value={formData.payment_schedule ? formData.payment_schedule : ''}
									onChange={(e) => {
										const newData = clone(formData);
										newData.payment_schedule = e.target.value;
										setChangesMade(true);
										setFormData(newData);
									}}
									inputProps={{
										maxLength: 82
									}}
								/>
								{formData.payment_schedule && formData.payment_schedule.length > 82 ? <div className="red-text"><small>Max of 82 characters exceeded!</small></div> : <div className="gray-text"><small>Max 82 Characters</small></div>}
							</div>
						</GridItem>
						<GridItem xs={12}>
							<div style={{ marginBottom: 15 }}>
								<TextField
									id="standard-multiline-flexible"
									label="Line 2"
									fullWidth={true}
									variant="standard"
									value={formData.payment_schedule2 ? formData.payment_schedule2 : ''}
									onChange={(e) => {
										const newData = clone(formData);
										newData.payment_schedule2 = e.target.value;
										setChangesMade(true);
										setFormData(newData);
									}}
									inputProps={{
										maxLength: 82
									}}
								/>
								{formData.payment_schedule2 && formData.payment_schedule2.length > 82 ? <div className="red-text"><small>Max of 82 characters exceeded!</small></div> : <div className="gray-text"><small>Max 82 Characters</small></div>}
							</div>
						</GridItem>
					</div>
					<GridItem xs={12} sm={7}>
						<div style={{ marginBottom: 5 }}>
							<TextField
								id="standard-multiline-flexible"
								label="Other Conditions"
								fullWidth={true}
								multiline
								variant="standard"
								value={formData.other_conditions ? formData.other_conditions : ''}
								onChange={(e) => {
									const newData = clone(formData);
									newData.other_conditions = e.target.value;
									setChangesMade(true);
									setFormData(newData);
								}}
								inputProps={{
									maxLength: 848
								}}
							/>
						</div>
					</GridItem>
				</GridContainer>
			</div>

			<div className="boxed">
				<GridContainer>
					<GridItem xs={12}>
						<hr />
						<h3>Credit Card Authorization</h3>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12} sm={4} md={4}>
						<div style={{ marginTop: 13 }}>
							<Datetime
								inputProps={{
									placeholder: "Charge Date"
								}}
								closeOnSelect={true}
								defaultValue={formData.credit_card_charge_date ? formData.credit_card_charge_date : props.initialData.credit_card_charge_date}
								onChange={(e) => {
									const newData = clone(formData);
									if (typeof e === 'object') {
										const stringDate = e.format('MM/DD/YYYY');
										// use stringDate to set value
										newData.credit_card_charge_date = stringDate;
										setFormData(newData);
										setChangesMade(true);
										return;

									}
									newData.credit_card_charge_date = e;
									setChangesMade(true);
									setFormData(newData);
								}}
								timeFormat={false}
							/>
							{(!date_is_valid && formData.credit_card_charge_date) && <div className="red-text mt-10">Invalid Date, please format to MM/DD/YYYY</div>}
						</div>
					</GridItem>
					<GridItem xs={12} sm={4} md={4}>
						{renderInput('credit_card_charge_amount', 'Charge Amount', 150, true)}
					</GridItem>
				</GridContainer>
				<GridContainer>
					<div className="boxed mt-20">
						<h3>Recurring Charges</h3>
						<GridItem xs={12} sm={12}>
							{renderInput('recurring_charges', 'Line 1', 50, false)}
							{formData.recurring_charges && <div>
								{formData.recurring_charges.length > 50 ? <div className="red-text"><small>Max of 50 characters exceeded!</small></div> : <div className="gray-text"><small>Max 50 Characters</small></div>}
							</div>}
						</GridItem>
						<GridItem xs={12} sm={12}>
							{renderInput('recurring_charges2', 'Line 2', 110, false)}
							{
								formData.recurring_charges2 &&
								<div>
									{formData.recurring_charges2.length > 110 ?
										<div className="red-text"><small>Max of 110 characters exceeded!</small></div>
										:
										<div className="gray-text"><small>Max 110 Characters</small></div>}
								</div>
							}
						</GridItem>
						<GridItem xs={12} sm={12}>
							{renderInput('recurring_charges3', 'Line 3', 110, false)}
							{
								formData.recurring_charges3 &&
								<div>
									{formData.recurring_charges3.length > 110 ?
										<div className="red-text"><small>Max of 110 characters exceeded!</small></div>
										:
										<div className="gray-text"><small>Max 110 Characters</small></div>}
								</div>
							}
						</GridItem>
						<GridItem xs={12} sm={12}>
							{renderInput('recurring_charges4', 'Line 4', 110, false)}
							{
								formData.recurring_charges4 &&
								<div>
									{formData.recurring_charges4.length > 110 ?
										<div className="red-text"><small>Max of 110 characters exceeded!</small></div>
										:
										<div className="gray-text"><small>Max 110 Characters</small></div>}
								</div>
							}
						</GridItem>
					</div>
				</GridContainer>
			</div>

			{notification && (
				<Notification
					open={notification ? true : false}
					close={() => {
						setNotification("");
					}}
					message={<h4>{notification}</h4>}
				/>
			)}
			{askBeforeDelete && (
				<Dialog
					open={askBeforeDelete}
					onClose={() => {
						setAskBeforeDelete(false);
					}}
				>
					<div className="dialog-wrapper">
						<h3>Are you sure you want to delete this Booking?</h3>
						<Button
							color="danger"
							onClick={() => {
								props.delete(formData.id, props.index);
								setAskBeforeDelete(false);
							}}
							className="mr-20"
						>
							YES, DELETE
						</Button>
						<Button
							color="white"
							onClick={() => {
								setAskBeforeDelete(false);
							}}
						>
							CANCEL
						</Button>
					</div>
				</Dialog>
			)}

			{openAsk && (
				<Dialog
					open={openAsk}
					onClose={() => {
						setOpenAsk(false);
					}}
				>
					<div className="dialog-wrapper">
						<h3>You are about to exit without saving.</h3>
						<Button
							color="success"
							onClick={() => {
								if (props.action === 'Edit') {
									if (!checkValidity(formData))
										return setNotification(
											"Some fields are required. Put N/A to leave blank."
										);
									const newFormData = clone(formData);
									props.update(newFormData, props.index);
								}
								else if (props.action === 'Add') {
									if (!checkValidity(formData))
										return setNotification(
											"Some fields are required. Put N/A to leave blank."
										);

									const newFormData = clone(formData);
									newFormData.defendant_id = props.defendant_id;
									props.add(newFormData);
								}
								setChangesMade(false);
								setOpenAsk(false);
								props.close();
							}}
							className="mr-20"
						>
							Save and Exit
						</Button>
						<Button
							className="mr-20"
							color="danger"
							onClick={() => {
								props.close();
							}}
						>
							Exit Without Saving
						</Button>
						<Button
							color="white"
							onClick={() => {
								setOpenAsk(false);
							}}
						>
							CANCEL
						</Button>
					</div>
				</Dialog>
			)}

			{(target || cc_loading) && <Notification
				open={(target || cc_loading)}
				close={() => {
				}}
				message={<div className="loader" style={{ marginTop: 40 }}><ClipLoader /><br />Sending, Please wait...</div>}
				no_ok_button={true}
			/>}

			{previewTarget && <Notification
				open={previewTarget}
				close={() => {
				}}
				message={<div className="loader" style={{ marginTop: 40 }}><ClipLoader /><br />Preparing, Please wait...</div>}
				no_ok_button={true}
			/>}

			{notification && <Notification
				open={true}
				close={() => {
					setNotification('');
				}}
				message={notification}
			/>}
		</div>
	);
};

export default BookingsForm;
