import { put } from 'redux-saga/effects';
import axios from '../axios/axios-crm';

import * as actions from '../actions/crm_arrest';

export function* getArrestsSaga(action) {
    yield put(actions.getArrestsStart());
    try {
        const response = yield axios.get('/arrests?id='+action.data);
        yield put(actions.getArrestsSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getArrestsFail(e.response.data));
            }
          }
        yield put(actions.getArrestsFail(e));
    }
}

export function* createArrestSaga(action) {
    yield put(actions.createArrestStart());
    try {
        const response = yield axios.post('/arrest', action.data);
        yield put(actions.createArrestSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.createArrestFail(e.response.data));
            }
          }
        yield put(actions.createArrestFail(e));
    }
}

export function* updateArrestSaga(action) {
    yield put(actions.updateArrestStart());
    try {
        const response = yield axios.put('/arrest', action.data);
        yield put(actions.updateArrestSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.updateArrestFail(e.response.data));
            }
          }
        yield put(actions.updateArrestFail(e));
    }
}

export function* deleteArrestSaga(action) {
    yield put(actions.deleteArrestStart());
    try {
        const response = yield axios.delete('/arrest?id='+action.data);
        yield put(actions.deleteArrestSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.deleteArrestFail(e.response.data));
            }
          }
        yield put(actions.deleteArrestFail(e));
    }
}