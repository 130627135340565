import React, {Component} from 'react';
import CustomInput from 'components/CustomInput/CustomInput2.jsx';
import InputAdornment from '@material-ui/core/InputAdornment';

function makeid(length) {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

const InputField = (props) => {
	const {classes} = props;
	let error = '';
	if (props.validate) {
		if (props.validate.length > 0) {
			props.validate.forEach((val) => {
				// if (!val) 
				const err = val(props.value);
				if (err) {
					error = err;
				}
			});
		}
	}

	const inputProps = {
		onChange: (e) => {
			const start = e.target.selectionStart,
				end = e.target.selectionEnd;
			if (props.normalize) {
				e.target.value = props.normalize(e.target.value);
				e.target.setSelectionRange(start, end);
			}
			if (!props.readonly) {
				props.onChange(e);
			}
			if (props.updateError) {
				props.updateError(error);
			}
		},
		onClick: () => {
			if (props.onClick) props.onClick();
		},
		value: props.value ? props.value : '',
		autoFocus: props.autoFocus ? true : false,
		role: 'presentation',
		autoComplete: 'nope',
		disabled: props.disabled ? true : props.readonly ? true : false,
		readOnly: props.readonly ? true : false,
		startAdornment: props.startAdornment ? <InputAdornment position="end">{props.startAdornment}<span> </span></InputAdornment> : null
	};

	if (props.number_only) {
		inputProps.type = 'number';
	}

	if (props.type) {
		inputProps.type = props.type;
	}

	if (props.uppercase) {
		inputProps.onInput = (e) => e.target.value = ("" + e.target.value).toUpperCase()
	}

	return (
		<div className={`${(props.readonly && props.readonly_show_gray) ? 'gray-bg-input' : ''}`}>
			<CustomInput
				rows={props.rows ? props.rows : 1}
				labelText={props.label}
				id={props.name ? props.name : 'a' + makeid(5)}
				formControlProps={{
					fullWidth: true
				}}
				name={props.name}
				inputProps={inputProps}
				error={error ? true : false}
			/>
			{props.additionalError && (
				<div className='red' style={{marginTop: 0, fontSize: '10px'}}>
					{props.additionalError}
				</div>
			)}
			{error && (
				<div className='red' style={{marginTop: 0, fontSize: '10px'}}>
					{error}
				</div>
			)}
			{!error && (
				<div className='red' style={{display:'none',marginTop: 0, opacity: 0, fontSize: '10px'}}>
					_
				</div>
			)}
			{props.similar && !error ? props.similar : null}
		</div>
	);
};

export default InputField;
