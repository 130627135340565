import React, { useEffect, Fragment, useState } from "react";
import GridItem from "components/Grid/GridItem";
import Indemnitors from "containers/CRM/Indemnitors/Indemnitors";

export const DefendantsFormIndemnitors = (props) => {
	
	const { defendant_id, canEdit, canDelete } = props;

	return (
		<>
			<Indemnitors 
				defendant_id={defendant_id}
				indemnitorData={props.indemnitorData}
				setIndemnitorData={props.setIndemnitorData}
				action={props.action}
				is_client={props.is_client}
				canEdit={canEdit}
				canDelete={canDelete}
			/>
		</>
	);
};
