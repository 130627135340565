import React, { useEffect, useState } from 'react';
import Button from '../../../components/CustomButtons/Button';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import Notification from '../../../components/Notification/Notification';
import { ClipLoader } from 'react-spinners';
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
const DefendantsFormFCSDocs = (props) => {
    const { success, error } = props;
    const [target, setTarget] = useState(false);
    const [notification, setNotification] = useState('');
    useEffect(() => {
        console.log('success or error changed', target, success, error);
        if (target) {
            if (success) {
                setNotification(success);
            } else if (error) {
                setNotification(error);
            }
            setTarget(false);
        }
    }, [success, error])

    console.log('DefendantsFormFCSDocs', props, notification, target);

    return <div>
        <h3>FCS Docs</h3>
        <Button color="primary" onClick={() => {
            console.log(props);
            if (props.data) {
                if (props.data.id) {
                    setTarget(true);
                    props.sendFcs(props.data.id);
                }
            }
        }}>SEND</Button>
        <hr />
        <h3>AABB Docs</h3>
        <Button color="primary" onClick={() => {
            console.log('props', props.data);
            if (props.data) {
                if (props.data.id) {
                    setTarget(true);
                    props.sendFcs(props.data.id, 'AABB');
                }
            }
        }}>SEND</Button>

        {target && <Notification
            open={target}
            close={() => {
            }}
            message={<div className="loader" style={{ marginTop: 40 }}><ClipLoader /><br />Sending, Please wait...</div>}
            no_ok_button={true}
        />}

        {notification && <Notification
            open={true}
            close={() => {
                setNotification('');
            }}
            message={notification}
        />}
    </div>
}

const mapStateToProps = (state) => {
    return {
        error: state.documents.error,
        success: state.documents.success,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendFcs: (data, data_type) => {
            dispatch(actions.sendFcs(data, data_type));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefendantsFormFCSDocs);