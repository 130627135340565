import { put } from 'redux-saga/effects';
import axios from '../axios/axios-crm';
import { clone } from 'shared/utility';

import * as actions from '../actions/crm_booking';

export function* getBookingsSaga(action) {
    yield put(actions.getBookingsStart());
    try {
        const response = yield axios.get('/bookings?id='+action.data);
        yield put(actions.getBookingsSuccess(response.data));

    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getBookingsFail(e.response.data));
            }
          }
        yield put(actions.getBookingsFail(e));
    }
}

export function* createBookingSaga(action) {
    yield put(actions.createBookingStart());
    try {
      if (action.data) {
        const data = clone(action.data);
        if (data.charges) {
          data.charges = JSON.stringify(data.charges);
        }
        if (data.court_times) {
          data.court_times = JSON.stringify(data.court_times);
        }
        const response = yield axios.post('/booking', data);
        yield put(actions.createBookingSuccess(response.data));
      }
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.createBookingFail(e.response.data));
            }
          }
        yield put(actions.createBookingFail(e));
    }
}

export function* updateBookingSaga(action) {
    yield put(actions.updateBookingStart());
    try {
        if (action.data) {
          const data = clone(action.data);
          if (data.charges) {
            data.charges = JSON.stringify(data.charges);
          }
          if (data.court_times) {
            data.court_times = JSON.stringify(data.court_times);
          }
          const response = yield axios.put('/booking', data);
          yield put(actions.updateBookingSuccess(response.data));
        }
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.updateBookingFail(e.response.data));
            }
          }
        yield put(actions.updateBookingFail(e));
    }
}

export function* deleteBookingSaga(action) {
    yield put(actions.deleteBookingStart());
    try {
        const response = yield axios.delete('/booking?id='+action.data);
        yield put(actions.deleteBookingSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.deleteBookingFail(e.response.data));
            }
          }
        yield put(actions.deleteBookingFail(e));
    }
}