import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import SupervisedUserCircle from "@material-ui/icons/AccountCircle";
import CardIcon from "components/Card/CardIcon.jsx";

import Leads from "containers/CRM/Leads/Leads";
import checkPermissions from 'shared/checkPermissions';

class CRMLeadPage extends React.Component {
  componentDidMount() {
    this.props.checkMaintenanceMode();
  }
  render() {
    if (!checkPermissions('CRM')) {
      return <Redirect to={{
          pathname: '/dashboard' }
        } />;
    }
    if (this.props.maintenanceMode === 'yes') return <Redirect to="/components/maintenance_page" />

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            <Card>
                <CardHeader color="rose" icon>
                <CardIcon color="rose">
                    <div className="hovered">
                    <SupervisedUserCircle
                        onClick={() => {
                        
                        }}
                    />
                    </div>
                </CardIcon>
                </CardHeader>
                <CardBody>
                    <Leads />
                </CardBody>
            </Card>
            </GridItem>
        </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    maintenanceMode: state.userPerms.maintenanceMode
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkMaintenanceMode: () => {
      dispatch(actions.checkMaintenanceMode());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CRMLeadPage);