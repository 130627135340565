import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "store/actions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ArrestsList from "./ArrestsList";
import ArrestsForm from "./ArrestsForm";
import { initialData } from "./ArrestsModel";
import { Dialog } from "@material-ui/core";
import { clone } from "shared/utility";
import { Close, Cached } from "@material-ui/icons";
import Notification from "../../../components/Notification/Notification";

const Arrests = (props) => {
	const [openForm, setOpenForm] = useState(false);
	const [action, setAction] = useState("Add");
	const [formData, setFormData] = useState(null);
	const [index, setIndex] = useState(-1);
	const [notification, setNotification] = useState("");
	const { defendant_id, arrestData, setArrestData, canEdit, canDelete } = props;

	useEffect(() => {
		if (props.success === "Successfully gotten arrests") {
			setArrestData(props.data);
			props.clearArrestMessages();
		} else if (
			props.success === "Successfully created arrest" ||
			props.success === "Successfully updated arrest" ||
			props.success === "Successfully deleted arrest"
		) {
			setNotification(props.success);
			props.getArrests(defendant_id);
			props.clearArrestMessages();
		}
	}, [props.success, props.error]);

	useEffect(() => {
        if (defendant_id && props.action === "Client Edit") {
            props.getArrests(defendant_id);
        }
    }, [defendant_id])

	useEffect(() => {
		if (defendant_id) props.getArrests(defendant_id);
	}, []);

	const updateDataObj = (field, value) => {
		const newObj = clone(formData);
		newObj[field] = value;
		setFormData(newObj);
	};

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={12}>
				<h3 style={{ display: "inline-block" }} className="mr-20">
					Arrests
				</h3>
				{props.action === "Edit" && (
					<Button
						justIcon
						round
						simple
						onClick={() => {
							props.getArrests(defendant_id);
						}}
						color="info"
					>
						<Cached />
					</Button>
				)}
				<br />
				{canEdit() &&
					<Button
					color="primary"
					onClick={() => {
						setAction("Add");
						setFormData(clone(initialData));
						setOpenForm(true);
					}}
				>
					<i className="fas fa-plus"></i> ADD NEW
				</Button>}
				<ArrestsList
					arrestData={arrestData}
					setArrestData={setArrestData}
					setAction={setAction}
					setFormData={setFormData}
					setOpenForm={setOpenForm}
					setIndex={setIndex}
					action={props.action}
				/>
				{openForm && (
					<Dialog
						open={openForm}
						onClose={() => {
							setOpenForm(false);
						}}
						fullScreen
					>
						<div>
							<span
								className="close-btn hoverable float-right"
								onClick={() => {
									setOpenForm(false);
								}}
							>
								<Close />
							</span>
						</div>
						<div className="dialog-wrapper2" style={{ maxWidth: 1200 }}>
							<ArrestsForm
								canDelete={canDelete}
								canEdit={canEdit}
								formData={formData}
								setFormData={setFormData}
								updateDataObj={updateDataObj}
								action={action}
								close={() => {
									setOpenForm(false);
								}}
								defendant_id={defendant_id}
								index={index}
								add={(formDat) => {
									
									if (props.action === "Add") {
										setArrestData(arrestData.concat([formDat]));
										setOpenForm(false);
									} else {
										props.createArrest(formDat);
									}
								}}
								update={(formDat, index) => {
									if (props.action === "Add") {
										setArrestData(
											arrestData.map((bd, i) => {
												if (i === index) {
													return formDat;
												} else {
													return bd;
												}
											})
										);
										setOpenForm(false);
									} else {
										props.updateArrest(formDat);
									}
								}}
								delete={(id, index) => {
									if (props.action === "Add") {
										setArrestData(
											arrestData.filter((bd, i) => {
												if (i === index) return false;
												return true;
											})
										);
										setOpenForm(false);
									} else {
										props.deleteArrest(id);
									}
								}}
							/>
						</div>
					</Dialog>
				)}
			</GridItem>
			{notification && (
				<Notification
					open={notification ? true : false}
					close={() => {
						setNotification("");
						setOpenForm(false);
					}}
					message={notification}
				/>
			)}
		</GridContainer>
	);
};

const mapStateToProps = (state) => {
	const a = state.crm_arrest;
	return {
		error: a.error,
		success: a.success,
		data: a.data,
		arrest: a.arrest,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		clearArrestMessages: () => {
			dispatch(actions.clearArrestMessages());
		},
		getArrests: (defendant_id) => {
			dispatch(actions.getArrests(defendant_id));
		},
		createArrest: (data) => {
			dispatch(actions.createArrest(data));
		},
		updateArrest: (data) => {
			dispatch(actions.updateArrest(data));
		},
		deleteArrest: (id) => {
			dispatch(actions.deleteArrest(id));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Arrests);
