import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { connect } from "react-redux";
import * as actions from "store/actions";
import { clone } from "shared/utility";
// import Button from "../components/CustomButtons/Button";
import { Dialog } from "@material-ui/core";
import CRMHistoryList from "./CRMHistoryList";
import { CRMHistoryForm } from "./CRMHistoryForm";

const CRMHistory = (props) => {
	// const [historiesData, setHistoriesData] = useState([]);
	const [openedModal, setOpenedModal] = useState(false);

	const [filters, setFilters] = useState({
		// first_name: "",
		// last_name: "",
		// email: "",
	});
	const [offset, setOffset] = useState(0);
	const [rows, setRows] = useState(10);
	const [sortData, setSortData] = useState({
		sortBy: "",
		sortDirection: "",
	});

	const getData = () => {
		const params = {
			offset,
			rows,
			sort_by: sortData.sortBy,
			sort_direction: sortData.sortDirection,
		};
		Object.keys(filters).forEach((field) => {
			if (filters[field]) {
				params[field] = filters[field];
			}
		});
		props.getHistories(params);
	};

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		getData();
	}, [sortData, rows, offset]);

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={12}>
				<h3>
					<strong>CRM History</strong>
				</h3>
				
				<CRMHistoryList
					data={
						props.historiesData
							? props.historiesData.data
								? props.historiesData.data
								: []
							: []
					}
					count={
						props.historiesData
							? props.historiesData.count
								? props.historiesData.count
								: 0
							: 0
					}
					filters={filters}
					setFilters={(f, val) => {
						const newFilters = clone(filters);
						newFilters[f] = val;
						setFilters(newFilters);
					}}
					getHistories={getData}
					offset={offset}
					setOffset={setOffset}
					rows={rows}
					setRows={setRows}
					sortBy={sortData.sortBy}
					sortDirection={sortData.sortDirection}
					setSortData={setSortData}
					getHistory={props.getHistory}
					history={props.history}
					setOpenedModal={setOpenedModal}
				/>
			</GridItem>

			{/* History Data Modal */}
			<Dialog
				open={openedModal}
				onClose={() => {
					setOpenedModal(false);
				}}
				fullScreen
			>
				<div className="dialog-wrapper">
					<CRMHistoryForm
						history={props.history}
						showCloseBtn
						close={() => setOpenedModal(false)}
					/>
				</div>
			</Dialog>
		</GridContainer>
	);
};

const mapStateToProps = (state) => {
	return {
		historiesData: state.crm_history.historiesData,
		history: state.crm_history.history,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getHistories: (params) => dispatch(actions.getHistories(params)),
		getHistory: (params) => dispatch(actions.getHistory(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CRMHistory);
