import React from "react";
import { useState, useEffect } from "react";
import Table from "components/Table/Table.jsx";
import SortButton from "components/SortButton/SortButton";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Cached from "@material-ui/icons/Cached";
import Datetime from "react-datetime";
import Tooltip from "@material-ui/core/Tooltip";
import { getUserPerms } from "../../../shared/authValidation";
import Checkbox from '@material-ui/core/Checkbox';
import { clone } from "shared/utility";
import Notification from "../../../components/Notification/Notification";
import { isFinite } from "lodash";


const NotificationsList = (props) => {
    const userPerms = getUserPerms();
    const isAdmin = userPerms ?
        userPerms.hasOwnProperty('CRM admin') ?
            true
            :
            false
        :
        false;
    console.log('userPerms', userPerms);
    const { rows, offset, setOpenedReminderModal, sendNotificationTo, setSendNotificationTo, additionalNumber, setAdditionalNumber, smsData, setMessageType, messageType } = props;
    const [checked, setChecked] = React.useState(true);
    const [message, setMessage] = useState('');
    const [sendButton, setSendButton] = useState(false);
    const handleChange = (event) => {
        console.log('is it handling the change?');
        setChecked(event.target.checked);
    };
    const page_num = Math.ceil((offset + 1) / rows);
    const page_count = Math.ceil(props.count / rows);
    const pages_links = isNaN(page_count)
        ? null
        : Array(page_count)
            .fill()
            .map((element, index) => index + 1)
            .map((num) => {
                if (page_count > 10) {
                    const four = page_num + 4;
                    const four2 = page_num - 4;
                    if (num > four || num < four2) {
                        return null;
                    }
                }
                return (
                    <span
                        onClick={() => {
                            props.setOffset((num - 1) * rows);
                        }}
                        className={
                            num === page_num ? "tiny-box current" : "hoverable tiny-box"
                        }
                        key={num}
                        style={{
                            marginRight: -1,
                        }}
                    >
                        {num}
                    </span>
                );
            });
    // const [dataArr, setDataArr] = useState([]);
    const showBack = page_num === 1 ? false : true;
    const showForward = page_num === page_count ? false : true;

    const checkArr = (arr, id, type, indemnitor_id) => {
        let isInArr = false;
        if (arr) {
            for (let i = 0; i < arr.length; i++) {
                const a = arr[i];
                // defendant / parent
                if (!type) {
                    if (a.parent_id === id) {
                        if (a.type === 'defendant') {
                            isInArr = true;
                            break;
                        }
                    }
                } else {
                    if (a.parent_id === id) {
                        if (a.indemnitor_id === indemnitor_id) {
                            if (a.type === type) {
                                isInArr = true;
                                break;
                            }
                        }
                    }
                }
            }
        }
        return isInArr;
    }

    const uncheck = (id, type) => {
        setSendNotificationTo(sendNotificationTo.filter(sn => {
            if (sn.parent_id === id) {
                if (sn.type === type) {
                    return false;
                }
            }
            return true;
        }))
    }

    const check = (obj) => {
        setSendNotificationTo(sendNotificationTo.concat([obj]))
    }

    const renderNav = () => {
        return (
            <div className="text-center">
                {props.data.length > 0 && (
                    <p style={{ paddingTop: "10px" }}>
                        Showing {offset + 1} to {offset + props.data.length} of{" "}
                        {props.count} defendant(s)
                    </p>
                )}

                {props.data.length > 0 && (
                    <div style={{ marginTop: 20 }}>
                        <span
                            onClick={() => {
                                props.setOffset(0);
                            }}
                            style={{ borderRight: !showBack ? '1px solid #ccc' : '0' }}
                            className="hoverable tiny-box">
                            First
                        </span>
                        <span
                            onClick={() => {
                                if (showBack) {
                                    props.setOffset((page_num - 1 - 1) * rows);
                                }
                            }}
                            style={{ opacity: showBack ? 1 : 0.2, marginRight: -1 }}
                            className={showBack ? "hoverable tiny-box" : "tiny-box"}
                        >
                            Previous
                        </span>
                        {pages_links}
                        {page_count > 10 ? ' . . . ' : ''}
                        <span
                            onClick={() => {
                                if (showForward) {
                                    props.setOffset((page_num + 1 - 1) * rows);
                                }
                            }}
                            style={{ opacity: showForward ? 1 : 0.2, marginRight: -1 }}
                            className={showForward ? "hoverable tiny-box" : "tiny-box"}
                        >
                            Next
                        </span>
                        <span
                            onClick={() => {
                                props.setOffset((page_count - 1) * rows);
                            }}
                            style={{ borderLeft: '1px solid #ccc' }}
                            className="hoverable tiny-box">
                            Last
                        </span>
                    </div>
                )}
            </div>
        );
    };

    const returnChecked = (arr, parent_id) => {
        const checked = {};
        if (arr) {
            arr.forEach(a => {
                if (parent_id === a.parent_id) {
                    console.log('aa checked', a.type);
                    if (a.type) checked[a.type] = true;
                }
            })
        }
        return checked;
    }

    const allTypes = [
        'landlord',
        'employer',
        'superior',
        'attorney',
        'spouse',
        'ex_parent_1',
        'ex_parent_2',
        'ex_parent_3',
        'mother',
        'father',
        'spouse_mother',
        'spouse_father',
        'bestfriend',
        'brother',
        'sister',
        'reference_1',
        'reference_2',
        'reference_3',
        'reference_4',
    ];

    const renderDataArr = () => {
        let dat = [];
        if (props.data) {
            dat = props.data.map((cd, i) => {
                const landlordName = cd.landlord_name ? cd.landlord_name.split(" ") : "";
                const superiorName = cd.superior ? cd.superior.split(" ") : "";
                const attorneyName = cd.defendants_attorney_name ? cd.defendants_attorney_name.split(" ") : "";
                const spouseName = cd.spouse_name ? cd.spouse_name.split(" ") : "";
                const ex1Name = cd.ex_parent_1_name ? cd.ex_parent_1_name.split(" ") : "";
                const ex2Name = cd.ex_parent_2_name ? cd.ex_parent_2_name.split(" ") : "";
                const ex3Name = cd.ex_parent_3_name ? cd.ex_parent_3_name.split(" ") : "";
                const motherName = cd.mother_name ? cd.mother_name.split(" ") : "";
                const fatherName = cd.father_name ? cd.father_name.split(" ") : "";
                const spouseMother = cd.spouse_mother_name ? cd.spouse_mother_name.split(" ") : "";
                const spouseFather = cd.spouse_father_name ? cd.spouse_father_name.split(" ") : "";
                const brotherName = cd.defendant_brother_name ? cd.defendant_brother_name.split(" ") : "";
                const sisterName = cd.defendant_sister_name ? cd.defendant_sister_name.split(" ") : "";
                const friendName = cd.bestfriend_name ? cd.bestfriend_name.split(" ") : "";
                const reference1 = cd.reference_1_name ? cd.reference_1_name.split(" ") : "";
                const reference2 = cd.reference_2_name ? cd.reference_2_name.split(" ") : "";
                const reference3 = cd.reference_3_name ? cd.reference_3_name.split(" ") : "";
                const reference4 = cd.reference_4_name ? cd.reference_4_name.split(" ") : "";

                // parent
                const isChecked = checkArr(sendNotificationTo, cd.id);

                const returnHasPhones = (obj) => {
                    // checks if defendant has any phones
                    let hasPhones = false;
                    const types = ['landlord_phone', 'employers_phone', 'superior_phone', 'defendants_attorney_phone',
                        'spouse_phone', 'ex_parent_1_phone', 'ex_parent_2_phone', 'ex_parent_3_phone', 'mother_phone',
                        'father_phone', 'spouse_mother_phone', 'spouse_father_phone', 'defendant_brother_phone', 'defendant_sister_phone',
                        'defendant_bestfriend_phone', 'reference_1_phone', 'reference_2_phone', 'reference_3_phone', 'reference_4_phone',
                    ];
                    if (obj) {
                        let count = 0;
                        types.forEach(t => {
                            if (obj[t]) {
                                count++;
                            }
                        })
                        if (count > 0) hasPhones = true;
                    }
                    return hasPhones;
                }
                const hasPhones = returnHasPhones(cd);

                const returnAllChecked = (arr, parent_id) => {
                    // checks if all has been checked
                    let allChecked = false;
                    let has_been_checked = [];
                    if (arr) {
                        // check defendant 
                        arr.forEach(a => {
                            if (parent_id === a.parent_id) {
                                if (a.type === 'defendant') {
                                    has_been_checked.push(a);
                                }
                            }
                        })
                        // check indemnitor
                        arr.forEach(a => {
                            if (parent_id === a.parent_id) {
                                if (a.type === 'indemnitor') {
                                    has_been_checked.push(a);
                                }
                            }
                        })
                        // check others
                        allTypes.forEach(type => {
                            arr.forEach(a => {
                                if (parent_id === a.parent_id) {
                                    if (type === a.type) {
                                        has_been_checked.push(a);
                                    }
                                }
                            })
                        })

                    }
                    const indemnitors_length = cd.indemnitors.length;
                    if (has_been_checked.length === (allTypes.length + indemnitors_length + 1)) allChecked = true;
                    return allChecked;
                }

                const allChecked = returnAllChecked(sendNotificationTo, cd.id);

                const checked = {}
                allTypes.forEach(ttype => {
                    checked[ttype] = checkArr(sendNotificationTo, cd.id, ttype);
                })


                console.log('- checked', checked, sendNotificationTo.length, cd.id);

                let rows = [
                    <div>
                        <Checkbox
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            onChange={() => {
                                if (isChecked) {
                                    // uncheck
                                    setSendNotificationTo(sendNotificationTo.filter(sn => {
                                        if (sn.parent_id === cd.id) {
                                            if (sn.type === 'defendant') return false;
                                        }
                                        return true;
                                    }))
                                } else {
                                    // check
                                    const newData = clone(sendNotificationTo);
                                    const newObj = {
                                        name: cd.first_name + " " + cd.last_name, phone: cd.phone, id: cd.id, defendant_id: cd.id, type: 'defendant',
                                        // identifier id
                                        parent_id: cd.id
                                    };
                                    newData.push(newObj);
                                    setSendNotificationTo(newData)
                                }
                            }}
                            className="notification-cell"
                            checked={checkArr(sendNotificationTo, cd.id)}
                        />
                        {cd.first_name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>&nbsp;
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (allChecked) {
                                        // uncheck all
                                        setSendNotificationTo(sendNotificationTo.filter(s => {
                                            if (s.parent_id === cd.id) return false;
                                            return true;
                                        }))
                                    } else {
                                        const newData = sendNotificationTo.filter(s => {
                                            if (s.parent_id === cd.id) return false;
                                            return true;
                                        });
                                        // add defendant
                                        newData.push({
                                            name: cd.first_name + " " + cd.last_name, phone: cd.phone, id: cd.id, defendant_id: cd.id, type: 'defendant',
                                            // identifier id
                                            parent_id: cd.id
                                        })
                                        // add indemnitors
                                        cd.indemnitors.forEach(ind => {
                                            newData.push({
                                                name: ind.name, phone: ind.phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor',
                                                // identifiers
                                                parent_id: cd.id, indemnitor_id: ind.id
                                            });
                                            newData.push({
                                                name: ind.employed_by, phone: ind.employers_phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor_employer',
                                                // identifiers
                                                parent_id: cd.id, indemnitor_id: ind.id
                                            });
                                            newData.push({
                                                name: ind.spouse, phone: ind.spouse_phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor_spouse',
                                                // identifiers
                                                parent_id: cd.id, indemnitor_id: ind.id
                                            });
                                            newData.push({
                                                name: ind.spouse_employed_by, phone: ind.phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor_spouse_employer',
                                                // identifiers
                                                parent_id: cd.id, indemnitor_id: ind.id
                                            });
                                            newData.push({
                                                name: ind.landlord_name, phone: ind.phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor_landlord',
                                                // identifiers
                                                parent_id: cd.id, indemnitor_id: ind.id
                                            });
                                            newData.push({
                                                name: ind.reference_1_name, phone: ind.phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor_reference_1',
                                                // identifiers
                                                parent_id: cd.id, indemnitor_id: ind.id
                                            });
                                            newData.push({
                                                name: ind.reference_2_name, phone: ind.phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor_reference_2',
                                                // identifiers
                                                parent_id: cd.id, indemnitor_id: ind.id
                                            });
                                            newData.push({
                                                name: ind.reference_3_name, phone: ind.phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor_reference_3',
                                                // identifiers
                                                parent_id: cd.id, indemnitor_id: ind.id
                                            });
                                            newData.push({
                                                name: ind.reference_4_name, phone: ind.phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor_reference_4',
                                                // identifiers
                                                parent_id: cd.id, indemnitor_id: ind.id
                                            });
                                        });

                                        setSendNotificationTo(newData.concat([
                                            {
                                                name: cd.landlord_name, phone: cd.landlord_phone, id: cd.id, defendant_id: cd.id,
                                                // identifiers 
                                                parent_id: cd.id, type: 'landlord'
                                            },
                                            {
                                                name: cd.employed_by, phone: cd.employers_phone, id: cd.id, defendant_id: cd.id,
                                                // identifier
                                                type: 'employer', parent_id: cd.id
                                            },
                                            {
                                                name: cd.superior, phone: cd.superior_phone, id: cd.id, defendant_id: cd.id,
                                                // identifiers
                                                type: 'superior', parent_id: cd.id
                                            },
                                            {
                                                name: cd.defendants_attorney_name, phone: cd.defendants_attorney_phone, id: cd.id, defendant_id: cd.id,
                                                // identifier
                                                type: 'attorney', parent_id: cd.id
                                            },
                                            {
                                                name: cd.spouse_name, phone: cd.spouse_phone, id: cd.id, defendant_id: cd.id,
                                                // identifier
                                                type: 'spouse', parent_id: cd.id
                                            },
                                            {
                                                name: cd.ex_parent_1_name, phone: cd.ex_parent_1_phone, id: cd.id, defendant_id: cd.id,
                                                type: 'ex_parent_1', parent_id: cd.id
                                            },
                                            {
                                                name: cd.ex_parent_2_name, phone: cd.ex_parent_2_phone, id: cd.id, defendant_id: cd.id,
                                                type: 'ex_parent_2', parent_id: cd.id
                                            },
                                            {
                                                name: cd.ex_parent_3_name, phone: cd.ex_parent_3_phone, id: cd.id, defendant_id: cd.id,
                                                type: 'ex_parent_3', parent_id: cd.id
                                            },
                                            {
                                                name: cd.mother_name, phone: cd.mother_phone, id: cd.id, defendant_id: cd.id,
                                                type: 'mother', parent_id: cd.id
                                            },
                                            {
                                                name: cd.father_name, phone: cd.father_phone, id: cd.id, defendant_id: cd.id,
                                                type: 'father', parent_id: cd.id
                                            },
                                            {
                                                name: cd.spouse_mother_name, phone: cd.spouse_mother_phone, id: cd.id, defendant_id: cd.id,
                                                type: 'spouse_mother', parent_id: cd.id
                                            },
                                            {
                                                name: cd.spouse_father_name, phone: cd.spouse_father_phone, id: cd.id, defendant_id: cd.id,
                                                type: 'spouse_father', parent_id: cd.id
                                            },
                                            {
                                                name: cd.defendant_brother_name, phone: cd.defendant_brother_phone, id: cd.id, defendant_id: cd.id,
                                                type: 'brother', parent_id: cd.id
                                            },
                                            {
                                                name: cd.defendant_sister_name, phone: cd.defendant_sister_phone, id: cd.id, defendant_id: cd.id,
                                                type: 'sister', parent_id: cd.id
                                            },
                                            {
                                                name: cd.bestfriend_name, phone: cd.bestfriend_phone, id: cd.id, defendant_id: cd.id,
                                                type: 'bestfriend', parent_id: cd.id
                                            },
                                            {
                                                name: cd.reference_1_name, phone: cd.reference_1_phone, id: cd.id, defendant_id: cd.id,
                                                type: 'reference_1', parent_id: cd.id
                                            },
                                            {
                                                name: cd.reference_2_name, phone: cd.reference_2_phone, id: cd.id, defendant_id: cd.id,
                                                type: 'reference_2', parent_id: cd.id
                                            },
                                            {
                                                name: cd.reference_3_name, phone: cd.reference_3_phone, id: cd.id, defendant_id: cd.id,
                                                type: 'reference_3', parent_id: cd.id
                                            },
                                            {
                                                name: cd.reference_4_name, phone: cd.reference_4_phone, id: cd.id, defendant_id: cd.id,
                                                type: 'reference_4', parent_id: cd.id
                                            }
                                        ]))
                                    }
                                }}
                                className="notification-cell"
                                checked={allChecked}
                            />
                            Check All
                        </span>
                        <br />
                        {(cd.indemnitors) && <div> {cd.indemnitors.map((ind, i) => {
                            const indemnitorChecked = checkArr(sendNotificationTo, cd.id, 'indemnitor', ind.id);
                            const indemnitorEmployerChecked = checkArr(sendNotificationTo, cd.id, 'indemnitor_employer', ind.id);
                            const indemnitorSpouseChecked = checkArr(sendNotificationTo, cd.id, 'indemnitor_spouse', ind.id);
                            const indemnitorSpouseEmpChecked = checkArr(sendNotificationTo, cd.id, 'indemnitor_spouse_employer', ind.id);
                            const indemnitorLandlordChecked = checkArr(sendNotificationTo, cd.id, 'indemnitor_landlord', ind.id);
                            const indemnitorRef1Checked = checkArr(sendNotificationTo, cd.id, 'indemnitor_reference_1', ind.id);
                            const indemnitorRef2Checked = checkArr(sendNotificationTo, cd.id, 'indemnitor_reference_2', ind.id);
                            const indemnitorRef3Checked = checkArr(sendNotificationTo, cd.id, 'indemnitor_reference_3', ind.id);
                            const indemnitorRef4Checked = checkArr(sendNotificationTo, cd.id, 'indemnitor_reference_4', ind.id);
                            const fullName = ind.name.split(" ");
                            const firstName = fullName[0];
                            const spouseName = ind.spouse.split(" ");
                            const landlordName = ind.landlord_name.split(" ");
                            const reference1Name = ind.reference_1_name.split(" ");
                            const reference2Name = ind.reference_2_name.split(" ");
                            const reference3Name = ind.reference_3_name.split(" ");
                            const reference4Name = ind.reference_4_name.split(" ");

                            return (<div key={`ind-${i}-${cd.id}`}>
                                {(ind.phone) &&
                                    <>
                                        <br />
                                        <div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                                            <Checkbox
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                onChange={() => {
                                                    if (!indemnitorChecked) {
                                                        // check
                                                        const newData = clone(sendNotificationTo);
                                                        const newObj = {
                                                            name: ind.name, phone: ind.phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor',
                                                            // identifiers
                                                            parent_id: cd.id, indemnitor_id: ind.id
                                                        }
                                                        newData.push(newObj);
                                                        setSendNotificationTo(newData)
                                                    } else {
                                                        // uncheck
                                                        setSendNotificationTo(sendNotificationTo.filter(sn => {
                                                            if (sn.parent_id === cd.id) {
                                                                if (sn.indemnitor_id === ind.id) {
                                                                    return false;
                                                                }
                                                            }
                                                            return true;
                                                        }))
                                                    }
                                                }}
                                                className="notification-cell"
                                                checked={indemnitorChecked}
                                            />
                                            {firstName}
                                        </div>
                                    </>}
                                {(ind.employers_phone) &&
                                    <>
                                        <br />
                                        <div className="notification-cell5"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                                            <Checkbox
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                onChange={() => {
                                                    if (!indemnitorEmployerChecked) {
                                                        // check
                                                        const newData = clone(sendNotificationTo);
                                                        const newObj = {
                                                            name: ind.employed_by, phone: ind.employers_phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor_employer',
                                                            // identifiers
                                                            parent_id: cd.id, indemnitor_id: ind.id
                                                        }
                                                        newData.push(newObj);
                                                        setSendNotificationTo(newData)
                                                    } else {
                                                        // uncheck
                                                        setSendNotificationTo(sendNotificationTo.filter(sn => {
                                                            if (sn.parent_id === cd.id) {
                                                                if (sn.indemnitor_id === ind.id) {
                                                                    if (sn.type === 'indemnitor_employer') {
                                                                        return false;
                                                                    }
                                                                }
                                                            }
                                                            return true;
                                                        }))
                                                    }
                                                }}
                                                className="notification-cell"
                                                checked={indemnitorEmployerChecked}
                                            />
                                            {ind.employed_by}
                                        </div>
                                    </>}
                                {(ind.spouse_phone) &&
                                    <>
                                        <br />
                                        <div className="notification-cell5"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                                            <Checkbox
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                onChange={() => {
                                                    if (!indemnitorSpouseChecked) {
                                                        // check
                                                        const newData = clone(sendNotificationTo);
                                                        const newObj = {
                                                            name: ind.spouse, phone: ind.spouse_phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor_spouse',
                                                            // identifiers
                                                            parent_id: cd.id, indemnitor_id: ind.id
                                                        }
                                                        newData.push(newObj);
                                                        setSendNotificationTo(newData)
                                                    } else {
                                                        // uncheck
                                                        setSendNotificationTo(sendNotificationTo.filter(sn => {
                                                            if (sn.parent_id === cd.id) {
                                                                if (sn.indemnitor_id === ind.id) {
                                                                    if (sn.type === 'indemnitor_spouse') {
                                                                        return false;
                                                                    }
                                                                }
                                                            }
                                                            return true;
                                                        }))
                                                    }
                                                }}
                                                className="notification-cell"
                                                checked={indemnitorSpouseChecked}
                                            />
                                            {spouseName[0]}
                                        </div>
                                    </>}
                                {(ind.spouse_employers_phone) &&
                                    <>
                                        <br />
                                        <div className="notification-cell5"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                                            <Checkbox
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                onChange={() => {
                                                    if (!indemnitorSpouseEmpChecked) {
                                                        // check
                                                        const newData = clone(sendNotificationTo);
                                                        const newObj = {
                                                            name: ind.spouse_employed_by, phone: ind.spouse_employers_phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor_spouse_employer',
                                                            // identifiers
                                                            parent_id: cd.id, indemnitor_id: ind.id
                                                        }
                                                        newData.push(newObj);
                                                        setSendNotificationTo(newData)
                                                    } else {
                                                        // uncheck
                                                        setSendNotificationTo(sendNotificationTo.filter(sn => {
                                                            if (sn.parent_id === cd.id) {
                                                                if (sn.indemnitor_id === ind.id) {
                                                                    if (sn.type === 'indemnitor_spouse_employer') {
                                                                        return false;
                                                                    }
                                                                }
                                                            }
                                                            return true;
                                                        }))
                                                    }
                                                }}
                                                className="notification-cell"
                                                checked={indemnitorSpouseEmpChecked}
                                            />
                                            {ind.spouse_employed_by}
                                        </div>
                                    </>}
                                {(ind.landlord_phone) &&
                                    <>
                                        <br />
                                        <div className="notification-cell5"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                                            <Checkbox
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                onChange={() => {
                                                    if (!indemnitorLandlordChecked) {
                                                        // check
                                                        const newData = clone(sendNotificationTo);
                                                        const newObj = {
                                                            name: ind.landlord_name, phone: ind.landlord_phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor_landlord',
                                                            // identifiers
                                                            parent_id: cd.id, indemnitor_id: ind.id
                                                        }
                                                        newData.push(newObj);
                                                        setSendNotificationTo(newData)
                                                    } else {
                                                        // uncheck
                                                        setSendNotificationTo(sendNotificationTo.filter(sn => {
                                                            if (sn.parent_id === cd.id) {
                                                                if (sn.indemnitor_id === ind.id) {
                                                                    if (sn.type === 'indemnitor_landlord') {
                                                                        return false;
                                                                    }
                                                                }
                                                            }
                                                            return true;
                                                        }))
                                                    }
                                                }}
                                                className="notification-cell"
                                                checked={indemnitorLandlordChecked}
                                            />
                                            {landlordName[0]}
                                        </div>
                                    </>}
                                {(ind.reference_1_phone) &&
                                    <>
                                        <br />
                                        <div className="notification-cell5"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                                            <Checkbox
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                onChange={() => {
                                                    if (!indemnitorRef1Checked) {
                                                        // check
                                                        const newData = clone(sendNotificationTo);
                                                        const newObj = {
                                                            name: ind.reference_1_name, phone: ind.reference_1_phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor_reference_1',
                                                            // identifiers
                                                            parent_id: cd.id, indemnitor_id: ind.id
                                                        }
                                                        newData.push(newObj);
                                                        setSendNotificationTo(newData)
                                                    } else {
                                                        // uncheck
                                                        setSendNotificationTo(sendNotificationTo.filter(sn => {
                                                            if (sn.parent_id === cd.id) {
                                                                if (sn.indemnitor_id === ind.id) {
                                                                    if (sn.type === 'indemnitor_reference_1') {
                                                                        return false;
                                                                    }
                                                                }
                                                            }
                                                            return true;
                                                        }))
                                                    }
                                                }}
                                                className="notification-cell"
                                                checked={indemnitorRef1Checked}
                                            />
                                            {reference1Name[0]}
                                        </div>
                                    </>}
                                {(ind.reference_2_phone) &&
                                    <>
                                        <br />
                                        <div className="notification-cell5"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                                            <Checkbox
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                onChange={() => {
                                                    if (!indemnitorRef2Checked) {
                                                        // check
                                                        const newData = clone(sendNotificationTo);
                                                        const newObj = {
                                                            name: ind.reference_2_name, phone: ind.reference_2_phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor_reference_2',
                                                            // identifiers
                                                            parent_id: cd.id, indemnitor_id: ind.id
                                                        }
                                                        newData.push(newObj);
                                                        setSendNotificationTo(newData)
                                                    } else {
                                                        // uncheck
                                                        setSendNotificationTo(sendNotificationTo.filter(sn => {
                                                            if (sn.parent_id === cd.id) {
                                                                if (sn.indemnitor_id === ind.id) {
                                                                    if (sn.type === 'indemnitor_reference_2') {
                                                                        return false;
                                                                    }
                                                                }
                                                            }
                                                            return true;
                                                        }))
                                                    }
                                                }}
                                                className="notification-cell"
                                                checked={indemnitorRef2Checked}
                                            />
                                            {reference2Name[0]}
                                        </div>
                                    </>}
                                {(ind.reference_3_phone) &&
                                    <>
                                        <br />
                                        <div className="notification-cell5"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                                            <Checkbox
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                onChange={() => {
                                                    if (!indemnitorRef3Checked) {
                                                        // check
                                                        const newData = clone(sendNotificationTo);
                                                        const newObj = {
                                                            name: ind.reference_3_name, phone: ind.reference_3_phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor_reference_3',
                                                            // identifiers
                                                            parent_id: cd.id, indemnitor_id: ind.id
                                                        }
                                                        newData.push(newObj);
                                                        setSendNotificationTo(newData)
                                                    } else {
                                                        // uncheck
                                                        setSendNotificationTo(sendNotificationTo.filter(sn => {
                                                            if (sn.parent_id === cd.id) {
                                                                if (sn.indemnitor_id === ind.id) {
                                                                    if (sn.type === 'indemnitor_reference_3') {
                                                                        return false;
                                                                    }
                                                                }
                                                            }
                                                            return true;
                                                        }))
                                                    }
                                                }}
                                                className="notification-cell"
                                                checked={indemnitorRef3Checked}
                                            />
                                            {reference3Name[0]}
                                        </div>
                                    </>}
                                {(ind.reference_4_phone) &&
                                    <>
                                        <br />
                                        <div className="notification-cell5"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                                            <Checkbox
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                onChange={() => {
                                                    if (!indemnitorRef4Checked) {
                                                        // check
                                                        const newData = clone(sendNotificationTo);
                                                        const newObj = {
                                                            name: ind.reference_4_name, phone: ind.reference_4_phone, id: ind.id, defendant_id: cd.id, type: 'indemnitor_reference_4',
                                                            // identifiers
                                                            parent_id: cd.id, indemnitor_id: ind.id
                                                        }
                                                        newData.push(newObj);
                                                        setSendNotificationTo(newData)
                                                    } else {
                                                        // uncheck
                                                        setSendNotificationTo(sendNotificationTo.filter(sn => {
                                                            if (sn.parent_id === cd.id) {
                                                                if (sn.indemnitor_id === ind.id) {
                                                                    if (sn.type === 'indemnitor_reference_4') {
                                                                        return false;
                                                                    }
                                                                }
                                                            }
                                                            return true;
                                                        }))
                                                    }
                                                }}
                                                className="notification-cell"
                                                checked={indemnitorRef4Checked}
                                            />
                                            {reference4Name[0]}
                                        </div>
                                    </>}

                            </div>
                            );
                        })}
                        </div>}
                        {cd.landlord_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['landlord']) {
                                        uncheck(cd.id, 'landlord');
                                    } else {
                                        check({
                                            name: cd.landlord_name, phone: cd.landlord_phone, id: cd.id, defendant_id: cd.id,
                                            // identifiers 
                                            parent_id: cd.id, type: 'landlord'
                                        })
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['landlord']}
                            />
                            {landlordName[0]}</div></> : ''}
                        {cd.employers_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['employer']) {
                                        uncheck(cd.id, 'employer');
                                    } else {
                                        // check 
                                        check({
                                            name: cd.employed_by, phone: cd.employers_phone, id: cd.id, defendant_id: cd.id,
                                            // identifier
                                            type: 'employer', parent_id: cd.id
                                        });
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['employer']}
                            />
                            {cd.employed_by}</div></> : ''}
                        {cd.superior_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    console.log('checkeding', checked)
                                    if (checked['superior']) {
                                        uncheck(cd.id, 'superior');
                                    } else {
                                        check({
                                            name: cd.superior, phone: cd.superior_phone, id: cd.id, defendant_id: cd.id,
                                            // identifiers
                                            type: 'superior', parent_id: cd.id
                                        });
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['superior']}
                            />
                            {superiorName[0]}</div></> : ''}
                        {cd.defendants_attorney_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['attorney']) {
                                        uncheck(cd.id, 'attorney');
                                    } else {
                                        check({
                                            name: cd.defendants_attorney_name, phone: cd.defendants_attorney_phone, id: cd.id, defendant_id: cd.id,
                                            // identifier
                                            type: 'attorney', parent_id: cd.id
                                        })
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['attorney']}
                            />
                            {attorneyName[0]}</div></> : ''}
                        {cd.spouse_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['spouse']) {
                                        uncheck(cd.id, 'spouse');
                                    } else {
                                        check({
                                            name: cd.spouse_name, phone: cd.spouse_phone, id: cd.id, defendant_id: cd.id,
                                            // identifier
                                            type: 'spouse', parent_id: cd.id
                                        })
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['spouse']}
                            />
                            {spouseName[0]}</div></> : ''}
                        {cd.ex_parent_1_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['ex_parent_1']) {
                                        uncheck(cd.id, 'ex_parent_1');
                                    } else {
                                        check({
                                            name: cd.ex_parent_1_name, phone: cd.ex_parent_1_phone, id: cd.id, defendant_id: cd.id,
                                            type: 'ex_parent_1', parent_id: cd.id
                                        });
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['ex_parent_1']}
                            />
                            {ex1Name[0]}</div></> : ''}
                        {cd.ex_parent_2_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['ex_parent_2']) {
                                        uncheck(cd.id, 'ex_parent_2');
                                    } else {
                                        check({
                                            name: cd.ex_parent_2_name, phone: cd.ex_parent_2_phone, id: cd.id, defendant_id: cd.id,
                                            type: 'ex_parent_2', parent_id: cd.id
                                        })
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['ex_parent_2']}
                            />
                            {ex2Name[0]}</div></> : ''}
                        {cd.ex_parent_3_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['ex_parent_3']) {
                                        uncheck(cd.id, 'ex_parent_3');
                                    } else {
                                        check({
                                            name: cd.ex_parent_3_name, phone: cd.ex_parent_3_phone, id: cd.id, defendant_id: cd.id,
                                            type: 'ex_parent_3', parent_id: cd.id
                                        })
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['ex_parent_3']}
                            />
                            {ex3Name[0]}</div></> : ''}
                        {cd.mother_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['mother']) {
                                        uncheck(cd.id, 'mother');
                                    } else {
                                        check({
                                            name: cd.mother_name, phone: cd.mother_phone, id: cd.id, defendant_id: cd.id,
                                            type: 'mother', parent_id: cd.id
                                        })
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['mother']}
                            />
                            {motherName[0]}</div></> : ''}
                        {cd.father_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['father']) {
                                        uncheck(cd.id, 'father');
                                    } else {
                                        check({
                                            name: cd.father_name, phone: cd.father_phone, id: cd.id, defendant_id: cd.id,
                                            type: 'father', parent_id: cd.id
                                        })
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['father']}
                            />
                            {fatherName[0]}</div></> : ''}
                        {cd.spouse_mother_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['spouse_mother']) {
                                        uncheck(cd.id, 'spouse_mother');
                                    } else {
                                        check({
                                            name: cd.spouse_mother_name, phone: cd.spouse_mother_phone, id: cd.id, defendant_id: cd.id,
                                            type: 'spouse_mother', parent_id: cd.id
                                        })
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['spouse_mother']}
                            />
                            {spouseMother[0]}</div></> : ''}
                        {cd.spouse_father_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['spouse_father']) {
                                        uncheck(cd.id, 'spouse_father');
                                    } else {
                                        check({
                                            name: cd.spouse_father_name, phone: cd.spouse_father_phone, id: cd.id, defendant_id: cd.id,
                                            type: 'spouse_father', parent_id: cd.id
                                        })
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['spouse_father']}
                            />
                            {spouseFather[0]}</div></> : ''}
                        {cd.defendant_brother_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['brother']) {
                                        uncheck(cd.id, 'brother');
                                    } else {
                                        check({
                                            name: cd.defendant_brother_name, phone: cd.defendant_brother_phone, id: cd.id, defendant_id: cd.id,
                                            type: 'brother', parent_id: cd.id
                                        })
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['brother']}
                            />
                            {brotherName[0]}</div></> : ''}
                        {cd.defendant_sister_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['sister']) {
                                        uncheck(cd.id, 'sister');
                                    } else {
                                        check({
                                            name: cd.defendant_sister_name, phone: cd.defendant_sister_phone, id: cd.id, defendant_id: cd.id,
                                            type: 'sister', parent_id: cd.id
                                        })
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['sister']}
                            />
                            {sisterName[0]}</div></> : ''}
                        {cd.bestfriend_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['bestfriend']) {
                                        uncheck(cd.id, 'bestfriend');
                                    } else {
                                        check({
                                            name: cd.bestfriend_name, phone: cd.bestfriend_phone, id: cd.id, defendant_id: cd.id,
                                            type: 'bestfriend', parent_id: cd.id
                                        })
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['bestfriend']}
                            />
                            {friendName[0]}</div></> : ''}
                        {cd.reference_1_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['reference_1']) {
                                        uncheck(cd.id, 'reference_1');
                                    } else {
                                        check({
                                            name: cd.reference_1_name, phone: cd.reference_1_phone, id: cd.id, defendant_id: cd.id,
                                            type: 'reference_1', parent_id: cd.id
                                        })
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['reference_1']}
                            />
                            {reference1[0]}</div></> : ''}
                        {cd.reference_2_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['reference_2']) {
                                        uncheck(cd.id, 'reference_2');
                                    } else {
                                        check({
                                            name: cd.reference_2_name, phone: cd.reference_2_phone, id: cd.id, defendant_id: cd.id,
                                            type: 'reference_2', parent_id: cd.id
                                        })
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['reference_2']}
                            />
                            {reference2[0]}</div></> : ''}
                        {cd.reference_3_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['reference_3']) {
                                        uncheck(cd.id, 'reference_3');
                                    } else {
                                        check({
                                            name: cd.reference_3_name, phone: cd.reference_3_phone, id: cd.id, defendant_id: cd.id,
                                            type: 'reference_3', parent_id: cd.id
                                        })
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['reference_3']}
                            />
                            {reference3[0]}</div></> : ''}
                        {cd.reference_4_phone ? <><br /><div className="notification-cell4"><span style={{ fontWeight: 'bold', color: 'black' }}>↳</span>
                            <Checkbox
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                onChange={() => {
                                    if (checked['reference_4']) {
                                        uncheck(cd.id, 'reference_4');
                                    } else {
                                        check({
                                            name: cd.reference_4_name, phone: cd.reference_4_phone, id: cd.id, defendant_id: cd.id,
                                            type: 'reference_4', parent_id: cd.id
                                        })
                                    }
                                }}
                                className="notification-cell"
                                checked={checked['reference_4']}
                            />
                            {reference4[0]}</div></> : ''}
                    </div>
                    ,
                    <div>
                        {cd.last_name}<br />
                        {(cd.indemnitors) && <div> {cd.indemnitors.map((ind, i) => {

                            const fullName = ind.name.split(" ");
                            const lastName = fullName[1];
                            const spouseName = ind.spouse.split(" ");
                            const landlordName = ind.landlord_name.split(" ");
                            const reference1Name = ind.reference_1_name.split(" ");
                            const reference2Name = ind.reference_2_name.split(" ");
                            const reference3Name = ind.reference_3_name.split(" ");
                            const reference4Name = ind.reference_4_name.split(" ");

                            return <div key={`ind2${i}-${cd.id}`}>
                                {ind.phone && lastName ? <><br /><div className="notification-cell4">&nbsp;{lastName}</div></> : ind.phone && !lastName ? <><br /><div className="notification-cell4">-</div></> : ''}
                                {ind.employers_phone && ind.employed_by ? <><br /><div className="notification-cell6">-</div></> : ''}
                                {ind.spouse_phone && spouseName ? <><br /><div className="notification-cell6">&nbsp;{spouseName[1]}</div></> : ind.spouse_phone && !spouseName[1] ? <><br /><div className="notification-cell6">-</div></> : ''}
                                {ind.spouse_employers_phone && ind.spouse_employed_by ? <><br /><div className="notification-cell6">-</div></> : ''}
                                {ind.landlord_phone && landlordName ? <><br /><div className="notification-cell6">&nbsp;{landlordName[1]}</div></> : ind.landlord_phone && !landlordName[1] ? <><br /><div className="notification-cell6">-</div></> : ''}
                                {ind.reference_1_phone && reference1Name ? <><br /><div className="notification-cell6">&nbsp;{reference1Name[1]}</div></> : ind.reference_1_phone && !reference1Name[1] ? <><br /><div className="notification-cell6">-</div></> : ''}
                                {ind.reference_2_phone && reference2Name ? <><br /><div className="notification-cell6">&nbsp;{reference2Name[1]}</div></> : ind.reference_2_phone && !reference2Name[1] ? <><br /><div className="notification-cell6">-</div></> : ''}
                                {ind.reference_3_phone && reference3Name ? <><br /><div className="notification-cell6">&nbsp;{reference3Name[1]}</div></> : ind.reference_3_phone && !reference3Name[1] ? <><br /><div className="notification-cell6">-</div></> : ''}
                                {ind.reference_4_phone && reference4Name ? <><br /><div className="notification-cell6">&nbsp;{reference4Name[1]}</div></> : ind.reference_4_phone && !reference4Name[1] ? <><br /><div className="notification-cell6">-</div></> : ''}
                            </div>;
                        })}
                        </div>}
                        {cd.landlord_phone && landlordName[1] ? <><br /><div className="notification-cell3"> {landlordName[1]}</div></> : cd.landlord_phone && !landlordName[1] ? <><br /><div className="notification-cell3">-</div></> : ''}
                        {cd.employers_phone ? <><br /><div className="notification-cell3">-</div></> : null}
                        {cd.superior_phone && superiorName[1] ? <><br /><div className="notification-cell3"> {superiorName[1]}</div></> : cd.superior_phone && !superiorName[1] ? <><br /><div className="notification-cell3">-</div></> : null}
                        {cd.defendants_attorney_phone && attorneyName[1] ? <><br /><div className="notification-cell3"> {attorneyName[1]}</div></> : cd.defendants_attorney_phone && !attorneyName[1] ? <><br /><div className="notification-cell3"> - </div></> : ''}
                        {cd.spouse_phone && spouseName[1] ? <><br /><div className="notification-cell3"> {spouseName[1]}</div></> : cd.spouse_phone && !spouseName[1] ? <><br /><div className="notification-cell3"> - </div></> : ''}
                        {cd.ex_parent_1_phone && ex1Name[1] ? <><br /><div className="notification-cell3"> {ex1Name[1]}</div></> : cd.ex_parent_1_phone && !ex1Name[1] ? <><br /><div className="notification-cell3"> - </div></> : ''}
                        {cd.ex_parent_2_phone && ex2Name[1] ? <><br /><div className="notification-cell3"> {ex2Name[1]}</div></> : cd.ex_parent_2_phone && !ex2Name[1] ? <><br /><div className="notification-cell3"> - </div></> : ''}
                        {cd.ex_parent_3_phone && ex3Name[1] ? <><br /><div className="notification-cell3"> {ex3Name[1]}</div></> : cd.ex_parent_3_phone && !ex3Name[1] ? <><br /><div className="notification-cell3"> - </div></> : ''}
                        {cd.mother_phone && motherName[1] ? <><br /><div className="notification-cell3"> {motherName[1]}</div></> : cd.mother_phone && !motherName[1] ? <><br /><div className="notification-cell3"> - </div></> : ''}
                        {cd.father_phone && fatherName[1] ? <><br /><div className="notification-cell3"> {fatherName[1]}</div></> : cd.father_phone && !fatherName[1] ? <><br /><div className="notification-cell3"> - </div></> : ''}
                        {cd.spouse_mother_phone && spouseMother[1] ? <><br /><div className="notification-cell3"> {spouseMother[1]}</div></> : cd.spouse_mother_phone && !spouseMother[1] ? <><br /><div className="notification-cell3"> - </div></> : ''}
                        {cd.spouse_father_phone && spouseFather[1] ? <><br /><div className="notification-cell3"> {spouseFather[1]}</div></> : cd.spouse_father_phone && !spouseFather[1] ? <><br /><div className="notification-cell3"> - </div></> : ''}
                        {cd.defendant_brother_phone && brotherName[1] ? <><br /><div className="notification-cell3">{brotherName[1]}</div></> : cd.brother_phone && !brotherName[1] ? <><br /><div className="notification-cell3">-</div></> : ''}
                        {cd.defendant_sister_phone && sisterName[1] ? <><br /><div className="notification-cell3">{sisterName[1]}</div></> : cd.sister_phone && !sisterName[1] ? <><br /><div className="notification-cell3">-</div></> : ''}
                        {cd.bestfriend_phone && friendName[1] ? <><br /><div className="notification-cell3">{friendName[1]}</div></> : cd.bestfriend_phone && !friendName[1] ? <><br /><div className="notification-cell3">-</div></> : ''}
                        {cd.reference_1_phone && reference1[1] ? <><br /><div className="notification-cell3"> {reference1[1]}</div></> : cd.reference_1_phone && !reference1[1] ? <><br /><div className="notification-cell3">-</div></> : ''}
                        {cd.reference_2_phone && reference2[1] ? <><br /><div className="notification-cell3">{reference2[1]}</div></> : cd.reference_2_phone && !reference2[1] ? <><br /><div className="notification-cell3">-</div></> : ''}
                        {cd.reference_3_phone && reference3[1] ? <><br /><div className="notification-cell3">{reference3[1]}</div></> : cd.reference_3_phone && !reference3[1] ? <><br /><div className="notification-cell3">-</div></> : ''}
                        {cd.reference_4_phone && reference4[1] ? <><br /><div className="notification-cell3">{reference4[1]}</div></> : cd.reference_4_phone && !reference4[1] ? <><br /><div className="notification-cell3">-</div></> : ''}
                    </div>
                    ,
                    <div>
                        {cd.phone}<br />
                        {(cd.indemnitors) && <div> {cd.indemnitors.map((ind, i) => {
                            return <div key={`ind2${i}-${cd.id}`}>
                                {ind.phone ? <><br /><div className="notification-cell2"> {ind.phone}</div></> : ''}
                                {ind.employers_phone ? <><br /><div className="notification-cell2">{ind.employers_phone}</div></> : ''}
                                {ind.spouse_phone ? <><br /><div className="notification-cell2"> {ind.spouse_phone}</div></> : ''}
                                {ind.spouse_employers_phone ? <><br /><div className="notification-cell2">{ind.spouse_employers_phone}</div></> : ''}
                                {ind.landlord_phone ? <><br /><div className="notification-cell2"> {ind.landlord_phone}</div></> : ''}
                                {ind.reference_1_phone ? <><br /><div className="notification-cell2"> {ind.reference_1_phone}</div></> : ''}
                                {ind.reference_2_phone ? <><br /><div className="notification-cell2"> {ind.reference_2_phone}</div></> : ''}
                                {ind.reference_3_phone ? <><br /><div className="notification-cell2"> {ind.reference_3_phone}</div></> : ''}
                                {ind.reference_4_phone ? <><br /><div className="notification-cell2"> {ind.reference_4_phone}</div></> : ''}
                            </div>;
                        })}
                        </div>}

                        {cd.landlord_phone ? <><br /><div className="notification-cell2"> {cd.landlord_phone}</div></> : ''}
                        {cd.employers_phone ? <><br /><div className="notification-cell2">{cd.employers_phone}</div></> : ''}
                        {cd.superior_phone ? <><br /><div className="notification-cell2">{cd.superior_phone}</div></> : ''}
                        {cd.defendants_attorney_phone ? <><br /><div className="notification-cell2">{cd.defendants_attorney_phone}</div></> : ''}
                        {cd.spouse_phone ? <><br /><div className="notification-cell2">{cd.spouse_phone}</div></> : ''}
                        {cd.ex_parent_1_phone ? <><br /><div className="notification-cell2">{cd.ex_parent_1_phone}</div></> : ''}
                        {cd.ex_parent_2_phone ? <><br /><div className="notification-cell2">{cd.ex_parent_2_phone}</div></> : ''}
                        {cd.ex_parent_3_phone ? <><br /><div className="notification-cell2">{cd.ex_parent_3_phone}</div></> : ''}
                        {cd.mother_phone ? <><br /><div className="notification-cell2">{cd.mother_phone}</div></> : ''}
                        {cd.father_phone ? <><br /><div className="notification-cell2">{cd.father_phone}</div></> : ''}
                        {cd.spouse_mother_phone ? <><br /><div className="notification-cell2">{cd.spouse_mother_phone}</div></> : ''}
                        {cd.spouse_father_phone ? <><br /><div className="notification-cell2">{cd.spouse_father_phone}</div></> : ''}
                        {cd.defendant_brother_phone ? <><br /><div className="notification-cell2">{cd.brother_phone}</div></> : ''}
                        {cd.defendant_sister_phone ? <><br /><div className="notification-cell2">{cd.sister_phone}</div></> : ''}
                        {cd.bestfriend_phone ? <><br /><div className="notification-cell2">{cd.bestfriend_phone}</div></> : ''}
                        {cd.reference_1_phone ? <><br /><div className="notification-cell2">{cd.reference_1_phone}</div></> : ''}
                        {cd.reference_2_phone ? <><br /><div className="notification-cell2">{cd.reference_2_phone}</div></> : ''}
                        {cd.reference_3_phone ? <><br /><div className="notification-cell2">{cd.reference_3_phone}</div></> : ''}
                        {cd.reference_4_phone ? <><br /><div className="notification-cell2">{cd.reference_4_phone}</div></> : ''}
                    </div>
                    ,
                    <div>
                        <div>Defendant</div>
                        {(cd.indemnitors) && <div> {cd.indemnitors.map((ind, i) => {
                            const name = ind.name;
                            return <div key={`ind2${i}-${cd.id}`}>
                                {ind.phone ? <><br /><div className="notification-cell2">Indemnitor</div></> : ''}
                                {ind.employers_phone ? <><br /><div className="notification-cell2">{name} Employer</div></> : ''}
                                {ind.spouse_phone ? <><br /><div className="notification-cell2"> {name} Spouse</div></> : ''}
                                {ind.spouse_employers_phone ? <><br /><div className="notification-cell2">{name} Spouse Employer</div></> : ''}
                                {ind.landlord_phone ? <><br /><div className="notification-cell2"> {name} Landlord</div></> : ''}
                                {ind.reference_1_phone ? <><br /><div className="notification-cell2"> {name} Reference 1</div></> : ''}
                                {ind.reference_2_phone ? <><br /><div className="notification-cell2"> {name} Reference 2</div></> : ''}
                                {ind.reference_3_phone ? <><br /><div className="notification-cell2"> {name} Reference 3</div></> : ''}
                                {ind.reference_4_phone ? <><br /><div className="notification-cell2"> {name} Reference 4</div></> : ''}
                            </div>;
                        })}
                        </div>}
                        {cd.landlord_phone ? <><br /><div className="notification-cell2">Landlord</div></> : ''}
                        {cd.employers_phone ? <><br /><div className="notification-cell2">Employer</div></> : ''}
                        {cd.superior ? <><br /><div className="notification-cell2">Superior</div></> : ''}
                        {cd.defendants_attorney_phone ? <><br /><div className="notification-cell2">Attorney</div></> : ''}
                        {cd.spouse_phone ? <><br /><div className="notification-cell2">Spouse</div></> : ''}
                        {cd.ex_parent_1_phone ? <><br /><div className="notification-cell2">Ex-Parent 1</div></> : ''}
                        {cd.ex_parent_2_phone ? <><br /><div className="notification-cell2">Ex-Parent 2</div></> : ''}
                        {cd.ex_parent_3_phone ? <><br /><div className="notification-cell2">Ex-Parent 3</div></> : ''}
                        {cd.mother_phone ? <><br /><div className="notification-cell2">Mother</div></> : ''}
                        {cd.father_phone ? <><br /><div className="notification-cell2">Father</div></> : ''}
                        {cd.spouse_mother_phone ? <><br /><div className="notification-cell2">Spouse Mother</div></> : ''}
                        {cd.spouse_father_phone ? <><br /><div className="notification-cell2">Spouse Father</div></> : ''}
                        {cd.defendant_brother_phone ? <><br /><div className="notification-cell2">Brother</div></> : ''}
                        {cd.defendant_sister_phone ? <><br /><div className="notification-cell2">Sister</div></> : ''}
                        {cd.bestfriend_phone ? <><br /><div className="notification-cell2">Bestfriend</div></> : ''}
                        {cd.reference_1_phone ? <><br /><div className="notification-cell2">Reference 1</div></> : ''}
                        {cd.reference_2_phone ? <><br /><div className="notification-cell2">Reference 2</div></> : ''}
                        {cd.reference_3_phone ? <><br /><div className="notification-cell2">Reference 3</div></> : ''}
                        {cd.reference_4_phone ? <><br /><div className="notification-cell2">Reference 4</div></> : ''}
                    </div>
                ];
                return rows;
            })
        }
        return dat;
    }

    const messageOptions = () => {
        const options = [];
        if (smsData.length > 0) {
            smsData.map((sms, i) => {
                if (sms.type == '1') {
                    options.push(sms.description);
                }
            })
        }
        return options;
    };

    const dataArr = renderDataArr();

    useEffect(() => {

    }, [props.data, sendNotificationTo]);

    const setSort = (sortBy, sortDirection) => {
        props.setSortData({
            sortBy,
            sortDirection,
        });
    };

    console.log('sendNotificationTo', sendNotificationTo);

    return (
        <div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    props.getDefendants();
                }}
            >
                <GridContainer>
                    <GridItem xs={8}>
                        <h3 style={{ display: "inline-block" }}>Notifications</h3>
                        <Button
                            justIcon
                            round
                            simple
                            onClick={() => {
                                props.getDefendants();
                            }}
                            color="info"
                        >
                            <Tooltip
                                title="Refresh"
                                placement="right"
                                style={{ display: "inline-block" }}
                            >
                                <Cached />
                            </Tooltip>
                        </Button>
                    </GridItem>
                    <GridItem xs={12} sm={2} md={2}>
                        <Button color="warning" type="submit" className="outlined-btn mr-20">
                            SEARCH
                        </Button>
                    </GridItem>
                    <GridItem xs={12} sm={2} md={2}>
                        <Button
                            color="primary"
                            onClick={() => {
                                props.resetFilters();
                            }}
                            className="outlined-btn"
                        >
                            CLEAR
                        </Button>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            label="Search by First Name"
                            value={props.filters.first_name}
                            onChange={(e) => {
                                props.setFilters("first_name", e.target.value);
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            label="Search by Phone"
                            value={props.filters.phone}
                            onChange={(e) => {
                                props.setFilters("phone", e.target.value);
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            label="Search by Case #"
                            value={props.filters.case_number}
                            onChange={(e) => {
                                props.setFilters("case_number", e.target.value);
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomSelect
                            label="Filter by Surety"
                            options={['FCS', 'Bankers']}
                            choose={(e, n) => {
                                props.setFilters('surety', e);
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            label="Search by Last Name"
                            value={props.filters.last_name}
                            onChange={(e) => {
                                props.setFilters("last_name", e.target.value);
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <div style={{ paddingTop: 25 }}>
                            <Datetime
                                inputProps={{
                                    placeholder: "Search by Bail Post Date",
                                    readOnly: true,
                                }}
                                closeOnSelect={true}
                                onChange={(e) => {
                                    if (typeof e === "object") {
                                        const stringDate = e.format("MM/DD/YYYY");
                                        props.setFilters("bail_post_date", stringDate);
                                    } else {
                                        props.setFilters("bail_post_date", e);
                                    }
                                }}
                                timeFormat={false}
                                value={
                                    props.filters.bail_post_date
                                        ? props.filters.bail_post_date
                                        : ""
                                }
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <div style={{ paddingTop: 25 }}>
                            <Datetime
                                inputProps={{
                                    placeholder: "Search by Arrest Date",
                                    readOnly: true,
                                }}
                                closeOnSelect={true}
                                onChange={(e) => {
                                    if (typeof e === "object") {
                                        const stringDate = e.format("MM/DD/YYYY");
                                        props.setFilters("arrest_when", stringDate);
                                    } else {
                                        props.setFilters("arrest_when", e);
                                    }
                                }}
                                timeFormat={false}
                                value={
                                    props.filters.arrest_when
                                        ? props.filters.arrest_when
                                        : ""
                                }
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomSelect
                            label="Filter by Docs Sent"
                            options={['Yes', 'No']}
                            choose={(e, n) => {
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            label="Search by Indemnitor Name"
                            value={props.filters.name}
                            onChange={(e) => {
                                props.setFilters("name", e.target.value);
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            label="Search by Booking #"
                            value={props.filters.booking}
                            onChange={(e) => {
                                props.setFilters("booking", e.target.value);
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                            label="Search by Power #"
                            value={props.filters.power_of_attorney_number}
                            onChange={(e) => {
                                props.setFilters("power_of_attorney_number", e.target.value);
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomSelect
                            label="Filter by Pending Signature"
                            options={['Yes', 'No']}
                            choose={(e, n) => {

                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        {renderNav()}
                    </GridItem>
                    <GridItem>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                            <Button
                                disabled={sendButton && sendNotificationTo.length > 0 || sendButton && additionalNumber ? false : true}
                                color="primary"
                                onClick={() => {
                                    console.log('sending to', sendNotificationTo);
                                    const newData = clone(sendNotificationTo);
                                    let id;
                                    let phone;
                                    let name;

                                    if (smsData) {
                                        for (let i = 0; i < smsData.length; i++) {
                                            if (smsData[i].description === messageType) {
                                                id = smsData[i].id;
                                            }
                                        }
                                    }

                                    console.log(additionalNumber)

                                    if (additionalNumber) {
                                        props.sendSmsOnly({
                                            id: id,
                                            phone: additionalNumber,
                                            name: '',
                                        });
                                    }

                                    for (let i = 0; i < newData.length; i++) {
                                        const o = newData[i];
                                        id = id;
                                        phone = o.phone;
                                        name = o.name
                                        if (phone && name) {
                                            if (phone.length === 10) {
                                                props.sendSmsOnly({
                                                    id: id,
                                                    phone: phone,
                                                    name: name,
                                                });
                                            }
                                        }
                                    };
                                    setSendNotificationTo([]);
                                    setSendButton(false);
                                    setMessageType('');
                                    setAdditionalNumber(null);
                                    setMessage('Sent SMS.');
                                    setAdditionalNumber('');
                                }}
                            >
                                Send Notification
                            </Button>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <div>
                            <CustomSelect
                                label="Message"
                                options={messageOptions()}
                                choose={(e, n) => {
                                    setSendButton(true)
                                    setMessageType(e);
                                }}
                            />
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <div>
                            <CustomInput
                                label="Add Phone Number"
                                value={additionalNumber}
                                onChange={(e, n) => {
                                    setAdditionalNumber(e.target.value);
                                }}
                            />
                        </div>
                    </GridItem>
                </GridContainer>
            </form>
            {props.data.length !== 0 && (
                <div className="illi-table">
                    <Table
                        tableHead={[
                            <div>
                                <strong>
                                    First Name
                                </strong>
                                <SortButton
                                    setSort={setSort}
                                    sortBy="first_name"
                                    sortDirection={props.sortDirection}
                                    currentSortBy={props.sortBy}
                                />
                            </div>,
                            <div>
                                <strong>
                                    Last Name
                                </strong>
                                <SortButton
                                    setSort={setSort}
                                    sortBy="last_name"
                                    sortDirection={props.sortDirection}
                                    currentSortBy={props.sortBy}
                                />
                            </div>,
                            <div>
                                <strong>
                                    Phone
                                </strong>
                                <SortButton
                                    setSort={setSort}
                                    sortBy="last_name"
                                    sortDirection={props.sortDirection}
                                    currentSortBy={props.sortBy}
                                />
                            </div>,
                            <div>
                                <strong>
                                    Relationship
                                </strong>
                            </div>,
                        ]}
                        tableData={dataArr}
                    />
                </div>
            )}
            <div className="text-center">
                <em>{props.data.length === 0 ? "No defendants found" : ""}</em>
            </div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                    <CustomSelect
                        label="Rows per page"
                        options={[10, 15, 20, 25, 30]}
                        choose={(e, n) => {
                            props.setOffset(0);
                            props.setRows(e);
                        }}
                        default={props.rows}
                    />
                </GridItem>
            </GridContainer>
            <div>{renderNav()}</div>

            {message && <Notification
                open={message}
                close={() => {
                    setMessage('');
                }}
                message={message}
            />}
        </div>
    );
};

export default NotificationsList;
