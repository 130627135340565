import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { clone } from "shared/utility";
import Button from "../../../components/CustomButtons/Button";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Notification from "../../../components/Notification/Notification";
import NotificationsList from "./NotificationsList";
import { getUserPerms } from 'shared/authValidation';
import { initialData } from "../Defendants/DefendantsModel";

const Notifications = (props) => {
	const [defendantData, setDefendantData] = useState(initialData);
	const [openNotificationModal, setOpenNotificationModal] = useState(false);
	const [sendNotificationTo, setSendNotificationTo] = useState([]);
	const [additionalNumber, setAdditionalNumber] = useState(null);
	const [smsData, setSmsData] = useState([]);
	const [messageType, setMessageType] = useState('');


	const [filters, setFilters] = useState({
		first_name: "",
		last_name: "",
		phone: "",
		power_of_attorney_number: "",
		booking: "",
		case_number: "",
		arrest_when: "",
		bail_post_date: "",
		name: "",
		surety: "",
	});
	const [offset, setOffset] = useState(0);
	const [rows, setRows] = useState(10);
	const [sortData, setSortData] = useState({
		sortBy: "date_created",
		sortDirection: "DESC",
	});

	const [id, setId] = useState(null);

	// Tracking updateDataObj
	const [unsavedWarning, setUnsavedWarning] = useState(false);
	const [openedWaning, setOpenedWarning] = useState(false);

	// Error/Success Modal
	const [openedMessageModal, setOpenedMessageModal] = useState(false);

	const { getDefendant, setIndemnitorData2 } = props;

	useEffect(() => {
		console.log('error', props.error)
		if (props.error) {
			if (props.error === 'Not authorized to access') {
				window.location.href = '/';
			}
		}
		if (props.success || props.error) {
			if (
				props.success !== "Successfully got defendant" &&
				props.success !== "Successfully got defendants"
			) {
				setOpenedMessageModal(true);
			}
		}
	}, [props.success, props.error]);

	// Delete defendant modal
	const userPerms = getUserPerms();

	const getData = () => {
		const params = {
			offset,
			rows,
			sort_by: sortData.sortBy,
			sort_direction: sortData.sortDirection,
		};
		Object.keys(filters).forEach((field) => {
			if (filters[field]) {
				params[field] = filters[field];
			}
		});
		props.getDefendants(params);
		props.getIndemnitors(null);
		props.getSmsConfig();
	};

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		let reset = true;
		for (let key in filters) {
			if (filters[key] !== "") {
				reset = false;
			}
		}
		reset && getData();
	}, [filters]);

	useEffect(() => {
		getData();
	}, [sortData, rows, offset]);

	useEffect(() => {
		if (props.defendant) {
			setDefendantData(props.defendant);

			if (props.defendant.length > 0) {
				setId(props.defendant[0].id);
			}
		}
	}, [props.defendant]);

	useEffect(() => {
		if (props.sms) {
			setSmsData(props.sms);
		}
	}, [props.sms]);

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={12}>
				<h3>
					<strong>CRM</strong>
				</h3>
				<NotificationsList
					data={
						props.defendantsData
							? props.defendantsData.data
								? props.defendantsData.data
								: []
							: []
					}
					count={
						props.defendantsData
							? props.defendantsData.count
								? props.defendantsData.count
								: 0
							: 0
					}
					filters={filters}
					setFilters={(f, val) => {
						const newFilters = clone(filters);
						newFilters[f] = val;
						setFilters(newFilters);
					}}
					resetFilters={() => {
						setFilters({
							first_name: "",
							last_name: "",
							phone: "",
							power_of_attorney_number: "",
							booking: "",
							case_number: "",
							arrest_when: "",
						});
					}}
					getDefendants={getData}
					offset={offset}
					setOffset={setOffset}
					rows={rows}
					setRows={setRows}
					sortBy={sortData.sortBy}
					sortDirection={sortData.sortDirection}
					setSortData={setSortData}
					setDefendantData={setDefendantData}
					getDefendant={props.getDefendant}
					allIndemnitors={props.allIndemnitors}
					openNotificationModal={setOpenNotificationModal}
					setOpenNotificationModal={setOpenNotificationModal}
					sendSmsOnly={props.sendSmsOnly}
					sendNotificationTo={sendNotificationTo}
					setSendNotificationTo={setSendNotificationTo}
					additionalNumber={additionalNumber}
					setAdditionalNumber={setAdditionalNumber}
					smsData={smsData}
					messageType={messageType}
					setMessageType={setMessageType}
				/>
			</GridItem>
		</GridContainer>
	);
};

const mapStateToProps = (state) => {
	return {
		error: state.crm.error,
		success: state.crm.success,
		defendantsData: state.crm.defendantsData,
		defendant: state.crm.defendant,
		allIndemnitors: state.crm.indemnitorsData,
		userData: state.user.userList,
		defendantUsers: state.crm.defendantUsers,
		sms: state.settings.sms,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		clearCRMMessages: () => {
			dispatch(actions.clearCRMMessages());
		},
		getDefendants: (params) => {
			dispatch(actions.getDefendants(params));
		},
		createDefendant: (data, bookingData, defendantData, arrestData, shouldSendSMS, photoData) => {
			dispatch(
				actions.createDefendant(data, bookingData, defendantData, arrestData, shouldSendSMS, photoData)
			);
		},
		deleteDefendant: (id) => {
			dispatch(actions.deleteDefendant(id));
		},
		getDefendant: (id) => {
			dispatch(actions.getDefendant(id));
		},
		updateDefendant: (data, photoUpdate, photoUpdateElement) => {
			dispatch(actions.updateDefendant(data, photoUpdate, photoUpdateElement));
		},
		setIndemnitorData2: (data) => {
			dispatch(actions.setIndemnitorData(data));
		},
		sendSmsOnly: (data) => {
			dispatch(actions.sendSmsOnly(data));
		},
		downloadDefendantPhoto: (data) => {
			dispatch(actions.downloadDefendantPhoto(data));
		},
		uploadDefendantPhotos: (data, fieldName) => {
			dispatch(actions.uploadDefendantPhotos(data, fieldName));
		},
		getIndemnitors: (defendant_id) => {
            dispatch(actions.getIndemnitors(defendant_id));
        },
		getAdminUsers: () => {
			dispatch(actions.getAdminUsers());
		},
		getDefendantUsers: (defendant_id) => {
			dispatch(actions.getDefendantUsers(defendant_id));
		}, 
		createDefendantUsers: (data) => {
			dispatch(actions.createDefendantUsers(data));
		},
		deleteDefendantUsers: (id) => {
			dispatch(actions.deleteDefendantUsers(id));
		},
		getSmsConfig: () => {
			dispatch(actions.getSmsConfig());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
