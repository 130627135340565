import React, { useState } from "react";
import GridItem from "../../../components/Grid/GridItem";
import { clone } from "shared/utility";
import TextField from '@material-ui/core/TextField';
import { sendSmsToDefendantStart } from "../../../store/actions";

const DefendantsFormLeadNotes = (props) => {

    console.log(props.data)

	const { updateDataObj, data} = props;
	return (
		<div style={{ width: "100%" }}>
				<GridItem xs={12}>
				<div className="boxed">
					<GridItem xs={12}>
						<TextField
							id="standard-multiline-flexible"
							label="Lead Notes"
							multiline
							fullWidth={true}
							variant="standard"
							value={props.data.lead_notes}
							onChange={(e) => {
								updateDataObj('lead_notes', e.target.value);
							}}
						/>
					</GridItem>
				</div>
			</GridItem>
        </div>	
	);
};

export default DefendantsFormLeadNotes;