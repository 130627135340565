import Dashboard from "views/Dashboard/Dashboard.jsx";
import Buttons from "views/Components/Buttons.jsx";
import GridSystem from "views/Components/GridSystem.jsx";
import Panels from "views/Components/Panels.jsx";
import SweetAlert from "views/Components/SweetAlert.jsx";
import Notifications from "views/Components/Notifications.jsx";
import Icons from "views/Components/Icons.jsx";
import Typography from "views/Components/Typography.jsx";
import RegularForms from "views/Forms/RegularForms.jsx";
import ExtendedForms from "views/Forms/ExtendedForms.jsx";
import ValidationForms from "views/Forms/ValidationForms.jsx";
import Wizard from "views/Forms/Wizard.jsx";
import RegularTables from "views/Tables/RegularTables.jsx";
import ExtendedTables from "views/Tables/ExtendedTables.jsx";
import ReactTables from "views/Tables/ReactTables.jsx";
import GoogleMaps from "views/Maps/GoogleMaps.jsx";
import FullScreenMap from "views/Maps/FullScreenMap.jsx";
import VectorMap from "views/Maps/VectorMap.jsx";
import Charts from "views/Charts/Charts.jsx";
import Calendar from "views/Calendar/Calendar.jsx";
import Widgets from "views/Widgets/Widgets.jsx";
import UserProfile from "views/Pages/UserProfile.jsx";
import TimelinePage from "views/Pages/Timeline.jsx";
import RTLSupport from "views/Pages/RTLSupport.jsx";
import SAPPage from "views/Pages/SAP.jsx";
import PortalPermissions from "views/Pages/PortalPermissions.jsx";
import UserAdminPage from "views/Pages/UserAdminPage.jsx";
import Maintenance from "views/Pages/Maintenance.jsx";
import MaintenancePage from "views/Pages/MaintenancePage.jsx";
import SettingsPage from "views/Pages/SettingsPage.jsx";
import PowerNumbersPage from "../views/Pages/PowerNumbersPage.jsx";
import AgentAreaPage from "../views/Pages/AgentAreaPage.jsx";
import CRMDefendantsPage from "../views/Pages/CRMDefendantsPage.jsx";
import CRMLeadPage from "../views/Pages/CRMLeadPage.jsx";
import PaymentsPage from "../views/Pages/PaymentsPage.jsx";
import PayrollPage from "../views/Pages/PayrollPage.jsx";
import ClientsPage from "../views/Pages/ClientsPage.jsx";
import userIsLoggedIn from "shared/authValidation";
import pagesRoutes from "./pages.jsx";
import CRMHistoryPage from "../views/Pages/CRMHistoryPage.jsx";
import CRMNotificationsPage from "../views/Pages/CRMNotificationsPage.jsx";
import NotificationsAdminPage from "views/Pages/NotificationsAdminPage.jsx";


// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Build from "@material-ui/icons/Build";
import Image from "@material-ui/icons/Image";
import Apps from "@material-ui/icons/Apps";
import ContentPaste from "@material-ui/icons/ContentPaste";
import GridOn from "@material-ui/icons/GridOn";
import Place from "@material-ui/icons/Place";
import WidgetsIcon from "@material-ui/icons/Widgets";
import Timeline from "@material-ui/icons/Timeline";
import DateRange from "@material-ui/icons/DateRange";
import checkPermissions from "shared/checkPermissions";
import { getUserPerms } from "../shared/authValidation.js";

const dashRoutes = () => {
  const userAdminPermitted = checkPermissions('UserAdmin');
  const userPermissionsPermitted = checkPermissions('UserPerms');
  const developerPermitted = checkPermissions('Developer');
  const crmPermitted = checkPermissions('CRM');
  const userPerms = getUserPerms();
  const crmAdminPermitted = userPerms.hasOwnProperty('CRM admin') ? true : false;
  const smsPermitted = userPerms.hasOwnProperty('SMS edit') ? true : false;
  console.log('userPerms?', userPerms, crmAdminPermitted);
  

  return [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      component: Dashboard,
      permitted: true
    },
    {
      path: "/power-numbers",
      name: "Power Numbers",
      icon: Apps,
      component: PowerNumbersPage,
      permitted: crmAdminPermitted
    },
    {
      path: "/agent-area",
      name: "Agent Area",
      icon: Apps,
      component: AgentAreaPage,
      permitted: crmAdminPermitted
    },
    {
      path: "/clients",
      name: "Clients",
      icon: Apps,
      component: ClientsPage,
      permitted: crmAdminPermitted
    },
    {
      collapse: true,
      path: "/crm",
      name: "CRM",
      state: "openForms",
      icon: ContentPaste,
      permitted: (crmPermitted),
      views: [
          {
            path: "/crm/leads",
            name: "Leads",
            mini: "LD",
            component: CRMLeadPage,
            permitted2: crmPermitted
         },
         {
            path: "/crm/defendants",
            name: "Defendants",
            mini: "DF",
            component: CRMDefendantsPage,
            permitted2: crmPermitted
          },
          {
            path: "/crm/notifications",
            name: "Notifications",
            mini: "NO",
            component: CRMNotificationsPage,
            permitted2: crmPermitted
          },
          {
            path: "/crm/histories",
            name: "History",
            mini: "HI",
            component: CRMHistoryPage,
            permitted2: crmAdminPermitted
          },
      ]
    },
    {
      path: "/payments",
      name: "Payments",
      icon: Apps,
      component: PaymentsPage,
      permitted: crmAdminPermitted
    },
    {
      path: "/payroll",
      name: "Payroll",
      icon: Apps,
      component: PayrollPage,
      permitted: crmAdminPermitted
    },
    {
      collapse: true,
      path: "/components",
      name: "Settings",
      state: "openComponents",
      icon: Apps,
      permitted: (developerPermitted),
      views: [
        // {
        //   path: "/components/portal_settings",
        //   name: "Portal Settings",
        //   mini: "PM",
        //   permitted2: (developerPermitted),
        //   component: SettingsPage
        // },
        {
          path: "/components/tool_maintenance",
          name: "Tool Maintenance",
          mini: "MA",
          permitted2: developerPermitted,
          component: Maintenance
        },
        {
          path: "/components/maintenance_page",
          name: "Maintenance Page",
          mini: "MP",
          permitted2: true,
          component: MaintenancePage,
          display: false
        },
        {
          path: "/components/panels",
          name: "Panels",
          mini: "P",
          permitted2: developerPermitted,
          component: Panels
        },
        {
          path: "/components/sweet-alert",
          name: "Sweet Alert",
          mini: "SA",
          permitted2: developerPermitted,
          component: SweetAlert
        },
        {
          path: "/components/notifications",
          name: "Notifications",
          mini: "N",
          permitted2: developerPermitted,
          component: Notifications

        },
        {
          path: "/forms/regular-forms",
          name: "Regular Forms",
          mini: "RF",
          permitted2: developerPermitted,
          component: RegularForms
        },
        {
          path: "/forms/extended-forms",
          name: "Extended Forms",
          mini: "EF",
          permitted2: developerPermitted,
          component: ExtendedForms
        },
        {
          path: "/forms/validation-forms",
          name: "Validation Forms",
          mini: "VF",
          permitted2: developerPermitted,
          component: ValidationForms
        },
        { path: "/forms/wizard", name: "Wizard", mini: "W", permitted2: developerPermitted, component: Wizard },
        {
          path: "/tables/regular-tables",
          name: "Regular Tables",
          mini: "RT",
          permitted2: developerPermitted,
          component: RegularTables
        },
        {
          path: "/tables/extended-tables",
          name: "Extended Tables",
          mini: "ET",
          permitted2: developerPermitted,
          component: ExtendedTables
        },
        {
          path: "/tables/react-tables",
          name: "React Tables",
          mini: "RT",
          permitted2: developerPermitted,
          component: ReactTables
        },
        {
          path: "/maps/google-maps",
          name: "Google Maps",
          mini: "GM",
          permitted2: developerPermitted,
          component: GoogleMaps
        },
        {
          path: "/maps/full-screen-maps",
          name: "Full Screen Map",
          mini: "FSM",
          permitted2: developerPermitted,
          component: FullScreenMap
        },
        {
          path: "/maps/vector-maps",
          name: "Vector Map",
          mini: "VM",
          permitted2: developerPermitted,
          component: VectorMap
        },
        { path: "/widgets", name: "Widgets", icon: WidgetsIcon, permitted2: developerPermitted, component: Widgets },
        { path: "/charts", name: "Charts", icon: Timeline, permitted2: developerPermitted, component: Charts },
        { path: "/calendar", name: "Calendar", icon: DateRange, permitted2: developerPermitted, component: Calendar },
        {
          collapse: true,
          path: "/tables",
          name: "Tables",
          state: "openTables",
          icon: GridOn,
          permitted2: developerPermitted,
          views: [
          ]
        },
        {
          collapse: true,
          path: "/maps",
          name: "Maps",
          state: "openMaps",
          icon: Place,
          permitted2: developerPermitted,
          views: [
          ]
        },
        {
          path: "/rtl/rtl-support-page",
          name: "RTL Support",
          mini: "RS",
          permitted2: developerPermitted,
          component: RTLSupport
        },
        {
          path: "/timeline-page",
          name: "Timeline Page",
          mini: "TP",
          component: TimelinePage
        },
        {
          path: "/SAP",
          name: "SAP",
          mini: "SAP",
          permitted2: developerPermitted,
          component: SAPPage
        },
      ]
    },
    {
      collapse: true,
      path: "/admin",
      name: "Admin",
      state: "openForms2",
      icon: ContentPaste,
      permitted: (userAdminPermitted || userPermissionsPermitted),
      views: [
         {
            path: "/user-permissions",
            name: "User Permissions",
            mini: "UP",
            component: PortalPermissions,
            permitted2: userPermissionsPermitted
          },
         {
           path: "/user-admin",
           name: "User Admin",
           mini: "UR",
           component: UserAdminPage,
           permitted2: userAdminPermitted
         },
         {
          path: "/components/notifications_admin",
          name: "Notifications Admin",
          mini: "NA",
          permitted2: (smsPermitted),
          component: NotificationsAdminPage
        },
        //  {
        //   path: "/components/portal_settings",
        //   name: "Portal Settings",
        //   mini: "PS",
        //   permitted2: (smsPermitted),
        //   component: SettingsPage
        // },
      ]
    },
    { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
  ];
}
export default dashRoutes;
