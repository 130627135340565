import React, { useEffect, useState } from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { Dialog } from "@material-ui/core";
import { clone } from "shared/utility";
import CustomInput from "components/CustomInput/CustomInputValidate";
import { required } from "shared/validation";
import Button from "../../../components/CustomButtons/Button";
import Table from "components/Table/Table";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import { stateList } from "../../../shared/utility";

const DefendantsFormReferences = (props) => {
	const initialData = {
		name: "",
		relationship: "",
		dob_or_age: "",
		phone: "",
		address: "",
		city: "",
		state: "",
		county: "",
		social_media: "",
	};

	const [refData, setRefData] = useState([]);
	const [openForm, setOpenForm] = useState(false);
	const [action, setAction] = useState(false);
	const [formData, setFormData] = useState(initialData);
	const [index, setIndex] = useState(-1);
	const [openAskBeforeDelete, setOpenAskBeforeDelete] = useState(false);
	const { updateDataObj, data, model } = props;
	const fields = ["reference_1", "reference_2", "reference_3", "reference_4"];

	const checkValid = () => {
		let valid = true;
		fields.forEach((f) => {
			if (props.data) {
				if (props.data[`${f}_name`]) {
					if (props.data[`${f}_name`].trim() === "") {
						valid = false;
					}
				}
			}
		});
		return valid;
	};

	const renderElement = (field, label, isRequired, uppercase) => {
		return (
			<CustomInput
				value={formData[field]}
				label={label}
				onChange={(e) => {
					const newData = clone(formData);
					newData[field] = e.target.value;
					setFormData(newData);
				}}
				validate={isRequired ? [required] : []}
				uppercase={uppercase ? true : false}
			/>
		);
	};

	const checkValidity = () => {
		const name = formData.name ? formData.name.trim() : "";
		const relationship = formData.relationship
			? formData.relationship.trim()
			: "";
		if (name && relationship) {
			return true;
		}
		return false;
	};

	const renderFields = () => {
		return fields.map((f, i) => {
			const ref = Object.keys(model).filter((item) => item.includes(f));
			return (
				<GridContainer key={`${i}-ref`}>
					<GridItem xs={12} sm={12} md={12}>
						<h4 className="bold">{f.split("_").join(" ").toUpperCase()}</h4>
					</GridItem>
					{ref.map((r, j) => {
						return (
							<GridItem xs={12} sm={3} md={3} key={`${j}-r`}>
								{props.renderElement(r, model)}
							</GridItem>
						);
					})}
				</GridContainer>
			);
		});
	};

	return (
		<div style={{ width: "100%" }}>
			<GridItem
				className={`${props.action === "History View" && "no-click"}`}
				xs={12}
				md={12}
				sm={12}
			>
				{renderFields()}
			</GridItem>
			{props.action !== "History View" && (
				<GridItem xs={12} md={12} sm={12}>
					<GridContainer>
						{/* <GridItem xs={12} sm={12} md={12}>
						<h3>References</h3>
					</GridItem> */}
						{/* {checkValid() && (
							<GridItem xs={12} sm={12} md={12}>
								<Button
									color="primary"
									onClick={() => {
										setAction("Add");
										setFormData(clone(initialData));
										setIndex(-1);
										setOpenForm(true);
									}}
								>
									<i className="fas fa-plus"></i> ADD REFERENCE
								</Button>
							</GridItem>
						)} */}
					</GridContainer>
				</GridItem>
			)}
			{Array.isArray(refData) && (
				<GridContainer>
					{refData.map((r, i) => {
						return (
							<GridItem xs={12} sm={4} md={4} key={`${i}-reff`}>
								<div className="boxed">
									{props.action !== "History View" && (
										<Button
											className="mr-20"
											color="primary"
											onClick={() => {
												setFormData(clone(r));
												setIndex(i);
												setAction("Edit");
												setOpenForm(true);
											}}
										>
											<i className="fas fa-edit"></i> EDIT
										</Button>
									)}

									{props.action !== "History View" && (
										<Button
											color="danger"
											onClick={() => {
												setIndex(i);
												setOpenAskBeforeDelete(true);
											}}
										>
											<i className="fas fa-trash"></i> DELETE
										</Button>
									)}
									<div style={{ lineHeight: 1.5 }} className="mt-20">
										<Table
											tableData={[
												["Name", "name"],
												["Relationship", "relationship"],
												["DOB or Approximate Age", "dob_or_age"],
												["Phone", "phone"],
												["Street Address", "address"],
												["City", "city"],
												["State", "state"],
												["Country", "country"],
												["Social Media Names or URL's:", "social_media"],
											].map((m) => {
												return [
													<div style={{ textAlign: "right" }}>
														<strong>{m[0]}</strong>
													</div>,
													r[m[1]],
												];
											})}
										/>
									</div>
								</div>
							</GridItem>
						);
					})}
				</GridContainer>
			)}

			{openForm && (
				<Dialog
					open={openForm}
					onClose={() => {
						setOpenForm(false);
					}}
					fullWidth={true}
					maxWidth="md"
				>
					<div className="dialog-wrapper">
						<h3>{action} Reference</h3>
						<GridContainer>
							<GridItem xs={12} sm={6} md={6}>
								{renderElement("name", "Name", true, true)}
							</GridItem>
							<GridItem xs={12} sm={6} md={6}>
								{renderElement("relationship", "Relationship", true)}
							</GridItem>
							<GridItem xs={12} sm={6} md={6}>
								{renderElement("dob_or_age", "DOB or Approximate Age")}
							</GridItem>
							<GridItem xs={12} sm={6} md={6}>
								{renderElement("phone", "Phone")}
							</GridItem>
							<GridItem xs={12} sm={12} md={12}>
								{renderElement("address", "Street Address", false, true)}
							</GridItem>
							<GridItem xs={12} sm={4} md={4}>
								{renderElement("city", "City", false, true)}
							</GridItem>
							<GridItem xs={12} sm={4} md={4}>
								{/* {renderElement("state", "State", false, true)} */}
								<CustomSelect
									label="State"
									choose={(e, n) => {
										const newData = clone(formData);
										newData["state"] = e;
										setFormData(newData);
									}}
									options={stateList}
								/>
							</GridItem>
							<GridItem xs={12} sm={4} md={4}>
								{renderElement("country", "Country", false, true)}
							</GridItem>
							<GridItem xs={12} sm={4} md={4}>
								{("social_media", "Social Media Names or URL's")}
							</GridItem>
						</GridContainer>
						<hr />
						{action === "Add" && (
							<Button
								color="success"
								className="mr-20"
								onClick={() => {
									if (checkValidity()) {
										updateDataObj(
											"rreferences",
											data.rreferences.concat([clone(formData)])
										);
										console.log(data)
										setOpenForm(false);
									}
								}}
							>
								ADD
							</Button>
						)}
						{action === "Edit" && (
							<Button
								color="primary"
								className="mr-20"
								onClick={() => {
									if (checkValidity()) {
										updateDataObj(
											"rreferences",
											data.rreferences.map((dr, j) => {
												if (j === index) {
													return clone(formData);
												}
												return dr;
											})
										);
										setOpenForm(false);
									}
								}}
							>
								UPDATE
							</Button>
						)}
						<Button
							color="white"
							onClick={() => {
								setOpenForm(false);
							}}
						>
							CANCEL
						</Button>
					</div>
				</Dialog>
			)}

			{openAskBeforeDelete && (
				<Dialog
					maxWidth="sm"
					fullWidth={true}
					open={openAskBeforeDelete}
					onClose={() => {
						setOpenAskBeforeDelete(false);
					}}
				>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12}>
							<div className="dialog-wrapper text-center">
								<h4>Are you sure you want to delete this reference?</h4>
								<Button
									color="danger"
									className="mr-20"
									onClick={() => {
										updateDataObj(
											"rreferences",
											data.rreferences.filter((dr, j) => {
												if (j === index) return false;
												return true;
											})
										);
										setOpenAskBeforeDelete(false);
									}}
								>
									OK DELETE
								</Button>
								<Button
									color="white"
									onClick={() => {
										setOpenAskBeforeDelete(false);
									}}
								>
									CANCEL
								</Button>
							</div>
						</GridItem>
					</GridContainer>
				</Dialog>
			)}
		</div>
	);
};

export default DefendantsFormReferences;
