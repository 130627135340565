import moment from "moment";
import jwt_decode from "jwt-decode";
import { cloneDeep, isEmpty } from "lodash";

export const updateObject = (oldObject, updatedProperties) => {
	const newObject = cloneDeep(oldObject);
	if (updatedProperties) {
		const properties = Object.keys(updatedProperties);
		for (let i = 0; i < properties.length; i++) {
			const property = properties[i];
			newObject[property] = updatedProperties[property];
		}
	}
	return newObject;
};

export const dataURItoBlob = (dataURI, mimeStringOverride) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = mimeStringOverride ? mimeStringOverride : dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
}

export const commatize = (x) => {
	if (typeof x !== "string") {
		if (x === 0) {
			return "0";
		}
		return x ? x.toLocaleString() : "";
	}
	//return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

	x = x.replace(/,/g, "");

	x = parseFloat(x).toLocaleString();

	return x;
};

export const numberWithCommas = (x) => {
	if (x) {
		if (!isNaN(x)) {
			var y = Number(parseFloat(x).toFixed(10));
			var parts = y.toString().split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return parts.join(".");
		}
		//return xx; //parseFloat(xx).toFixed(10).replace(/0+$/, "");
	}
	return x;
};

export const convertToFixed = (num) => {
	if (num) {
		if (!isNaN(num)) {
			return Number(num).toFixed(5);
		}
	}
	return num;
};

export const convertToFixed3 = (num) => {
	if (num) {
		if (!isNaN(num)) {
			return Number(num).toFixed(3);
		}
	}
	return num;
};

export const nameSort = (a, b) => {
	if (a.name < b.name) return -1;
	if (a.name > b.name) return 1;
	return 0;
};

export const checkValidity = (value, rules) => {
	let isValid = true;
	if (rules) {
		if (rules.required) {
			isValid = value.trim() !== "" && isValid;
		}
		if (rules.minLength) {
			isValid = value.length >= rules.minLength && isValid;
		}
		if (rules.maxLength) {
			isValid = value.length <= rules.maxLength && isValid;
		}
		if (rules.isEmail) {
			const pattern =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			isValid = pattern.test(value) && isValid;
		}
		if (rules.isNumeric) {
			const pattern = /^\d+$/;
			isValid = pattern.test(value) && isValid;
		}
	}
	return isValid;
};

export const upperCase = (word) => {
	if (word) {
		return word.toUpperCase();
	}
	return word;
};

export const formatTime = (date) => {
	return "";
};

export const formatDate2 = (date) => {
	return moment(date).utc().format("MM/DD/YYYY");
};

export const formatDate3 = (date) => {
	return moment(date).utc().format("MM/DD/YYYY h:mma");
};

export const formatTime2 = (date) => {
	if (!date) return "";
	const d = moment(date).utc().format("hh:mm a");
	let darr = d.split(" ");
	darr[1] = darr[1].toUpperCase();
	const newd = darr.join(" ");
	return newd;
};

export const getTimestamp = (date) => {
	return moment(date).unix();
};

export const convertTime12to24 = (time12h) => {
	const [time, modifier] = time12h.split(" ");

	let [hours, minutes] = time.split(":");

	if (hours === "12") {
		hours = "00";
	}

	if (modifier === "PM") {
		hours = parseInt(hours, 10) + 12;
	}

	return `${hours}:${minutes}`;
};

export const formatDateForBackend = (date, time) => {
	if (!date) return "";
	const date_arr = date.split("/");
	if (!time) return `${date_arr[2]}-${date_arr[0]}-${date_arr[1]} 00:00:00`;
	if (time) {
		const time24 = convertTime12to24(time);
		return `${date_arr[2]}-${date_arr[0]}-${date_arr[1]} ${time24}:00`;
	}
};

export const formatDate = (date, timeStatus) => {
	var d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;

	let finalFormat = [month, day, year].join("/");
	if (timeStatus) {
		const h = d.getHours(),
			m = d.getMinutes(),
			s = d.getSeconds();
		const time = `${h}:${m}:${s}`;
		finalFormat = [month, day, year].join("/") + " " + time;
	}
	return finalFormat;
};

export const getParameterByName = (name, url) => {
	if (!url) url = window.location.href;
	name = name.replace(/[[]/, "[").replace(/[\]]/, "]");
	var regexS = "[\\?&]" + name + "=([^&#]*)";
	var regex = new RegExp(regexS);
	var results = regex.exec(url);
	return results == null ? null : results[1];
};

export const normalizePhone = (value) => {
	var cleaned = ("" + value).replace(/[^A-Za-z0-9]/g, "");
	// var cleaned = ('' + value).replace(/\D/g, '')
	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		var intlCode = match[1] ? "+1" : "";
		return [intlCode, "(", match[2], ")", match[3], "-", match[4]].join("");
	}
	return value;
};

export const denormalizePhone = (value) => {
	if (value) {
		// value = value.match(/\d/g);
		// if (value) {
		//   value = value.join("");
		// } else {
		//   value = '';
		// }
		if (typeof value === "string") {
			return value.replace(/[)( -+-]/g, "");
		}
	}
	return value; // replace all leading non-digits with nothing
};

export const generateCSV = (data, file_name) => {
	// Building the CSV from the Data two-dimensional array
	// Each column is separated by ";" and new line "\n" for next row
	var csvContent = "";
	data.forEach(function (infoArray, index) {
		let dataString = infoArray.join(";");
		csvContent += index < data.length ? dataString + "\n" : dataString;
	});

	// The download function takes a CSV string, the filename and mimeType as parameters
	// Scroll/look down at the bottom of this snippet to see how download is called
	var download = function (content, fileName, mimeType) {
		var a = document.createElement("a");
		mimeType = mimeType || "application/octet-stream";

		if (navigator.msSaveBlob) {
			// IE10
			navigator.msSaveBlob(
				new Blob([content], {
					type: mimeType,
				}),
				fileName
			);
		} else if (URL && "download" in a) {
			//html5 A[download]
			a.href = URL.createObjectURL(
				new Blob([content], {
					type: mimeType,
				})
			);
			a.setAttribute("download", fileName);
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		} else {
			window.location.href =
				"data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
		}
	};

	download(csvContent, file_name);
};

export const qs = (queryString) => {
	var query = {};
	var pairs = (
		queryString[0] === "?" ? queryString.substr(1) : queryString
	).split("&");
	for (var i = 0; i < pairs.length; i++) {
		var pair = pairs[i].split("=");
		query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
	}
	return query;
};

export const checkForDuplicates = (arr) => {
	return arr.reduce(
		(acc, v, i, arr) =>
			arr.indexOf(v) !== i && acc.indexOf(v) === -1 ? acc.concat(v) : acc,
		[]
	);
};

export const convertDateStr = (dateStr) => {
	if (!dateStr) {
		return -1;
	}
	const months = {
		JAN: "01",
		FEB: "02",
		MAR: "03",
		APR: "04",
		MAY: "05",
		JUN: "06",
		JUL: "07",
		AUG: "08",
		SEP: "09",
		OCT: "10",
		NOV: "11",
		DEC: "12",
	};

	const da = dateStr.split(" ");
	const month = months[da[0].toUpperCase()];
	const date = `${da[2]}-${month}-${da[1]}`;

	const d = new Date(date);
	return d.getTime();
};

export const checkDateOrder = (start, end) => {
	const startTime = convertDateStr(start),
		endTime = convertDateStr(end);
	if (startTime > endTime) {
		return false;
	}
	return true;
};

export const isValidDate = (str) => {
	return moment(str, "MMM DD YYYY", true).isValid();
};

export const dateRangeOverlaps = (
	startDateA,
	endDateA,
	startDateB,
	endDateB
) => {
	if (endDateA < startDateB || startDateA > endDateB) {
		return null;
	}

	var obj = {};
	obj.startDate = startDateA <= startDateB ? startDateB : startDateA;
	obj.endDate = endDateA <= endDateB ? endDateA : endDateB;

	return obj;
};

export const cleanAllPhones = (bpo) => {
	const bp = { ...bpo };
	if (bp.gen_tel) {
		bp.gen_tel = denormalizePhone(bp.gen_tel);
	}
	if (bp.gen_fax) {
		bp.gen_fax = denormalizePhone(bp.gen_fax);
	}
	["tel", "alttel", "mobile", "fax"].forEach((field) => {
		if (bp[`accounts_payable_${field}`]) {
			bp[`accounts_payable_${field}`] = denormalizePhone(
				bp[`accounts_payable_${field}`]
			);
		}
		if (bp[`purchasing_${field}`]) {
			bp[`purchasing_${field}`] = denormalizePhone(
				bp[`accounts_payable_${field}`]
			);
		}
	});

	if (bp.additionalContacts) {
		if (bp.additionalContacts.length > 0) {
			bp.additionalContacts.forEach((ac, i, bpp) => {
				["tel", "alttel", "mobile", "fax"].forEach((field) => {
					if (bpp[i][field]) {
						bpp[i][field] = denormalizePhone(bpp[i][field]);
					}
				});
			});
		}
	}

	return bp;
};

export const swapKeyValues = (json) => {
	var ret = {};
	for (var key in json) {
		ret[json[key]] = key;
	}
	return ret;
};

export const arraysEqual = (_arr1, _arr2) => {
	if (
		!Array.isArray(_arr1) ||
		!Array.isArray(_arr2) ||
		_arr1.length !== _arr2.length
	)
		return false;

	var arr1 = _arr1.concat().sort();
	var arr2 = _arr2.concat().sort();

	for (var i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) return false;
	}
	return true;
};

export const dynamicSort = (property) => {
	var sortOrder = 1;
	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}
	return function (a, b) {
		/* next line works with strings and numbers,
		 * and you may want to customize it to your needs
		 */
		var result =
			a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
		return result * sortOrder;
	};
};

export const dateToday = () => {
	const today = moment(new Date()).format("MMM DD YYYY"),
		oneYear = moment(new Date()).add("years", 1).format("MMM DD YYYY");

	return [today.toUpperCase(), oneYear.toUpperCase()];
};

// check if number is between 0.00001 and 100,000
export const checkMinMax = (num, decimals, allowZero) => {
	if (!isNaN(num)) {
		const parsed = parseFloat(num);
		if (parsed === 0) {
			if (!allowZero) {
				return false;
			} else {
				return true;
			}
		}

		if (decimals === 5) {
			if (parsed >= 0.00001 && parsed <= 100000) {
				return true;
			}
		}

		if (decimals === 3) {
			if (parsed >= 0.001 && parsed <= 100000) {
				return true;
			}
		}
	}
	return false;
};

export const getUsername = () => {
	const token = localStorage.getItem("token");
	let username = "";
	if (token) {
		const decoded = jwt_decode(token);
		username = decoded.name;
	}
	return username;
};

export const filterByDate = (arr, field, startD, endD) => {
	let startDate = null,
		endDate = null;
	if (startD) {
		// const startArr = startD.split('/');
		//startDate = new Date(startArr[2], parseInt(startArr[0])-1, startArr[1], 0);
		startDate = moment(startD, "MM-DD-YYYY hh:mm a").valueOf();
	}
	if (endD) {
		// const endArr = endD.split('/');
		//endDate = new Date(endArr[2], parseInt(endArr[0])-1, endArr[1], 24);
		endDate = moment(endD, "MM-DD-YYYY hh:mm a").valueOf();
	}

	if (startDate && endDate) {
		if (startDate > endDate || startDate === endDate) {
			return null;
		}
	}

	return arr.filter((obj) => {
		const d = new Date(obj[field]).getTime();
		if (startDate && !endDate) {
			return d > startDate;
		} else if (!startDate && endDate) {
			return d < endDate;
		} else if (startDate && endDate) {
			return d > startDate && d < endDate;
		} else {
			return true;
		}
	});
};

export const renameToTPP = (str) => {
	return str.replace(/GMP/g, "TPP");
};

export const capitalize = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeAll = (str) => {
	if (!str) return str;
	const arr = str.split(" ");
	let newarr = [];
	arr.forEach((a) => {
		newarr.push(capitalize(a));
	});
	return newarr.join(" ");
};

export const removeWhitespace = (obj) => {
	if (!obj) return obj;
	const newObj = { ...obj };
	Object.keys(obj).forEach((field) => {
		newObj[field] = obj[field]
			? typeof obj[field] === "string"
				? obj[field].trim()
				: obj[field]
			: obj[field];
	});
	return newObj;
};

export const clone = (obj) => {
	if (!obj) return null;
	return cloneDeep(obj);
};

export const checkEmpty = (obj) => {
	if (obj) {
		if (typeof obj === 'string') {
			return true;
		}
		if (Array.isArray(obj)) {
			if (obj.length === 0) {
				return true;
			}
		}
	}
	return isEmpty(obj);
};

export const base64toBlob = (base64Data, contentType = "") => {
	const sliceSize = 1024;
	const byteCharacters = atob(base64Data);
	const bytesLength = byteCharacters.length;
	const slicesCount = Math.ceil(bytesLength / sliceSize);
	const byteArrays = new Array(slicesCount);

	for (let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex += 1) {
		const begin = sliceIndex * sliceSize;
		const end = Math.min(begin + sliceSize, bytesLength);

		const bytes = new Array(end - begin);
		for (let offset = begin, i = 0; offset < end; i += 1, offset += 1) {
			bytes[i] = byteCharacters[offset].charCodeAt(0);
		}

		byteArrays[sliceIndex] = new Uint8Array(bytes);
	}

	return new Blob(byteArrays, { type: contentType });
};

export const toBase64 = (u8) => {
	return btoa(String.fromCharCode.apply(null, u8));
};

export const formatCurrencyUSD = (num) => {
	return new Intl.NumberFormat("en-US", {
		currency: "usd",
		style: "currency",
	}).format(num);
};

export const formatInteger = (num) => {
	return new Intl.NumberFormat("en-US").format();
};

export const isValidEmail = (email) => {
	return /^\S+@\S+\.\S+$/.test(email);
};

export const stateList = [
	"AL",
	"AK",
	"AZ",
	"AR",
	"CA",
	"CZ",
	"CO",
	"CT",
	"DE",
	"DC",
	"FL",
	"GA",
	"GU",
	"HI",
	"ID",
	"IL",
	"IN",
	"IA",
	"KS",
	"KY",
	"LA",
	"ME",
	"MD",
	"MA",
	"MI",
	"MN",
	"MS",
	"MO",
	"MT",
	"NE",
	"NV",
	"NH",
	"NJ",
	"NM",
	"NY",
	"NC",
	"ND",
	"OH",
	"OK",
	"OR",
	"PA",
	"PR",
	"RI",
	"SC",
	"SD",
	"TN",
	"TX",
	"UT",
	"VT",
	"VI",
	"VA",
	"WA",
	"WV",
	"WI",
	"WY",
];
