import { put } from 'redux-saga/effects';
import axios from '../axios/axios-client';
import * as actions from '../actions/client';

export function* sendSmsOnlySaga(action) {
    yield put(actions.sendSmsOnlyStart());
    try {
        const response = yield axios.post('/sms_only', action.data);
        yield put(actions.sendSmsOnlySuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.sendSmsOnlyFail(e.response.data));
            }
          }
        yield put(actions.sendSmsOnlyFail(e));
    }
}