import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import CustomInput from 'components/CustomInput/CustomInputValidate';
import {min1, min6, min4, min10, email, required, no_letters, no_special_chars, no_numbers, max4, max10, max15, max20, max50, max100, max250, max500} from 'shared/validation';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { clone } from 'shared/utility';
import Button from '../../components/CustomButtons/Button';
import { connect } from 'react-redux';
import * as actions from 'store/actions';

const UserAdminDialog = (props) => {
    const [data, setData] = useState({});
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        if (props.data) {
            setData(clone(props.data));
        }
    }, []);

    useEffect(() => {
        if (update) {
            if (props.success === 'Successfully created user') {
                props.alert(props.success)
                props.onClose();
                props.reload();
            } else if (props.success === 'Successfully updated user') {
                props.alert(props.success)
                props.onClose();
                props.reload();
            }
        }
    }, [props.success]);

    const updateData = (field, val) => {
        const newData = clone(data);
        newData[field] = val;
        setData(newData);
    }

    const getErrors = () => {
        let errorsArr = [];
        if (data) {
            if (data.password && data.password2) {
                if (data.password !== data.password2) {
                    errorsArr.push('Passwords must match');
                }
            }
        }
        return <div style={{margin:10,color:'red'}}>
            {errorsArr.join(', ')}
        </div>
    }

    return <Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth="sm"
        fullWidth={true}
    >
        <DialogContent>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h4><strong>{props.action}</strong> {props.data ? 'for ' + props.data.name : ''}</h4>
                </GridItem>
                {(props.action === 'Edit Details' || props.action === 'Add User' ) && <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                        label="Username"
                        value={data.username}
                        onChange={(e) => { 
                            updateData('username', e.target.value);
                        }}
                        validate={[required]}
                    />
                </GridItem>}
                {(props.action === 'Edit Details' || props.action === 'Add User' ) && <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                        label="Name"
                        value={data.name}
                        onChange={(e) => { 
                            updateData('name', e.target.value);
                        }}
                        validate={[required]}
                    />
                </GridItem>}
                {(props.action === 'Edit Details' || props.action === 'Add User' ) && <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                        label="Email"
                        value={data.email}
                        onChange={(e) => { 
                            updateData('email', e.target.value);
                        }}
                        validate={[required,email]}
                    />
                </GridItem>}
                {(props.action === 'Edit Details' || props.action === 'Add User' ) && <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                        label="Phone Number"
                        value={data.phone_number}
                        onChange={(e) => { 
                            updateData('phone_number', e.target.value);
                        }}
                        type='number'
                        validate={[required, max10, min10]}
                    />
                </GridItem>}
            </GridContainer>
            <GridContainer>
                {(props.action === 'Edit Password' || props.action === 'Add User' ) && <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                        label="Password"
                        value={data.password}
                        onChange={(e) => { 
                            updateData('password', e.target.value);
                        }}
                        validate={[required]}
                        type="password"
                    />
                </GridItem>}
                {(props.action === 'Edit Password' || props.action === 'Add User' ) && <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                        label="Re-enter password"
                        value={data.password2}
                        onChange={(e) => { 
                            updateData('password2', e.target.value);
                        }}
                        type='password'
                        validate={[required]}
                    />
                </GridItem>}
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {getErrors()}
                </GridItem>
            </GridContainer>
            <hr />
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => {
                        setUpdate(true);
                        if (props.action === 'Add User') {
                            if (data.username && data.name && data.email && data.phone_number && data.password) {
                                if (data.password === data.password2) {
                                    props.createUser(data);
                                    console.log(data)
                                }
                            }
                        } else if (props.action === 'Edit Details') {
                            console.log(data)
                            if (data.username && data.name && data.email && data.phone_number) {
                                props.updateUser({
                                    id: data.id,
                                    username: data.username,
                                    name: data.name,
                                    email: data.email,
                                    phone_number: data.phone_number
                                });
                            }
                        } else if (props.action === 'Edit Password') {
                            if (data.password && data.password2) {
                                props.updateUser({
                                    id: data.id,
                                    password: data.password,
                                    password2: data.password2
                                });
                            }
                        }
                        
                    }}
                >
                    SUBMIT
                </Button>
                <Button color="white" 
                    onClick={() => {
                        props.onClose();
                    }}
                >
                    CANCEL
                </Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
}

const mapStateToProps = state => {
    return {
        error: state.user.error,
        success: state.user.success,
        loading: state.user.loading
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      createUser: (data) => {
          dispatch(actions.createUser(data));
      },
      updateUser: (data) => {
        dispatch(actions.updateUser(data));
      }
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(UserAdminDialog);