import React, { useState, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { clone } from "shared/utility";
import { connect } from "react-redux";
import Button from "components/CustomButtons/Button";
import Element from "../Element";
import { initialData } from "./DefendantsModel";
import fields from "./DefendantsModel";
import bookingFields from "../Bookings/BookingsModel";
import indemnitorFields from "../Indemnitors/IndemnitorsModel";
import arrestFields from "../Arrests/ArrestsModel";
import Close from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { Tabs, Tab } from "@material-ui/core";
import Person from "@material-ui/icons/Person";
import DateRange from "@material-ui/icons/DateRange";
import { DefendantsFormGeneral } from "./DefendantsFormGeneral";
import DefendantsFormInfo from "./DefendantsFormInfo";
import { DefendantsFormBooking } from "./DefendantsFormBookings";
import { DefendantsFormFamily } from "./DefendantsFormFamily";
import DefendantsFormReferences from "./DefendantsFormReferences";
import DefendantsFormReview from "./DefendantsFormReview";
import DefendantsFormFCSDocs from './DefendantsFormFCSDocs';
import DefendantsFormShare from './DefendantsFormShare';
import { DefendantsFormIndemnitors } from "./DefendantsFormIndemnitors";
import DefendantsFormLeadNotes from "./DefendantFormLeadNotes";
import { Dialog } from "@material-ui/core";
import { DefendantsFormArrests } from "./DefendantsFormArrests";
import { updateCleanup } from "./DefendantsLogic";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import { extractDefendantSends } from "./DefendantsLogic";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import Notification from "../../../components/Notification/Notification";
import { getUserID } from "../../../shared/authValidation";

const Form = (props) => {
	const [data, setData] = useState(initialData);
	const [photos, setPhotos] = useState(new FormData());
	const [errors, setErrors] = useState({});
	const [tracking, setTracking] = useState(false);
	const [openAskBeforeDelete, setOpenAskBeforeDelete] = useState(false);
	const [sendSMS, setSendSMS] = useState("Yes");
	const [notificationType, setNotificationType] = useState(false);
	const [sendTo, setSendTo] = useState(false);
	const [additionalNumber, setAdditionalNumber] = useState(null);
	const [hasSent, setHasSent] = useState(false);

	// Tab tracking
	const [currentTab, setCurrentTab] = useState("General");

	const { defendantData, uploadDefendantPhotos, userPerms, sendOptions, sendOptionsObj, setSendOptions, setSendOptionsObj, setSendInfo, setSendName, setSendType, sendInfo, sendName, sendType, smsNotification, setSmsNotification, smsSuccess, smsError, listIndemnitors, indemnitorData } = props;
	console.log('DefendantsForm', props, data, sendOptions, sendOptionsObj, setSendOptions, setSendOptionsObj);

	// Defendant
	const appendFile = (input, blob_field) => {
		if (blob_field) {
			const newFormData = new FormData();
			const length = 1;
			const name = blob_field;
			const newErrors = clone(errors);
			for (let i = 0; i < length; ++i) {
				newFormData.append(blob_field, input, blob_field + '.png');
			}
			for (let [key, value] of photos.entries()) {
				if (key !== name) {
					newFormData.append(key, value);
				}
			}
			newErrors[name] = null;
			setErrors(newErrors);
			setPhotos(newFormData);
			for (let [key, value] of photos.entries()) {
				console.log('added key', key, value);
			}
		} else {
			const newFormData = new FormData();
			const { length } = input.target.files;
			const { name } = input.target;
			const newErrors = clone(errors);
			if (length > fields[name].maxCount) {
				newErrors[name] = `Maximum ${fields[name].maxCount} photos.`;
				setErrors(newErrors);
				return;
			}
			for (let i = 0; i < length; ++i) {
				newFormData.append(input.target.name, input.target.files[i]);
			}
			for (let [key, value] of photos.entries()) {
				if (key !== input.target.name) {
					newFormData.append(key, value);
				}
			}
			newErrors[name] = null;
			setErrors(newErrors);
			setPhotos(newFormData);
		}

	};

	const contactInfoArr = ["General", "Information", "Family", "References", "Lead Notes", "Submit"];
	const getTabIndex = () => {
		if (contactInfoArr.indexOf(currentTab) !== -1) {
			return 0;
		}
		if (currentTab === "Bookings") {
			return 1;
		}
		if (currentTab === "Indemnitors") {
			return 2;
		}
		if (currentTab === "Arrests") {
			return 3;
		}
		if (currentTab === "Share") {
			return 4;
		}
	};

	const canDelete = () => {
		if (data) {
			if (data.access === 'F' || !data.access) {
				return true;
			}
			else if (userPerms.hasOwnProperty('UserAdmin edit')) {
				return true;
			} else {
				return false;
			}

		} else {
			return true;
		}
	};

	const canEdit = () => {
		if (props.action === "Client Edit") return true;
		if (data) {
			if (data.access === 'E' || !data.access || data.access === 'F') {
				return true;
			}
			else if (userPerms.hasOwnProperty('UserAdmin edit')) {
				return true;
			} else {
				return false;
			}

		} else {
			return true;
		}
	};

	useEffect(() => {
		setSendName('');
		setSendInfo('');
		setSendType('');
		console.log('defendant data', defendantData);
	}, []);

	// Edit the current Defendant
	useEffect(() => {
		console.log('firing... 1');
		if (defendantData && defendantData.length > 0) {
			console.log('firing... 2');
			const newDefendantData = clone(defendantData[0]);

			if (indemnitorData.length > 0) {
				newDefendantData.indemnitors = indemnitorData;
			} else {
				newDefendantData.indemnitors = listIndemnitors;
			}
			



			setData(newDefendantData);


			// update options
			const extracted_data = extractDefendantSends(newDefendantData);
			const sendToOptions = extracted_data.sendToOptions;
			const optionsObj = extracted_data.optionsObj;
			setSendOptions(sendToOptions);
			setSendOptionsObj(optionsObj);
		}
	}, [defendantData, indemnitorData]);

	useEffect(() => {
		if (hasSent && smsSuccess) {
			setSmsNotification(smsSuccess);
			setHasSent(false);
		} else if (smsError) {
			setSmsNotification(smsError);
			setHasSent(false);
		}
	}, [smsSuccess, smsError]);

	const updateDataObj = (field, value) => {
		// Tracking unsaved data
		console.log('got here', field, value)
		props.setUnsavedWarning(true);

		const newData = clone(data);
		newData[field] = value;
		setData(newData);
		if (!tracking) setTracking(true);
	};

	const renderElement = (field, model) => {
		return (
			<Element
				field={field}
				data={data}
				updateDataObj={updateDataObj}
				model={model}
				action={props.action}
			/>
		);
	};

	const allBookings = props.theBookingData ?
		props.theBookingData
		:
		[];
	console.log('allBookings!', allBookings, data.allBookings, sendOptions, props.theBookingData);

	const allBookingOptions = props.theBookingData ?
		props.theBookingData.map(a => a.booking)
		:
		[];

	const getSendOptions = arr => {
		console.log(arr)
		let arrNew = [];
		if (arr) {
			arr.forEach(a => {
				if (a) {
					console.log(a)
					arrNew.push({
						type: 'other_options',
						name: a,
						phone_number: null
					})
				}
			})
		}
		if (props.defendantUsers) {
			const currentUserID = getUserID();
			if (currentUserID) {
				props.defendantUsers.forEach(pdu => {
					console.log('pdu', pdu)
					const usr = pdu.user_data ? pdu.user_data : null;
					if (usr) {
						if (usr.name && usr.phone_number) {
							if (currentUserID !== pdu.user_id) {
								arrNew.push({
									type: 'shared_user',
									name: usr.name,
									phone_number: usr.phone_number
								})
							}
						}
					}
				})
			}
		}
		arrNew.push({
			type: 'other_options',
			name: 'Other',
			phone_number: null
		})
		return arrNew;
	}

	const sendOptionsName = { name: '' };
	if (sendName) {
		sendOptionsName.name = sendName;
	}

	const sendOptionsDisabled = () => {
		console.log('sendOptionsDisabled', notificationType, sendName, sendTo, allBookings);
		return ((notificationType && sendName) && (sendTo && allBookings.length > 0) || (notificationType && additionalNumber) && (allBookings.length > 0))
	}

	return (
		<div>
			{props.showCloseBtn && (
				<div
					className="close-btn hoverable"
					onClick={() => {
						props.close();
					}}
				>
					<Close />
				</div>
			)}

			<GridContainer>
				{props.action !== "History View" && props.action !== "Client Edit" && (
					<GridItem xs={12} sm={12} md={12}>
						<h3 style={{ display: 'flex', alignItems: 'center' }}>
							{
								<div
									style={{
										marginRight: 20,
										marginBottom: 10,
										display: "inline-block",
									}}
									onClick={() => {
										props.close();
									}}
									className="hoverable"
								>
									<Tooltip
										id="tooltip-top-start1"
										title="Back to List"
										placement="top"
									>
										<ArrowBack />
									</Tooltip>
								</div>
							}
							{props.action} Defendant &nbsp;<span><strong>{data.first_name ? data.first_name : ''} {data.last_name ? data.last_name : ''}</strong></span>
							<GridItem xs={12} sm={3} md={3}>
								{props.action !== 'Add' && <Button
									color="primary"
									onClick={() => {
										setHasSent(true);
										props.sendSmsToDefendant({
											id: data.id,
											phone: data.phone,
											ind: false,
											defendant_id: data.id,
											name: data.first_name,
											type: 'booking_info',
										});
									}}
								>
									Send Booking Info
								</Button>}
							</GridItem>
						</h3>
						{props.action === "Edit" && canDelete() && (
							<div
								className={`${props.action === "Edit" ? "red" : "lightgray"
									}-text inline-block mr-20 hoverable`}
								onClick={() => {
									props.setUnsavedWarning(false);
									setOpenAskBeforeDelete(true);
								}}
								style={{ display: "inline-block", float: "right", paddingBottom: '25px' }}
							>
								<Tooltip
									id="tooltip-top-start2"
									title="Delete Defendant"
									placement="top"
									style={{ display: "inline-block" }}
								>
									<i className="fas fa-trash font-22"></i>
								</Tooltip>
								<span className="icon-text">DELETE</span>
							</div>
						)}
						{props.action !== 'Add' && <div className="boxed" style={{ display: 'flex', alignItems: 'center' }}>
							<GridItem xs={12} sm={3} md={3}>
								<CustomSelect
									label="Notification Type"
									options={['ARREST & COURT INFO (FULL)', 'ARREST & COURT INFO (PARTIAL)', 'BAIL PAYMENT SCHEDULE']}
									choose={(e, n) => {
										setNotificationType(true);
										setSendType(e);
									}}
									default={sendType}
								/>
							</GridItem>
							<GridItem xs={12} sm={3} md={3}>
								{props.theBookingData && <div>
									{props.theBookingData.length === 0 && <div style={{ color: 'red' }}>At least 1 booking is required.</div>}
								</div>}
								<CustomSelect
									options={allBookingOptions}
									choose={(e, n) => {
										setSendInfo(e);
									}}
									label="Additional Info"
									disabled={allBookingOptions.length === 0 && notificationType ? true : false}
									default={sendInfo}
								>
								</CustomSelect>
							</GridItem>
							<GridItem xs={12} sm={3} md={3}>
								<CustomSelect
									label="Send To"
									options={getSendOptions(sendOptions ? [''].concat(sendOptions).filter(f => {
										if (f) return true;
										return false;
									}) : [''])}
									choose={(e, n) => {
										setSendTo(true);
										console.log('choosing', e);
										setSendName(e);
									}}
									indx="name"
									desc="name"
									default={sendName}
								/>
								{sendOptions && <div>
									{sendOptions.length === 0 && <div style={{ color: 'red' }}>Phone number required</div>}
								</div>}
							</GridItem>
							<GridItem xs={12} sm={3} md={3}>
								<div>
									<CustomInput
										label="Add Phone Number"
										value={additionalNumber}
										onChange={(e, n) => {
											setAdditionalNumber(e.target.value);
										}}
									/>
								</div>
							</GridItem>
							<GridItem xs={12} sm={3} md={3}>
								<Button
									disabled={sendOptionsDisabled() ? false : true}
									color="primary"
									onClick={() => {
										const name = sendName;
										const type = sendType;
										const addInfo = sendInfo;
										const contactObj = clone(sendOptionsObj);
										const defendant_id = data.id;
										let booking;
										let id;
										let phone;
										let ind;

										for (let i = 0; i < contactObj.length; i++) {
											if (name === sendOptionsObj[i].name) {
												id = sendOptionsObj[i].id;
												phone = sendOptionsObj[i].phone;
												if (sendOptionsObj[i].type === 'indemnitor') {
													ind = true;
												} else {
													ind = false;
												}
												break;
											}
										};

										// users shared to
										if (props.defendantUsers) {
											for (let i = 0; i < props.defendantUsers.length; i++) {
												const pdu = props.defendantUsers[i];
												const usr = pdu.user_data ? pdu.user_data : null;
												if (usr) {
													if (name === usr.name) {
														phone = usr.phone_number
													}
												}
											}
										}

										if (addInfo) {
											if (props.theBookingData) {
												for (let i = 0; i < props.theBookingData.length; i++) {
													const bk = props.theBookingData[i];
													if (bk) {
														if (bk.booking === addInfo) {
															booking = bk;
														}
													}
												}
											}
										}

										let sent = false;
										if (additionalNumber && sendName === 'Other') {
											if (additionalNumber.length === 10) {
												console.log(additionalNumber)
												sent = true;
												props.sendSmsOnly({
													id: type,
													phone: additionalNumber,
													name: '',
													booking: booking
												});
											}
										}

										if (phone && sendName && sendName !== 'Other') {
											if (phone.length === 10) {
												sent = true;
												props.sendSmsOnly({
													id: type,
													phone: phone,
													name: name,
													booking: booking
												});
											}
										}
										if (sent) setHasSent(true);
										setAdditionalNumber('');
										setSendName('');
										setSendType('');
										setSendInfo('');
										setSendTo(false);
										setNotificationType(false);
									}}
									style={{ width: '150px' }}
								>
									Send
								</Button>
							</GridItem>
						</div>}
					</GridItem>
				)}
				{props.action !== "History View" && props.action !== "Client Edit" && (
					<>
						<GridItem
							xs={12}
							sm={12}
							md={12}
							style={{ filter: "grayscale(100%)" }}
						>
							<br />
							<Tabs
								TabIndicatorProps={{
									style: {
										background: "#da3b2f",
										borderBottom: "1px solid #2580cd",
										color: "#2580cd",
									},
								}}
								style={{ marginBottom: 10 }}
								value={getTabIndex()}
							>
								{
									<Tab
										onClick={() => {
											setCurrentTab("General");
										}}
										style={{
											color:
												getTabIndex() === 0 ? "#2580cd" : "rgba(0, 0, 0, 0.87)",
										}}
										tabIndex={0}
										label="Defendants Info"
										icon={<Person />}
									/>
								}
								{
									<Tab
										onClick={() => {
											setCurrentTab("Bookings");
										}}
										style={{
											color:
												getTabIndex() === 1 ? "#2580cd" : "rgba(0, 0, 0, 0.87)",
										}}
										tabIndex={1}
										label="Bookings"
										icon={<DateRange />}
									/>
								}
								{
									<Tab
										onClick={() => {
											setCurrentTab("Indemnitors");
										}}
										style={{
											color:
												getTabIndex() === 2 ? "#2580cd" : "rgba(0, 0, 0, 0.87)",
										}}
										tabIndex={2}
										label="Indemnitors"
										icon={
											<i style={{ fontSize: 25 }} className="fas fa-globe"></i>
										}
									/>
								}
								{
									<Tab
										onClick={() => {
											setCurrentTab("Arrests");
										}}
										style={{
											color:
												getTabIndex() === 3 ? "#2580cd" : "rgba(0, 0, 0, 0.87)",
										}}
										tabIndex={3}
										label="Arrests History"
										icon={
											<i
												style={{ fontSize: 25 }}
												className="fas fa-building"
											></i>
										}
									/>
								}
								{userPerms.hasOwnProperty('UserAdmin edit') &&
									<Tab
										onClick={() => {
											setCurrentTab("Share");
										}}
										style={{
											color:
												getTabIndex() === 4 ? "#2580cd" : "rgba(0, 0, 0, 0.87)",
										}}
										tabIndex={4}
										label="Share Defendant"
										icon={
											<i
												style={{ fontSize: 25 }}
												className="fas fa-handshake"
											></i>
										}
									/>
								}
							</Tabs>
						</GridItem>

						{props.action === "Add" && (
							<div className="bottom-dweller">
								<Button
									color="primary"
									simple
									onClick={() => {
										setCurrentTab("Submit");
									}}
									className="font-18"
								>
									<i className="fas fa-save font-22"></i> CREATE DEFENDANT
								</Button>
							</div>
						)}
						{props.action === "Edit" && getTabIndex() === 0 && canEdit() && (
							<div className="bottom-dweller">
								<Button
									color="primary"
									simple
									onClick={() => {
										props.updateDefendant(updateCleanup(data));
									}}
									className="font-18"
								>
									<i className="fas fa-save font-22"></i> UPDATE DEFENDANT
								</Button>
							</div>
						)}
					</>
				)}

				{props.action !== "Client Edit" && (
					<GridItem xs={12} sm={12} md={12}>
						{/* Tab Nav */}
						<div style={{ marginBottom: 20 }}>
							{contactInfoArr.map(
								(t) => {
									if (props.action === "Add" && t === "Docs") return null;
									if (props.action === "Add" && t === "Submit") return null;
									if (props.action === "View" && t === "Submit") return null;
									if (props.action === "History View" && t === "Submit")
										return null;
									if (currentTab === "Bookings") return null;
									if (currentTab === "Indemnitors") return null;
									if (currentTab === "Arrests") return null;
									if (t === "Submit") {
										return (
											<div className="bottom-dweller" key={`${t}-a`}>
												{canEdit() &&
													<Button
														key={`${t}-btn`}
														color={
															currentTab === t
																? "success"
																: t === "Submit"
																	? "transparent"
																	: "primary"
														}
														style={{ marginRight: 10 }}
														onClick={() => {
															if (props.action === 'Add') {
																setCurrentTab(t);
															} else {
																props.updateDefendant(updateCleanup(data));
															}
														}}
														className="font-18"
													>
														<span className="darkblue-text">
															<i className="fas fa-save mr-10"></i> {props.action !== 'Add' ? 'Save Defendant' : 'Create Defendant'}
														</span>
													</Button>}
											</div>
										);
									}
									return (
										<Button
											key={t}
											color={
												currentTab === t
													? "success"
													: t === "Submit"
														? "transparent"
														: "primary"
											}
											style={{ marginRight: 10 }}
											onClick={() => {
												// props.clearMessages();
												setCurrentTab(t);
											}}
										>
											{t === "Addresses" ? "Addresses & Other Info" : t}
										</Button>
									);
								}
							)}
						</div>
					</GridItem>
				)}

				{currentTab === "General" && props.action !== "Client Edit" && (
					<DefendantsFormGeneral
						action={props.action}
						model={fields}
						updateDataObj={updateDataObj}
						renderElement={renderElement}
						setSendSMS={setSendSMS}
						sendSMS={sendSMS}
						setData={setData}
						sendSmsToDefendant={props.sendSmsToDefendant}
						data={data}
					/>
				)}

				{currentTab === "Information" && (
					<DefendantsFormInfo
						action={props.action}
						model={fields}
						renderElement={renderElement}
						data={data}
						updateDataObj={updateDataObj}
						photos={photos}
						setPhotos={setPhotos}
						appendFileNew={appendFile}
						downloadDefendantPhotos={props.downloadDefendantPhotos}
						errors={errors}
						getDefendant={props.getDefendant}
						uploadDefendantPhotos={uploadDefendantPhotos}
						doUpdate={(photoUpdate, photoUpdateElement) => {
							console.log('doUpdate', photoUpdate, photoUpdateElement);
							props.updateDefendant(updateCleanup(data), photoUpdate, photoUpdateElement);
						}}
						canDelete={canDelete}
						canEdit={canEdit}
					/>
				)}

				{currentTab === "Family" && (
					<DefendantsFormFamily
						action={props.action}
						model={fields}
						renderElement={renderElement}
						data={data}
						updateDataObj={updateDataObj}
					/>
				)}

				{currentTab === "References" && (
					<DefendantsFormReferences
						action={props.action}
						model={fields}
						data={data}
						setData={setData}
						updateDataObj={updateDataObj}
						renderElement={renderElement}
					/>
				)}
				{currentTab === "Lead Notes" && (
					<DefendantsFormLeadNotes
						action={props.action}
						model={fields}
						data={data}
						setData={setData}
						updateDataObj={updateDataObj}
					/>
				)}

				{currentTab === "Bookings" && (
					<DefendantsFormBooking
						model={bookingFields}
						bookingData={props.bookingData}
						setBookingData={props.setBookingData}
						renderElement={renderElement}
						defendant_id={data ? data.id : null}
						defendantData={data}
						createDefendantIndemnitors={props.indemnitorData}
						action={props.action}
						canDelete={canDelete}
						canEdit={canEdit}
					/>
				)}

				{currentTab === "Indemnitors" && (
					<DefendantsFormIndemnitors
						model={indemnitorFields}
						indemnitorData={props.indemnitorData}
						setIndemnitorData={props.setIndemnitorData}
						renderElement={renderElement}
						defendant_id={data ? data.id : null}
						action={props.action}
						canDelete={canDelete}
						canEdit={canEdit}
					/>
				)}

				{currentTab === "Arrests" && (
					<DefendantsFormArrests
						model={arrestFields}
						arrestData={props.arrestData}
						setArrestData={props.setArrestData}
						renderElement={renderElement}
						defendant_id={data ? data.id : null}
						action={props.action}
						canDelete={canDelete}
						canEdit={canEdit}
					/>
				)}

				{currentTab === "Share" && (
					<DefendantsFormShare
						model={arrestFields}
						arrestData={props.arrestData}
						setArrestData={props.setArrestData}
						renderElement={renderElement}
						defendant_id={data ? data.id : null}
						action={props.action}
						getAdminUsers={props.getAdminUsers}
						userData={props.userData}
						getDefendantUsers={props.getDefendantUsers}
						createDefendantUsers={props.createDefendantUsers}
						deleteDefendantUsers={props.deleteDefendantUsers}
						defendantUsers={props.defendantUsers}
						canDelete={canDelete}
						canEdit={canEdit}
					/>
				)}

				{currentTab === "Submit" && (
					<DefendantsFormReview
						errors={errors}
						model={fields}
						createDefendant={props.createDefendant}
						updateDefendant={props.updateDefendant}
						bookingData={props.bookingData}
						indemnitorData={props.indemnitorData}
						arrestData={props.arrestData}
						close={props.close}
						action={props.action}
						data={data}
						submit={props.submit}
						sendSMS={sendSMS}
						photos={photos}
						setSendSMS={setSendSMS}
						sendSmsToDefendant={props.sendSmsToDefendant}
						error={props.error}
						setCurrentTab={setCurrentTab}
						successMessage={props.success}
						closeFinal={props.closeFinal}
						setUnsavedWarning={props.setUnsavedWarning}
					/>
				)}

				{currentTab === "Docs" && (
					<DefendantsFormFCSDocs
						data={data}
					/>
				)}

				{props.action === "Client Edit" && (
					<GridItem xs={12} sm={12} md={12}>
						{props.clientTab === "info" && (
							<DefendantsFormGeneral
								action={props.action}
								model={fields}
								renderElement={renderElement}
								canEdit={canEdit}
								canDelete={canDelete}
							/>
						)}
						{props.clientTab === "info" && (
							<div>
								<hr />
								<DefendantsFormInfo
									action={props.action}
									model={fields}
									renderElement={renderElement}
									data={data}
									updateDataObj={updateDataObj}
									setPhotos={setPhotos}
									photos={photos}
									appendFileNew={appendFile}
									errors={errors}
									getDefendant={props.getDefendant}
									uploadDefendantPhotos={uploadDefendantPhotos}
									doUpdate={(photoUpdate, photoUpdateElement) => {
										console.log('doing update', photoUpdate, photoUpdateElement);
										props.updateDefendant(updateCleanup(data), photoUpdate, photoUpdateElement);
									}}
									canEdit={canEdit}
									canDelete={canDelete}
								/>
							</div>
						)}
						{props.clientTab === "info" && (
							<div>
								<hr />
								<DefendantsFormFamily
									action={props.action}
									model={fields}
									updateDataObj={updateDataObj}
									renderElement={renderElement}
									data={data}
									canEdit={canEdit}
									canDelete={canDelete}
								/>
							</div>
						)}
						{props.clientTab === "info" && (
							<div style={{ paddingBottom: 91 }}>
								<hr />
								<DefendantsFormReferences
									action={props.action}
									model={fields}
									data={data}
									setData={setData}
									updateDataObj={updateDataObj}
									renderElement={renderElement}
									canEdit={canEdit}
									canDelete={canDelete}
								/>
							</div>
						)}
						{props.clientTab === "bookings" && (
							<DefendantsFormBooking
								model={bookingFields}
								bookingData={props.bookingData}
								setBookingData={props.setBookingData}
								renderElement={renderElement}
								defendant_id={data ? data.id : null}
								action={props.action}
								canEdit={canEdit}
								canDelete={canDelete}
							/>
						)}
						{props.clientTab === "indemnitor" && (
							<DefendantsFormIndemnitors
								model={indemnitorFields}
								indemnitorData={props.indemnitorDa}
								setIndemnitorData={props.setIndemnitorData}
								renderElement={renderElement}
								defendant_id={data ? data.id : null}
								action={props.action}
								is_client={true}
								canEdit={canEdit}
								canDelete={canDelete}
							/>
						)}
						{props.clientTab === "arrests" && (
							<DefendantsFormArrests
								model={arrestFields}
								arrestData={props.arrestData}
								setArrestData={props.setArrestData}
								renderElement={renderElement}
								defendant_id={data ? data.id : null}
								action={props.action}
								data={data}
								submit={props.submit ? props.submit : () => { }}
								error={props.error}
								canEdit={canEdit}
								canDelete={canDelete}
							/>
						)}
						{props.clientTab !== "info" && <div style={{ padding: 20 }}></div>}
						{props.clientTab === "info" && (
							<div className="bottom-dweller">
								<DefendantsFormReview
									getIndemnitors={props.getIndemnitors}
									errors={errors}
									model={fields}
									createDefendant={props.createDefendant}
									setCurrentTab={setCurrentTab}
									updateDefendant={props.updateDefendant}
									bookingData={props.bookingData}
									indemnitorData={props.indemnitorData}
									arrestData={props.arrestData}
									close={props.close}
									action={props.action}
									data={data}
									submit={props.submit ? props.submit : () => { }}
									photos={photos}
									indemnitorDa={props.indemnitorDa}
									allIndemnitors={props.allIndemnitors}
									setRequiredFieldsComplete={props.setRequiredFieldsComplete}
									requiredFieldsComplete={props.requiredFieldsComplete}
									canEdit={canEdit}
									canDelete={canDelete}
								/>
							</div>
						)}
					</GridItem>
				)}
			</GridContainer>

			{openAskBeforeDelete && (
				<Dialog
					maxWidth="sm"
					fullWidth={true}
					open={openAskBeforeDelete}
					onClose={() => {
						setOpenAskBeforeDelete(false);
					}}
				>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12}>
							<div className="dialog-wrapper text-center">
								<h4>Are you sure you want to delete this Defendant?</h4>
								<Button
									color="danger"
									className="mr-20"
									onClick={() => {
										props.deleteDefendant(data.id);
										props.close();
										setOpenAskBeforeDelete(false);
									}}
								>
									OK DELETE
								</Button>
								<Button
									color="white"
									onClick={() => {
										setOpenAskBeforeDelete(false);
									}}
								>
									CANCEL
								</Button>
							</div>
						</GridItem>
					</GridContainer>
				</Dialog>
			)}

			{smsNotification && <Notification
				open={smsNotification ? true : false}
				close={() => {
					setSmsNotification(false);
					setAdditionalNumber('');
				}}
				message={<h4>{`${smsNotification}`}</h4>}
			/>}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		error: state.crm.error,
		success: state.crm.success,
		defendantsData: state.crm.defendantsData,
		defendant: state.crm.defendant,
		allIndemnitors: state.crm.indemnitorsData,
		userData: state.user.userList,
		defendantUsers: state.crm.defendantUsers,
		smsError: state.client.error,
		smsSuccess: state.client.success
	};
};


export default connect(mapStateToProps, null)(Form);
