import GridItem from "components/Grid/GridItem";
import React, { useState } from "react";
import Datetime from "react-datetime";
import GridContainer from "../../../components/Grid/GridContainer";
import Element from "containers/CRM/Element";
import CustomInput from "components/CustomInput/CustomInputValidate";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Button from "components/CustomButtons/Button";
import { clone } from "shared/utility";
import { Dialog } from "@material-ui/core";
import Notification from "components/Notification/Notification";
import TextField from '@material-ui/core/TextField';
import SharesModal from "containers/CRM/Leads/SharesModal";


const LeadsForm = (props) => {
    console.log(props.data)
    const [openAskBeforeDelete, setOpenAskBeforeDelete] = useState(false);
    const [openAskBeforeConvert, setOpenAskBeforeConvert] = useState(false);
    const [notification, setNotification] = useState("");
    const [openShares, setOpenShares] = useState(false);

    console.log(props.bookingData)
    const renderElement = (field, model) => {
        return (
            <Element
                field={field}
                data={props.data}
                updateDataObj={updateDataObj}
                model={model}
            />
        );
    };

    const renderInput = (field, label, max, number) => {
        return (
            <CustomInput
                value={props.bookingData[field]}
                label={label}
                onChange={(e) => {
                    const newData = clone(props.bookingData);
                    const newFormData = clone(props.data);
                    newData[field] = e.target.value;
                    props.setBookingData(newData);
                    newFormData.lead_data = props.bookingData;
                    props.setData(newFormData);
                }}
                inputProps={{
                    maxLength: max,
                    type: number ? 'number' : 'text'
                }}
                type={number ? 'number' : 'text'}
            />
        );
    };

    const updateDataObj = (field, value) => {
        // Tracking unsaved data
        console.log(props.data)
        props.setUnsavedWarning(true);
        const newData = clone(props.data);
        newData[field] = value;
        props.setData(newData);
    };

    const checkValidity = () => {
        const code = props.data.first_name ? props.data.first_name.trim() : "";
        const type = props.data.last_name ? props.data.last_name.trim() : "";
        if (code && type) {
            return true;
        }
        return false;
    };

    const fields = {
        defendant_info: [
            "first_name",
            "middle_name",
            "last_name",
            "address",
            "lived_at_this_address_for",
            "email",
            "phone",
            "date_of_birth",
            "place_of_birth",
            "ss",
            "employed_by",
            "employers_address",
            "proof_of_income",
        ],
        reference_1: [
            "reference_1_relationship",
            "reference_1_phone",
            "reference_1_address",
            "reference_1_city"
        ],
        reference_2: [
            "reference_2_relationship",
            "reference_2_phone",
            "reference_2_address",
            "reference_2_city"
        ],
        reference_3: [
            "reference_3_relationship",
            "reference_3_phone",
            "reference_3_address",
            "reference_3_city"
        ],
        reference_4: [
            "reference_4_relationship",
            "reference_4_phone",
            "reference_4_address",
            "reference_4_city"
        ],

    };

    const renderFields = () => {

        return Object.keys(fields).map((key, index) => {
            return (
                <GridContainer
                    className={`${props.action === "History View" && "no-click"}`}
                    key={index}
                >
                    <GridItem xs={12} sm={12} md={12}>
                        <h4 className="bold">{key.split("_").join(" ").toUpperCase()}</h4>
                    </GridItem>
                    {fields[key].map((element, i) => {
                        if (element === null) {
                            return <GridItem key={i} xs={12} sm={3} md={3}></GridItem>
                        }
                        if (key === "date_of_birth") {
                            return (
                                <GridItem key={i} xs={12} sm={3} md={3}>
                                    <Datetime
                                        inputProps={{
                                            placeholder: "Last Attempted Contact Date",
                                        }}
                                        closeOnSelect={true}
                                        onChange={(e) => {
                                            if (props.action === "View") return;
                                            if (typeof e === "object") {
                                                const stringDate = e.format("MM/DD/YYYY");
                                                return;
                                            }
                                        }}
                                        timeFormat={false}
                                    />
                                </GridItem>
                            );
                        }

                        return (
                            <GridItem key={i} xs={12} sm={3} md={3}>
                                {renderElement(element, props.model)}
                            </GridItem>
                        );
                    })}
                    <GridItem xs={12} sm={12} md={12}>
                        <hr />
                    </GridItem>
                </GridContainer>
            );
        });
    };

    return <>

        {props.action !== "History View" && (
            <GridItem xs={12} sm={12} md={12}>
                <h3>
                    {
                        <div
                            style={{
                                marginRight: 20,
                                marginBottom: 10,
                                display: "inline-block",
                            }}
                            onClick={() => {
                                props.close();
                            }}
                            className="hoverable"
                        >
                            <Tooltip
                                id="tooltip-top-start1"
                                title="Back to List"
                                placement="top"
                            >
                                <ArrowBack />
                            </Tooltip>
                        </div>
                    }
                    Lead
                </h3>


                {props.action === "Add" && (
                    <div className="bottom-dweller">
                        <Button
                            // className={`darkblue-text inline-block mr-20 hoverable`}
                            simple
                            color="primary"
                            onClick={() => {
                                console.log('in add')
                                if (!checkValidity())
                                    return setNotification(
                                        "Some fields are required. Put N/A to leave blank."
                                    );
                                const newFormData = clone(props.data);
                                newFormData.is_lead = 1;
                                if (newFormData.lead_data === null) {
                                    newFormData.lead_data = props.initialBookingData;
                                }
                                props.add(newFormData);
                                props.closeFinal();
                            }}
                            style={{ display: "inline-block" }}
                        >
                            <Tooltip
                                title="Add"
                                placement="top"
                                style={{ display: "inline-block" }}
                            >
                                <i className="fas fa-save font-22"></i>
                            </Tooltip>
                            SAVE LEAD
                        </Button>
                    </div>
                )}

                {props.action === "Edit" && (
                    <div className="bottom-dweller">
                        <Button
                            // className={`darkblue-text inline-block mr-20 hoverable`}
                            simple
                            color="primary"
                            onClick={() => {
                                console.log('in edit')
                                if (!checkValidity())
                                    return setNotification(
                                        "Some fields are required. Put N/A to leave blank."
                                    );
                                const newFormData = clone(props.data);
                                newFormData.defendant_id = props.defendant_id;
                                if (newFormData.lead_data === null) {
                                    newFormData.lead_data = props.initialBookingData;
                                }
                                props.update(newFormData, props.index);
                                props.closeFinal();
                            }}
                            style={{ display: "inline-block" }}
                        >
                            <Tooltip
                                title="Update"
                                placement="top"
                                style={{ display: "inline-block" }}
                            >
                                <i className="fas fa-save font-22"></i>
                            </Tooltip>
                            SAVE LEAD
                        </Button>
                    </div>
                )}

                {props.action === "Edit" && (
                    <div
                        className={`${props.action === "Edit" ? "red" : "lightgray"
                            }-text inline-block mr-20 hoverable`}
                        onClick={() => {
                            setOpenAskBeforeDelete(true);
                        }}
                        style={{ display: "inline-block", float: "right" }}
                    >
                        <Tooltip
                            title="Delete"
                            placement="top"
                            style={{ display: "inline-block" }}
                        >
                            <i className="fas fa-trash font-22"></i>
                        </Tooltip>
                        <span className="icon-text">DELETE</span>
                    </div>

                )}

                {props.action === "Edit" && (
                    <div>
                        <Button
                            onClick={() => {
                                setOpenAskBeforeConvert(true);
                            }}
                            color="primary">
                            Convert to Defendant
                        </Button>
                    </div>

                )}

            </GridItem>
        )}


        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <h4 className="bold">BOOKING</h4>
            </GridItem>
            <GridItem xs={3}>
                {renderInput('booking', 'Booking Number')}
            </GridItem>
            <GridItem xs={3}>
                {renderInput('booking_name', 'Booking Name')}
            </GridItem>
            <GridItem xs={3}>
                {renderInput('bail_amount', 'Bail Amount')}
            </GridItem>
            <GridItem xs={3}>
                {renderInput('jail_court', 'Jail/Court')}
            </GridItem>
            <GridItem xs={3}>
                {renderInput('arrest_county', 'Arrest County')}
            </GridItem>
            <GridItem xs={3}>
                {renderInput('arrest_case', 'Arrest Case')}
            </GridItem>
            <GridItem xs={3}>
                {renderInput('court_case', 'Court Case')}
            </GridItem>
            
            <GridItem xs={3}>
            <div className={'mt-25'}>
                <Datetime
                    inputProps={{
                        placeholder: "Arrest Date"
                    }}
                    closeOnSelect={true}
                    defaultValue={props.bookingData.arrest_date}
                    onChange={(e) => {
                        const newData = clone(props.bookingData);
                        const newFormData = clone(props.data);
                        if (typeof e === 'object') {
                            const stringDate = e.format('MM/DD/YYYY');
                            // use stringDate to set value
                            newData.arrest_date = stringDate;
                            props.setBookingData(newData);
                            newFormData.lead_data = props.bookingData;
                            props.setData(newFormData);
                            return;

                        }
                        // use e to set value if it is a string
            
                        newFormData.lead_data = props.bookingData;
                        props.setData(newFormData);
                        newData.arrest_date = e;
                        props.setData(newFormData);
                    }}
                    timeFormat={false}
                />
                </div>
            </GridItem>
            
        </GridContainer>
        {renderFields()}
        <div className="boxed">
            <GridContainer>
                <GridItem xs={12}>
                    <TextField
                        id="standard-multiline-flexible"
                        label="Lead Notes"
                        multiline
                        fullWidth={true}
                        variant="standard"
                        value={props.data.lead_notes}
                        onChange={(e) => {
                            const newData = clone(props.data);
                            newData.lead_notes = e.target.value;
                            props.setData(newData);
                        }}
                    />
                </GridItem>
            </GridContainer>
        </div>
        {openAskBeforeDelete && (
            <Dialog
                maxWidth="sm"
                fullWidth={true}
                open={openAskBeforeDelete}
                onClose={() => {
                    setOpenAskBeforeDelete(false);
                }}
            >
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className="dialog-wrapper text-center">
                            <h4>Are you sure you want to delete this Lead?</h4>
                            <Button
                                color="danger"
                                className="mr-20"
                                onClick={() => {
                                    props.delete(props.defendant_id);
                                    props.closeFinal();
                                    setOpenAskBeforeDelete(false);
                                }}
                            >
                                OK DELETE
                            </Button>
                            <Button
                                color="white"
                                onClick={() => {
                                    setOpenAskBeforeDelete(false);
                                }}
                            >
                                CANCEL
                            </Button>
                        </div>
                    </GridItem>
                </GridContainer>
            </Dialog>
        )}
        {openAskBeforeConvert && (
            <Dialog
                maxWidth="sm"
                fullWidth={true}
                open={openAskBeforeConvert}
                onClose={() => {
                    setOpenAskBeforeConvert(false);
                }}
            >
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className="dialog-wrapper text-center">
                            <h4>Are you sure you want to convert this Lead?</h4>
                            <Button
                                color="primary"
                                className="mr-20"
                                onClick={() => {
                                    const newData = clone(props.data);
                                    let newBookingData = clone(props.data.lead_data);
                                    newData.is_lead = 0;
                                    newData.lead_data = props.initialData;
                                    if (newBookingData === null) {
                                        newBookingData = '';
                                    } else {
                                        newBookingData.defendant_id = props.defendant_id;
                                        props.createBooking(newBookingData);
                                        console.log(newBookingData)
                                    }
                                    props.setData(newData)
                                    props.update(newData, props.index);
                                    props.setNoMessage(true);
                                    setOpenAskBeforeConvert(false);
                                    setOpenShares(true);
                                }}
                            >
                                YES CONVERT
                            </Button>
                            <Button
                                color="white"
                                onClick={() => {
                                    setOpenAskBeforeConvert(false);
                                }}
                            >
                                CANCEL
                            </Button>
                        </div>
                    </GridItem>
                </GridContainer>
            </Dialog>
        )}

        {openShares && (
        <SharesModal
        close={props.closeFinal}
        setData={props.setData}
        data={props.data}
        userData={props.userData}
        getDefendantUsers={props.getDefendantUsers}
        createDefendantUsers={props.createDefendantUsers}
        defendantUsers={props.defendantUsers}
        defendant_id={props.data.id}
        openShares={openShares}
        setOpenShares={setOpenShares}
        getAdminUsers={props.getAdminUsers}
        deleteDefendantUsers={props.deleteDefendantUsers}
        noMessage={props.noMessage}
        setNoMessage={props.setNoMessage}
        >
        </SharesModal>
       )}
        {notification && <Notification
            open={true}
            close={() => {
                setNotification('');
            }}
            message={notification}
        />}
    </>;
};

export default LeadsForm;