import { takeLatest } from "redux-saga/effects";

import * as actionTypes from "../actions/actionTypes";

import { loginUserSaga } from "./auth";

import {
	uploadDefendantPhotosSaga,
	getDefendantsSaga,
	getLeadsSaga,
	createDefendantSaga,
	updateDefendantSaga,
	getDefendantSaga,
	deleteDefendantSaga,
	sendSmsToDefendantSaga,
	resendSmsToDefendantSaga,
	downloadDefendantPhotoSaga,
	getDefendantUsersSaga,
	createDefendantUsersSaga,
	deleteDefendantUsersSaga
} from "./crm";

import {
	createUserSaga,
	getAdminUsersSaga,
	deleteUserSaga,
	updateUserSaga,
} from "./user";

import {
	getUsersSaga,
	getUsersFilteredSaga,
	getUserSaga,
	saveUserPermsSaga,
	bulkAssignSaga,
	getUserByNameSaga,
	maintenanceModeSaga,
	checkMaintenanceModeSaga,
	notifyMailSaga,
} from "./userperms";

import { 
	getSmsConfigSaga,
	updateSmsConfigSaga,
	deleteSmsConfigSaga,
	createSmsConfigSaga,
} from "./settings";

import {
	createIndemnitorSaga,
	deleteIndemnitorSaga,
	downloadIndemnitorPhotoSaga,
	getIndemnitorSaga,
	getIndemnitorsSaga,
	updateIndemnitorSaga,
	uploadIndemnitorPhotosSaga,
} from "./crm_indemnitor";

import {
	getBookingsSaga,
	createBookingSaga,
	updateBookingSaga,
	deleteBookingSaga,
} from "./crm_booking";
import { getHistoriesSaga, getHistorySaga } from "./crm_history";
import {
	createArrestSaga,
	deleteArrestSaga,
	getArrestsSaga,
	updateArrestSaga,
} from "./crm_arrest";
import { loginClientSaga } from "./client_auth";
import {
	sendSmsOnlySaga,
} from "./client";

import {
	sendFcsSaga,
	getDocumentPdfSaga,
	sendSinglePdfSaga,
	sendCcSaga,
	sendReminderSaga,
	downloadDocumentSaga
} from './documents';

export function* watchAD() {
	yield takeLatest(actionTypes.AUTH_REQUEST, loginUserSaga);

	// USER ADMIN
	yield takeLatest(actionTypes.CREATE_USER, createUserSaga);
	yield takeLatest(actionTypes.GET_ADMIN_USERS, getAdminUsersSaga);
	yield takeLatest(actionTypes.DELETE_USER, deleteUserSaga);
	yield takeLatest(actionTypes.UPDATE_USER, updateUserSaga);
	yield takeLatest(actionTypes.GET_DEFENDANT_USERS, getDefendantUsersSaga);
	yield takeLatest(actionTypes.CREATE_DEFENDANT_USERS, createDefendantUsersSaga);
	yield takeLatest(actionTypes.DELETE_DEFENDANT_USERS, deleteDefendantUsersSaga);

	// USER PERMS
	yield takeLatest(actionTypes.GET_USERS, getUsersSaga);
	yield takeLatest(actionTypes.GET_USERS_FILTERED, getUsersFilteredSaga);
	yield takeLatest(actionTypes.GET_USER, getUserSaga);
	yield takeLatest(actionTypes.GET_USER_BY_NAME, getUserByNameSaga);
	yield takeLatest(actionTypes.SAVE_USER_PERMS, saveUserPermsSaga);
	yield takeLatest(actionTypes.BULK_ASSIGN, bulkAssignSaga);
	yield takeLatest(actionTypes.MAINTENANCE_MODE, maintenanceModeSaga);
	yield takeLatest(
		actionTypes.CHECK_MAINTENANCE_MODE,
		checkMaintenanceModeSaga
	);
	yield takeLatest(actionTypes.NOTIFY_MAIL, notifyMailSaga);

	// SETTINGS
	yield takeLatest(actionTypes.GET_SMS_CONFIG, getSmsConfigSaga);
	yield takeLatest(actionTypes.UPDATE_SMS_CONFIG, updateSmsConfigSaga);
	yield takeLatest(actionTypes.DELETE_SMS_CONFIG, deleteSmsConfigSaga);
	yield takeLatest(actionTypes.CREATE_SMS_CONFIG, createSmsConfigSaga);

	// CRM
	yield takeLatest(actionTypes.GET_DEFENDANTS, getDefendantsSaga);
	yield takeLatest(actionTypes.GET_LEADS, getLeadsSaga);
	yield takeLatest(actionTypes.CREATE_DEFENDANT, createDefendantSaga);
	yield takeLatest(actionTypes.UPDATE_DEFENDANT, updateDefendantSaga);
	yield takeLatest(actionTypes.GET_DEFENDANT, getDefendantSaga);
	yield takeLatest(actionTypes.DELETE_DEFENDANT, deleteDefendantSaga);
	yield takeLatest(
		actionTypes.UPLOAD_DEFENDANT_PHOTOS,
		uploadDefendantPhotosSaga
	);
	yield takeLatest(
		actionTypes.DOWNLOAD_DEFENDANT_PHOTO,
		downloadDefendantPhotoSaga
	);

	// BOOKINGS
	yield takeLatest(actionTypes.GET_BOOKINGS, getBookingsSaga);
	yield takeLatest(actionTypes.CREATE_BOOKING, createBookingSaga);
	yield takeLatest(actionTypes.UPDATE_BOOKING, updateBookingSaga);
	yield takeLatest(actionTypes.DELETE_BOOKING, deleteBookingSaga);

	// INDEMNITORS
	yield takeLatest(actionTypes.GET_INDEMNITORS, getIndemnitorsSaga);
	yield takeLatest(actionTypes.GET_INDEMNITOR, getIndemnitorSaga);
	yield takeLatest(actionTypes.CREATE_INDEMNITOR, createIndemnitorSaga);
	yield takeLatest(actionTypes.UPDATE_INDEMNITOR, updateIndemnitorSaga);
	yield takeLatest(actionTypes.DELETE_INDEMNITOR, deleteIndemnitorSaga);
	yield takeLatest(actionTypes.UPLOAD_INDEMNITOR_PHOTOS, uploadIndemnitorPhotosSaga);
	yield takeLatest(actionTypes.DOWNLOAD_INDEMNITOR_PHOTO, downloadIndemnitorPhotoSaga);

	// ARRESTS
	yield takeLatest(actionTypes.GET_ARRESTS, getArrestsSaga);
	yield takeLatest(actionTypes.CREATE_ARREST, createArrestSaga);
	yield takeLatest(actionTypes.UPDATE_ARREST, updateArrestSaga);
	yield takeLatest(actionTypes.DELETE_ARREST, deleteArrestSaga);

	// HISTORIES
	yield takeLatest(actionTypes.GET_HISTORIES, getHistoriesSaga);
	yield takeLatest(actionTypes.GET_HISTORY, getHistorySaga);

	// CLIENTS
	yield takeLatest(actionTypes.CLIENT_AUTH_REQUEST, loginClientSaga);
	yield takeLatest(actionTypes.SEND_SMS_TO_DEFENDANT, sendSmsToDefendantSaga);
	yield takeLatest(
		actionTypes.RESEND_SMS_TO_DEFENDANT,
		resendSmsToDefendantSaga
	);
	yield takeLatest(actionTypes.SEND_SMS_ONLY, sendSmsOnlySaga);

	// DOCUMENTS 
	yield takeLatest(actionTypes.SEND_FCS, sendFcsSaga);
	yield takeLatest(actionTypes.GET_DOCUMENT_PDF, getDocumentPdfSaga);
	yield takeLatest(actionTypes.SEND_SINGLE_PDF, sendSinglePdfSaga);
	yield takeLatest(actionTypes.SEND_CC, sendCcSaga);
	yield takeLatest(actionTypes.SEND_REMINDER, sendReminderSaga);
	yield takeLatest(actionTypes.DOWNLOAD_DOCUMENT, downloadDocumentSaga);
}
