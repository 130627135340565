import React from "react";
import { useState, useEffect } from "react";
import Table from "components/Table/Table.jsx";
import SortButton from "components/SortButton/SortButton";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Cached from "@material-ui/icons/Cached";
import moment from "moment";
import { Dialog } from "@material-ui/core";

const List = (props) => {
	const { rows, offset } = props;
	const page_num = Math.ceil((offset + 1) / rows);
	const page_count = Math.ceil(props.count / rows);
	const pages_links = isNaN(page_count)
		? null
		: Array(page_count)
				.fill()
				.map((element, index) => index + 1)
				.map((num) => {
					return (
						<span
							onClick={() => {
								props.setOffset((num - 1) * rows);
							}}
							className={
								num === page_num ? "tiny-box current" : "hoverable tiny-box"
							}
							key={num}
							style={{
								marginRight: -1,
							}}
						>
							{num}
						</span>
					);
				});
	const [dataArr, setDataArr] = useState([]);
	const showBack = page_num === 1 ? false : true;
	const showForward = page_num === page_count ? false : true;

	const openHistoryModal = (id) => {
		props.getHistory(id);
		props.setOpenedModal(true);
	};

	const renderNav = () => {
		return (
			<div className="text-center">
				{props.data.length > 0 && (
					<p>
						Showing {offset + 1} to {offset + props.data.length} of{" "}
						{props.count} defendant(s)
					</p>
				)}
				{props.data.length > 0 && (
					<div style={{ marginTop: 20 }}>
						<span
							onClick={() => {
								if (showBack) {
									props.setOffset((page_num - 1 - 1) * rows);
								}
							}}
							style={{ opacity: showBack ? 1 : 0.2, marginRight: -1 }}
							className={showBack ? "hoverable tiny-box" : "tiny-box"}
						>
							Previous
						</span>
						{pages_links}
						<span
							onClick={() => {
								if (showForward) {
									props.setOffset((page_num + 1 - 1) * rows);
								}
							}}
							style={{ opacity: showForward ? 1 : 0.2, marginRight: -1 }}
							className={showForward ? "hoverable tiny-box" : "tiny-box"}
						>
							Next
						</span>
					</div>
				)}
			</div>
		);
	};

	useEffect(() => {
		if (props.data) {
			
			setDataArr(
				props.data.map((cd, i) => {
					return [
						offset + (i + 1),
						<div
							className="hoverable"
							onClick={() => {
								openHistoryModal(cd.id);
							}}
						>
							{moment(cd.created_date).format("MM/DD/YYYY h:mm:ss a")}
						</div>,
						<div
							className="hoverable"
							onClick={() => {
								openHistoryModal(cd.id);
							}}
						>
							{cd.username}
						</div>,
						<div
							className="hoverable"
							onClick={() => {
								openHistoryModal(cd.id);
							}}
						>
							{cd.action}
						</div>,
						<div
							className="hoverable"
							onClick={() => {
								openHistoryModal(cd.id);
							}}
						>
							{cd.endpoint}
						</div>,
					];
				})
			);
		}
	}, [props.data]);

	const setSort = (sortBy, sortDirection) => {
		props.setSortData({
			sortBy,
			sortDirection,
		});
	};

	return (
		<div className="illi-table">
			{/* <form
				onSubmit={(e) => {
					e.preventDefault();
					props.getDefendants();
				}}
			>
				<GridContainer>
					<GridItem xs={12} sm={2} md={2}>
						<h3 style={{ display: "inline-block" }}>Defendants</h3>
						<Button
							justIcon
							round
							simple
							onClick={() => {
								props.getDefendants();
							}}
							color="info"
						>
							<Cached />
						</Button>
					</GridItem>
					<GridItem xs={12} sm={2} md={2}>
						<CustomInput
							label="Search by First Name"
							value={props.filters.first_name}
							onChange={(e) => {
								props.setFilters("first_name", e.target.value);
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={2} md={2}>
						<CustomInput
							label="Search by Last Name"
							value={props.filters.last_name}
							onChange={(e) => {
								props.setFilters("last_name", e.target.value);
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={2} md={2}>
						<CustomInput
							label="Search by Email"
							value={props.filters.email}
							onChange={(e) => {
								props.setFilters("email", e.target.value);
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={2} md={2}>
						<Button color="warning" type="submit" className="outlined-btn">
							SEARCH
						</Button>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						{renderNav()}
					</GridItem>
				</GridContainer>
			</form> */}
			{props.data.length !== 0 && (
				<Table
					tableHeaderColor="primary"
					tableHead={[
						"#",
						<strong>
							Date / Time
							<SortButton
								setSort={setSort}
								sortBy="created_date"
								sortDirection={props.sortDirection}
								currentSortBy={props.sortBy}
							/>
						</strong>,
						<strong>
							Username
							<SortButton
								setSort={setSort}
								sortBy="username"
								sortDirection={props.sortDirection}
								currentSortBy={props.sortBy}
							/>
						</strong>,
						<strong>
							Action
							<SortButton
								setSort={setSort}
								sortBy="action"
								sortDirection={props.sortDirection}
								currentSortBy={props.sortBy}
							/>
						</strong>,
					]}
					tableData={dataArr}
				/>
			)}
			{/* <div className="text-center">
				<em>{props.data.length === 0 ? "No defendants found" : ""}</em>
			</div> */}
			<GridContainer>
				<GridItem xs={12} sm={12} md={3}>
					<CustomSelect
						label="Rows per page"
						options={[10, 15, 20, 25, 30]}
						choose={(e, n) => {
							props.setRows(e);
						}}
						default={props.rows}
					/>
				</GridItem>
			</GridContainer>
			<div>{renderNav()}</div>
		</div>
	);
};

export default List;
