import Pages from "layouts/Pages.jsx";
import RTL from "layouts/RTL.jsx";
import Dashboard from "layouts/Dashboard.jsx";
import Logout from "layouts/Logout.jsx";
import Client from "layouts/Client.jsx"
import ClientDashboard from "../layouts/ClientDashboard";
import ClientDefendants from "../containers/CRM/ClientDefentdants/ClientDefendants";
import ClientDefendantBooking from "../containers/CRM/ClientDefentdants/ClientDefendantBooking";

var indexRoutes = [
  { path: "/rtl", name: "RTL", component: RTL },
  { path: "/pages", name: "Pages", component: Pages },
  { path: "/logout", name: "Logout", component: Logout},
  { path: "/client", name: "Client", component: Client},
  { path: "/c/dashboard", name:"Client Dashboard", component: ClientDefendants},
  { path: "/c/booking_info", name: "Client Booking Info", component: ClientDefendantBooking},
  { path: "/", name: "Home", component: Dashboard }
];

export default indexRoutes;
