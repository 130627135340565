import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    error: null,
    success: null,
    data: [],
    booking: null
}

// MESSAGES 
const clearBookingMessages = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

// GET_BOOKINGS

const getBookingsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getBookingsSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully gotten bookings',
        data: action.response.data
    })
}

const getBookingsFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// CREATE_BOOKING

const createBookingStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const createBookingSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully created booking',
    })
}

const createBookingFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// UPDATE_BOOKING

const updateBookingStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const updateBookingSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully updated booking'
    })
}

const updateBookingFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// DELETE_BOOKING

const deleteBookingStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const deleteBookingSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully deleted booking'
    })
}

const deleteBookingFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_BOOKING_MESSAGES:
            return clearBookingMessages(state, action);

        case actionTypes.GET_BOOKINGS_START:
            return getBookingsStart(state, action);
        case actionTypes.GET_BOOKINGS_SUCCESS:
            return getBookingsSuccess(state, action);
        case actionTypes.GET_BOOKINGS_FAIL:
            return getBookingsFail(state, action);

        case actionTypes.CREATE_BOOKING_START:
            return createBookingStart(state, action);
        case actionTypes.CREATE_BOOKING_SUCCESS:
            return createBookingSuccess(state, action);
        case actionTypes.CREATE_BOOKING_FAIL:
            return createBookingFail(state, action);

        case actionTypes.UPDATE_BOOKING_START:
            return updateBookingStart(state, action);
        case actionTypes.UPDATE_BOOKING_SUCCESS:
            return updateBookingSuccess(state, action);
        case actionTypes.UPDATE_BOOKING_FAIL:
            return updateBookingFail(state, action);

        case actionTypes.DELETE_BOOKING_START:
            return deleteBookingStart(state, action);
        case actionTypes.DELETE_BOOKING_SUCCESS:
            return deleteBookingSuccess(state, action);
        case actionTypes.DELETE_BOOKING_FAIL:
            return deleteBookingFail(state, action);

        default: return state;
    }
};

export default reducer;