import React, { useEffect, Fragment } from "react";
import GridItem from "components/Grid/GridItem";
import Arrests from "containers/CRM/Arrests/Arrests";

export const DefendantsFormArrests = (props) => {
	
	const { defendant_id, canEdit, canDelete } = props;
	return (
		<>
			<Arrests 
				defendant_id={defendant_id}
				arrestData={props.arrestData}
				setArrestData={props.setArrestData}
				action={props.action}
				canEdit={canEdit}
				canDelete={canDelete}
				
			/>
		</>
	);
};
