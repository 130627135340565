import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import LeadsList from "./LeadsList";
import LeadsForm from "./LeadsForm";
import LeadsModal from "./LeadsModal";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { clone } from "shared/utility";
import Button from "../../../components/CustomButtons/Button";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Notification from "../../../components/Notification/Notification";
import Element from "../Element";
import { initialData } from "../Defendants/DefendantsModel";
import fields from "../Defendants/DefendantsModel";
import { createBooking } from "../../../store/actions";

const changeToLead = (msg) => {
    if (msg) {
        if (msg.indexOf('Successfully created defendant') !== -1) {
            return 'Successfully created lead';
        } else if (msg.indexOf('Successfully updated defendant') !== -1) {
            return 'Successfully updated lead';
        }
    }
    return msg;
}

const Leads = (props) => {
    const initialBookingData = {
        booking: "",
        booking_name: "",
        bail_amount: "",
        jail_court: "",
        arrest_county: "",
        arrest_case: "",
        arrest_date: "",
        court_case: "",
    };

    const updateDataObj = (field, value) => {
        // Tracking unsaved data
        props.setUnsavedWarning(true);

        const newData = clone(data);
        newData[field] = value;
        setData(newData);
    };

    const renderElement = (field, model) => {
        return (
            <Element
                field={field}
                data={data}
                updateDataObj={updateDataObj}
                model={model}
            />
        );
    };

    const [data, setData] = useState(initialData);
    const [openedModal, setOpenedModal] = useState(false);
    const [defendantData, setDefendantData] = useState(null);
    const [bookingData, setBookingData] = useState(initialBookingData)
    const [errors, setErrors] = useState({});
    const [action, setAction] = useState("Add");
    const [noMessage, setNoMessage] = useState(false);
    const [filters, setFilters] = useState({
        first_name: "",
        last_name: "",
        phone: "",
        power_of_attorney_number: "",
        booking: "",
        case_number: "",
        arrest_when: "",
    });
    const [offset, setOffset] = useState(0);
    const [rows, setRows] = useState(10);
    const [sortData, setSortData] = useState({
        sortBy: "date_created",
        sortDirection: "DESC",
    });

    const [id, setId] = useState(null);

    // Tracking updateDataObj
    const [unsavedWarning, setUnsavedWarning] = useState(false);
    const [openedWaning, setOpenedWarning] = useState(false);

    // Error/Success Modal
    const [openedMessageModal, setOpenedMessageModal] = useState(false);


    useEffect(() => {
        if (!noMessage) {
            if (props.success || props.error) {
                if (
                    props.success !== "Successfully got lead" &&
                    props.success !== "Successfully got leads"
                ) {
                    setOpenedMessageModal(true);
                }
            }
        }
    }, [props.success, props.error]);

    // Delete defendant modal
    const [openedDefendantModal, setOpenedDefendantModal] = useState(false);

    const getData = () => {
        const params = {
            offset,
            rows,
            sort_by: sortData.sortBy,
            sort_direction: sortData.sortDirection,
        };
        Object.keys(filters).forEach((field) => {
            if (filters[field]) {
                params[field] = filters[field];
            }
        });
        props.getLeads(params);
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        let reset = true;
        for (let key in filters) {
            if (filters[key] !== "") {
                reset = false;
            }
        }
        reset && getData();
    }, [filters]);

    useEffect(() => {
        getData();
    }, [sortData, rows, offset]);

    useEffect(() => {
        if (props.defendant) {
            setDefendantData(props.defendant);

            if (props.defendant.length > 0) {
                setId(props.defendant[0].id);
            }
        }
    }, [props.defendant]);

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <h3>
                    <strong>CRM</strong>
                </h3>
                <Button
                    color="primary"
                    onClick={() => {
                        setAction("Add");
                        setBookingData(initialBookingData);
                        setData(initialData);
                        setDefendantData(null);
                        setOpenedModal(true);
                    }}
                >
                    <i className="fas fa-plus"></i> ADD
                </Button>
                <LeadsList
                    data={
                        props.defendantsData
                            ? props.defendantsData.data
                                ? props.defendantsData.data
                                : []
                            : []
                    }
                    count={
                        props.defendantsData
                            ? props.defendantsData.count
                                ? props.defendantsData.count
                                : 0
                            : 0
                    }
                    filters={filters}
                    setFilters={(f, val) => {
                        const newFilters = clone(filters);
                        newFilters[f] = val;
                        setFilters(newFilters);
                    }}
                    resetFilters={() => {
                        setFilters({
                            first_name: "",
                            last_name: "",
                            phone: "",
                            power_of_attorney_number: "",
                            booking: "",
                            case_number: "",
                            arrest_when: "",
                        });
                    }}
                    initialBookingData={initialBookingData}
                    setData={setData}
                    setBookingData={setBookingData}
                    getDefendants={getData}
                    getLeads={props.getLeads}
                    offset={offset}
                    setOffset={setOffset}
                    rows={rows}
                    setRows={setRows}
                    sortBy={sortData.sortBy}
                    sortDirection={sortData.sortDirection}
                    setSortData={setSortData}
                    setAction={setAction}
                    // setDefendantData={setDefendantData}
                    setOpenedModal={setOpenedModal}
                    getDefendant={props.getDefendant}
                />
            </GridItem>

            <Dialog
                open={openedModal}
                onClose={() => {
                    if (unsavedWarning) {
                        setOpenedWarning(true);
                    } else {
                        setOpenedModal(false);
                    }
                }}
                fullScreen
            >
                <div className="dialog-wrapper" style={{ marginBottom: '90px' }}>
                    <LeadsForm
                        action={props.action}
                        model={fields}
                        renderElement={renderElement}
                        data={data}
                        errors={errors}
                        action={action}
                        defendantData={defendantData}
                        bookingData={bookingData}
                        initialBookingData={initialBookingData}
                        setBookingData={setBookingData}
                        add={props.createDefendant}
                        createBooking={props.createBooking}
                        update={props.updateDefendant}
                        delete={props.deleteDefendant}
                        close={() => {
                            if (unsavedWarning) {
                                setOpenedWarning(true);
                            } else {
                                setOpenedModal(false);
                            }
                        }}
                        submit={() => {
                            setOpenedModal(false);
                            setOpenedWarning(false);
                        }}
                        model={fields}
                        setData={setData}
                        errors={errors}
                        setUnsavedWarning={setUnsavedWarning}
                        showCloseBtn
                        defendant_id={id}
                        error={props.error}
                        success={props.success}
                        setUnsavedWarning={setUnsavedWarning}
                        closeFinal={() => {
                            setOpenedModal(false);
                        }}
                        userData={props.userData}
                        getDefendantUsers={props.getDefendantUsers}
                        createDefendantUsers={props.createDefendantUsers}
                        defendantUsers={props.defendantUsers}
                        getAdminUsers={props.getAdminUsers}
                        deleteDefendantUsers={props.deleteDefendantUsers}
                        noMessage={noMessage}
                        setNoMessage={setNoMessage}
                    />
                </div>
            </Dialog>

            <LeadsModal
                deleteDefendant={props.deleteDefendant}
                id={id}
                open={openedDefendantModal}
                close={() => setOpenedDefendantModal(false)}
            />

            {/* Unsaved data warning modal */}
            <Dialog
                open={openedWaning}
                onClose={() => {
                    setOpenedWarning(false);
                    setUnsavedWarning(false);
                }}
                maxWidth="md"
                fullWidth={true}
            >
                <DialogTitle>
                    <div style={{ textAlign: "center" }}>
                        Are you sure? Any unsaved data will be lost.
                    </div>
                </DialogTitle>

                <div style={{ textAlign: "center", padding: 20 }}>
                    <Button
                        color="danger"
                        style={{ marginRight: 10 }}
                        onClick={() => {
                            setOpenedWarning(false);
                            setUnsavedWarning(false);
                            setOpenedModal(false);
                        }}
                    >
                        OK, CLOSE
                    </Button>
                    <Button
                        color="white"
                        style={{ marginRight: 10 }}
                        onClick={() => {
                            setOpenedWarning(false);
                        }}
                    >
                        CANCEL
                    </Button>
                </div>
            </Dialog>

            {/* Error, success modal */}
            {props.success !== "Successfully got indemnitors" &&
                (props.success || props.error) && (
                    <Notification
                        open={openedMessageModal}
                        message={<h4>{`${changeToLead(props.success) || props.error}`}</h4>}
                        close={() => {
                            setOpenedMessageModal(false);
                            getData();
                            props.clearCRMMessages();
                        }}
                    />
                )}
        </GridContainer>

    );
};

const mapStateToProps = (state) => {
    return {
        error: state.crm.error,
        success: state.crm.success,
        defendantsData: state.crm.defendantsData,
        defendant: state.crm.defendant,
        userData: state.user.userList,
        defendantUsers: state.crm.defendantUsers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearCRMMessages: () => {
            dispatch(actions.clearCRMMessages());
        },

        getLeads: (params) => {
            dispatch(actions.getLeads(params));
        },
        createDefendant: (data, bookingData, defendantData) => {
            dispatch(
                actions.createDefendant(data, bookingData, defendantData)
            );
        },
        createBooking: (data) => {
            dispatch(actions.createBooking(data));
        },

        deleteDefendant: (id) => {
            dispatch(actions.deleteDefendant(id));
        },

        getDefendant: (id) => {
            dispatch(actions.getDefendant(id));
        },

        updateDefendant: (data) => {
            dispatch(actions.updateDefendant(data));
        },
        getDefendantUsers: (defendant_id) => {
            dispatch(actions.getDefendantUsers(defendant_id));
        },
        createDefendantUsers: (data) => {
            dispatch(actions.createDefendantUsers(data));
        },
        getAdminUsers: () => {
            dispatch(actions.getAdminUsers());
        },
        deleteDefendantUsers: (id) => {
            dispatch(actions.deleteDefendantUsers(id));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Leads);