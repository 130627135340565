const fields = {
    court: {
        type: 'input',
        label: 'Court',
        validations: ['min1','required']
    },
    county: {
        type: 'input',
        label: 'County',
        validations: ['min1']
    },
    arrest_when: {
        type: 'date',
        label: 'Arrest When',
        validations: ['min1'],
        hasTime: true,
        required: true
    },
    bail_post_date: {
        type: 'date',
        label: 'Bail Post Date',
        validations: ['min1'],
        hasTime: true,
        required: true
    },
    disposition: {
        type: 'input',
        label: 'Disposition',
        validations: ['min1']
    },
    previous_bail: {
        type: 'input',
        label: 'Previous Bail',
        validations: ['min1']
    },
    with_whom: {
        type: 'input',
        label: 'With Whom',
        validations: ['min1']
    },
    surety: {
        type: 'input',
        label: 'Surety',
        validations: ['min1']
    },
    charges: {
        type: 'input',
        label: 'Charges',
        validations: ['min1']
    },
};

export default fields;

const initialDat = {};
Object.keys(fields).forEach(field => {
    initialDat[field] = '';
})

export const initialData = initialDat;

