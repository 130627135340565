import RegisterPage from "views/Pages/RegisterPage.jsx";
import LockScreenPage from "views/Pages/LockScreenPage.jsx";

// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import LockOpen from "@material-ui/icons/LockOpen";
import Help from "@material-ui/icons/Help";
import ClientLoginPage from "../views/Pages/ClientLoginPage";
import ClientDashboard from "../views/Pages/ClientDashboard";
import ClientDefendantBooking from "../containers/CRM/ClientDefentdants/ClientDefendantBooking";

const clientRoutes = [
	{
		path: "/client",
		name: "Login Page",
		short: "Login",
		mini: "CL",
		icon: Fingerprint,
		component: ClientLoginPage,
	},
	


	// {
	//   redirect: true,
	//   path: "/pages",
	//   pathTo: "/pages/register-page",
	//   name: "Register Page"
	// }
];

export default clientRoutes;
