import { put } from "redux-saga/effects";
import axios from "../axios/axios-crm";
import { clone } from 'shared/utility';

import * as actions from "../actions";

export function* getIndemnitorsSaga(action) {
	yield put(actions.getIndemnitorsStart());
	try {
		const response = yield axios.get("/indemnitors?id=" + action.data);
		yield put(actions.getIndemnitorsSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getIndemnitorsFail(e.response.data));
			}
		}
		yield put(actions.getIndemnitorsFail(e));
	}
}

export function* getIndemnitorSaga(action) {
    yield put(actions.getIndemnitorStart());
    try {
        const response = yield axios.get('/indemnitor?id='+action.data);
        yield put(actions.getIndemnitorSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getIndemnitorFail(e.response.data));
            }
          }
        yield put(actions.getIndemnitorFail(e));
    }
}

export function* createIndemnitorSaga(action) {
	yield put(actions.createIndemnitorStart());
	try {
		const indemnitor = clone(action.data);
		[
			'back_id_photo',
			'front_id_photo',
			'indemnitor_holding_id_photo'
		].forEach(f => {
			if (indemnitor[f]) {
				indemnitor[f] = JSON.stringify(indemnitor[f]);
			}
		})
		const response = yield axios.post("/indemnitor", indemnitor);
		yield put(actions.createIndemnitorSuccess(response.data));
		if (action.shouldSendSMS === "Yes") {
			yield put(
				actions.sendSmsToDefendant({
					phone: action.data.phone,
					id: response.data.insertId,
					ind: true,
					defendant_id: action.data.defendant_id
				})
			);
		}
		let photosCount = 0;
		console.log('uploading photos ->', action.photosData);
		if (action.photosData) {
			for (let [key, value] of action.photosData.entries()) {
				console.log('phot ? key', key, value);
				if (key && value) photosCount++;
			}
		} 
		if (photosCount) {
			console.log('uploading photos');
			yield put(
				actions.uploadIndemnitorPhotos({
					id: response.data.insertId,
					photos: action.photosData,
				})
			);
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.createIndemnitorFail(e.response.data));
			}
		}
		yield put(actions.createIndemnitorFail(e));
	}
}

export function* updateIndemnitorSaga(action) {
	yield put(actions.updateIndemnitorStart());
	try {
		const indemnitor = clone(action.data);
		const pdfData = action.pdfData;
		[
			'back_id_photo',
			'front_id_photo',
			'indemnitor_holding_id_photo'
		].forEach(f => {
			if (indemnitor[f]) {
				indemnitor[f] = JSON.stringify(indemnitor[f]);
			}
		})
		console.log('indemnitor', indemnitor);
		const response = yield axios.put("/indemnitor", indemnitor);
		if (action.photosData && action.element) {
			yield put(actions.uploadIndemnitorPhotos(action.photosData, action.element));
		}
		yield put(actions.updateIndemnitorSuccess(response.data));
		// ** Removed autosend document for docusign **
		// if (pdfData) {
		// 	if (pdfData.sendPdf === true && pdfData.booking_id !== false) {
		// 		yield put(
		// 			actions.sendSinglePdf({
		// 				type: pdfData.type,
		// 				defendant_id: pdfData.defendant_id,
		// 				booking_id: pdfData.booking_id,
		// 				indemnitor_id: pdfData.indemnitor_id
		// 			})
		// 		);
		// 	}
		// }
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.updateIndemnitorFail(e.response.data));
			}
		}
		yield put(actions.updateIndemnitorFail(e));
	}
}

export function* deleteIndemnitorSaga(action) {
	yield put(actions.deleteIndemnitorStart());
	try {
		const response = yield axios.delete("/indemnitor?id=" + action.data);
		yield put(actions.deleteIndemnitorSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.deleteIndemnitorFail(e.response.data));
			}
		}
		yield put(actions.deleteIndemnitorFail(e));
	}
}

export function* uploadIndemnitorPhotosSaga(action) {
	yield put(actions.uploadIndemnitorPhotosStart(action.fieldName ? action.fieldName : ''));
	try {
		const response = yield axios.post(
			`/indemnitor/upload?id=${action.data.id}`,
			action.data.photos
		);
		yield put(actions.uploadIndemnitorPhotosSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.uploadIndemnitorPhotosFail(e.response.data));
			}
		}
		yield put(actions.uploadIndemnitorPhotosFail(e));
	}
}

export function* downloadIndemnitorPhotoSaga(action) {
	// yield put(actions.downloadIndemnitorPhotoStart());
	try {
		const response = yield axios({
			url: `/indemnitor/download`,
			method: "POST",
			data: action.data,
			responseType: "blob",
		});
		console.log(response);
		const url = URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", action.data.name); //or any other extension
		document.body.appendChild(link);
		link.click();
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.downloadIndemnitorPhotoFail(e.response.data));
			}
		}
		yield put(actions.downloadIndemnitorPhotoFail(e));
	}
}
