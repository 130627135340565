import GridItem from "components/Grid/GridItem";
import React, { Fragment, useState, useEffect } from "react";
import Datetime from "react-datetime";
import GridContainer from "../../../components/Grid/GridContainer";
import { checkEmpty } from "../../../shared/utility";
import Upload from "../../../components/Uploads/Upload";
import Button from "../../../components/CustomButtons/Button";
import NotificationAsk from "../../../components/Notification/NotificationAsk";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";
import * as actions from "store/actions";

const DefendantsFormInfo = (props) => {
	console.log('DefendantsFormInfo', props);
	const [openUpload, setOpenUpload] = useState(false);
	const [uploadData, setUploadData] = useState({});
	const [openAskReplace, setOpenAskReplace] = useState(null);
	const [startUpload, setStartUpload] = useState(false); 
	const { renderElement, photos, setPhotos, updateDataObj, doUpdate, photo_success, data, appendFileNew, canEdit } = props;

	useEffect(() => {
		if (startUpload) {
			if (photo_success) {
				props.getDefendant(props.data.id);
				setStartUpload(false);
			}
		}
	}, [photo_success]);

	const fields = {
		info: [
			"email",
			"instagram",
			"place_of_birth",
			null,
			"us_citizen",
			"sex",
			"ss",
			null,
			"dl",
			"issuing_state",
			"front_id_photo",
			"back_id_photo",
			"defendant_photos",
			"defendant_holding_id_photo",
			"selfie_photo",
			"defendant_holding_power_photo",
			"proof_of_income",
		],
		address_info: [
			"address",
			"city",
			"years_in_city",
			"zip",
			"state",
			"county",
			"lived_at_this_address_for",
			"rent_own",
			"proof_of_address",
		],
		former_address_info: [
			"former_address",
			"former_lived_there_how_long",
			"last_county",
			"last_state",
			"last_lived_there",
		],
		employment_info: [
			"employed_by",
			"employers_phone",
			"occupation_title",
			"compensation",
			"how_long",
			"employers_address",
			"superior",
			"superior_phone",
		],
		vehicle_info: ["has_vehicle"],
	};

	const isRequired = (element) => {
		if (props.data[element].length > 0) {
			return false;
		}
		else if (element === 'front_id_photo' || 
			element === 'back_id_photo' || 
			element === 'defendant_photos' || 
			element === 'defendant_holding_id_photo')  {

				return true;
		} else {
			return false;
		}
	}


	const renderFields = () => {
		const disableUpload = canEdit() ? false : true;
		return Object.keys(fields).map((key, index) => {
			return (
				<GridContainer
					className={`${props.action === "History View" && "no-click"}`}
					key={index}
				>
					<GridItem xs={12} sm={12} md={12}>
						<h4 className="bold">{key.split("_").join(" ").toUpperCase()}</h4>
					</GridItem>
					{fields[key].map((element, i) => {
						if (element === null) {
							return <GridItem key={i} xs={12} sm={3} md={3}></GridItem>
						}
						if (key === "date_of_birth") {
							return (
								<GridItem key={i} xs={12} sm={3} md={3}>
									<Datetime
										inputProps={{
											placeholder: "Last Attempted Contact Date",
										}}
										closeOnSelect={true}
										onChange={(e) => {
											if (props.action === "View") return;
											if (typeof e === "object") {
												const stringDate = e.format("MM/DD/YYYY");
												return;
											}
											// props.updateDataObj("last_attempted_contact_date", e);
										}}
										timeFormat={false}
									/>
								</GridItem>
							);
						}

						if (element === "rent_own" && props.data.rent_own === "Rent") {
							return (
								<Fragment key={i}>
									<GridItem xs={12} sm={3} md={3}>
										{renderElement(element, props.model)}
									</GridItem>
									<GridItem xs={12} sm={3} md={3}>
										{renderElement("landlord_name", props.model)}
									</GridItem>
									<GridItem xs={12} sm={3} md={3}>
										{renderElement("landlord_phone", props.model)}
									</GridItem>
								</Fragment>
							);
						}

						if (element === "us_citizen" && props.data.us_citizen === "No") {
							return (
								<Fragment key={i}>
									<GridItem xs={12} sm={3} md={3}>
										{renderElement(element, props.model)}
									</GridItem>
									<GridItem xs={12} sm={3} md={3}>
										{renderElement("how_long_in_us", props.model)}
									</GridItem>
								</Fragment>
							);
						}

						if (element === "has_vehicle" && props.data.has_vehicle === "Yes") {
							return (
								<Fragment key={i}>
									<GridItem xs={12} sm={12} md={12}>
										{renderElement(element, props.model)}
									</GridItem>
									{[
										"vehicle_make",
										"model",
										"vehicle_year",
										"color",
										"lic",
										"license_plate_state",
										"vehicle_financed",
									].map((el, j) => {
										if (
											el === "vehicle_financed" &&
											props.data.vehicle_financed === "Yes"
										) {
											return (
												<Fragment key={i}>
													<GridItem xs={12} sm={3} md={3}>
														{renderElement(el, props.model)}
													</GridItem>
													<GridItem xs={12} sm={3} md={3}>
														{renderElement("where_financed", props.model)}
													</GridItem>
													<GridItem xs={12} sm={3} md={3}>
														{renderElement("how_much_owned", props.model)}
													</GridItem>
												</Fragment>
											);
										}
										return (
											<GridItem xs={12} sm={3} md={3} key={`${j}-hasvehicle`}>
												{renderElement(el, props.model)}
											</GridItem>
										);
									})}
								</Fragment>
							);
						}

						if (
							element === "vehicle_financed" &&
							props.data.vehicle_financed === "Yes"
						) {
							return (
								<Fragment key={i}>
									<GridItem xs={12} sm={3} md={3}>
										{renderElement(element, props.model)}
									</GridItem>
									<GridItem xs={12} sm={3} md={3}>
										{renderElement("where_financed", props.model)}
									</GridItem>
									<GridItem xs={12} sm={3} md={3}>
										{renderElement("how_much_owned", props.model)}
									</GridItem>
								</Fragment>
							);
						}

						if (
							props.model[element].type === "photo" ||
							props.model[element].type === "file"
						) {
							console.log(
								'checking photo upload...',
								props.photos.has(element),
								props.data[element],
								checkEmpty(props.data[element]),
								props.errors[element]
							);
							const hasUploaded = (
									props.photos.has(element) 
								||
									(props.data[element] && !checkEmpty(props.data[element]))
							);
							return (
								<GridItem
									style={{ marginBottom: 20 }}
									key={i}
									xs={12}
									sm={3}
									md={3}
								>
									<div id={element} className="mt-10">
										<label
											className={props.errors[element] && "red"}
											style={{ display: "block" }}
										>
											{props.model[element].label}
										</label>
										{(props.photo_loading === element) && <div>
														<ClipLoader />
										</div>}

										{(props.photo_loading !== element) && <div>
											{props.action !== "History View" && (
												<div>
													{<Button 
													disabled={disableUpload}
													color={(hasUploaded &&
														!props.errors[element]) ? 'danger' : 'primary'} 
													onClick={() => {
														const photoData = {
															element,
															multiple: props.model[element].maxCount > 1,
															label: props.model[element].label
														};
														if (hasUploaded && !props.errors[element]) {
															setOpenAskReplace(photoData);
														} else {
															setOpenUpload(true);
															setUploadData(photoData);
														}
													}}>
																{(hasUploaded &&
														!props.errors[element]) ? (
															<span>REPLACE</span>
														) : <span>UPLOAD</span>}
													</Button>}
												</div>
											)}
											
											{(hasUploaded && !props.errors[element]) && (
													<i className="fa fa-check green-text ml-20"></i> 
												)}
											{props.errors[element] && (
												<>
													<i className="red-text fa fa-times ml-20"></i>
													<p className="red-text">{props.errors[element]}</p>
												</>
											)}
											{props.data[element] &&
												props.action === "Edit" &&
												props.data[element].map((photo, index) => (
													<div key={`${index}-photo`}>
														<a
															onClick={() => {
																props.downloadDefendantPhoto(photo);
															}}
															className="text-ellipsis"
															style={{ cursor: "pointer" }}
														>
															Download {photo.name}
														</a>
													</div>
											))}
											{(props.data[element] && props.action === "Client Edit") &&
												<div className="mb-10">{props.data[element].length} file(s) uploaded</div>}
												
												{(props.photos.has(element) || isRequired(element) && checkEmpty(props.data[element]) && <div className="red-text"><small>Required</small></div>)}
										</div>}
										
									</div>
								</GridItem>
							);
						}

						return (
							<GridItem key={i} xs={12} sm={3} md={3}>
								{renderElement(element, props.model)}
							</GridItem>
						);
					})}
					<GridItem xs={12} sm={12} md={12}>
						<hr />
					</GridItem>

					{openUpload && <Upload 
						open={openUpload}
						close={() => {
							setOpenUpload(false);
						}}
						uploadData={uploadData}
						appendFile={(input, blob_field) => {
							if (data.id) {
								if (blob_field) {
									console.log('adding');
									const newFormData = new FormData();
									const element = blob_field;
									newFormData.append(element, input, element+'.png');
									setStartUpload(true);
									doUpdate({
										id: data.id,
										photos: newFormData
									}, element);
								} else {
									const newFormData = new FormData();
									const { length } = input.target.files;
									for (let i = 0; i < length; ++i) {
										newFormData.append(input.target.name, input.target.files[i]);
									}
									setStartUpload(true);
									console.log('doing update', {
										id: data.id,
										photos: newFormData
									}, uploadData.element)
									doUpdate({
										id: data.id,
										photos: newFormData
									}, uploadData.element);
								}
							} else {
								appendFileNew(input, blob_field);
							}
						}}
					/>}

					{openAskReplace && <NotificationAsk 
						open={openAskReplace ? true : false}
						close={() => {
							setOpenAskReplace(null);
						}}
						success={() => {
							if (data.id) {
								// EDIT
								setOpenUpload(true);
								setUploadData(openAskReplace);
								setOpenAskReplace(null);
							} else {
								// NEW
								let shouldReplace = false;
								const photoField = openAskReplace.element;
								if (photos) {
									if (photos.hasOwnProperty(photoField)) {
										const newPhotos = new FormData();
										for (let [key, value] of photos.entries()) {
											if (key !== photoField) {
												newPhotos.append(key, value);
											}
										}
										setPhotos(newPhotos);
										shouldReplace = true;
									}
								}
								if (props.data) {
									if (props.data[photoField] && typeof props.data[photoField] === 'object') {
										if (props.data[photoField].length > 0) {
											updateDataObj(photoField, []);
											shouldReplace = true;
										}
									}
								}
								if (shouldReplace) {
									setOpenUpload(true);
									setUploadData(openAskReplace);
								}
								setOpenAskReplace(null);
							}
						}}
						message={`Replace this file/photo ?`}
						success_button_text="YES"
						fail_button_text="NO"
					/>}

				</GridContainer>
			);
		});
	};

	return <>{renderFields()}</>;
};

const mapStateToProps = (state) => {
	return {
		photo_loading: state.crm.loading_upload_defendant_photos,
		photo_error: state.crm.error_upload_defendant_photos,
		photo_success: state.crm.success_upload_defendant_photos
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getDefendant: (data) => {
			dispatch(actions.getDefendant(data));
		},
		downloadDefendantPhoto: (data) => {
			dispatch(actions.downloadDefendantPhoto(data));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DefendantsFormInfo);
