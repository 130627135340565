import React, { useState } from 'react';
import { pdfjs } from 'react-pdf';
import { Page, Document } from 'react-pdf';
import Dialog from '@material-ui/core/Dialog';
import Button from "components/CustomButtons/Button.jsx";
import ClipLoader from 'react-spinners/ClipLoader';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfModal = (props) => {
    const { pdfData, open, close } = props;

    const downloadPdf = (pdfName, buffer) => {
        const pdfBlob = new Blob([new Uint8Array(buffer)], {type: "application/pdf"});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = pdfName;
        link.click();
    };

    const printPdf = (buffer) => {
        const pdfBlob = new Blob([new Uint8Array(buffer)], {type: "application/pdf"});
        let pdfFrame = document.body.appendChild(document.createElement('iframe'));
        pdfFrame.style.display = 'none';
        pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
        pdfFrame.src = window.URL.createObjectURL(pdfBlob);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={close}
                keepMounted
                maxWidth={false}
                fullWidth={false}
            >
                <div style={{ margin: 20 }}>
                    <PdfViewer pdfData={pdfData} />
                    <br />
                    {pdfData && <div className="text-center">
                        <Button color="white" className="mr-20" onClick={() => { close() }}>Close</Button>
                        <Button color="rose" className="mr-20" onClick={() => { downloadPdf('AABail-doc.pdf', pdfData.data) }}>Download</Button>
                        <Button color="primary" onClick={() => { printPdf(pdfData.data) }}>Print</Button>
                    </div>}
                </div>
            </Dialog>
        </div>
    )
}

const PdfViewer = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = (dat) => {
        const { numPages } = dat;
        console.log('numOfPages',numPages, dat)
        setNumPages(numPages);
    };

    const renderNav = () => {
        return <div className="text-center">
            <div>Page {pageNumber} of {numPages}</div>
            <hr />
            <Button
                onClick={() => {
                    setPageNumber(pageNumber - 1)
                }}
                disabled={pageNumber === 1 ? true : false}
                className="mr-20"
            ><i className="fas fa-arrow-left"></i></Button>
            <Button
                onClick={() => {
                    setPageNumber(pageNumber + 1)
                }}
                disabled={pageNumber === numPages ? true : false}
            ><i className="fas fa-arrow-right"></i></Button>
        </div>
    }

    console.log('numpages', numPages);
    console.log(props.pdfData);
    return (
        <Document
            file={props.pdfData}
            onLoadSuccess={onDocumentLoadSuccess}
        >
            {numPages && <div>
                {renderNav()}
                <div className="light-border">
                    <Page pageNumber={pageNumber} width={1000} />
                </div>
                {renderNav()}
            </div>}
            {!numPages && <div className="mb-20 mt-20 text-center"><ClipLoader /></div>}
        </Document>
    );
}

export default PdfModal;