import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";
import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import Notification from "../../components/Notification/Notification";

class SettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sms_content: '',
      notification: ''
    }
  }

  componentDidMount() {
    this.props.getSmsConfig();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.success !== this.props.success) {
      if (this.props.success) return this.setState({notification:this.props.success})
    }
    if (prevProps.error !== this.props.error) {
      if (this.props.error) return this.setState({notification:this.props.error})
    }
    if (prevProps.sms !== this.props.sms) {
      if (this.props.sms) {
        if (this.props.sms.length > 0) {
          const sms = this.props.sms[0];
          this.setState({ sms_content: sms.content ? sms.content : ''})
        }
      }
    }
  }

  render() {
    console.log('SettingsPage', this.props)
    const smsPermitted = checkPermissions('SMS');
    if (!smsPermitted) {
      return <Redirect to="/dashboard" />;
    }
    return (
  <div>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <div className="pointed"><Cached /></div>
            </CardIcon>
          </CardHeader>
          <CardBody>
            <h1>Settings</h1>

            <div style={smsPermitted ? {} : {display:'none'}}>
              <h3><strong>SMS Templates</strong></h3>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    id="standard-multiline-flexible2"
                    label="Client SMS Content"
                    multiline
                    fullWidth={true}
                    variant="standard"
                    value={this.state.sms_content}
                    onChange={(e) => {
                      if (e.target.value.length < 1555) this.setState({
                        sms_content: e.target.value
                      })
                    }}
                  />
                  <hr />
                  <Button color="primary" className="mt-20" onClick={() => {
                    this.props.updateSmsConfig({
                      id: 1,
                      content: this.state.sms_content
                    })
                  }}>UPDATE</Button>
                </GridItem>
              </GridContainer>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
                

                {this.state.notification && <Notification 
                  open={this.state.notification ? true : false}
                  close={() => {
                    this.setState({notification:''})
                  }}
                  message={this.state.notification}
                  title="Notification"
                />}
  </div>
    );
  }
}

const mapStateToProps = state => {
  
  return {
    sms: state.settings.sms,
    error: state.settings.error,
    success: state.settings.success
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSmsConfig: () => {
      dispatch(actions.getSmsConfig());
    },
    updateSmsConfig: (data) => {
      dispatch(actions.updateSmsConfig(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
