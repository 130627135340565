import React from 'react';
import { Dialog } from '@material-ui/core';
import Button from '../CustomButtons/Button';
import { Close } from '@material-ui/icons';

const Notification = (props) => {
    return <Dialog 
      open={props.open}
      keepMounted  
      maxWidth="sm" 
      fullWidth={true}
    >
      {!props.no_ok_button && <div><span className="close-btn" onClick={() => { props.close(); }}><Close /></span></div>}
      <div style={{padding:'0px 20px 40px'}} className="text-center">
        {props.message}
        <div className="mt-20">
            {!props.no_ok_button && <Button color="primary" onClick={() => { props.close(); }}>OK</Button>}
        </div>
      </div>
    </Dialog>
  }

  export default Notification;