import { stateList } from "../../../shared/utility";

const fields = {
	booking: {
		type: "input",
		label: "Booking #",
		validations: ["min1"],
	},
	bail_amount: {
		type: "input",
		label: "Bail Amount",
		validations: ["min1", "no_letters"],
		client_required: true,
	},
	booking_name: {
		type: "input",
		label: "Booking Name",
		validations: ["min1"],
	},
	bail_post_date: {
		type: "date",
		label: "Bail Post Date",
		validations: ["min1"],
	},
	jail_court: {
		type: "input",
		label: "Jail or Court",
		validations: ["min1"],
	},
	where_held: {
		type: "input",
		label: "Where held",
		validations: ["min1"],
	},
	charges: {
		type: "text",
		label: "Charges",
		validations: ["min1"],
	},
	court_times: {
		type: "text",
		label: "court_times",
		validations: ["min1"],
	},
	court_name: {
		type: "input",
		label: "Court Name",
		validations: ["min1"],
	},
	case_number: {
		type: "input",
		label: "Other Case#",
		validations: ["min1"],
	},
	bond_no: {
		type: "input",
		label: "Bond No.",
		validations: ["min1"],
	},
	arrest_county: {
		type: "input",
		label: "Arrest County",
		validations: ["min1"],
	},
	arrest_state: {
		type: "select",
		label: "Arrest State",
		options: stateList,
	},
	arrest_case: {
		type: "input",
		label: "Arrest Case#",
		validations: ["min1"],
	},
	arrest_date: {
		type: "input",
		label: "Arrest Date",
		validations: ["min1"],
	},
	case_county: {
		type: "input",
		label: "Case County",
		validations: ["min1"],
	},
	case_state: {
		type: "select",
		label: "Case State",
		options: stateList,
	},
	court_case: {
		type: "input",
		label: "Court Case#",
		validations: ["min1"],
	},
	judicial_district: {
		type: "input",
		label: "Judicial District",
		validations: ["min1"],
	},
	notes: {
		type: "text",
		label: "Notes",
		validations: ["min1"]
	},
	other_conditions: {
		type: "text",
		label: "Other Conditions",
		validations: ["min1"]
	},
	payment_schedule: {
		type: "text",
		label: "Payment Schedule",
		
	},
	payment_schedule2: {
		type: "text",
		label: "Payment Schedule",
		
	},
	payment_amount_1: {
		type: "input",
		label: "Amount Of Payment",
		
	},
	payment_amount_2: {
		type: "input",
		label: "Amount Of Payment",
		
	},
	payment_amount_3: {
		type: "input",
		label: "Amount Of Payment",
		
	},
	payment_amount_4: {
		type: "input",
		label: "Amount Of Payment",
		
	},
	payment_date_1: {
		type: "input",
		label: "Date Payment Due",
		
	},
	payment_date_2: {
		type: "input",
		label: "Date Payment Due",
		
	},
	payment_date_3: {
		type: "input",
		label: "Date Payment Due",
		
	},
	payment_date_4: {
		type: "input",
		label: "Date Payment Due",
		
	},
	amount_paid_down: {
		type: "input",
		label: "Amount Paid Down",
		
	},
	unpaid_balance: {
		type: "input",
		label: "Unpaid Balance",
		
	},
	cash_collateral: {
		type: "input",
		label: "Cash Collateral",
		
	},
	court_room: {
		type: "input",
		label: "Court Room",
		validations: ["min1"]
	},
	title_10: {
		type: "select",
		label: "Title 10",
		options: ['Self']
	},
	surety: {
		type: "select",
		label: "Surety",
		options: ["Bankers", "FCS"],
		required: true
	},
	premium_amount: {
		type: "input",
		label: "Premium Amount"
	},
	recurring_charges: {
		type: "input",
		label: "Recurring Charges"
	},
	recurring_charges2: {
		type: "input",
		label: "Recurring Charges"
	},
	recurring_charges3: {
		type: "input",
		label: "Recurring Charges"
	},
	recurring_charges4: {
		type: "input",
		label: "Recurring Charges"
	},
	includeCC: {
		type: "select",
		label: "Include CC",
		options: ["Yes","No"]
	},
	credit_card_charge_date: {
		type: "input",
		label: "Charge Date"
	},
	credit_card_charge_amount: {
		type: "input",
		label: "Charge Amount"
	},
};

export default fields;

const initialDat = {};
Object.keys(fields).forEach((field) => {
	if (field === 'charges') {
		initialDat.charges = [];
	} else if (field === 'court_times') {
		initialDat.court_times = [];
	} else if (field === 'includeCC') {
		initialDat.includeCC = "Yes";
	} else {
		initialDat[field] = "";
	}
});


export const initialData = initialDat;
