import { capitalizeAll, stateList } from "../../../shared/utility";

const fields = {
	// General Info
	status: {
		type: "select",
		label: "Status",
		options: ["New Lead", "Assigned to Agent"],
		defaultValue: "New Lead",
		required: true
	},
	first_name: {
		type: "input",
		label: "First Name",
		validations: [
			"min1",
			"max50",
			"no_numbers",
			"no_special_chars",
		],
		required: true,
		uppercase: true,
		client_required: true,
	},
	last_name: {
		type: "input",
		label: "Last Name",
		validations: [
			"min1",
			"max50",
			"no_numbers",
			"no_special_chars",
		],
		required: true,
		uppercase: true,
		client_required: true,
	},
	middle_name: {
		type: "input",
		label: "Middle Name",
		validations: ["min1", "no_numbers", "no_special_chars"],
		uppercase: true,
	},
	power_of_attorney_number: {
		type: "input",
		label: "Power of Attorney Number",
		validations: ["min1"],
	},
	email: {
		type: "input",
		label: "Email",
		validations: ["min6", "max100", "email"],
		client_required: true,
	},
	instagram: {
		type: "input",
		label: "Instagram",
		validations: ["min1"],
	},
	date_of_birth: {
		type: "date",
		label: "Date of Birth",
		validations: ["min1"],
		client_required: true,
	},

	place_of_birth: {
		type: "input",
		label: "Place of Birth",
		validations: ["min1"],
		client_required: true,
	},
	us_citizen: {
		type: "select",
		label: "US Citizen",
		options: ["Yes", "No"],
	},
	how_long_in_us: {
		type: "input",
		label: "If No (how long in US)",
		validations: ["min1"],
	},

	sex: {
		type: "input",
		label: "Sex",
		validations: ["min1"],
	},
	ss: {
		type: "input",
		label: "SS#",
		validations: ["min1"],
		client_required: true,
	},
	dl: {
		type: "input",
		label: "DL#",
		validations: ["min1"],
		client_required: true,
	},
	issuing_state: {
		type: "select",
		label: "Issuing State",
		options: stateList,
		client_required: true,
	},

	// ADDRESS INFO
	address: {
		type: "input",
		label: "Address",
		validations: ["min1"],
		uppercase: true,
		client_required: true,
	},
	city: {
		type: "input",
		label: "City",
		validations: ["min1"],
		uppercase: true,
		client_required: true,
	},
	zip: {
		type: "input",
		label: "Zipcode",
		validations: ["min1"]
	},
	phone: {
		type: "input",
		label: "Mobile Phone",
		validations: [
			"min10",
			"no_letters",
			"max10",
			"no_special_chars",
		],
		required: true,
		client_required: true,
		number_only: true,
		max_length: 10
	},
	home_phone: {
		type: "input",
		label: "Home Phone",
		validations: ["min10", "no_letters", "max10", "no_special_chars"],
		client_required: true,
	},
	lived_there_how_long: {
		type: "input",
		label: "Lived there how long?",
		validations: ["min1"],
	},
	former_address: {
		type: "input",
		label: "Former address",
		validations: ["min1"],
		uppercase: true,
	},
	former_lived_there_how_long: {
		type: "input",
		label: "Lived there how long?",
		validations: ["min1"],
	},
	years_in_city: {
		type: "input",
		label: "Years in city",
		validations: ["min1"],
		client_required: true,
	},
	county: {
		type: "input",
		label: "Current County",
		validations: ["min1"],
		client_required: true,
	},
	state: {
		type: "select",
		label: "Current State",
		client_required: true,
		options: [
			"AL",
			"AK",
			"AZ",
			"AR",
			"CA",
			"CO",
			"CT",
			"DE",
			"DC",
			"FL",
			"GA",
			"GU",
			"HI",
			"ID",
			"IL",
			"IN",
			"IA",
			"KS",
			"KY",
			"LA",
			"ME",
			"MD",
			"MA",
			"MI",
			"MN",
			"MS",
			"MO",
			"MT",
			"NE",
			"NV",
			"NH",
			"NJ",
			"NM",
			"NY",
			"NC",
			"ND",
			"OH",
			"OK",
			"OR",
			"PA",
			"PR",
			"RI",
			"SC",
			"SD",
			"TN",
			"TX",
			"UT",
			"VT",
			"VI",
			"VA",
			"WA",
			"WV",
			"WI",
			"WY",
		],
	},
	last_county: {
		type: "input",
		label: "Last County",
		validations: ["min1"],
		client_required: true,
	},
	last_state: {
		type: "select",
		label: "Last State",
		client_required: true,
		options: [
			"AL",
			"AK",
			"AZ",
			"AR",
			"CA",
			"CO",
			"CT",
			"DE",
			"DC",
			"FL",
			"GA",
			"GU",
			"HI",
			"ID",
			"IL",
			"IN",
			"IA",
			"KS",
			"KY",
			"LA",
			"ME",
			"MD",
			"MA",
			"MI",
			"MN",
			"MS",
			"MO",
			"MT",
			"NE",
			"NV",
			"NH",
			"NJ",
			"NM",
			"NY",
			"NC",
			"ND",
			"OH",
			"OK",
			"OR",
			"PA",
			"PR",
			"RI",
			"SC",
			"SD",
			"TN",
			"TX",
			"UT",
			"VT",
			"VI",
			"VA",
			"WA",
			"WV",
			"WI",
			"WY",
		],
	},
	last_lived_there: {
		type: "date",
		label: "Last Lived There",
	},
	rent_own: {
		type: "select",
		label: "Rent or Own ?",
		options: ["Rent", "Own"],
	},
	lived_at_this_address_for: {
		type: "input",
		label: "Lived at this address for?",
		validations: ["min1"],
		uppercase: true,
		client_required: true,
	},
	landlord_name: {
		type: "input",
		label: "(If Rent) Landlord Name",
		validations: ["min1"],
		uppercase: true,
	},
	landlord_phone: {
		type: "input",
		label: "(If Rent) Landlord Phone",
		validations: ["min1"],
		uppercase: true,
	},

	// EMPLOYMENT
	employed_by: {
		type: "input",
		label: "Employed By",
		validations: ["min1"],
		client_required: true,
	},
	occupation_title: {
		type: "input",
		label: "Occupation / Title",
		validations: ["min1"],
		client_required: true,
	},
	compensation: {
		type: "input",
		label: "Compensation",
		validations: ["min1"]
	},
	how_long: {
		type: "input",
		label: "How Long",
		validations: ["min1"],
		client_required: true,
	},
	employers_phone: {
		type: "input",
		label: "Work Phone",
		validations: ["min1"],
		client_required: true,
	},
	employers_address: {
		type: "input",
		label: "Employer Address",
		validations: ["min1"],
		uppercase: true,
		client_required: true,
	},
	superior: {
		type: "input",
		label: "Superior",
		validations: ["min1"],
		client_required: true,
	},
	superior_phone: {
		type: "input",
		label: "Superior Phone",
		validations: ["min1"],
		client_required: true,
	},

	// VEHICLE
	has_vehicle: {
		type: "radio",
		label: "Do you have a vehicle?",
		options: ["Yes", "No"],
		client_required: true,
	},
	vehicle_make: {
		type: "input",
		label: "Vehicle Make",
		validations: ["min1"],
		client_required: true,
	},
	model: {
		type: "input",
		label: "Model",
		validations: ["min1"],
		client_required: true,
	},
	vehicle_year: {
		type: "input",
		label: "Year",
		validations: ["min1"],
		client_required: true,
	},
	color: {
		type: "input",
		label: "Color",
		validations: ["min1"],
		client_required: true,
	},
	lic: {
		type: "input",
		label: "LIC#",
		validations: ["min1"],
		client_required: true,
	},
	license_plate_state: {
		type: "select",
		label: "License Plate State",
		options: stateList,
		client_required: true,
	},
	vehicle_financed: {
		type: "select",
		label: "Vehicle Financed",
		options: ["Yes", "No"],
		client_required: true,
	},
	where_financed: {
		type: "input",
		label: "(If yes) Where Financed",
		validations: ["min1"],
		client_required: true,
	},
	how_much_owned: {
		type: "input",
		label: "(If yes) How much Owned",
		validations: ["min1"],
		client_required: true,
	},

	// ATTORNEY
	defendants_attorney_name: {
		type: "input",
		label: `Defendant Attorney's Name`,
		uppercase: true,
	},
	defendants_attorney_address: {
		type: "input",
		label: `Defendant Attorney's Address`,
		uppercase: true,
	},
	defendants_attorney_phone: {
		type: "input",
		label: `Defendant Attorney's Phone`,
	},

	// FAMILY
	spouse_how_long: {
		type: "input",
		label: "Spouse for how long?",
		client_required: true,
	},

	spouse_dob: {
		type: "date",
		label: "Spouse Date of Birth",
		validations: ["min1"],
	},

	spouse_employer: {
		type: "input",
		label: `Spouse's employer`,
	},
	front_id_photo: {
		type: "photo",
		label: "Front ID Photo",
		maxCount: 1,
		client_required: true,
	},
	back_id_photo: {
		type: "photo",
		label: "Back ID Photo",
		maxCount: 1,
		client_required: true,
	},
	defendant_photos: {
		type: "photo",
		label: "3 recent photos of defendant",
		maxCount: 3,
		client_required: true,
	},
	defendant_holding_id_photo: {
		type: "photo",
		label: "Close Up Picture of Defendant Holding ID Photo",
		maxCount: 1,
		client_required: true,
	},
	front_credit_card_photo: {
		type: "photo",
		label: "Credit Card Front Photo",
		maxCount: 1,
	},
	back_credit_card_photo: {
		type: "photo",
		label: "Credit Card Back Photo",
		maxCount: 1,
	},
	proof_of_income: {
		type: "file",
		label: "Proof Of Income",
		maxCount: 1,
	},
	proof_of_address: {
		type: "file",
		label: "Proof Of Address",
		maxCount: 1,
	},
	selfie_photo: {
		type: "photo",
		label: "Selfie Photo",
		maxCount: 1,
	},
	defendant_holding_power_photo: {
		type: "photo",
		label: "Photo of Defendant Holding Power Number",
		maxCount: 1,
	},
	general_notes: {
		type: "text",
		label: "Notes",
		validations: ["min1"]
	},
	lead_notes: {
		type: "text",
		label: "Leada Notes",
		validations: ["min1"]
	}
};

[
	"spouse",
	"ex_parent_1",
	"ex_parent_2",
	"ex_parent_3",
	"mother",
	"father",
	"spouse_mother",
	"spouse_father",
	"defendant_brother",
	"defendant_sister",
	"defendant_brother",
	"bestfriend",
].forEach((z) => {
	const ff = capitalizeAll(z.replace("_", " "));
	fields[`${z}_name`] = {
		type: "input",
		label: `Name`,
		uppercase: true,
		client_required: z === "spouse" ? true : false
	};
	fields[`${z}_address`] = {
		type: "input",
		label: `Address`,
		uppercase: true,
		client_required: z === "spouse" ? true : false
	};
	fields[`${z}_phone`] = {
		type: "input",
		label: `Phone`,
		client_required: z === "spouse" ? true : false
	};
});

["ex_parent_1", "ex_parent_2", "ex_parent_3"].forEach((z) => {
	const ff = capitalizeAll(z.replace("_", " "));
	fields[`${z}_has_children`] = {
		type: "radio",
		label: "Children",
		options: ["Yes", "No"],
	};
	fields[`${z}_children`] = {
		type: "object",
	};
});

["reference_1", "reference_2", "reference_3", "reference_4"].forEach((z) => {
	fields[`${z}_name`] = {
		type: "input",
		label: "Name",
		validations: ["min1"],
		client_required: true,
	};
	fields[`${z}_relationship`] = {
		type: "input",
		label: "Relationship",
		validations: ["min1"],
		client_required: true,
	};
	fields[`${z}_dob_or_age`] = {
		type: "input",
		label: "DOB or Approximate Age",
		validations: ["min1"],
	};
	fields[`${z}_phone`] = {
		type: "input",
		label: "Phone",
		validations: ["min1", "max10", "no_letters"],
		client_required: true,
	};
	fields[`${z}_address`] = {
		type: "input",
		label: "Address",
		validations: ["min1"],
	};
	fields[`${z}_city`] = {
		type: "input",
		label: "City",
		validations: ["min1"],
	};
	fields[`${z}_state`] = {
		type: "select",
		label: "State",
		options: stateList,
	};
	fields[`${z}_country`] = {
		type: "input",
		label: "Country",
		validations: ["min1"],
	};
	fields[`${z}_social_media`] = {
		type: "input",
		label: "Social Media",
		validations: ["min1"],
	};
});

fields.rreferences = {
	type: "object",
};

export default fields;

const initialDat = {};
Object.keys(fields).forEach((field) => {
	if (field === "rreferences") {
		initialDat[field] = [];
	} else if (
		field.includes("ex_parent") &&
		field.includes("children") &&
		!field.includes("has_children")
	) {
		initialDat[field] = [];
	} else if (fields[field].type === "photo" || fields[field].type === "file") {
		initialDat[field] = [];
	} else {
		initialDat[field] = "";
	}
});

export const initialData = initialDat;
