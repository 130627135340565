import React from "react";
import ReactTable from "react-table";
import { formatDate2 } from "shared/utility";

const BookingsList = (props) => {
	const { bookingData, setFormData, setAction, setOpenForm } = props;
	const dataList = bookingData
		? bookingData.map((l, key) => {
				// map users to table rows
				return {
					key: key + 1,
					id: l.id,
					date_created: formatDate2(l.date_created),
					date_modified: formatDate2(l.date_modified),
					date_to_appear: formatDate2(l.date_to_appear),
					time_to_appear: l.time_to_appear,
					booking: l.booking,
					bookingData: l,
					jail_court: l.jail_court,
					where_held: l.where_held,
					charges: l.charges,
				};
		  })
		: [];

	const columns =
		props.action === "Client Edit"
			? [
					{
						Header: "#",
						accessor: 'key',
					},
					{
						Header: "Date To Appear",
						accessor: "date_to_appear",
					},
			  ]
			: [
					{
						Header: "Date Created",
						accessor: "date_created",
					},
					// {
					// 	Header: "Date to Appear",
					// 	accessor: "date_to_appear",
					// },
					// {
					// 	Header: "Time to Appear",
					// 	accessor: "time_to_appear",
					// },
					{
						Header: "Booking",
						accessor: "booking",
					},
					{
						Header: "Jail or Court",
						accessor: "jail_court",
					},
					{
						Header: "Where Held",
						accessor: "where_held",
					}
			  ];

	return (
		<div className="table-adjust2 table-scroll">
			<ReactTable
				filterable
				className="-striped -highlight"
				showPaginationTop
				showPaginationBottom
				resizable={false}
				defaultPageSize={10}
				pageSizeOptions={[10, 20, 30]}
				data={dataList}
				columns={columns}
				getTrProps={(state, rowInfo) => {
					if (rowInfo && rowInfo.row) {
						return {
							onClick: (e) => {
								if (rowInfo) {
									if (rowInfo.original) {
										props.setIndex(rowInfo.index);
										setAction("Edit");

										setFormData(rowInfo.original.bookingData);
										setOpenForm(true);
									}
								}
							},
						};
					} else {
						return {};
					}
				}}
			/>
		</div>
	);
};

export default BookingsList;
