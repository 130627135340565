import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import setAuthToken from 'shared/setAuthToken';
import { logoutUser } from 'store/actions/user';

class Logout extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    localStorage.removeItem('token');
    localStorage.removeItem('client-token');
    // remove auth header for future request
    setAuthToken(false);
    this.props.logoutUser();
  }

  render() {
    // const loc = this.props.origin == 'none' || this.props.origin == null ? '' : '/?o='+origin
    const loc = '';
    const origin = `/pages/login-page${loc}`;
    // return <div></div>
    return <Redirect to={origin} />
  }
}

const mapStateToProps = (state) => {
  return {
    origin: state.auth.origin
  }
}

export default connect(mapStateToProps, { logoutUser })(Logout);
