import { Close } from "@material-ui/icons";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { NavLink } from "react-router-dom";
import * as actions from "store/actions";
import ClipLoader from "react-spinners/ClipLoader";
import {
	clientLogout,
	getClientID, getIndemnitorId
} from "../../../shared/clientAuthValidation";

const ClientDefendants = (props) => {
	const [defendantData, setDefendantData] = useState(null);
	const [bookingData, setBookingData] = useState([]);
	const [redirect, setRedirect] = useState(false);

	const [action, setAction] = useState("Client Edit");

	const [id, setId] = useState(null);
	const [clientTab, setClientTab] = useState("info");


	// Error/Success Modal
	const [openedMessageModal, setOpenedMessageModal] = useState(false);


	useEffect(() => {
		const clientID = getClientID();
		console.log('clientID', clientID);
		props.getDefendant(clientID);
		props.getBookings(clientID);
	}, []);

	useEffect(() => {
		if (props.defendant) {
			setDefendantData(props.defendant);

			if (props.defendant.length > 0) {
				setId(props.defendant[0].id);
			}
		}
	}, [props.defendant]);

	useEffect(() => {
		if (props.booking.length > 0) {
			const bookings = props.booking;
			const mostRecent = new Date(Math.max.apply(null, bookings.map( e => {
				return new Date(e.date_created);
			 })));
			for (let i = 0; i < props.booking.length; i++) {
				if (props.booking[i].date_created === mostRecent) {
					setBookingData(props.booking);
					break;
				}
			}
			setBookingData(props.booking[0]);
		}
	}, [props.booking]);

	useEffect(() => {
		if (props.success || props.error) {
			if (
				props.success !== "Successfully got defendant" &&
				props.success !== "Successfully got defendants"
			) {
				setOpenedMessageModal(true);
			}
		}
	}, [props.success, props.error]);


	useEffect(() => {
		window.scrollTo(0, 0);
	}, [clientTab]);

	console.log(bookingData, defendantData)

	if (redirect) return <Redirect to="/client/booking_info" />;

	return (
		<div style={{backgroundColor:'white'}}>
			<div>
				{(!bookingData) && <div>
					<ClipLoader />
				</div>}
				{(bookingData) &&
					<GridContainer style={{ padding: '25px' }}>
						<GridItem><h1><strong>{bookingData.booking_name}</strong> Booking Info</h1></GridItem>
						<GridItem xs={12}>
							<h4><strong>Bail Amount:</strong></h4>

							<p>{bookingData.bail_amount}</p>
						</GridItem>
						<GridItem xs={12}>
							<h4><strong>Charges:</strong></h4>
							{(bookingData.charges) && bookingData.charges.map((ch, i) => {
								return (
									<>
										<p><strong>Code:</strong> {ch.code}</p>
										<p><strong>Type:</strong> {ch.type}</p>
									</>
								);
							})}
						</GridItem>
						<GridItem xs={12}>
							<h4><strong>Court Dates and Times:</strong></h4>
							{(bookingData.court_times) && bookingData.court_times.map((ct, i) => {
								return (
									<>
										<p><strong>Date:</strong> {ct.date}</p>
										<p><strong>Time:</strong> {ct.time}</p>
									</>
								);
							})}
						</GridItem>
						<GridItem xs={12}>
							<h4><strong>Premium Amount:</strong></h4>
							<p>{bookingData.premium_amount}</p>
						</GridItem>
						<GridItem xs={12}>
							<h4><strong>Amount Paid Down:</strong></h4>
							<p>{bookingData.amount_paid_down}</p>
						</GridItem>
						<GridItem xs={12}>
							<h4><strong>Unpaid Balance:</strong></h4>
							<p>{bookingData.unpaid_balance}</p>
						</GridItem>
						<GridItem xs={12}>
							<h4><strong>Payment 1</strong></h4>
							<p><strong>Date:</strong> {bookingData.payment_date_1}</p>
							<p><strong>Amount:</strong> {bookingData.payment_amount_1}</p>
						</GridItem>
						<GridItem xs={12}>
							<h4><strong>Payment 2</strong></h4>
							<p><strong>Date:</strong> {bookingData.payment_date_2}</p>
							<p><strong>Amount:</strong> {bookingData.payment_amount_2}</p>
						</GridItem>
						<GridItem xs={12}>
							<h4><strong>Payment 3</strong></h4>
							<p><strong>Date:</strong> {bookingData.payment_date_3}</p>
							<p><strong>Amount:</strong> {bookingData.payment_amount_3}</p>
						</GridItem>
						<GridItem xs={12}>
							<h4><strong>Payment 4</strong></h4>
							<p><strong>Date:</strong> {bookingData.payment_date_4}</p>
							<p><strong>Amount:</strong> {bookingData.payment_amount_4}</p>
						</GridItem>
						<GridItem xs={12}>
							<h4><strong>Other Payment Schedule/ Conditions</strong></h4>
							<p>{bookingData.other_conditions}</p>
						</GridItem>
						<GridItem xs={12}>
							<h4><strong>Recurring Charges</strong></h4>
							<p>{bookingData.recurring_charges}</p>
						</GridItem>
					</GridContainer>
				}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		error: state.crm.error,
		success: state.crm.success,
		defendantsData: state.crm.defendantsData,
		defendant: state.crm.defendant,
		booking: state.crm_booking.data,
		defendant_id: state.client.defendant_id,
		loggedInStatus: state.client.loggedInStatus,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {

		getDefendant: (id) => {
			dispatch(actions.getDefendant(id));
		},

		getBookings: (defendant_id) => {
			dispatch(actions.getBookings(defendant_id));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientDefendants);
