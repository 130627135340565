import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
	error: null,
	success: null,
	historiesData: {},
	history: null,
};

// MESSAGES
const clearHistoryMessages = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

// GET_HISTORIES

const getHistoriesStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getHistoriesSuccess = (state, action) => {
	return updateObject(state, {
		success: "Successfully gotten histories",
		historiesData: action.response,
	});
};

const getHistoriesFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// GET HISTORY BY ID
const getHistoryStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getHistorySuccess = (state, action) => {
	return updateObject(state, {
		success: "Successfully gotten history",
		history: action.response,
	});
};

const getHistoryFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_HISTORY_MESSAGES:
			return clearHistoryMessages(state, action);

		case actionTypes.GET_HISTORIES_START:
			return getHistoriesStart(state, action);
		case actionTypes.GET_HISTORIES_SUCCESS:
			return getHistoriesSuccess(state, action);
		case actionTypes.GET_HISTORIES_FAIL:
			return getHistoriesFail(state, action);

		case actionTypes.GET_HISTORY_START:
			return getHistoryStart(state, action);
		case actionTypes.GET_HISTORY_SUCCESS:
			return getHistorySuccess(state, action);
		case actionTypes.GET_HISTORY_FAIL:
			return getHistoryFail(state, action);

		default:
			return state;
	}
};

export default reducer;
