import React from "react";
import PropTypes from "prop-types";
import { loginUser } from "../../store/actions/user";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import { qs } from "../../shared/utility";
import Notification from "../../components/Notification/Notification";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import LockOpen from "@material-ui/icons/LockOpen";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import TextFieldGroup from "../Components/textFieldGroup";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import { loginClient } from "../../store/actions/client";
import isClientLoggedIn from "../../shared/clientAuthValidation";
import { clearCRMMessages, resendSmsToDefendant } from "../../store/actions";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel } from "@material-ui/core";

class ClientLoginPage extends React.Component {
	constructor(props) {
		super(props);
		// we use this to make the card to appear after the page has been rendered
		this.state = {
			cardAnimaton: "",
			phone: "",
			otp: "",
			ind: false,
			defendant_id: "",
			display_data: "",
			errors: {},
			openedMessageModal: false,
			redirect: false,
		};

		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onSubmit(e) {
		e.preventDefault();
		const loginCredentials = {
			phone: this.state.phone,
			otp: this.state.otp,
			ind: this.state.ind,
			defendant_id: this.state.defendant_id,
			display_data: this.state.display_data
		};
		this.props.dispatch(loginClient(loginCredentials));
	}

	componentDidUpdate(prevProps) {
		if (prevProps.message !== this.props.message) {
			if (isClientLoggedIn() && this.props.message === "Logged in") {
				console.log("client is logged in");
				this.setState({ redirect: true });
			}
		}
	}

	componentDidMount() {
		if (this.props.location.search) {
			const query = qs(this.props.location.search);
			if (query.hasOwnProperty("otp") && query.hasOwnProperty("phone") && query.hasOwnProperty("ind")) {
				const loginCredentials = {
					phone: query.phone,
					otp: query.otp,
					ind: query.ind === "true" ? true : false,
					defendant_id: query.def,
					display_data: query.display_data === "true" ? true : false
				};
				console.log('login creds', loginCredentials)
				localStorage.removeItem("client-token");
				this.setState(loginCredentials);
				this.props.dispatch(loginClient(loginCredentials));
			}
		}

		if (isClientLoggedIn()) {
			this.setState({ redirect: true });
		}
	}
	render() {
		const { classes, origin, loading, loggedInStatus, message } = this.props;
		if (this.state.redirect) {
			console.log(this.state.display_data)
			if (this.state.display_data) {
				return <Redirect to="/c/booking_info" />;
			} else {
				return <Redirect to="/c/dashboard" />;
			}
			
		}

		return (
			<div className={classes.content}>
				<div className={classes.container}>
					<GridContainer justify="center">
						<GridItem xs={12} sm={6} md={4}>
							<form onSubmit={this.onSubmit}>
								<Card login className={classes[this.state.cardAnimaton]}>
									<CardHeader
										className={`${classes.cardHeader} ${classes.textCenter}`}
										color="primary"
									>
										<h4 className={classes.cardTitle}>Client Log in</h4>
										<h1 className={classes.cardTitle}>
											Greatest Bail Bonds Portal
										</h1>
										<div className={classes.socialLine}>
											{[].map((prop, key) => {
												return (
													<Button
														color="transparent"
														justIcon
														key={key}
														className={classes.customButtonClass}
													>
														<i className={prop} />
													</Button>
												);
											})}
										</div>
									</CardHeader>
									<CardBody>
										<TextFieldGroup
											placeholder="10 digit phone number (no country code)"
											name="phone"
											value={this.state.phone}
											type="number"
											onChange={this.onChange}
										/>
										<TextFieldGroup
											placeholder="Access Code"
											name="otp"
											value={this.state.otp}
											onChange={this.onChange}
											uppercase={true}
										/>
										<TextFieldGroup
											placeholder="Defendant ID"
											name="defendant_id"
											value={this.state.defendant_id}
											onChange={this.onChange}
											uppercase={true}
										/>

										<FormControlLabel
											control={
												<Checkbox
													checked={this.state.ind}
													onChange={() => {
														this.setState({ ind: !this.state.ind });
													}}
													color="default"
												/>
											}
											label={"I am an Indemnitor."}
										/>
									</CardBody>
									<CardFooter className={classes.justifyContentCenter}>
										{loading && (
											<FadeLoader
												sizeUnit={"px"}
												size={150}
												color={"#123abc"}
												loading={true}
											/>
										)}

										{!loading && (
											<Button
												color="success"
												simple
												size="lg"
												block
												type="submit"
												onClick={this.onSubmit}
											>
												LOG IN
											</Button>
										)}
									</CardFooter>
									{message && (
										<div style={{ marginLeft: "10px", marginRight: "10px" }}>
											<br />
											<SnackbarContent message={message} close color="danger" />
										</div>
									)}

									{message === "Access Code expired." && (
										<Button
											simple
											onClick={() => {
												this.props.dispatch(
													resendSmsToDefendant({
														phone: this.state.phone,
														otp: this.state.otp,
														ind: this.state.ind
													})
												);
												this.setState({ openedMessageModal: true });
											}}
											color="danger"
										>
											Send a new code
										</Button>
									)}

									{(this.props.success || this.props.error) && (
										<Notification
											open={this.state.openedMessageModal}
											message={
												<h4>{`${this.props.success || this.props.error}`}</h4>
											}
											close={() => {
												this.setState({ openedMessageModal: false });
												this.props.dispatch(clearCRMMessages());
											}}
										/>
									)}
								</Card>
							</form>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		);
	}
}

ClientLoginPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		origin: state.client.origin,
		loading: state.client.loading,
		message: state.client.message,
		loggedInStatus: state.client.loggedInStatus,
		success: state.crm.success,
		error: state.crm.error,
	};
};

export default compose(
	withStyles(loginPageStyle),
	connect(mapStateToProps, null)
)(ClientLoginPage);
