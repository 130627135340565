import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const toInputUppercase = e => {
  e.target.value = ("" + e.target.value).toUpperCase();
};

const TextFieldGroup = (props) => {
  const {
    placeholder,
    name,
    value,
    error,
    info,
    type,
    onChange,
    disabled,
    onInput
  } = props;
  return (
    <div className="form-group">
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onInput={props.uppercase ? toInputUppercase : (e) => { } }
        disabled={disabled}
        style={{width:'100%',display:'block',padding:'20px',margin:'10px auto'}}
      />
      {info && <small className="form-text text-muted">{info}</small>}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

TextFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.string
}

TextFieldGroup.defaultProps = {
  type: 'text'
}

export default TextFieldGroup;
