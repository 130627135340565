import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import ReactDOM from "react-dom";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Button from "../../components/CustomButtons/Button.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
// core components
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyleNoOverflow.jsx";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import Table from "components/Table/Table.jsx";

import { swapKeyValues } from 'shared/utility';
import { permsList, permsDict } from 'shared/permsObj';
import combineStyles from "shared/combineStyles";

const styles2 = theme => ({
  dialogCustomizedWidth: {
    'max-width': '80%'
  }
});

class UserPermsModal extends Component  {
  constructor(props) {
    super(props);

    this.state = {
      selectedUsers: props.userList.map(user => {
        return { id: user.id, name: user.name }
      }),
      selectedUsersID: props.userList.map(user => user.id),
      submitted: false
    }
  }

  submitBulkAdd = () => {
    this.setState({
      submitted: true
    });
    
    // convert bulkObj
    let perms = [];
    Object.keys(this.props.bulkObj).forEach(perm_id => {
      if (this.props.bulkObj[perm_id][0] === null) {
        perms.push({
          perm_id,
          db_id: null
        });
      } else {
        this.props.bulkObj[perm_id].forEach(db => {
          perms.push({
            perm_id,
            db_id: this.props.DBs[db]
          });
        });
      }
    });

    let removes = [];
    Object.keys(this.props.removeObj).forEach(perm_id => {
      if (this.props.removeObj[perm_id][0] === null) {
        removes.push({
          perm_id,
          db_id: null
        });
      } else {
        this.props.removeObj[perm_id].forEach(db => {
          removes.push({
            perm_id,
            db_id: this.props.DBs[db]
          });
        });
      }
    });
    
    this.props.bulkAssign(this.state.selectedUsers, perms, removes);
  }

  renderBulkPermissions = () => {
    const bulkObj = this.props.bulkObj;
    const dbDict = this.props.DBs;
    const bulks = Object.keys(bulkObj).map((perm_id,i) => {
      const p = permsDict[perm_id];
      let str = '';
      if (bulkObj[perm_id][0] !== null) {
        bulkObj[perm_id].forEach(p => str += `${p} `)
      }
      return (
        <div
          className="green-text"
          key={`fii${i}`}
          style={{marginBottom:'10px'}}
        >
          {p.main} - {p.subgroup} - {p.name} {str ? <div style={{fontSize:'10px'}}>`({str})`</div> : ''}
        </div>
      )
    });
    return bulks.length > 0 ? <div><h4><strong>Permissions to be assigned</strong></h4>{bulks}</div> : <div><h4><strong>Permissions to be assigned</strong></h4><div>None</div></div>;
  }

  renderRemovePermissions = () => {
    const removeObj = this.props.removeObj;
    const dbDict = this.props.DBs;
    const removes = Object.keys(removeObj).map((perm_id,i) => {
      const p = permsDict[perm_id];
      let str = '';
      if (removeObj[perm_id][0] !== null) {
        removeObj[perm_id].forEach(p => str += `${p} `)
      }
      return (
        <div
          className="red-text"
          key={`fii${i}`}
          style={{marginBottom:'10px'}}
        >
          {p.main} - {p.subgroup} - {p.name} {str ? <div style={{fontSize:'10px'}}>`({str})`</div> : ''}
        </div>
      )
    });
    return removes.length > 0 ? <div><h4><strong>Permissions to be removed</strong></h4>{removes}</div> : <div><h4><strong>Permissions to be removed</strong></h4><div>None</div></div>;
  }

  render() {
    const props = this.props, { classes } = this.props;
    
    const selectAll = <Checkbox
      tabIndex={-1}
      checkedIcon={<Check className={classes.checkedIcon} />}
      icon={<Check className={classes.uncheckedIcon} />}
      classes={{checked: classes.checked}}
      checked={this.state.selectedUsers.length === this.props.userList.length}
      onClick={() => {
        if (this.state.selectedUsers.length === this.props.userList.length) {
          this.setState({
            selectedUsers: [],
            selectedUsersID: []
          })
        } else {
          this.setState({
            selectedUsers: props.userList.map(user => { return {id: user.id, name: user.name} }),
            selectedUsersID: props.userList.map(user => { return user.id })
          })
        }
      }}
    />
    const tableData = this.props.userList.map(user => [
      user.name,
      user.office,
      user.department,
      <Checkbox
        tabIndex={-1}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{checked: classes.checked}}
        checked={this.state.selectedUsersID.indexOf(user.id) !== -1}
        onClick={() => {
          const index = this.state.selectedUsersID.indexOf(user.id);
          if (index !== -1) {
            this.setState({
              selectedUsers: this.state.selectedUsers.filter((user,i) => {
                if (i === index) {
                  return false;
                }
                return true;
              }),
              selectedUsersID: this.state.selectedUsersID.filter((user,i) => {
                if (i === index) {
                  return false;
                }
                return true;
              })
            });
          } else {
            this.setState({
              selectedUsers: this.state.selectedUsers.concat([{ id:user.id, name:user.name }]),
              selectedUsersID: this.state.selectedUsersID.concat([user.id]),
            });
          }
        }}
      />
    ]);
    return (
      <Dialog
          classes={{
              root: classes.center,
              paper: classes.modal
          }}
          style={{overflow:'hidden !important'}}
          open={props.open}
          keepMounted
          fullWidth={true}
          fullScreen
          classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
          className="dialogo"
          onClose={() => {
            props.close()
          }}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description">
          <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}>
              <span><span className="text-center" style={{fontSize:'20px',textAlign:'center'}}><strong>CONFIRM USERS FOR BULK ASSIGNMENT</strong></span>
              <Button
                  justIcon
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => {
                    props.close();
                  }}
              >
                  <Close className={classes.modalClose} />
              </Button></span>
          </DialogTitle>
          <div style={{padding:'20px'}}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <div style={{marginBottom:'20px'}}>Select All - {selectAll}</div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                {!this.state.submitted && <Button
                  color="success"
                  onClick={this.submitBulkAdd}
                >SUBMIT BULK ASSIGN/REMOVE</Button>}
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                {this.state.submitted && <div style={{textAlign:'center',padding:'20px',fontSize:'20px'}}>
                  <div className="green-text">{this.props.bulkStatus}</div>
                  <div style={{marginTop:'20px'}}>
                    <Button
                      color="rose"
                      onClick={() => {
                        props.close();
                        props.reload();
                      }}
                    >OK</Button>
                  </div>
                </div>}
                {!this.state.submitted && <div>
                  <h4><strong>Users</strong></h4>
                  <div className="maxscroll600">
                    <Table
                      tableHeaderColor="primary"
                      tableHead={["Name", "Office", "Department", ""]}
                      tableData={tableData}
                      coloredColls={[3]}
                      colorsColls={["primary"]}
                    />
                  </div>
                </div>}
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <div className="maxscroll600">
                  {this.renderBulkPermissions()}
                  {this.renderRemovePermissions()}
                </div>
              </GridItem>
            </GridContainer>
          </div>
    </Dialog>
    )
  }
}

const mapStateToProps = state => {
  return {
    bulkStatus: state.userPerms.bulkStatus
  }
}

const combinedStyle = combineStyles(styles, modalStyle, styles2);
export default connect(mapStateToProps, null)(withStyles(combinedStyle)(UserPermsModal));
