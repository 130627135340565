import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from "components/CustomButtons/Button.jsx";
import DialogTitle from '@material-ui/core/DialogTitle';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInputValidate";
import Table from "components/Table/Table";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";

const dict = {
    R: 'Read only',
    F: 'Full access (Update, Delete)',
    E: 'Edit (Update only)'
}

const Modal = (props) => {
    const [users, setUsers] = useState([]); // already shared
    const [search, setSearch] = useState('');
    const [userType, setUserType] = useState(null);

    useEffect(() => {
        props.getAdminUsers();
        console.log(props.defendant_id)
        props.getDefendantUsers(props.defendant_id);
        // if (user) setUserType(user.type);
    }, []);

    useEffect(() => {
        const users = [];
        if (props.defendantUsers && props.defendantUsers.length > 0) {
            for (let i = 0; i < props.defendantUsers.length; i++) {
                const defendantUserId = props.defendantUsers[i].user_id;
                const accessId = props.defendantUsers[i].id;
                const type = props.defendantUsers[i].access_type;
                if (props.userData && props.userData.length > 0) {
                    for (let i = 0; i < props.userData.length; i++) {
                        const userId = props.userData[i].id;
                        if (userId === defendantUserId) {
                            const user = {
                                id: props.userData[i].id,
                                access_id: accessId,
                                name: props.userData[i].name,
                                access_type: type
                            }
                            users.push(user);
                        }
                    }
                }
            }
        }
        setUsers(users);

    }, [props.defendantUsers])

    const usersArr = users ? users.sort((a, b) => (a.name) ? 1 : -1).map((u, i) => {
        console.log(u.access_type)
        return [
            (userType === 'A' && u.user_id === 1) ? 'illi' : u.name,
            dict[u.access_type],
            <div className="red-text hoverable"
                onClick={() => {
                    if (props.action !== 'Add') {
                        props.deleteDefendantUsers(u.access_id);
                        setTimeout(() => {
                            props.getDefendantUsers(props.defendant_id);
                        }, 1000);
                    }
                }}
            ><Close /></div>
        ]
    }) : [];
    

    return (
        <Dialog
        open={props.openShares}
        keepMounted
        onClose={props.close}
        maxWidth="md"
        fullWidth={true}
    >
        <DialogTitle>
            <div style={{textAlign:'center'}}>Allow other users access to defendant.</div>
        </DialogTitle>
            
        <div style={{textAlign:'center',padding:20}}>
        <GridContainer className='enclosure' >
            <GridItem xs={12} sm={6} md={6}>
                <h4 className="bold">Users this Defendant {props.action === 'Add' ? 'will be' : 'is'} shared with</h4>
                <div className="illi-table">
                    <Table
                        tableHeaderColor="primary"
                        tableHead={[<strong>Name</strong>, <strong>Access</strong>, <strong>Remove</strong>]}
                        tableData={usersArr}
                    />
                    {usersArr.length === 0 && <div className="text-center"
                        style={{ marginTop: 20 }}><em>None</em></div>}
                </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                    label="Search Users"
                    value={search}
                    onChange={(e) => { setSearch(e.target.value) }}
                />
                <div style={{ padding: 20 }}>
                    {props.userData ?
                        props.userData.filter(f => {
                            let alreadyShared = false;
                            users.forEach(u => {
                                if (u.id === f.id) {
                                    alreadyShared = true;
                                }
                            })
                            if (f.id === 2) return false;
                            if (f.id === 1 && userType === 'E') return false;
                            if (alreadyShared) return false;
                            if (!search) return true;
                            const lowered = f.name.toLowerCase();
                            const s = search.toLowerCase();
                            if (lowered.indexOf(s) === 0) {
                                return true;
                            }
                            return false;
                        })
                            .sort((a, b) => (a.name > b.name) ? 1 : -1)
                            .map((m, i) => {
                                const user_id = m.id
                                const name = m.name;
                                return <div className="tiny-box" key={i}>
                                    <div><span>{name}</span></div>
                                    <Button
                                        color="primary"
                                        size="sm"
                                        style={{ marginRight: 20 }}
                                        onClick={(e) => {
                                            const newShare = {
                                                user_id: user_id,
                                                defendant_id: props.defendant_id,
                                                access_type: 'F'
                                            }

                                            props.createDefendantUsers(newShare)
                                            setTimeout(() => {
                                                props.getDefendantUsers(props.defendant_id);
                                            }, 1000);
                                        }}><Add /> Full</Button>
                                    <Button
                                        color="primary"
                                        size="sm"
                                        style={{ marginRight: 20 }}
                                        onClick={(e) => {
                                            const newShare = {
                                                user_id: user_id,
                                                defendant_id: props.defendant_id,
                                                access_type: 'E'
                                            }

                                            props.createDefendantUsers(newShare)
                                            setTimeout(() => {
                                                props.getDefendantUsers(props.defendant_id);
                                            }, 1000);
                                        }}><Add /> Edit</Button>
                                    <Button
                                        color="primary"
                                        size="sm"
                                        style={{ marginRight: 20 }}
                                        onClick={() => {
                                            const newShare = {
                                                user_id: user_id,
                                                defendant_id: props.defendant_id,
                                                access_type: 'R'
                                            }

                                            props.createDefendantUsers(newShare)
                                            setTimeout(() => {
                                                props.getDefendantUsers(props.defendant_id);
                                            }, 1000);
                                        }}><Add /> Read</Button>
                                </div>
                            })
                        :
                        null}
                </div>
            </GridItem>
            <Button
                color="white"
                style={{marginRight:10}}
                onClick={() => {
                    props.setOpenShares(false);
                    props.setNoMessage(false);
                    props.close();
                }}
            >
                Close
            </Button>
        </GridContainer>
        </div>
        </Dialog>
    )
}

export default Modal;