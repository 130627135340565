import * as actionTypes from './actionTypes';

// LOGIN USER
export const loginClient = (client) => {
    return {
        type: actionTypes.CLIENT_AUTH_REQUEST,
        client
    }
}

export const loginClientSuccess = (client) => {
    return {
        type: actionTypes.CLIENT_AUTH_SUCCESS,
        client
    }
}

export const loginClientFail = (err) => {
    return {
        type: actionTypes.CLIENT_AUTH_FAILURE,
        err
    }
}

export const logoutClient = () => {
  return {
    type: actionTypes.CLIENT_AUTH_LOGOUT
  }
}

// SEND SMS
export const sendSmsToDefendant = (data) => ({
	type: actionTypes.SEND_SMS_TO_DEFENDANT,
	data,
})

export const sendSmsToDefendantStart = () => ({
	type: actionTypes.SEND_SMS_TO_DEFENDANT_START,
})

export const sendSmsToDefendantSuccess = (response) => ({
	type: actionTypes.SEND_SMS_TO_DEFENDANT_SUCCESS,
	response,
})

export const sendSmsToDefendantFail = (err) => ({
	type: actionTypes.SEND_SMS_TO_DEFENDANT_FAIL,
	err,
})

// RESEND SMS
export const resendSmsToDefendant = (data) => ({
	type: actionTypes.RESEND_SMS_TO_DEFENDANT,
	data,
})

export const resendSmsToDefendantStart = () => ({
	type: actionTypes.RESEND_SMS_TO_DEFENDANT_START,
})

export const resendSmsToDefendantSuccess = (response) => ({
	type: actionTypes.RESEND_SMS_TO_DEFENDANT_SUCCESS,
	response,
})

export const resendSmsToDefendantFail = (err) => ({
	type: actionTypes.RESEND_SMS_TO_DEFENDANT_FAIL,
	err,
})

// SEND_SMS_ONLY 
export const sendSmsOnly = (data) => {
    return {
        type: actionTypes.SEND_SMS_ONLY,
        data
    }
}
export const sendSmsOnlyStart = () => {
    return {
        type: actionTypes.SEND_SMS_ONLY_START
    }
}
export const sendSmsOnlySuccess = (response) => {
    return {
        type: actionTypes.SEND_SMS_ONLY_SUCCESS,
        response
    }
}
export const sendSmsOnlyFail = (err) => {
    return {
        type: actionTypes.SEND_SMS_ONLY_FAIL,
        err
    }
}