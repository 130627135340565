import { Close } from "@material-ui/icons";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { NavLink } from "react-router-dom";
import * as actions from "store/actions";
import Notification from "../../../components/Notification/Notification";
import IndemnitorsForm from "../Indemnitors/IndemnitorsForm";
import {
	clientLogout,
	getClientID, getIndemnitorId
} from "../../../shared/clientAuthValidation";
import DefendantsForm from "../Defendants/DefendantsForm";
import version from "../../../shared/version";

const ClientDefendants = (props) => {
	const [defendantData, setDefendantData] = useState(null);
	const [bookingData, setBookingData] = useState([]);
	const [indemnitorData, setIndemnitorData] = useState([]);
	const [arrestData, setArrestData] = useState([]);
	const [redirect, setRedirect] = useState(false);
	const [showIndemnitorTab, setShowIndemnitorTab] = useState(false);
	const [hasLoadedIndemnitor, setHasLoadedIndemnitor] = useState(false);
	const [requiredFieldsComplete, setRequiredFieldsComplete] = useState(false);

	const [sendInfo, setSendInfo] = useState('');
	const [sendType, setSendType] = useState('');
	const [sendName, setSendName] = useState('');
	const [sendNotification, setSendNotification] = useState({
		messageType: "",
		additional_info: "",
		send_to: "",
	})
	const [sendOptions, setSendOptions] = useState([]);
	const [sendOptionsObj, setSendOptionsObj] = useState([]);
	const [additionalNumber, setAdditionalNumber] = useState('');
	const [openedReminderModal, setOpenedReminderModal] = useState(false);
	const [reminderData, setReminderData] = useState(null);
	const [listIndemnitors, setListIndemnitors] = useState(null);

	const [allBookings, setAllBookings] = useState([]);
	const [bookingOptions, setBookingOptions] = useState([]);

	const [action, setAction] = useState("Client Edit");

	const [id, setId] = useState(null);
	const [openedMenu, setOpenedMenu] = useState(false);
	const [clientTab, setClientTab] = useState("info");
	const [clientIndemnitor, setClientIndemnitor] = useState(null);

	// Tracking updateDataObj
	const [unsavedWarning, setUnsavedWarning] = useState(false);
	const [openedWaning, setOpenedWarning] = useState(false);

	// Error/Success Modal
	const [openedMessageModal, setOpenedMessageModal] = useState(false);

	const { getDefendant, updateDefendant } = props;

	useEffect(() => {
		const clientID = getClientID();
		console.log('clientID', clientID);
		props.getDefendant(clientID);
		props.getIndemnitors(clientID);
		props.getBookings(clientID);
	}, []);

	useEffect(() => {
		if (props.defendant) {
			setDefendantData(props.defendant);

			if (props.defendant.length > 0) {
				setId(props.defendant[0].id);
			}
		}
	}, [props.defendant]);

	useEffect(() => {
		if (props.success || props.error) {
			if (
				props.success !== "Successfully got defendant" &&
				props.success !== "Successfully got defendants"
			) {
				setOpenedMessageModal(true);
			}
		}
	}, [props.success, props.error]);


	useEffect(() => {
		window.scrollTo(0, 0);
	}, [clientTab]);

	useEffect(() => {
		const indemnitor_id = getIndemnitorId();
		if (indemnitor_id && props.indemnitorDa.data) {
			for (let i = 0; i < props.indemnitorDa.data.length; i++) {
				if (props.indemnitorDa.data[i].id === indemnitor_id) {
					if (!hasLoadedIndemnitor) {
						console.log('redirecting....');
						setClientIndemnitor(props.indemnitorDa.data[i]);
						setClientTab("indemnitor");
						setShowIndemnitorTab(true);
						setHasLoadedIndemnitor(true);
					}

				}
			}
		}
	}, [props.indemnitorDa]);

	const notificationCheck = () => {
		console.log(requiredFieldsComplete)
		if (props.success && requiredFieldsComplete === false) {
			return `${props.success}`;
		} else if (props.success && requiredFieldsComplete === true) {
			return "Information Saved, Check your email to Digitally Sign.";
		} else {
			return props.error;
		}
	};

		const firstName = defendantData ? defendantData[0].first_name : "";
		const lastName = defendantData ? defendantData[0].last_name : "";

		if (redirect) return <Redirect to="/client" />;

		return (
			<div className="grid-no-margin">
				<div className="top-dweller">
					<GridContainer>
						<GridItem xs={8}>
							<div>
								<strong>DEFENDANT {`${firstName} ${lastName}`}</strong>
							</div>
						</GridItem>
						<GridItem xs={4}>
							<div style={{ textAlign: "right" }}>
								{!openedMenu && (
									<i
										className="fas fa-bars font-22 hoverable"
										onClick={() => {
											setOpenedMenu(true);
										}}
									></i>
								)}
								{openedMenu && (
									<span
										className="hoverable"
										onClick={() => {
											setOpenedMenu(false);
										}}
									>
										<Close />
									</span>
								)}
							</div>
						</GridItem>
					</GridContainer>
					{openedMenu && (
						<div>
							<hr />
							<GridContainer>
								<GridItem xs={12}>
									<div style={{ textAlign: "right" }}>
										<div
											className="sub-menu"
											onClick={() => {
												setOpenedMenu(false);
												setClientTab("info");
											}}
										>
											Defendant Info
										</div>
										{showIndemnitorTab &&
											<div
												className="sub-menu"
												onClick={() => {
													setOpenedMenu(false);
													setClientTab("indemnitor");
												}}
											>
												Indemnitor Info
											</div>
										}
										<div
											className="sub-menu"
											onClick={() => {
												setOpenedMenu(false);
												setClientTab("arrests");
											}}
										>
											Arrests
										</div>
										<div
											className="sub-menu"
											onClick={() => {
												setOpenedMenu(false);
												clientLogout();
											}}
										>
											Log out
										</div>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					)}
				</div>
				<GridContainer
					style={{ backgroundColor: "white", margin: "61px auto 0px" }}
				>
					<GridItem
						xs={12}
						sm={12}
						md={12}
						style={{ textAlign: "right", marginTop: 30, marginRight: 30 }}
					></GridItem>

					<GridItem xs={12} sm={12} md={12}>
						<DefendantsForm
							action={action}
							defendantData={defendantData}
							bookingData={bookingData}
							setBookingData={setBookingData}
							updateDefendant={updateDefendant}
							clientTab={clientTab}
							setUnsavedWarning={setUnsavedWarning}
							defendantID={id}
							indemnitorData={indemnitorData}
							setIndemnitorData={setIndemnitorData}
							arrestData={arrestData}
							setArrestData={setArrestData}
							getIndemnitors={props.getIndemnitors}
							indemnitorDa={clientIndemnitor}
							allIndemnitors={props.indemnitorDa.data}
							requiredFieldsComplete={requiredFieldsComplete}
							setRequiredFieldsComplete={setRequiredFieldsComplete}

							sendInfo={sendInfo}
							setSendInfo={setSendInfo}
							sendType={sendType}
							setSendType={setSendType}
							sendName={sendName}
							setSendName={setSendName}

							sendNotification={sendNotification}
							setSendNotification={setSendNotification}
							sendOptions={sendOptions}
							sendOptionsObj={sendOptionsObj}
							setSendOptions={setSendOptions}
							setSendOptionsObj={setSendOptionsObj}
							allBookings={allBookings}
							setAllBookings={setAllBookings}
							bookingOptions={bookingOptions}

							additionalNumber={additionalNumber}
							setAdditionalNumber={setAdditionalNumber}
							listIndemnitors={listIndemnitors}
							setListIndemnitors={setListIndemnitors}
						/>
					</GridItem>

					{/* Error, success modal */}
					{props.success !== "Successfully got indemnitors" &&
						(props.success || props.error) && (
							<Notification
								open={openedMessageModal}
								message={<h4>{notificationCheck()}</h4>}
								close={() => {
									if (props.error === "Unauthorized") {
										window.location.href = '/client';
										return;
									}
									setOpenedMessageModal(false);
									console.log('DIS defendant -->', defendantData, props.success);
									if (defendantData && (props.success === 'Successfully updated defendant')) {
										if (defendantData.length > 0) {
											if (defendantData[0].id) {
												getDefendant(defendantData[0].id);
											}
										}
									}
									props.clearCRMMessages();
								}}
							/>
						)}

					<GridItem
						xs={12}
						sm={12}
						md={12}
					>
						<div style={{ padding: '100px 20px', float: 'right' }}>
							<small>&copy; Greatest Bail Bonds, v.{version}</small>
						</div>
					</GridItem>
				</GridContainer>
			</div>
		);
	};

	const mapStateToProps = (state) => {
		return {
			error: state.crm.error,
			success: state.crm.success,
			defendantsData: state.crm.defendantsData,
			defendant: state.crm.defendant,
			indemnitorDa: state.crm.indemnitorsData,
			defendant_id: state.client.defendant_id,
			loggedInStatus: state.client.loggedInStatus,
		};
	};

	const mapDispatchToProps = (dispatch) => {
		return {
			clearCRMMessages: () => {
				dispatch(actions.clearCRMMessages());
			},

			getDefendant: (id) => {
				dispatch(actions.getDefendant(id));
			},

			updateDefendant: (data, photoUpdate, photoUpdateElement, pdfData) => {
				console.log('updating')
				dispatch(actions.updateDefendant(data, photoUpdate, photoUpdateElement, pdfData));
			},

			getIndemnitors: (defendant_id) => {
				dispatch(actions.getIndemnitors(defendant_id));
			},

			getBookings: (defendant_id) => {
				dispatch(actions.getBookings(defendant_id));
			}
		};
	};

	export default connect(mapStateToProps, mapDispatchToProps)(ClientDefendants);
