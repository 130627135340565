import { put } from "redux-saga/effects";
import axios from "../axios/axios-client";

import * as actions from "../actions/client";

export function* loginClientSaga(action) {
	try {
		const response = yield axios.post("verifyOTP", action.client);
		yield put(actions.loginClientSuccess(response.data));
	} catch (err) {
		if (err.response && err.response.data) {
			yield put(actions.loginClientFail(err.response.data));
		} 
	}
}

export function* logoutClientFailSaga(action) {}
