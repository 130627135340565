import React from "react";
import { useState, useEffect } from "react";
import Table from "components/Table/Table.jsx";
import SortButton from "components/SortButton/SortButton";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Cached from "@material-ui/icons/Cached";
import moment from "moment";
import Datetime from "react-datetime";
import Tooltip from "@material-ui/core/Tooltip";
import { getUserPerms } from "../../../shared/authValidation";

const List = (props) => {
	const userPerms = getUserPerms();
	const isAdmin = userPerms ? 
			userPerms.hasOwnProperty('CRM admin') ? 
				true
			:
				false
		: 
			false;
	const { rows, offset } = props;
	const page_num = Math.ceil((offset + 1) / rows);
	const page_count = Math.ceil(props.count / rows);
	const pages_links = isNaN(page_count)
		? null
		: Array(page_count)
				.fill()
				.map((element, index) => index + 1)
				.map((num) => {
					if (page_count > 10) {
						const four = page_num + 4;
						const four2 = page_num - 4;
						if (num > four || num < four2) {
							return null;
						}
					}
					return (
						<span
							onClick={() => {
								props.setOffset((num - 1) * rows);
							}}
							className={
								num === page_num ? "tiny-box current" : "hoverable tiny-box"
							}
							key={num}
							style={{
								marginRight: -1,
							}}
						>
							{num}
						</span>
					);
				});
	const [dataArr, setDataArr] = useState([]);
	const showBack = page_num === 1 ? false : true;
	const showForward = page_num === page_count ? false : true;

	const renderNav = () => {
		return (
			<div className="text-center">
				{props.data.length > 0 && (
					<p style={{ paddingTop: "10px" }}>
						Showing {offset + 1} to {offset + props.data.length} of{" "}
						{props.count} lead(s)
					</p>
				)}
				{props.data.length > 0 && (
					<div style={{ marginTop: 20 }}>
						<span
							onClick={() => {
								props.setOffset(0);
							}}
							style={{borderRight: !showBack ? '1px solid #ccc' : '0'}}
							className="hoverable tiny-box">
							First
						</span>
						<span
							onClick={() => {
								if (showBack) {
									props.setOffset((page_num - 1 - 1) * rows);
								}
							}}
							style={{ opacity: showBack ? 1 : 0.2, marginRight: -1 }}
							className={showBack ? "hoverable tiny-box" : "tiny-box"}
						>
							Previous
						</span>
						{pages_links}
						{page_count > 10 ? ' . . . ' : ''}
						<span
							onClick={() => {
								if (showForward) {
									props.setOffset((page_num + 1 - 1) * rows);
								}
							}}
							style={{ opacity: showForward ? 1 : 0.2, marginRight: -1 }}
							className={showForward ? "hoverable tiny-box" : "tiny-box"}
						>
							Next
						</span>
						<span
							onClick={() => {
								props.setOffset((page_count - 1) * rows);
							}}
							style={{borderLeft:'1px solid #ccc'}}
							className="hoverable tiny-box">
							Last
						</span>
					</div>
				)}
			</div>
		);
	};

	useEffect(() => {
		if (props.data) {
			setDataArr(
				props.data.map((cd, i) => {
					const open = () => {
						props.getDefendant(cd.id);
					};
					let row = [
						offset + (i + 1),
						<div
							className="hoverable"
							onClick={() => {
								open();
								props.setAction("Edit");
								props.setData(cd);
								props.setBookingData(() => {
									if (cd.lead_data){
										return cd.lead_data;
									} else {
										return props.initialBookingData;
									}
								});
								props.setOpenedModal(true);
							}}
						>
							{cd.first_name}
						</div>,
						<div
							className="hoverable"
							onClick={() => {
								open();
								props.setAction("Edit");
								props.setData(cd);
								props.setBookingData(() => {
									if (cd.lead_data){
										return cd.lead_data;
									} else {
										return props.initialBookingData;
									}
								});
								props.setOpenedModal(true);
							}}
						>
							{cd.last_name}
						</div>,
						cd.email,
						moment(cd.date_created).format("MM/DD/YYYY h:mm:ss a"),
					];
					if (isAdmin) row.push(cd.created_by_name);
					return row;
				})
			);
		}
	}, [props.data]);

	const setSort = (sortBy, sortDirection) => {
		props.setSortData({
			sortBy,
			sortDirection,
		});
	};

	return (
		<div>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					props.getLeads();
				}}
			>
				<GridContainer>
					<GridItem xs={12} sm={8} md={8}>
						<h3 style={{ display: "inline-block" }}>Leads</h3>
						<Button
							justIcon
							round
							simple
							onClick={() => {
								props.getLeads();
							}}
							color="info"
						>
							
							<Tooltip
                                title="Refresh"
                                placement="right"
                                style={{ display: "inline-block" }}
                            >
								<Cached />
                            </Tooltip>

						</Button>
					</GridItem>
					<GridItem xs={12} sm={2} md={2}>
						<Button color="warning" type="submit" className="outlined-btn mr-20">
							SEARCH
						</Button>
					</GridItem>
					<GridItem xs={12} sm={2} md={2}>
						<Button
							color="primary"
							onClick={() => {
								props.resetFilters();
							}}
							className="outlined-btn"
						>
							CLEAR
						</Button>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<CustomInput
							label="Search by First Name"
							value={props.filters.first_name}
							onChange={(e) => {
								props.setFilters("first_name", e.target.value);
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<CustomInput
							label="Search by Phone"
							value={props.filters.phone}
							onChange={(e) => {
								props.setFilters("phone", e.target.value);
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<CustomInput
							label="Search by Case #"
							value={props.filters.case_number}
							onChange={(e) => {
								props.setFilters("case_number", e.target.value);
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<div style={{paddingTop: 25}}>
                            <Datetime
                                inputProps={{
                                    placeholder: "Search by Arrest Date",
                                    readOnly: true,
                                }}
                                closeOnSelect={true}
                                onChange={(e) => {
                                    if (typeof e === "object") {
                                        const stringDate = e.format("MM/DD/YYYY");
                                        props.setFilters("arrest_when", stringDate);
                                    } else {
                                        props.setFilters("arrest_when", e);
                                    }
                                }}
                                timeFormat={false}
                                value={
                                    props.filters.arrest_when
                                        ? props.filters.arrest_when
                                        : ""
                                }
                            />
						</div>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<CustomInput
							label="Search by Last Name"
							value={props.filters.last_name}
							onChange={(e) => {
								props.setFilters("last_name", e.target.value);
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<CustomInput
							label="Search by Booking #"
							value={props.filters.booking}
							onChange={(e) => {
								props.setFilters("booking", e.target.value);
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={3} md={3}>
						<CustomInput
							label="Search by Power #"
							value={props.filters.power_of_attorney_number}
							onChange={(e) => {
								props.setFilters("power_of_attorney_number", e.target.value);
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						{renderNav()}
					</GridItem>
				</GridContainer>
			</form>
			{props.data.length !== 0 && (
            <div className="illi-table">
				<Table
					tableHead={[
						"#",
						<strong>
							First Name
							<SortButton
								setSort={setSort}
								sortBy="first_name"
								sortDirection={props.sortDirection}
								currentSortBy={props.sortBy}
							/>
						</strong>,
						<strong>
							Last Name
							<SortButton
								setSort={setSort}
								sortBy="last_name"
								sortDirection={props.sortDirection}
								currentSortBy={props.sortBy}
							/>
						</strong>,
						<strong>
							Email
							<SortButton
								setSort={setSort}
								sortBy="email"
								sortDirection={props.sortDirection}
								currentSortBy={props.sortBy}
							/>
						</strong>,
						<strong>
							Created
							<SortButton
								setSort={setSort}
								sortBy="date_created"
								sortDirection={props.sortDirection}
								currentSortBy={props.sortBy}
							/>
						</strong>,
						isAdmin ? <strong>
							Created By
						</strong> : null
					]}
					tableData={dataArr}
				/>
            </div>
			)}
			<div className="text-center">
				<em>{props.data.length === 0 ? "No leads found" : ""}</em>
			</div>
			<GridContainer>
				<GridItem xs={12} sm={12} md={3}>
					<CustomSelect
						label="Rows per page"
						options={[10, 15, 20, 25, 30]}
						choose={(e, n) => {
							props.setOffset(0);
							props.setRows(e);
						}}
						default={props.rows}
					/>
				</GridItem>
			</GridContainer>
			<div>{renderNav()}</div>
		</div>
	);
};

export default List;