import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import PagesHeader from "components/Header/PagesHeader.jsx";
import Footer from "components/Footer/Footer.jsx";

import clientRoutes from "../routes/client";

import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";

import bgImage from "assets/img/register.jpeg";
import isClientLoggedIn from '../shared/clientAuthValidation';

// var ps;

class Client extends React.Component {

  componentDidUpdate(){
    if (isClientLoggedIn()) {
      console.log('////////login redirect/////////')
      window.location.href = "/c/dashboard"
    }
  }

  render() {
    const { classes, ...rest } = this.props;

    

    return (
      <div>
        {/* <PagesHeader {...rest} /> */}
        <div className={classes.wrapper} ref="wrapper">
          <div className={classes.fullPage}>
            <Switch>
              {clientRoutes.map((prop, key) => {
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
            </Switch>
            <Footer white />
            <div
              className={classes.fullPageBackground}
              style={{ backgroundImage: "url(" + bgImage + ")" }}
            />
          </div>
        </div>
      </div>
    );
  }
}

Client.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(pagesStyle)(Client);
