import Dashboard from "views/Dashboard/Dashboard.jsx";
import Buttons from "views/Components/Buttons.jsx";
import GridSystem from "views/Components/GridSystem.jsx";
import Panels from "views/Components/Panels.jsx";
import SweetAlert from "views/Components/SweetAlert.jsx";
import Notifications from "views/Components/Notifications.jsx";
import Icons from "views/Components/Icons.jsx";
import Typography from "views/Components/Typography.jsx";
import RegularForms from "views/Forms/RegularForms.jsx";
import ExtendedForms from "views/Forms/ExtendedForms.jsx";
import ValidationForms from "views/Forms/ValidationForms.jsx";
import Wizard from "views/Forms/Wizard.jsx";
import RegularTables from "views/Tables/RegularTables.jsx";
import ExtendedTables from "views/Tables/ExtendedTables.jsx";
import ReactTables from "views/Tables/ReactTables.jsx";
import GoogleMaps from "views/Maps/GoogleMaps.jsx";
import FullScreenMap from "views/Maps/FullScreenMap.jsx";
import VectorMap from "views/Maps/VectorMap.jsx";
import Charts from "views/Charts/Charts.jsx";
import Calendar from "views/Calendar/Calendar.jsx";
import Widgets from "views/Widgets/Widgets.jsx";
import UserProfile from "views/Pages/UserProfile.jsx";
import TimelinePage from "views/Pages/Timeline.jsx";
import RTLSupport from "views/Pages/RTLSupport.jsx";
import SAPPage from "views/Pages/SAP.jsx";
import PortalPermissions from "views/Pages/PortalPermissions.jsx";
import UserAdminPage from "views/Pages/UserAdminPage.jsx";
import Maintenance from "views/Pages/Maintenance.jsx";
import MaintenancePage from "views/Pages/MaintenancePage.jsx";
import SettingsPage from "views/Pages/SettingsPage.jsx";
import PowerNumbersPage from "../views/Pages/PowerNumbersPage.jsx";
import AgentAreaPage from "../views/Pages/AgentAreaPage.jsx";
import CRMDefendantsPage from "../views/Pages/CRMDefendantsPage.jsx";
import PaymentsPage from "../views/Pages/PaymentsPage.jsx";
import PayrollPage from "../views/Pages/PayrollPage.jsx";
import ClientsPage from "../views/Pages/ClientsPage.jsx";
import userIsLoggedIn from "shared/authValidation";
import pagesRoutes from "./pages.jsx";
import CRMHistoryPage from "../views/Pages/CRMHistoryPage.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Build from "@material-ui/icons/Build";
import Image from "@material-ui/icons/Image";
import Apps from "@material-ui/icons/Apps";
import ContentPaste from "@material-ui/icons/ContentPaste";
import GridOn from "@material-ui/icons/GridOn";
import Place from "@material-ui/icons/Place";
import WidgetsIcon from "@material-ui/icons/Widgets";
import Timeline from "@material-ui/icons/Timeline";
import DateRange from "@material-ui/icons/DateRange";
import checkPermissions from "shared/checkPermissions";
import ClientDashboard from "../views/Pages/ClientDashboard.jsx";
import checkClientPermission from "../shared/checkClientPermissions.js";

const dashRoutes = () => {
  const userAdminPermitted = checkPermissions('UserAdmin');
  const userPermissionsPermitted = checkPermissions('UserPerms');
  const developerPermitted = checkPermissions('Developer');
  const crmPermitted = checkPermissions('CRM');

  return [
    {
      path: "/c/dashboard",
      name: "Defendant Information",
      icon: DashboardIcon,
      component: ClientDashboard,
      permitted: checkClientPermission(),
    },
    { redirect: true, path: "/client", pathTo: "/client/dashboard", name: "Client Dashboard" }
  ];
}
export default dashRoutes;
