import React, { useEffect, useState } from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { Dialog } from "@material-ui/core";
import { clone } from "shared/utility";
import CustomInput from "components/CustomInput/CustomInputValidate";
import { required } from "shared/validation";
import Button from "../../../components/CustomButtons/Button";
import Table from "components/Table/Table";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import { checkEmpty, stateList } from "../../../shared/utility";

const DefendantsFormChildren = (props) => {
	const initialData = {
		name: "",
		age: "",
		sex: "",
	};
	const [childrenData, setChildrenData] = useState([]);
	const [index, setIndex] = useState(-1);
	const [openAskBeforeDelete, setOpenAskBeforeDelete] = useState(false);
	const { updateDataObj, data, childField } = props;

	useEffect(() => {
		if (props.has_child === "Yes" && checkEmpty(data[childField])) {
			const newChildrenData = clone(data[childField]);
			newChildrenData.push(initialData);
			updateDataObj(childField, newChildrenData);
		}
	}, [props.has_child])

	useEffect(() => {
		if (!Array.isArray(data[childField])) {
			let cData = [];
			try {
				cData = JSON.parse(data[childField]);
			} catch (e) {}
			setChildrenData(cData);
		} else {
			setChildrenData(data[childField]);
		}
	}, [data[childField]]);

	const renderElement = (index, field, label, isRequired, uppercase) => {
		return (
			<CustomInput
				value={childrenData[index][field]}
				label={label}
				onChange={(e) => {
					const newData = clone(data[childField]);
					newData[index][field] = e.target.value;
					// setFormData(newData);
					updateDataObj(childField, newData);
				}}
				validate={isRequired ? [required] : []}
				uppercase={uppercase ? true : false}
			/>
		);
	};

	const checkValidity = (data) => {
		const name = data.name ? data.name.trim() : "";
		const age = data.age ? data.age.trim() : "";

		if (name && age) return true;
		return false;
	};

	return (
		<div style={{ width: "100%" }}>
			{props.action !== "History View" && (
				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
						<Button
							color="primary"
							onClick={() => {
								let valid = true;
								data[childField].forEach((child) => {
									if (!checkValidity(child)) {
										valid = false;
									}
								});
								if (valid) {
									const newChildrenData = clone(data[childField]);
									newChildrenData.push(initialData);
									updateDataObj(childField, newChildrenData);
								}
							}}
						>
							<i className="fas fa-plus"></i> ADD CHILD
						</Button>
					</GridItem>
				</GridContainer>
			)}
			{childrenData &&
				Array.isArray(childrenData) &&
				childrenData.map((c, i) => {
					return (
						<GridContainer key={`${i}-child`}>
							<GridItem xs={12} sm={12} md={12}>
								<h4 className="bold">CHILD {i + 1}</h4>
							</GridItem>
							{props.action !== "History View" && (
								<GridItem xs={12} sm={12} md={12}>
									<Button
										color="danger"
										onClick={() => {
											setOpenAskBeforeDelete(true);
											setIndex(i);
										}}
									>
										DELETE CHILD {i + 1}
									</Button>
								</GridItem>
							)}
							<GridItem xs={12} sm={4} md={4}>
								{renderElement(i, "name", "Name", true, true)}
							</GridItem>
							<GridItem xs={12} sm={4} md={4}>
								{renderElement(i, "age", "Age", true)}
							</GridItem>
							<GridItem xs={12} sm={4} md={4}>
								{renderElement(i, "sex", "Sex")}
							</GridItem>
						</GridContainer>
					);
				})}

			{openAskBeforeDelete && (
				<Dialog
					maxWidth="sm"
					fullWidth={true}
					open={openAskBeforeDelete}
					onClose={() => {
						setOpenAskBeforeDelete(false);
					}}
				>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12}>
							<div className="dialog-wrapper text-center">
								<h4>Are you sure you want to delete this child?</h4>
								<Button
									color="danger"
									className="mr-20"
									onClick={() => {
										updateDataObj(
											childField,
											data[childField].filter((dr, j) => {
												if (j === index) return false;
												return true;
											})
										);
										setOpenAskBeforeDelete(false);
									}}
								>
									OK DELETE
								</Button>
								<Button
									color="white"
									onClick={() => {
										setOpenAskBeforeDelete(false);
									}}
								>
									CANCEL
								</Button>
							</div>
						</GridItem>
					</GridContainer>
				</Dialog>
			)}
		</div>
	);
};

export default DefendantsFormChildren;
