import isUserLoggedIn from './authValidation';
import isClientLoggedIn from './clientAuthValidation';

const checkClientPermission = () => {
  const permissions = isClientLoggedIn();
  
  if (!permissions) {
    window.location.href = '/client';
    // section = section ? section : '';
    // if (noLogin) {
    //   //window.location.reload(true);
    // }
    return false;
  }
  
  // } else {
  //   if (section) {
  //     const permissionFound = permissions.indexOf(section);
  //     if (permissionFound === -1) {
  //       return false;
  //     }
  //   }
  // }
  return true;
}

export default checkClientPermission;
