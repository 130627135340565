import * as actionTypes from "./actionTypes";

// ARRESTS

// CLEAR ARREST MESSAGES 
export const clearArrestMessages = () => {
    return {
        type: actionTypes.CLEAR_ARREST_MESSAGES
    }
}

// GET_ARRESTS
export const getArrests = (data) => {
	return {
		type: actionTypes.GET_ARRESTS,
		data,
	};
};
export const getArrestsStart = () => {
	return {
		type: actionTypes.GET_ARRESTS_START,
	};
};
export const getArrestsSuccess = (response) => {
	return {
		type: actionTypes.GET_ARRESTS_SUCCESS,
		response,
	};
};
export const getArrestsFail = (err) => {
	return {
		type: actionTypes.GET_ARRESTS_FAIL,
		err,
	};
};

// CREATE_ARREST
export const createArrest = (data) => {
	return {
		type: actionTypes.CREATE_ARREST,
		data,
	};
};
export const createArrestStart = () => {
	return {
		type: actionTypes.CREATE_ARREST_START,
	};
};
export const createArrestSuccess = (response) => {
	return {
		type: actionTypes.CREATE_ARREST_SUCCESS,
		response,
	};
};
export const createArrestFail = (err) => {
	return {
		type: actionTypes.CREATE_ARREST_FAIL,
		err,
	};
};

// UPDATE ARREST
export const updateArrest = (data) => ({
	type: actionTypes.UPDATE_ARREST,
	data,
});

export const updateArrestStart = () => ({
	type: actionTypes.UPDATE_ARREST_START,
});

export const updateArrestSuccess = (response) => ({
	type: actionTypes.UPDATE_ARREST_SUCCESS,
	response,
});

export const updateArrestFail = (err) => ({
	type: actionTypes.UPDATE_ARREST_FAIL,
	err,
});

// GET ARREST BY ID
export const getArrest = (data) => ({
	type: actionTypes.GET_ARREST,
	data,
});

export const getArrestStart = () => ({
	type: actionTypes.GET_ARREST_START,
});

export const getArrestSuccess = (response) => ({
	type: actionTypes.GET_ARREST_SUCCESS,
	response,
});

export const getArrestFail = (err) => ({
	type: actionTypes.GET_ARREST_FAIL,
	err,
});

// DELETE ARREST
export const deleteArrest = (data) => ({
	type: actionTypes.DELETE_ARREST,
	data,
});

export const deleteArrestStart = () => ({
	type: actionTypes.DELETE_ARREST_START,
});

export const deleteArrestSuccess = (response) => ({
	type: actionTypes.DELETE_ARREST_SUCCESS,
	response,
});

export const deleteArrestFail = (err) => ({
	type: actionTypes.DELETE_ARREST_FAIL,
	err,
});
