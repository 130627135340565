import * as actionTypes from "./actionTypes";


// GET_HISTORIES
export const getHistories = (data) => {
	return {
		type: actionTypes.GET_HISTORIES,
		data,
	};
};
export const getHistoriesStart = () => {
	return {
		type: actionTypes.GET_HISTORIES_START,
	};
};
export const getHistoriesSuccess = (response) => {
	return {
		type: actionTypes.GET_HISTORIES_SUCCESS,
		response,
	};
};
export const getHistoriesFail = (err) => {
	return {
		type: actionTypes.GET_HISTORIES_FAIL,
		err,
	};
};


// GET HISTORY BY ID
export const getHistory = (data) => {
	return {
		type: actionTypes.GET_HISTORY,
		data,
	};
};
export const getHistoryStart = () => {
	return {
		type: actionTypes.GET_HISTORY_START,
	};
};
export const getHistorySuccess = (response) => {
	return {
		type: actionTypes.GET_HISTORY_SUCCESS,
		response,
	};
};
export const getHistoryFail = (err) => {
	return {
		type: actionTypes.GET_HISTORY_FAIL,
		err,
	};
};