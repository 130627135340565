const permsObj = {
  Admin: {
    'User Permissions': [
        { id:1, name:'Edit', subCategory:'User Permissions', showLabel:false}
      ],
    'User Admin': [
        { id:2, name:'Edit', subCategory:'User Admin', showLabel:false}
      ],
    'SMS Template': [
        { id:5, name:'Edit', subCategory:'SMS Template', showLabel:false}
      ]
  },
  CRM: {
    'CRM': [
      { id:3, name:'Edit', subCategory:'CRM', showLabel:false},
      {id:4, name:'Admin', subCategory:'CRM', showLabel:false}
    ]
  }
}

const permsDictionary = {};
Object.keys(permsObj).forEach((main) => {
  Object.keys(permsObj[main]).forEach((subgroup) => {
    permsObj[main][subgroup].forEach((perm) => {
      permsDictionary[perm.id] = {
        subgroup,
        main,
        name: perm.name,
        hasDBs: perm.hasDBs
      }
    });
  });
});

export const permsDict = permsDictionary;

export const permsList = {
  hasDBs: Object.keys(permsDictionary).filter(perm_id => permsDictionary[perm_id].hasDBs ? true : false),
  noDBs: Object.keys(permsDictionary).filter(perm_id => permsDictionary[perm_id].hasDBs ? false : true)
}

export default permsObj;
