import React from 'react';
import { Dialog } from '@material-ui/core';
import Button from '../CustomButtons/Button';
import { Close } from '@material-ui/icons';

const NotificationAsk = (props) => {
    return <Dialog 
      open={props.open}
      keepMounted  
      maxWidth="sm" 
      fullWidth={true}
    >
      {!props.no_ok_button && <div><span className="close-btn" onClick={() => { props.close(); }}><Close /></span></div>}

      <div style={{padding:'0px 20px 40px'}} className="text-center">
        {props.title ? <h3>{props.title}</h3> : ''}
        {props.message}
        <div className="mt-20">
            <Button color="primary"
                className="mr-20"
                onClick={() => {
                    if (props.success) {
                        props.success();
                    }
                }}
            >
                {props.success_button_text ? props.success_button_text : 'OK'}
            </Button>
            {!props.no_ok_button && <Button color="white" onClick={() => { props.close(); }}>
                {props.fail_button_text ? props.fail_button_text : 'CANCEL'}
            </Button>}
        </div>
      </div>
    </Dialog>
  }

  export default NotificationAsk;