import { put } from 'redux-saga/effects';
import axios from '../axios/axios-user';
import * as actions from '../actions/user';

export function* getAdminUsersSaga(action) {
  yield put(actions.getAdminUsersStart());
  try {
      const response = yield axios.get('/');
      yield put(actions.getAdminUsersSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getAdminUsersFail(e.response.data));
          }
        }
      yield put(actions.getAdminUsersFail(e));
  }
}

export function* createUserSaga(action) {
  yield put(actions.createUserStart());
  try {
      const response = yield axios.post('/', action.data);
      yield put(actions.createUserSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.createUserFail(e.response.data));
          }
        }
      yield put(actions.createUserFail(e));
  }
}

export function* deleteUserSaga(action) {
  yield put(actions.deleteUserStart());
  try {
      const response = yield axios.delete('/?id='+action.data);
      yield put(actions.deleteUserSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.deleteUserFail(e.response.data));
          }
        }
      yield put(actions.deleteUserFail(e));
  }
}

export function* updateUserSaga(action) {
  yield put(actions.updateUserStart());
  try {
      const response = yield axios.put('/', action.data);
      yield put(actions.updateUserSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.updateUserFail(e.response.data));
          }
        }
      yield put(actions.updateUserFail(e));
  }
}