import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import Notification from "../../components/Notification/Notification";
import Table from "components/Table/Table.jsx";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import { clone } from "shared/utility";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import CustomRadio from "components/CustomRadio/CustomRadio";


class NotificationsAdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationData: null,
      openedModal: false,
      openedAskDelete: false,
      openedAdd: false,
      notification: null,
      openAlert: false,
      notificationPage: true,
      smsConfigPage: false,
    }
  }

  componentDidMount() {
    this.props.getSmsConfig();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.success !== this.props.success) {
      if (this.props.success) return this.setState({ notification: this.props.success })
    }
    if (prevProps.error !== this.props.error) {
      if (this.props.error) return this.setState({ notification: this.props.error })
    }
    if (prevProps.sms !== this.props.sms) {
      if (this.props.sms) {
        if (this.props.sms.length > 0) {
          const sms = this.props.sms[0];
          this.setState({ sms_content: sms.content ? sms.content : '' })
        }
      }
    }
  }

  render() {
    console.log('SettingsPage', this.props)
    let smsNotificationData = [];
    const getSmsData = () => {
      this.props.sms.map((sms, i) => {
        if (sms.type == '1') {
          smsNotificationData.push(sms);
        }
      })
    };
    let smsConfigData = [];
    const getConfigData = () => {
      this.props.sms.map((sms, i) => {
        if (sms.type == '2') {
          smsConfigData.push(sms);
        }
      })
    };
    getConfigData();
    getSmsData();
    const notification = this.state.notification;
    const smsPermitted = checkPermissions('SMS');
    const notificationData = this.state.notificationData;
    const openAlert = this.state.openAlert;
    const notificationPage = this.state.notificationPage;
    const smsConfigPage = this.state.smsConfigPage;
    if (!smsPermitted) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <div style={{maxWidth:1200}}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button
              style={{ marginBottom: '25px', marginRight: '20px' }}
              color={notificationPage ? "success" : "primary"}
              onClick={() => {
                this.setState({
                  notificationPage: true,
                  smsConfigPage: false
                });
              }}
            >
              Notifications
            </Button>
            <Button
              style={{ marginBottom: '25px' }}
              color={smsConfigPage ? "success" : "primary"}
              onClick={() => {
                this.setState({
                  smsConfigPage: true,
                  notificationPage: false,
                });
              }}
            >
              SMS Config
            </Button>

            {notificationPage && <div>
              <Button
                style={{ marginBottom: '25px' }}
                color="primary"
                onClick={() => {
                  this.setState({
                    openedAdd: true,
                    notificationData: {
                      description: null,
                      content: null,
                      type: '1',
                    }
                  });
                }}
              >
                <i className="fas fa-plus"></i> Add New
              </Button>
              <div className="notification-table">
                <Table
                  tableHeaderColor="primary"
                  tableHead={["ID", "Description", "Content", "Actions"]}
                  tableData={smsNotificationData.map((sms, i) => {
                    return [sms.id, sms.description, <div style={{ whiteSpace: 'pre-wrap' }}>{sms.content}</div>, <div>
                      <>
                        <Button
                          color="primary"
                          onClick={() => {
                            this.setState({
                              openedModal: true,
                              notificationData: sms
                            });
                          }}
                        >
                          <i className="fas fa-edit"></i> EDIT
                        </Button>
                      </>,
                      <>
                        <Button
                          color="danger"
                          onClick={() => {
                            this.setState({
                              openedAskDelete: true,
                              notificationData: sms
                            });
                          }}
                        >
                          <i className="fas fa-trash"></i> Delete
                        </Button>

                      </>
                    </div>
                    ]
                  })}
                />
              </div>

            </div>}

            {smsConfigPage && <div>
              <div className="notification-table">
                <Table
                  tableHeaderColor="primary"
                  tableHead={["ID", "Description", "Content", "Actions"]}
                  tableData={smsConfigData.map((sms, i) => {
                    return [sms.id, sms.description, <div style={{ whiteSpace: 'pre-wrap' }}>{sms.content}</div>, <div>
                      <>
                        <Button
                          color="primary"
                          onClick={() => {
                            this.setState({
                              openedModal: true,
                              notificationData: sms
                            });
                          }}
                        >
                          <i className="fas fa-edit"></i> EDIT
                        </Button>
                      </>,
                    </div>
                    ]
                  })}
                />
              </div>

            </div>}
          </GridItem>

        </GridContainer>

        <Dialog
          open={this.state.openedModal}
          onClose={() => {
            this.setState({
              openedModal: false
            });
          }}
          maxWidth="md"
          fullWidth={true}
        >
          <div style={smsPermitted ? {} : { display: 'none' }}>
            <h3 style={{ paddingLeft: '25px' }}><strong>SMS Template</strong></h3>
            {openAlert && <h5 style={{ color: 'red', paddingLeft: '25px' }}>This Description is already being used. Please rename.</h5>}
            <GridContainer style={{ padding: '25px' }}>
              <GridItem xs={12} sm={12} md={12}>
                <div style={{ paddingBottom: '25px' }}>
                  <CustomInput
                    label="Description"
                    value={notificationData && notificationData.description ? notificationData.description : ''}
                    onChange={(e, n) => {
                      const newData = clone(notificationData)
                      newData.description = e.target.value;
                      this.setState({
                        notificationData: newData
                      })
                    }}
                  />
                </div>
                <TextField
                  style={{ whiteSpace: 'pre-wrap' }}
                  id="standard-multiline-flexible2"
                  label="Client SMS Content"
                  multiline
                  fullWidth={true}
                  variant="standard"
                  value={notificationData && notificationData.content ? notificationData.content : ''}
                  onChange={(e) => {
                    const newData = clone(notificationData)
                    if (e.target.value.length < 1555) {
                      newData.content = e.target.value;
                      this.setState({
                        notificationData: newData
                      })
                    }
                  }}
                />
                <hr />
                <Button color="primary" className="mt-20"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    const newData = this.state.notificationData;

                    let messageDifferent = true;
                    for (let i = 0; i < smsConfigData.length; i++) {
                      const smsDescription = smsConfigData[i].description.toLowerCase();
                      const newDescription = newData.description.toLowerCase();
                      if (smsConfigData[i].id !== newData.id) {
                        if (smsDescription == newDescription) {
                          this.setState({
                            openAlert: true
                          })
                          messageDifferent = false;
                        }
                      }
                    }

                    if (messageDifferent && newData.description && newData.content) {
                      this.props.updateSmsConfig({
                        id: newData.id,
                        content: newData
                      })
                      this.setState({
                        openedModal: false,
                        openAlert: false
                      });
                    }
                  }}>UPDATE
                </Button>
                <Button
                  color="white"
                  onClick={() => {
                    this.setState({
                      openedModal: false,
                      openAlert: false
                    });
                  }}
                >
                  CANCEL
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Dialog>

        <Dialog
          open={this.state.openedAdd}
          onClose={() => {
            this.setState({
              openedAdd: false
            });
          }}
          maxWidth="md"
          fullWidth={true}
        >
          <div style={smsPermitted ? {} : { display: 'none' }}>
            <h3 style={{ paddingLeft: '25px' }}><strong>SMS Template</strong></h3>
            {openAlert && <h5 style={{ color: 'red', paddingLeft: '25px' }}>This Description is already being used. Please rename.</h5>}
            <GridContainer style={{ padding: '25px' }}>
              <GridItem xs={12} sm={12} md={12}>
                <div style={{ paddingBottom: '25px' }}>
                  <CustomInput
                    label="Description"
                    value={notificationData && notificationData.description ? notificationData.description : ''}
                    onChange={(e, n) => {
                      const newData = clone(notificationData)
                      newData.description = e.target.value;
                      this.setState({
                        notificationData: newData
                      })
                    }}
                  />
                </div>
                <TextField
                  style={{ paddingBottom: '25px' }}
                  id="standard-multiline-flexible2"
                  label="Client SMS Content"
                  multiline
                  fullWidth={true}
                  variant="standard"
                  value={notificationData && notificationData.content ? notificationData.content : ''}
                  onChange={(e) => {
                    const newData = clone(notificationData)
                    if (e.target.value.length < 1555) {
                      newData.content = e.target.value;
                      this.setState({
                        notificationData: newData
                      })
                    }
                  }}
                />
                <hr />
                <Button color="primary" className="mt-20"
                  disabled={notificationData && notificationData.description && notificationData.content ? false : true}
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    const newData = this.state.notificationData;
                    let messageDifferent = true;
                    for (let i = 0; i < smsNotificationData.length; i++) {
                      const smsDescription = smsNotificationData[i].description.toLowerCase();
                      const newDescription = newData.description.toLowerCase();
                      if (smsNotificationData[i].id !== newData.id) {
                        if (smsDescription == newDescription) {
                          this.setState({
                            openAlert: true
                          })
                          messageDifferent = false;
                        }
                      }
                    }
                    if (messageDifferent && newData.description && newData.content) {
                      this.props.createSmsConfig(newData);
                      this.setState({
                        openedAdd: false,
                        openAlert: false
                      });
                    }
                  }}><i className="fas fa-plus"></i>Add
                </Button>
                <Button
                  color="white"
                  onClick={() => {
                    this.setState({
                      openedAdd: false,
                      openAlert: false
                    });
                  }}
                >
                  CANCEL
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Dialog>

        <Dialog
          open={this.state.openedAskDelete}
          onClose={() => {
            this.setState({
              openedAskDelete: false
            });
          }}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle>
            <div style={{ textAlign: "center" }}>
              Are you sure you want to delete this message?
            </div>
          </DialogTitle>

          <div style={{ textAlign: "center", padding: 20 }}>
            <Button
              color="danger"
              style={{ marginRight: 10 }}
              onClick={() => {
                this.props.deleteSmsConfig(notificationData.id);
                this.setState({
                  openedAskDelete: false,
                });
              }}
            >
              Delete
            </Button>
            <Button
              color="white"
              style={{ marginRight: 10 }}
              onClick={() => {
                this.setState({
                  openedAskDelete: false
                });
              }}
            >
              CANCEL
            </Button>
          </div>
        </Dialog>

        {notification && <Notification
          open={notification ? true : false}
          close={() => {
            this.setState({
              notification: null
            });
            this.props.getSmsConfig();
          }}
          message={<h4>{`${notification}`}</h4>}
        />}

      </div>
    );
  }
}

const mapStateToProps = state => {

  return {
    sms: state.settings.sms,
    error: state.settings.error,
    success: state.settings.success
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSmsConfig: () => {
      dispatch(actions.getSmsConfig());
    },
    updateSmsConfig: (data) => {
      dispatch(actions.updateSmsConfig(data));
    },
    deleteSmsConfig: (id) => {
      dispatch(actions.deleteSmsConfig(id));
    },
    createSmsConfig: (data) => {
      dispatch(actions.createSmsConfig(data));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsAdminPage);
