import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Edit from "@material-ui/icons/Edit";
import Lock from "@material-ui/icons/Lock";
import Close from "@material-ui/icons/Close";
import ReactTable from "react-table";
import withStyles from "@material-ui/core/styles/withStyles";
import SweetAlert from "react-bootstrap-sweetalert";
import { FadeLoader } from 'react-spinners';
import Tooltip from "@material-ui/core/Tooltip";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { css } from 'react-emotion';
import Button from "../../components/CustomButtons/Button.jsx";
import CustomSelect from "../../components/CustomSelect/CustomSelect.jsx";
import UserAdminDialog from "./UserAdminDialog";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";

const override = css`
            display: block;
            margin: 0 auto;
            border-color: red;
        `;

const DialogDelete = (props) => {
  return <Dialog 
    open={props.open}
    onClose={props.onClose}
    maxWidth="sm"
    fullWidth={true}
  >
    <DialogContent>
      <h4><strong>Are you sure?</strong></h4>
      <p>Do you want to remove/deactivate this user ? {props.data.name}</p>
      <DialogActions>
        <Button color="danger" 
          onClick={() => {
            
            props.deleteUser(props.data.id);
            props.onClose();
          }}
        >OK, REMOVE</Button>
        <Button color="white" 
          onClick={props.onClose}
        >CANCEL</Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
}

class UserAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        addUserModal: false,
        alert: null,
        deleteData: null,
        editData: null,
        editType: null
    }
  }
  

  openEdit = (editType, editData) => {
    this.setState({
      editType,
      editData
    })
  }

  closeEdit = () => {
    this.setState({
      editType: null,
      editData: null
    })
  }

  openAlert = (alert) => {
      this.setState({ alert });
  }

  closeAlert = () => {
    this.setState({ alert:null });
  }

  openAddUserModal = () => {
    this.setState({addUserModal:true});
  }

  closeAddUserModal = () => {
    this.setState({addUserModal:false});
  }

  componentDidMount() {
    this.props.checkMaintenanceMode();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success) {
      if (this.props.success === 'Successfully deleted user' && prevProps.success === null) {
        this.openAlert(this.props.success);
        this.props.getAdminUsers();
      }
    }
  }

  render() {
    
    if (!checkPermissions('UserAdmin')) {
      return <Redirect to="/dashboard" />;
    }
    if (this.props.maintenanceMode === 'yes') return <Redirect to="/components/maintenance_page" />

    const loader = <FadeLoader
        className={override}
        sizeUnit={"px"}
        size={150}
        color={'#123abc'}
        loading={true}
    />

    const alert = <SweetAlert
        onConfirm={() => this.closeAlert('')}
        confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
        }
        confirmBtnText="OK"
    >
        {this.state.alert}
    </SweetAlert>

    const table = <ReactTable
                data={
                    this.props.userList.map((user, key) => { // map users to table rows
                        return ({
                            id: key,
                            name: user.name,
                            email: user.email,
                            username: user.username,
                            phone_number: user.phone_number,
                            status: user.active ? 'Active' : 'Inactive',
                            actions: (
                                <div className="actions-right">
                                  <Tooltip
                                    id="tooltip-top-start1"
                                    title="Edit Password"
                                    placement="top"
                                    classes={{ tooltip: this.props.classes.tooltip }}
                                  >
                                    <Button
                                        justIcon
                                        round
                                        simple
                                        onClick={() => {
                                          this.openEdit('Edit Password', user)
                                        }}
                                        color="primary"
                                    >
                                        <Lock />
                                    </Button>
                                  </Tooltip>
                                  <Tooltip
                                    id="tooltip-top-start2"
                                    title="Edit Details"
                                    placement="top"
                                    classes={{ tooltip: this.props.classes.tooltip }}
                                  >
                                    <Button
                                        justIcon
                                        round
                                        simple
                                        onClick={() => {
                                          this.openEdit('Edit Details', user);
                                        }}
                                        color="warning"
                                    >
                                        <Edit />
                                    </Button>
                                  </Tooltip>
                                    {" "}
                                    { /* use this button to remove the data row */}
                                  <Tooltip
                                    id="tooltip-top-start3"
                                    title="Remove"
                                    placement="top"
                                    classes={{ tooltip: this.props.classes.tooltip }}
                                  >
                                    <Button
                                        justIcon
                                        round
                                        simple
                                        onClick={() => {
                                          this.setState({
                                            deleteData: user
                                          })
                                        }}
                                        color="danger"
                                    >
                                        <Close />
                                    </Button>
                                  </Tooltip>
                                </div>
                            )
                        })
                    })
                }
                columns={[
                    {
                        Header: "Name",
                        accessor: "name"
                    },
                    {
                      Header: "Username",
                      accessor: "username"
                  },
                    {
                        Header: "Email",
                        accessor: "email"
                    },
                    {
                      Header: "Phone",
                      accessor: "phone_number"
                    },
                    {
                        Header: "Status",
                        accessor: "status"
                    },
                    {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                    }
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
            />

    return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {this.state.deleteData && <DialogDelete 
          open={this.state.deleteData ? true : false}
          onClose={() => {
            this.setState({
              deleteData: null
            })
          }}
          data={this.state.deleteData}
          deleteUser={(id) => {
            this.props.deleteUser(id);
          }}
          alert={this.openAlert}
        />}
        <UserAdminDialog 
          open={this.state.addUserModal}
          onClose={this.closeAddUserModal}
          action="Add User"
          data={{
            username: '',
            name: '',
            email: '',
            phone_number: '',
            password: '',
            password2: ''
          }}
          reload={this.props.getAdminUsers}
          alert={this.openAlert}
        />
        {this.state.editType && <UserAdminDialog 
          open={this.state.editType ? true : false}
          onClose={this.closeEdit}
          action={this.state.editType}
          data={this.state.editData}
          reload={this.props.getAdminUsers}
          alert={this.openAlert}
        />}
        <Button
            color="rose"
            round
            onClick={() => this.openAddUserModal()}>
            Add User
        </Button>

        {this.props.loading && loader}
        {!this.props.loading && table}
      </GridItem>
      {this.state.alert && alert}
    </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    maintenanceMode: state.userPerms.maintenanceMode,
    userList: state.user.userList ? state.user.userList : [],
    loading: state.user.loading,
    error: state.user.error,
    success: state.user.success
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkMaintenanceMode: () => {
      dispatch(actions.checkMaintenanceMode());
    },
    getAdminUsers: () => dispatch(actions.getAdminUsers()),
    deleteUser: (id) => {
      dispatch(actions.deleteUser(id));
    },
    updateUser: (data) => {
      dispatch(actions.updateUser(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(sweetAlertStyle)(UserAdmin));
