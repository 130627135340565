import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import invariant from 'redux-immutable-state-invariant';

import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import authReducer from './store/reducers/auth';
import userPermsReducer from './store/reducers/userperms';
import settingsReducer from './store/reducers/settings';
import userReducer from './store/reducers/user';
import crmReducer from './store/reducers/crm';
import crmBookingReducer from './store/reducers/crm_booking';
import crmIndemnitorReducer from './store/reducers/crm_indemnitor';
import crmHistoryReducer from './store/reducers/crm_history';
import crmArrestReducer from './store/reducers/crm_arrest';
import clientReducer from './store/reducers/client';
import documentsReducer from './store/reducers/documents';

import { watchAD } from './store/sagas/';
import indexRoutes from "routes/index.jsx";
import "assets/scss/material-dashboard-pro-react.css?v=1.2.0";
import devStatus from 'shared/devStatus';

let composeEnhancers = null || compose;
switch(devStatus) {
  case 'live':
  case 'stage':
    composeEnhancers = null || compose;
    break;
  case 'local_stage':
  case 'local_live':
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ;
    break;
  default:
    composeEnhancers = null || compose;
}

const rootReducer = combineReducers({
  auth: authReducer,
  userPerms: userPermsReducer,
  settings: settingsReducer,
  user: userReducer,
  crm: crmReducer,
  crm_booking: crmBookingReducer,
  crm_indemnitor: crmIndemnitorReducer,
  crm_history: crmHistoryReducer,
  crm_arrest: crmArrestReducer,
  client: clientReducer,
  documents: documentsReducer
})

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(invariant(), sagaMiddleware)
));

sagaMiddleware.run(watchAD);

const hist = createBrowserHistory();
const app = (
  <Router history={hist}>
    <Switch>
      {indexRoutes.map((prop, key) => {
        return <Route path={prop.path} component={prop.component} key={key} />;
      })}
    </Switch>
  </Router>
);
ReactDOM.render(<Provider store={store}>
  {app}
</Provider>,
  document.getElementById("root")
);
