import React, { useEffect, Fragment } from "react";
import GridItem from "components/Grid/GridItem";
import Bookings from "containers/CRM/Bookings/Bookings";

export const DefendantsFormBooking = (props) => {
	const { defendant_id, canEdit, canDelete } = props;
	return (
		<>
			<Bookings 
				defendant_id={defendant_id}
				defendantData={props.defendantData}
				bookingData={props.bookingData}
				setBookingData={props.setBookingData}
				createDefendantIndemnitors={props.createDefendantIndemnitors}
				action={props.action}
				canEdit={canEdit}
				canDelete={canDelete}
			/>
		</>
	);
};
