import React from "react";
import ReactTable from "react-table";
import { formatDate2  } from "shared/utility";

const IndemnitorsList = (props) => {
	const { indemnitorData, setFormData, setAction, setOpenForm, getIndemnitor, setPhotos } = props;

	const dataList = indemnitorData
		? indemnitorData.map((l, key) => {
				// map users to table rows
				const dob = formatDate2(l.date_of_birth);
				return {
					key: key + 1,
					id: l.id,
					date_created: formatDate2(l.date_created),
					date_modified: formatDate2(l.date_modified),
					indemnitor: l.name,
					indemnitorData: l,
					date_of_birth: dob === "Invalid date" ? "" : dob,
					ss: l.ss,
					dl: l.dl,
					phone: l.phone,
					email: l.email,
				};
		  })
		: [];

	const columns =
		props.action === "Client Edit"
			? [
					{
						Header: "#",
						accessor: "key",
					},
					{
						Header: "Indemnitor",
						accessor: "indemnitor",
					},
			  ]
			: [
					{
						Header: "Date Created",
						accessor: "date_created",
					},
					{
						Header: "Indemnitor",
						accessor: "indemnitor",
					},
					{
						Header: "Date of Birth",
						accessor: "date_of_birth",
					},
					{
						Header: "SS#",
						accessor: "ss",
					},
					{
						Header: "DL#",
						accessor: "dl",
					},
					{
						Header: "Phone",
						accessor: "phone",
					},
					{
						Header: "Email",
						accessor: "email",
					},
			  ];

	return (
		<div className="table-adjust2 table-scroll">
			<ReactTable
				filterable
				className="-striped -highlight"
				showPaginationTop
				showPaginationBottom
				resizable={false}
				defaultPageSize={10}
				pageSizeOptions={[10, 20, 30]}
				data={dataList}
				columns={columns}
				getTrProps={(state, rowInfo) => {
					if (rowInfo && rowInfo.row) {
						return {
							onClick: (e) => {
								if (rowInfo) {
									if (rowInfo.original) {
										props.setIndex(rowInfo.index);
										setAction("Edit");
										setPhotos(new FormData());
										if (rowInfo.original.indemnitorData.id) {
											getIndemnitor(rowInfo.original.indemnitorData.id);
										} else {
											setFormData(rowInfo.original.indemnitorData);
										}
										setOpenForm(true);
									}
								}
							},
						};
					} else {
						return {};
					}
				}}
			/>
		</div>
	);
};

export default IndemnitorsList;
