import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

let initialState = {
  sms: [],
  error: null,
  success: null
}

// GET_SMS_CONFIG
const getSmsConfigStart = (state, action) => {
  return updateObject(state, {
    error: null,
    success: null
  })
}
const getSmsConfigSuccess = (state, action) => {
  return updateObject(state, {
    success: '',
    sms: action.response
  })
}
const getSmsConfigFail = (state, action) => {
  return updateObject(state, {
    error: action.err
  })
}

// UPDATE_SMS_CONFIG
const updateSmsConfigStart = (state, action) => {
  return updateObject(state, {
    error: null,
    success: null
  })
}
const updateSmsConfigSuccess = (state, action) => {
  return updateObject(state, {
    success: 'Successfully Updated SMS Config'
  })
}
const updateSmsConfigFail = (state, action) => {
  return updateObject(state, {
    error: action.err
  })
}

// DELETE_SMS_CONFIG
const deleteSmsConfigStart = (state, action) => {
  return updateObject(state, {
    error: null,
    success: null
  })
}
const deleteSmsConfigSuccess = (state, action) => {
  return updateObject(state, {
    success: 'Successfully Deleted SMS Config'
  })
}
const deleteSmsConfigFail = (state, action) => {
  return updateObject(state, {
    error: action.err
  })
}

// CREATE_SMS_CONFIG
const createSmsConfigStart = (state, action) => {
  return updateObject(state, {
    error: null,
    success: null
  })
}
const createSmsConfigSuccess = (state, action) => {
  return updateObject(state, {
    success: 'Successfully Created SMS Config'
  })
}
const createSmsConfigFail = (state, action) => {
  return updateObject(state, {
    error: action.err
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SMS_CONFIG_START:
      return getSmsConfigStart(state, action);
    case actionTypes.GET_SMS_CONFIG_SUCCESS:
      return getSmsConfigSuccess(state, action);
    case actionTypes.GET_SMS_CONFIG_FAIL:
      return getSmsConfigFail(state, action);

    case actionTypes.UPDATE_SMS_CONFIG_START:
      return updateSmsConfigStart(state, action);
    case actionTypes.UPDATE_SMS_CONFIG_SUCCESS:
      return updateSmsConfigSuccess(state, action);
    case actionTypes.UPDATE_SMS_CONFIG_FAIL:
      return updateSmsConfigFail(state, action);

    case actionTypes.DELETE_SMS_CONFIG_START:
      return deleteSmsConfigStart(state, action);
    case actionTypes.DELETE_SMS_CONFIG_SUCCESS:
      return deleteSmsConfigSuccess(state, action);
    case actionTypes.DELETE_SMS_CONFIG_FAIL:
      return deleteSmsConfigFail(state, action);

    case actionTypes.CREATE_SMS_CONFIG_START:
      return createSmsConfigStart(state, action);
    case actionTypes.CREATE_SMS_CONFIG_SUCCESS:
      return createSmsConfigSuccess(state, action);
    case actionTypes.CREATE_SMS_CONFIG_FAIL:
      return createSmsConfigFail(state, action);

    default: return state;
  }
};

export default reducer;
