import { put } from 'redux-saga/effects';
import axios from '../axios/axios-user';

import * as actions from '../actions/user';

export function* loginUserSaga(action) {
    try {
        const response = yield axios.post('login', {
            username: action.user.username,
            password: action.user.password
        });
        yield put(actions.loginUserSuccess(response.data));
    } catch (err) {
        
        yield put(actions.loginUserFail(err));
    }
}

export function* logoutUserFailSaga(action) {

}
